import React, { useState, useRef, useEffect } from "react";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Inject,
  Page,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Search,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { db, auth } from "../../firebase";
import {
  getDoc,
  doc,
  collection,
  where,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const PartnerCitizenSearch = () => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(true); // NEW: Loading state
  const gridRef = useRef(null);

  // Monitor authentication status
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
      setLoading(false); // End loading after auth state is checked
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const mapCitizenData = (citizens) => {
    return citizens.map((citizen) => ({
      name: `${citizen.first_name} ${citizen.last_name}`,
      address: `${citizen.address1}, ${citizen.address2}, ${citizen.city}, ${citizen.state}, ${citizen.zip}`,
      mobile_number: citizen.mobile_number,
      email: citizen.email,
      citizen_id: citizen.citizen_id,
    }));
  };

  const fetchData = async () => {
    try {
      const docRef = doc(db, "users", userId);
      const docc = await getDoc(docRef);
      console.log("Doc:", docc.data());

      if (docc.exists()) {
        setData({ id: docc.id, ...docc.data() });
        const centerIds = docc.data().center_id;
        console.log("Center IDs:", centerIds);
        const usersRef = collection(db, "users");
        const gridData = [];

        for (const centerId of centerIds) {
          const queryRef = query(
            usersRef,
            where("center_id", "==", centerId),
            where("user_role", "==", "citizen")
          );
          const querySnapshot = await getDocs(queryRef);
          const citizens = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          const mappedCitizens = mapCitizenData(citizens);
          gridData.push(...mappedCitizens);
        }

        console.log(gridData);
        setGridData(gridData);
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const navigate = useNavigate();

  const handleViewAssistanceLog = async (args) => {
    console.log("handleViewAssistanceLog called with args:", args);
    try {
      if (loading) {
        console.error("Loading in progress, please wait...");
        return;
      }
  
      if (!userId) {
        console.error("User ID is not set. Cannot update document.");
        return;
      }
  
      if (!gridRef.current) {
        console.error("Grid reference is not available.");
        return;
      }
  
      // Get the row data directly from the args parameter
      const rowData = args.rowData;
      console.log("Row data:", rowData);
  
      if (!rowData) {
        console.error("No row data available.");
        return;
      }
  
      const { citizen_id } = rowData;
      console.log("Extracted citizen_id:", citizen_id);
  
      if (!citizen_id || typeof citizen_id !== 'string') {
        console.error("Invalid citizen_id: ", citizen_id);
        return;
      }
  
      console.log("Selected Citizen ID: ", citizen_id);
  
      const userDocRef = doc(db, "users", userId);
      console.log("User document reference:", userDocRef);
  
      try {
        await updateDoc(userDocRef, { accessed_userId: citizen_id });
        console.log(`Field 'accessed_userId' successfully updated with value: ${citizen_id}`);
        
        // Verify the update
        const updatedDoc = await getDoc(userDocRef);
        console.log("Updated document data:", updatedDoc.data());
        
        navigate("/partnerassistancelog");
      } catch (error) {
        console.error("Error updating 'accessed_userId': ", error);
      }
  
    } catch (error) {
      console.error("Error in handleViewAssistanceLog: ", error);
    }
  };

  const sortSettings = {
    allowSorting: true,
  };

  const filterSettings = {
    type: "CheckBox",
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };
  const [isGridVisible, setIsGridVisible] = useState(true);
  const toggleGrid = (event) => {
    event.preventDefault();
    setIsGridVisible(!isGridVisible);
  };

  const handleCommandClick = (args) => {
    if (args.commandColumn.type === "Access") {
      const citizenData = args.rowData;
      localStorage.setItem("accessAccountEmail", citizenData.email);
      localStorage.setItem("orgUserId", userId);
      localStorage.setItem("orgType", "partner");
      window.open(window.location.origin + "/accessaccount", '_blank');
    } else if (args.commandColumn.type === "View") {
      handleViewAssistanceLog(args);
    }
  };

  return (
    <div>
      <section className="box-border flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch px-0 pb-[5px] pt-0 text-left text-xs text-dodgerblue">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1 text-sm font-bold text-blue-500">
              Citizen Search
            </h2>
          </div>

          <div className="flex items-center gap-2">
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleGrid}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isGridVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        <div className="relative font-poppins font-medium leading-[21px] text-darkslategray-200">
          Partners only have access to citizens that have authorized their
          information to be shared with a partner.
        </div>

        {isGridVisible && !loading && ( // Show the grid only when it's visible and not loading
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              ref={gridRef}
              dataSource={gridData}
              allowSorting={true}
              sortSettings={sortSettings}
              filterSettings={filterSettings}
              allowFiltering={true}
              toolbar={["Search"]}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
              commandClick={handleCommandClick}
              allowSelection={true}
              selectionSettings={{ type: "Single" }}
            >
              <ColumnsDirective>
                <ColumnDirective field="citizen_id" headerText="Citizen ID" width="200" />
                <ColumnDirective field="name" headerText="Name" width="200" />
                <ColumnDirective field="address" headerText="Address" />
                <ColumnDirective
                  field="mobile_number"
                  headerText="Mobile Number"
                  width="200"
                />
                <ColumnDirective
                  field="email"
                  headerText="Email Address"
                  width="200"
                />
                <ColumnDirective field="accessed_userId" visible={false} />
                <ColumnDirective
                  headerText="Actions"
                  textAlign="Center"
                  commands={[
                    {
                      type: "View",
                      buttonOption: {
                        content: "View Assistance Log",
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Access",
                      buttonOption: {
                        content: "Access Account",
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[
                  Page,
                  Edit,
                  Toolbar,
                  Sort,
                  Filter,
                  Search,
                  ColumnMenu,
                ]}
              />
            </GridComponent>
          </div>
        )}
      </section>
    </div>
  );
};

export default PartnerCitizenSearch;