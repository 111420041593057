import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { db, auth } from "../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import Menu from "../pages/Profile-Popup/Menu";
import { useAuth } from "./AuthContext";
import ContactSupport from "../pages/Profile-Popup/ContactSupport";

const Navbar = () => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const { role } = useAuth();

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
      console.log("Rolee: " + role);
    }
  }, [userId]);

  const fetchData = async () => {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        if (userData.user_role === "center") {
          console.log("Center ID: " + userData.center_id);
          const centerDocRef = doc(db, "centers", userData.center_id);
          const centerDocSnap = await getDoc(centerDocRef);
          if (centerDocSnap.exists()) {
            setData({ id: centerDocSnap.id, ...centerDocSnap.data() });
          }
        } else if (userData.user_role === "partner") {
          const partnerDocRef = doc(db, "partners", userData.partner_id);
          const partnerDocSnap = await getDoc(partnerDocRef);
          if (partnerDocSnap.exists()) {
            setData({ id: partnerDocSnap.id, ...partnerDocSnap.data() });
          }
        } else {
          setData({ id: userDocSnap.id, ...userDocSnap.data() });
        }
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const [showSupport, setShowSupport] = useState(false);
  
  const toggleSupport= () => {
    setShowSupport(!showSupport);
  };


  return (
    <nav className={`h-[6vh] border-none bg-dodgerblue text-white shadow-none`}>
      <div
        className={`fixed left-0 right-0 top-0 z-10 flex h-[6vh] w-full flex-row items-center justify-between bg-dodgerblue text-left font-poppins text-base leading-[normal] tracking-[normal] text-white shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)]`}
      >
        {/* Blue Sky Logo */}
        <div className="flex h-[6vh] flex-row items-center justify-center gap-[11px] pl-7">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="41"
            height="44"
            viewBox="0 0 41 44"
            fill="none"
            className="max-h-[4vh]"
          >
            <path
              d="M34.7906 29.8727C34.7537 29.9353 34.7156 30.0002 34.6775 30.0651C31.8621 34.8843 27.6715 39.6085 21.4472 43.2976H21.3901C21.3721 43.3092 21.3553 43.3197 21.3363 43.3312H21.3878L20.2041 44L19.0215 43.3312L18.9677 43.3011L18.9621 43.2976C14.3292 40.4696 10.8206 37.1606 8.16532 33.7195C8.14068 33.6882 8.11828 33.6557 8.09364 33.6244C0.54113 23.7692 -0.0456894 12.8478 0.00246576 9.06124V9.05777C0.0136646 8.17343 0.0606999 7.67853 0.0606999 7.67853L0.460499 7.2636C1.0014 6.70147 1.58934 6.16832 2.21872 5.66531C2.23216 5.65603 2.24448 5.64676 2.25792 5.63517C2.3867 5.53665 2.51885 5.43698 2.65772 5.33267C2.67339 5.32223 2.68795 5.31064 2.70475 5.2979C2.71371 5.2921 2.72491 5.28399 2.73387 5.27587C3.41924 4.76011 4.14941 4.27911 4.92213 3.83869C4.98372 3.80391 5.04531 3.76798 5.10803 3.73205C5.18418 3.68917 5.26257 3.64513 5.33984 3.60109C5.37232 3.5837 5.40256 3.56631 5.43392 3.54893C7.75096 2.29023 10.4029 1.34215 13.2507 0.754524C13.9137 0.621237 14.5643 0.507652 15.2038 0.411453C15.3192 0.394068 15.4334 0.379001 15.5476 0.362774C17.1703 0.134447 18.7135 0.0197034 20.1772 0C21.6398 0.0197034 23.1841 0.134447 24.8068 0.362774C24.9211 0.379001 25.0353 0.394068 25.1495 0.411453C25.7901 0.507652 26.4407 0.621237 27.1037 0.754524C29.9516 1.34215 32.6035 2.29023 34.9194 3.54893C34.9519 3.56516 34.9832 3.58254 35.0146 3.60109C35.0919 3.64513 35.1691 3.68917 35.2453 3.73205C35.308 3.76798 35.3707 3.80391 35.4312 3.83869C36.0012 4.17364 36.5208 4.50628 36.9912 4.82965C37.0483 4.86906 37.1054 4.90846 37.1614 4.94787C37.3216 5.0603 37.4739 5.16924 37.6206 5.27587C37.6295 5.28399 37.6396 5.2921 37.6486 5.2979C37.6654 5.31064 37.681 5.32223 37.6967 5.33267C37.8345 5.43698 37.9677 5.53665 38.0954 5.63517C38.1088 5.6456 38.1212 5.65487 38.1346 5.66531C38.764 6.16832 39.353 6.70147 39.8939 7.2636L40.2926 7.67853C40.2926 7.67853 40.3408 8.17343 40.3509 9.05777V9.06124C40.3934 12.3714 39.9499 21.1348 34.7906 29.8727Z"
              fill="white"
            />
            <path
              d="M13.6113 12.5137L15.9426 13.2914L14.3876 14.8494L13.6113 12.5137Z"
              fill="#193861"
            />
            <path
              d="M26.7336 12.5137L25.9561 14.8494L24.4023 13.2914L26.7336 12.5137Z"
              fill="#193861"
            />
            <path
              d="M26.7336 25.8574L24.4023 25.0784L25.9561 23.5205L26.7336 25.8574Z"
              fill="#193861"
            />
            <path
              d="M13.6113 25.8574L14.3876 23.5205L15.9426 25.0784L13.6113 25.8574Z"
              fill="#193861"
            />
            <path
              d="M7.25279 17.9641C6.73645 15.5137 6.59097 13.3417 6.58143 11.7599C9.40401 9.13509 13.9318 7.50189 18.9569 7.2737L19.1513 5.08496C13.2843 5.29404 7.948 7.29282 4.72594 10.5269L4.41232 10.8412L4.40755 11.2856C4.39324 12.6679 4.48864 15.1266 5.09919 18.0836L7.25279 17.9641Z"
              fill="#348BFF"
            />
            <path
              d="M21.3897 7.2737C26.4136 7.50189 30.9414 9.13509 33.7652 11.7599C33.7556 13.3417 33.6113 15.5137 33.0938 17.9641L35.2474 18.0836C35.8579 15.1266 35.9533 12.6679 35.939 11.2856L35.9343 10.8412L35.6206 10.5269C32.3986 7.29401 27.0611 5.29404 21.1953 5.08496L21.3897 7.2737Z"
              fill="#348BFF"
            />
            <path
              d="M32.5016 20.302C31.0146 25.3103 27.8569 31.0115 21.2828 35.205L21.1016 37.849C29.4548 33.0736 33.1431 25.9961 34.7434 20.1777L32.5016 20.302Z"
              fill="#348BFF"
            />
            <path
              d="M19.0661 35.2038C12.492 31.0115 9.33433 25.3103 7.84732 20.302L5.60547 20.1777C7.20696 25.9973 10.8941 33.0736 19.2473 37.849L19.0661 35.205V35.2038Z"
              fill="#348BFF"
            />
            <path
              d="M21.8747 12.7175C24.2215 13.3435 26.0555 15.2276 26.6124 17.6051L27.434 17.6505C26.8592 14.7891 24.6341 12.5239 21.8008 11.8848L21.8747 12.7175Z"
              fill="#FDB841"
            />
            <path
              d="M13.7357 17.6051C14.2926 15.2276 16.1266 13.3435 18.4734 12.7175L18.5473 11.8848C15.714 12.5239 13.49 14.7891 12.9141 17.6505L13.7357 17.6051Z"
              fill="#FDB841"
            />
            <path
              d="M18.3959 25.4957C16.086 24.8494 14.2866 22.9796 13.7357 20.6284L12.9141 20.583C13.4829 23.4121 15.6651 25.6558 18.4531 26.3237L18.3959 25.4957Z"
              fill="#FDB841"
            />
            <path
              d="M26.61 20.6284C26.0591 22.9796 24.2596 24.8494 21.9498 25.4957L21.8926 26.3237C24.6794 25.6558 26.8616 23.4109 27.4304 20.583L26.6088 20.6284H26.61Z"
              fill="#FDB841"
            />
            <path
              d="M18.0687 17.3675L18.2977 14.7773H18.2869H15.6504V16.6387V17.5025L18.0687 17.3675Z"
              fill="#348BFF"
            />
            <path
              d="M22.2901 17.3691L25.6469 17.5555C25.6099 17.4444 25.5729 17.3345 25.5276 17.2293C25.2903 16.6881 24.9385 16.2341 24.4699 15.8709C24.0013 15.5065 23.4146 15.2341 22.7098 15.0513C22.5083 14.9988 22.2948 14.957 22.0742 14.9199L22.2912 17.3691H22.2901Z"
              fill="#348BFF"
            />
            <path
              d="M18.0854 20.8762L15.6504 20.7412V21.6038V23.4652H18.2631L18.0854 20.8762Z"
              fill="#348BFF"
            />
            <path
              d="M22.2717 20.8749L22.1035 23.3169C22.3122 23.281 22.5149 23.2392 22.7069 23.1902C23.4117 23.0086 23.9995 22.735 24.467 22.3718C24.9356 22.0075 25.2874 21.5547 25.5247 21.0134C25.57 20.9095 25.607 20.7984 25.644 20.6885L22.2705 20.876L22.2717 20.8749Z"
              fill="#348BFF"
            />
            <path
              d="M21.7242 21.3024L20.1728 19.6357L18.6262 21.3036C18.613 21.3167 18.5975 21.3227 18.582 21.3334L20.1752 39.884L21.7683 21.3323C21.754 21.3215 21.7373 21.3155 21.7242 21.3024Z"
              fill="#193861"
            />
            <path
              d="M18.6264 16.6173L20.1778 18.2839L21.7244 16.6161C21.7244 16.6161 21.734 16.6113 21.7376 16.6065L20.1754 2.55176L18.6133 16.6065C18.6133 16.6065 18.6228 16.6125 18.6276 16.6161L18.6264 16.6173Z"
              fill="#193861"
            />
            <path
              d="M22.2623 17.6445L20.8516 18.9623L22.5163 20.5119C22.5318 20.5274 22.5401 20.5465 22.552 20.5644L38.6921 19.1176L22.2623 17.6445Z"
              fill="#193861"
            />
            <path
              d="M17.8372 20.5099L19.5007 18.9556L18.0912 17.6426L1.66016 19.1157L17.8015 20.5625C17.8134 20.5446 17.8217 20.5243 17.8372 20.5087V20.5099Z"
              fill="#193861"
            />
          </svg>
          <div className="flex flex-col items-start font-semibold text-[inherit]">
            <div className="flex flex-col items-baseline text-white">
              <p className="text-lg ">DARCI</p>
              <p className="text-[8px] font-normal -my-1 text-white">
                Powered by BlueSky
              </p>
            </div>
          </div>
        </div>

        {/* User Nav */}
        <div className="flex max-h-[6vh] flex-col items-center justify-center pr-7 text-right text-xs text-honeydew">
          <div className="flex flex-row items-center justify-center gap-[14px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="26"
              viewBox="0 0 25 26"
              fill="none"
              onClick={toggleSupport}
            >
              <rect y="0.5" width="25" height="25" rx="6" fill="white" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.25 10.5208C7.25 9.05658 7.80312 7.65235 8.78769 6.617C9.77226 5.58165 11.1076 5 12.5 5C13.8924 5 15.2277 5.58165 16.2123 6.617C17.1969 7.65235 17.75 9.05658 17.75 10.5208V11.3371C19.022 11.5287 20 12.6794 20 14.0698V14.267C19.9999 14.609 19.9347 14.9475 19.8083 15.2626C19.6819 15.5777 19.4968 15.863 19.2639 16.1017C19.0311 16.3404 18.7551 16.5277 18.4523 16.6526C18.1496 16.7774 17.8261 16.8373 17.501 16.8287C16.946 18.5007 15.5345 19.6277 14.0113 19.9132C13.6588 20.0489 13.2417 19.985 12.875 19.985C12.5766 19.985 12.2905 19.8603 12.0795 19.6385C11.8685 19.4166 11.75 19.1157 11.75 18.8019C11.75 18.4882 11.8685 18.1873 12.0795 17.9654C12.2905 17.7436 12.5766 17.6189 12.875 17.6189H13.625C13.8011 17.6189 13.9748 17.6623 14.132 17.7457C14.2892 17.8292 14.4256 17.9502 14.5302 18.0992C15.5022 17.6126 16.25 16.585 16.25 15.2529V10.5208C16.25 9.47492 15.8549 8.4719 15.1517 7.73236C14.4484 6.99283 13.4946 6.57737 12.5 6.57737C11.5054 6.57737 10.5516 6.99283 9.84835 7.73236C9.14509 8.4719 8.75 9.47492 8.75 10.5208V15.45C8.75 15.8161 8.61172 16.1672 8.36558 16.426C8.11944 16.6848 7.7856 16.8302 7.4375 16.8302C6.79103 16.8302 6.17105 16.5602 5.71393 16.0795C5.25681 15.5988 5 14.9468 5 14.267V14.0698C4.99984 13.4059 5.22722 12.7642 5.64047 12.2623C6.05371 11.7604 6.62514 11.4319 7.25 11.3371V10.5208ZM7.25 12.9539C7.03057 13.0354 6.8406 13.1866 6.70627 13.3865C6.57195 13.5863 6.49988 13.8251 6.5 14.0698V14.267C6.5 14.7442 6.8225 15.1425 7.25 15.2332V12.9539ZM17.75 12.9539V15.2332C18.1775 15.1417 18.5 14.7442 18.5 14.267V14.0698C18.5001 13.8251 18.4281 13.5863 18.2937 13.3865C18.1594 13.1866 17.9694 13.0354 17.75 12.9539Z"
                fill="#348BFF"
              />
            </svg>
            <div className="flex flex-col items-end justify-start">
              <p
                className="relative inline-block min-w-[86px] cursor-pointer font-semibold text-[inherit] [text-decoration:none]"
                onClick={toggleMenu}
              >
                {data.first_name
                  ? data.first_name
                  : // eslint-disable-next-line no-useless-concat
                  "First Name" + " " + data.last_name
                  ? data.last_name
                  : "Last Name"}
              </p>
              <p className="relative inline-block min-w-[40px] font-light text-white [text-decoration:none]">
                {role === "admin"
                  ? "Admin"
                  : role === "citizen"
                  ? "Citizen"
                  : role === "center"
                  ? "Center"
                  : "Partner"}
              </p>
            </div>
            <div
              className="box-border flex w-[25px] cursor-pointer flex-col items-start justify-start px-0 pb-0  text-center text-base text-dodgerblue"
              onClick={toggleMenu}
            >
              <div className="flex flex-row items-start justify-start self-stretch rounded-md bg-white">
                <div className="relative hidden h-[25px] w-[25px] rounded-md bg-white" />
                <p className="relative z-[1] flex-1 font-medium text-[inherit] [text-decoration:none]">
                  {data.first_name
                    ? data.first_name.charAt(0)
                    : role === "partner"
                    ? "P"
                    : role === "center"
                    ? "C"
                    : role === "admin"
                    ? "A"
                    : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Menu isOpen={showMenu} onClose={toggleMenu} data={data} />
      <ContactSupport isOpen={showSupport} onClose={toggleSupport} data={data} />
    </nav>
  );
};

export default Navbar;
