import React, { useState, useEffect, useRef } from "react";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import ContactProfilePopup from "./Communication/ContactProfilePopup";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-richtexteditor/styles/material.css";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
  doc,
  addDoc,
  serverTimestamp,
  orderBy,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import CreateGroupChatModal from "../components/CreateGroupChatModal";

const Communication = ({ className = "" }) => {
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [messages, setMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentChatId, setCurrentChatId] = useState("");
  const [chats, setChats] = useState([]);
  const [editorContent, setEditorContent] = useState("");
  const [isCreateGroupChatModalOpen, setIsCreateGroupChatModalOpen] =
    useState(false);

  const handleContactClick = () => {
    setShowProfilePopup(true);
  };

  const handleEditorChange = (args) => {
    setEditorContent(args.value);
  };

  const handleSend = () => {
    if (editorContent.trim() !== "") {
      sendMessage(currentChatId, auth.currentUser.uid, editorContent);
      setEditorContent("");
    }
  };

  // Function to strip HTML tags for preview in contacts list
  const stripHtml = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      "Image",
      "|",
      "|",
      "Undo",
      "Redo",
    ],
  };

  const quickToolbarSettings = {
    image: [
      "Replace",
      "Align",
      "Caption",
      "Remove",
      "InsertLink",
      "OpenImageLink",
      "-",
      "EditImageLink",
      "RemoveImageLink",
      "Display",
      "AltText",
      "Dimension",
    ],
  };

  const ContactAvatar = ({ contact }) => {
    if (contact.image) {
      return (
        <img
          src={contact.image}
          alt={`${contact.first_name}'s profile`}
          className="mr-4 h-12 w-12 flex-shrink-0 rounded-full object-cover"
        />
      );
    } else {
      const initials = (contact.first_name + " " + contact.last_name)
        .split(" ")
        .map((name) => name[0])
        .join("")
        .toUpperCase()
        .slice(0, 2);

      return (
        <div className="text-lg mr-4 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-500 font-semibold text-white">
          {initials}
        </div>
      );
    }
  };

  useEffect(() => {
    const fetchChats = () => {
      if (auth && auth.currentUser && auth.currentUser.uid) {
        const chatsRef = collection(db, "chats");
        const q = query(
          chatsRef,
          where("users", "array-contains", auth.currentUser.uid)
        );

        // Set up a real-time listener
        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
          const chats = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const contacts = [];

          for (const chat of chats) {
            if (chat.groupName) {
              const groupChatUser = {
                id: chat.id,
                first_name: chat.groupName,
                last_name: "",
                email: chat.groupName,
                groupName: chat.groupName,
                selectedUsers: chat.users,
              };
              contacts.push(groupChatUser);
            } else {
              let otherUserId = chat.users.find(
                (uid) => uid !== auth.currentUser.uid
              );
              if (!otherUserId) {
                otherUserId = auth.currentUser.uid;
              }
              const userDocRef = doc(db, "users", otherUserId);
              const userDoc = await getDoc(userDocRef);

              if (userDoc.exists()) {
                contacts.push({ id: userDoc.id, ...userDoc.data() });
              }
            }
          }

          setChats(chats);
          setContacts(contacts);
        });

        return () => unsubscribe();
      }
    };

    fetchChats();
  }, [auth.currentUser]);

  const searchUsers = async (term) => {
    try {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "==", term));

      const querySnapshot = await getDocs(q);
      const users = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return users[0];
    } catch (error) {
      console.error("Error searching for users:", error);
      return null;
    }
  };

  const initializeChat = async (currentUser, otherUser) => {
    try {
      const chatId = [currentUser.uid, otherUser.id].sort().join("_");
      setCurrentChatId(chatId);

      const chatsRef = collection(db, "chats");
      const chatDocRef = doc(chatsRef, chatId);
      const chatDoc = await getDoc(chatDocRef);

      let chat = null;

      if (chatDoc.exists()) {
        chat = { id: chatDoc.id, ...chatDoc.data() };
      } else {
        const newChat = {
          users: [currentUser.uid, otherUser.id],
          createdAt: serverTimestamp(),
        };
        await setDoc(chatDocRef, newChat);
        chat = { id: chatDocRef.id, ...newChat };
      }

      return chat;
    } catch (error) {
      console.error("Error initializing chat:", error);
      return null;
    }
  };

  const sendMessage = async (chatId, senderId, messageContent) => {
    try {
      const messagesRef = collection(db, "chats", chatId, "messages");
      const newMessage = {
        senderId,
        message: messageContent,
        timestamp: serverTimestamp(),
      };
      await addDoc(messagesRef, newMessage);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  useEffect(() => {
    if (!currentChatId) return;

    const messagesRef = collection(db, "chats", currentChatId, "messages");
    const q = query(messagesRef, orderBy("timestamp", "asc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const msgs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(msgs);
    });

    return () => unsubscribe();
  }, [currentChatId]);

  const onSearchClick = async () => {
    const user = await searchUsers(searchTerm);

    if (user) {
      const chat = await initializeChat(auth.currentUser, user);
      const chatExists = chats.some(
        (existingChat) => existingChat.id === chat.id
      );
      const contactExists = contacts.some(
        (existingContact) => existingContact.id === user.id
      );

      if (!chatExists) {
        setChats((prevChats) => [...prevChats, chat]);
      }

      if (!contactExists) {
        setContacts((prevContacts) => [...prevContacts, user]);
      }

      if (chat) {
        selectContact(user);
      }
    } else {
      alert("User not found!");
    }
  };

  const selectContact = async (contact) => {
    try {
      let chat;

      if (contact.groupName) {
        setSelectedContact(contact);
        chat = await initializeGroupChat(
          auth.currentUser,
          contact.selectedUsers,
          contact.groupName
        );
      } else {
        setSelectedContact(contact);
        chat = await initializeChat(auth.currentUser, contact);
      }
      if (chat) {
        setCurrentChatId(chat.id);
      }
    } catch (error) {
      console.error("Error selecting contact:", error);
    }
  };

  const convertTimestampToDate = (timestamp) => {
    if (timestamp instanceof Timestamp) {
      const date = timestamp.toDate();
      const formattedDate = date.toLocaleString();
      return formattedDate;
    } else {
      return "";
    }
  };

  const now = new Date().toLocaleString();

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const initializeGroupChat = async (currentUser, otherUsers, groupName) => {
    try {
      const chatsRef = collection(db, "chats");

      // Check if a group chat with the same name already exists
      const q = query(chatsRef, where("groupName", "==", groupName));
      const querySnapshot = await getDocs(q);

      let chat = null;

      if (!querySnapshot.empty) {
        // Group chat with the same name already exists
        const existingChatDoc = querySnapshot.docs[0]; // Assuming you take the first match if there are multiple
        chat = { id: existingChatDoc.id, ...existingChatDoc.data() };
      } else {
        // Create a new group chat if it doesn't exist
        const chatDocRef = doc(chatsRef); // Generates a random ID
        const chatId = chatDocRef.id;
        const newChat = {
          groupName: groupName,
          users: [currentUser.uid, ...otherUsers.map((user) => user.id)],
          createdAt: serverTimestamp(),
        };
        await setDoc(chatDocRef, newChat);
        chat = { id: chatDocRef.id, ...newChat };
      }

      setCurrentChatId(chat.id);
      return chat;
    } catch (error) {
      console.error("Error initializing group chat:", error);
      return null;
    }
  };

  const handleCreateGroup = async (groupName, selectedUsers) => {
    const chat = initializeGroupChat(
      auth.currentUser,
      selectedUsers,
      groupName
    );
    const chatExists = chats.some(
      (existingChat) => existingChat.id === chat.id
    );
    const contactExists = contacts.some(
      (existingContact) => existingContact.id === chat.id
    );
    let groupChatUser = {};

    if (!chatExists) {
      setChats((prevChats) => [...prevChats, chat]);
    }

    if (!contactExists) {
      groupChatUser = {
        id: chat.id,
        first_name: groupName,
        last_name: "",
        email: groupName,
        groupName: groupName,
        selectedUsers: selectedUsers,
      };
      setContacts((prevContacts) => [...prevContacts, groupChatUser]);
    }

    if (chat) {
      setSelectedContact(groupChatUser);

      if (chat) {
        setCurrentChatId(chat.id);
      }
    }
  };

  const searchUsersByEmail = async (email) => {
    try {
      const user = await searchUsers(email);

      return [user].filter((user) => user.email.includes(email));
    } catch (err) {
      alert("User not found.");
    }
  };

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <CreateGroupChatModal
        isOpen={isCreateGroupChatModalOpen}
        onClose={() => setIsCreateGroupChatModalOpen(false)}
        onCreateGroup={handleCreateGroup}
        searchUsersByEmail={searchUsersByEmail}
      />
      <div id="dialog-container"></div>
      <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
          <a className="relative inline-block min-w-[100px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Communication
          </a>
      </div>

      <section className="flex max-w-full flex-row items-start justify-start gap-[16px] self-stretch text-left text-sm text-darkslateblue">
        {/* Sidebar */}
        <div className="bg-gray-100 w-1/3 rounded-lg p-4">
          <input
            type="text"
            placeholder="Search email..."
            className="mb-4 w-full rounded border p-2"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="mb-4 flex flex-wrap items-center justify-between">
            <select className="rounded border p-2">
              <option>All Messages</option>
              <option>Unread Messages</option>
            </select>
            <button
              className="flex items-center rounded-full bg-dodgerblue px-4 py-2 text-white"
              onClick={onSearchClick}
            >
              Search
            </button>
            <button
              className="flex items-center rounded-full bg-dodgerblue px-4 py-2 text-white"
              onClick={() => setIsCreateGroupChatModalOpen(true)}
            >
              Create Group Chat
            </button>
          </div>
          <div className="max-h-[calc(100vh-200px)] space-y-4 overflow-y-auto ">
            {contacts.map((contact) => (
              <div
                key={contact.id + "qwerty"}
                className={`flex cursor-pointer items-center rounded p-2 transition-colors duration-200  ${
                  selectedContact?.id === contact.id
                    ? "bg-blue-100"
                    : "hover:bg-gray-200"
                }`}
                onClick={() => selectContact(contact)}
              >
                <ContactAvatar contact={contact} />
                <div className="min-w-0 flex-grow ">
                  <h3 className="truncate font-semibold">
                    {contact.first_name + " " + contact.last_name}
                  </h3>
                  <p className="text-gray-600 truncate text-sm">
                    {contact.email}
                  </p>
                  <span className="text-xs text-gray-400">{now}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Messages */}
        <div className="mt-4 flex h-[calc(100vh-140px)] flex-1 flex-col self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white">
          {selectedContact ? (
            <div className="flex h-full w-full flex-col">
              <div className="flex items-center justify-between border-[1px] border-b border-solid border-silver-100 p-4">
                <div className="flex items-center">
                  <ContactAvatar contact={selectedContact} />
                  <div
                    className="hover:bg-gray-100 ml-3 cursor-pointer rounded p-2 transition-all duration-200"
                    onClick={handleContactClick}
                  >
                    <h2 className="text-xl font-bold">
                      {selectedContact.first_name}
                    </h2>
                    <span className="text-sm text-gray-500">{now}</span>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <button className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-100 text-blue-500 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                  </button>
                  <button className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-100 text-blue-500 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                      />
                    </svg>
                  </button>
                  <button className="text-gray-600 flex h-10 w-10 items-center justify-center rounded-full border border-gray-200 bg-white hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="flex-1 overflow-y-auto p-4">
                <div className="mb-4">
                  <span className="text-sm text-gray-500">Today</span>
                </div>
                {messages.map((message) => (
                  <div
                    key={message.id}
                    className={`mb-4 ${
                      message.senderId === auth.currentUser.uid
                        ? "text-right"
                        : ""
                    }`}
                  >
                    <div
                      className={`inline-block rounded-lg p-3 ${
                        message.senderId === auth.currentUser.uid
                          ? "bg-blue-100"
                          : "bg-gray-200"
                      }`}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: message.message }}
                      ></div>
                      <span className="text-xs text-gray-500">
                        {convertTimestampToDate(message.timestamp)}
                      </span>
                    </div>
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>
              <div className="border-t border-gray-300 p-4">
                <RichTextEditorComponent
                  height={150}
                  toolbarSettings={toolbarSettings}
                  quickToolbarSettings={quickToolbarSettings}
                  value={editorContent}
                  change={handleEditorChange}
                >
                  <Inject
                    services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]}
                  />
                </RichTextEditorComponent>
                <div className="mt-2 flex justify-end">
                  <button
                    onClick={handleSend}
                    className="rounded-full bg-blue-500 px-4 py-2 text-white transition duration-150 hover:bg-blue-600"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex h-full items-center justify-center">
              <p className="text-center text-gray-500">
                Select a contact to start messaging
              </p>
            </div>
          )}
        </div>
      </section>
      {showProfilePopup && (
        <ContactProfilePopup
          contact={selectedContact}
          onClose={() => setShowProfilePopup(false)}
        />
      )}
    </div>
  );
};

export default Communication;
