import React, { useState } from "react";

const DictionaryItem = ({ term, definition }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-2">
      <div
        className="flex cursor-pointer items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span
          className={`mr-2 transform transition-transform duration-200 ${
            isOpen ? "rotate-90" : ""
          }`}
        >
          ▶
        </span>
        <span className="font-semibold">{term}</span>
      </div>
      {isOpen && <div className="ml-6 mt-2 text-sm">{definition}</div>}
    </div>
  );
};

const QuestionItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-2">
      <div
        className="flex cursor-pointer items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span
          className={`mr-2 transform transition-transform duration-200 ${
            isOpen ? "rotate-90" : ""
          }`}
        >
          ▶
        </span>
        <span className="font-semibold">{question}</span>
      </div>
      {isOpen && <div className="ml-6 mt-2 text-sm">{answer}</div>}
    </div>
  );
};
const QuickInfo = () => {
  return (
    <div className="relative flex w-full flex-row items-start justify-start leading-[normal] tracking-[normal]">
      <main className="mq725:pb-[26px] mq725:box-border mq1050:pt-5 mq1050:pb-10 mq1050:box-border box-border flex max-w-full flex-1 flex-col items-start justify-start gap-[5.4px] bg-white pb-[10px] pt-[10px] text-left text-sm text-darkslateblue">
        <header className="flex flex-col items-start justify-start gap-1.5 self-stretch rounded-b-8xs rounded-tl-none rounded-tr-8xs text-left text-sm text-darkslateblue">
          <h2 className="mb-1 text-sm font-bold text-blue-500">
            National voad resources
          </h2>
          <div className="text-darkslategray flex flex-col items-start justify-start gap-1.5 self-stretch rounded-b-mini rounded-tl-none rounded-tr-mini border-[1px] border-solid border-white bg-white px-4">
            <a
              className="relative flex h-[37px] shrink-0 items-center self-stretch whitespace-nowrap leading-[0%] text-[inherit] [text-decoration:underline]"
              href="https://www.nvoad.org/wp-content/uploads/longtermrecoveryguide-final2012.pdf"
              target="_blank"
            >
              <span>
                <div className="font-inherit m-0 text-inherit">
                  <li>National VOAD Long Term Recovery Guide</li>
                </div>
              </span>
            </a>
            <a
              className="relative flex h-[37px] shrink-0 items-center self-stretch whitespace-nowrap leading-[0%] text-[inherit] [text-decoration:underline]"
              href="https://www.nvoad.org/wp-content/uploads/dcmguidelines-final.pdf"
              target="_blank"
            >
              <span>
                <div className="font-inherit m-0 text-inherit">
                  <li>National VOAD Case Management Guidelines</li>
                </div>
              </span>
            </a>
            <a
              className="relative flex h-[37px] shrink-0 items-center self-stretch whitespace-nowrap leading-[0%] text-[inherit] [text-decoration:underline]"
              href="https://www.nvoad.org/wp-content/uploads/dscg_quick_reference.pdf"
              target="_blank"
            >
              <span>
                <div className="font-inherit m-0 text-inherit">
                  <li>
                    Quick Reference to the National VOAD Disaster Spiritual Care
                    Guidelines
                  </li>
                </div>
              </span>
            </a>
          </div>
        </header>
        <section className="flex flex-col items-start justify-start gap-1.5 self-stretch text-left text-sm text-darkslateblue">
          <h2 className="mb-1 text-sm font-bold text-blue-500">
            Long-Term Recovery Group
          </h2>
          <div className="flex flex-col items-start justify-start self-stretch rounded-b-8xs rounded-tl-none rounded-tr-8xs px-0 py-0">
            <div className="flex flex-col items-start justify-start self-stretch rounded-b-mini rounded-tl-none rounded-tr-mini border-[1px] border-solid border-white bg-white px-4">
              <a
                href="https://www.co.lincoln.or.us/DocumentCenter/View/2793/FAQ---Long-Term-Recovery-Group-PDF"
                target="_blank"
                rel="noopener noreferrer"
                className="relative flex h-[37px] shrink-0 items-center self-stretch whitespace-nowrap leading-[0%] text-[inherit] [text-decoration:underline]"
              >
                <li>
                  Long Term Recovery Group (LTRG) Frequently Asked Questions
                </li>
              </a>
            </div>
          </div>
        </section>

        <section className="flex flex-col items-start justify-start gap-1.5 self-stretch text-left text-sm text-darkslateblue">
          <h2 className="mb-1 text-sm font-bold text-blue-500">
              Check List
          </h2>
          <div className="flex flex-col items-start justify-start self-stretch rounded-b-8xs rounded-tl-none rounded-tr-8xs px-0 py-0">
            <div className="flex flex-col items-start justify-start gap-3 self-stretch rounded-b-mini rounded-tl-none rounded-tr-mini border-[1px] border-solid border-white bg-white px-4 py-[10px]">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                <li>Long Term Recovery Check List on the page</li>
              </a>

              <a
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                <li>Printable PDF</li>
              </a>
            </div>
          </div>
        </section>

        <h2 className="mb-1 text-sm font-bold text-blue-500 mt-4">
          Blue Sky Dictionary
        </h2>
        <section className="text-darkslategray flex flex-col items-start justify-start gap-2.5 self-stretch rounded-b-mini rounded-tl-none rounded-tr-mini border-[1px] border-solid border-white bg-white px-4 text-left text-sm mq450:box-border mq450:pb-5 mq450:pt-5">
          <DictionaryItem
            term="FEMA"
            definition="Federal Emergency Management Agency"
          />
          <DictionaryItem
            term="EM"
            definition="Emergency Management: State of local emergency management agency"
          />
          <DictionaryItem
            term="LTRG"
            definition="Long Term Recovery Group: Before FEMA leaves, localities are required to establish a Long-Term Recovery Group (LTRG) if one does not already exist. LTRG's are independent of FEMA, American Red Cross or any other federal agency, and exist to help meet the unmet disaster recovery needs of the community and individuals. National VOAD defines a long term recovery group as 'A long term recovery group (LTRG) is a cooperative body that is made up of representatives from faith-based, non-profit, government, business and other organizations working within a community to assist individuals and families as they recover from disaster.'"
          />
          <DictionaryItem
            term="VOAD"
            definition="Volunteer Organizations Active in Disaster: An association of organizations (which can include national, state, and local volunteer organizations) that mitigate and reduce the impact of disasters."
          />
          <DictionaryItem
            term="NVOAD"
            definition="National Volunteer Organizations Active in Disaster"
          />
          <DictionaryItem
            term="DCM"
            definition="Disaster Case Management is a time-limited collaboration between a trained case manager and a disaster survivor involving the development of a disaster recovery plan and a mutual effort to meet those disaster-caused unmet needs described in the plan. FEMA Disaster Case Management"
          />
          <DictionaryItem
            term="UN"
            definition="Unmet needs are defined by the need for goods or services by those significantly impacted by disaster that are not covered by personal means, insurance, or existing assistance programs."
          />
          <DictionaryItem
            term="CDBG-DR"
            definition="Community Development Block Grant Disaster Recovery (CDBG-DR) grant funds are appropriated by Congress and allocated by HUD to rebuild disaster-impacted areas and provide crucial seed money to start the long-term recovery process. These flexible grants local governments and States recover from Presidentially declared disasters, especially in low-income areas, subject to availability of supplemental appropriations. Since CDBG-DR assistance may fund a broad range of recovery activities, HUD can help communities and neighborhoods that otherwise might not recover due to limited resources. It could take 2-4 years after the disaster for CDBG-DR funds to arrive in the impacted community. HUD"
          />
          <DictionaryItem
            term="DR"
            definition="Disaster Recovery: The process that occurs after a disaster impacts a community. This is commonly divided into two categories, Immediate Response which occurs in the days and weeks following the disaster and Long-Term Recovery which occurs during the months and years following the disaster."
          />
          <DictionaryItem
            term="Victim"
            definition="A citizen who perishes as a result of the disaster."
          />
          <DictionaryItem
            term="Survivor"
            definition="A citizen who survives the disaster but is impacted and needs immediate and/or long-term assistance."
          />
          <DictionaryItem
            term="Blue Sky"
            definition="Non-disaster periods for communities and citizens in which they should develop mitigation and preparedness plans."
          />
          <DictionaryItem
            term="Grey Sky"
            definition="Post-disaster periods for communities and citizens in which they participate in immediate response and long-term recovery."
          />
          <DictionaryItem term="Recovery" definition="" />
          <DictionaryItem term="Certified Citizen" definition="" />
          <DictionaryItem term="Certified Comunity" definition="" />
        </section>
        <h2 className="mb-1 text-sm font-bold text-blue-500 mt-4">
          DISASTER RELATED QUESTIONS
        </h2>
        <section className="text-darkslategray mq725:pt-5 mq725:pb-5 mq725:box-border flex flex-col items-start justify-start gap-2.5 self-stretch rounded-b-mini rounded-tl-none rounded-tr-mini border-[1px] border-solid border-white bg-white px-4 text-left text-sm">
          <QuestionItem
            question="What are the stages of Disaster Recovery?"
            answer="Disasters are viewed as as recurring events with four phases: Mitigation, Preparedness, Response, and Recovery.
              Mitigation
              This phase includes actions taken to prevent or reduce the cause, impact, and consequences of disasters. Examples of
              hazard mitigation include:
              *Tying down homes or barns with ground anchors to
              withstand wind damage
              *Digging water channels to redirect water and planting
              vegetation to absorb water
              * Constructing levees or permanent barriers to control
              flooding
              *Reinforcing fencing to prevent animal escapes
              *Buying insurance policies

              Preparedness
              This phase includes planning, training, and educational activities for events that cannot be mitigated. Examples
              include:
              *Developing disaster preparedness plans for what to do,
              where to go, or who to call for help in a disaster
              *Exercising plans through drills, tabletop exercises, and
              full-scale exercises
              *Creating a supply list of items that are useful in a
              disaster
              *Walking around a farm and identifying possible
              vulnerabilities to high winds

              Response
              The response phase occurs in the immediate aftermath of a disaster. During the response phase, business and other
              operations do not function normally. Personal safety and wellbeing in an emergency and the duration of the response phase
              depend on the level of preparedness. Examples of response activities include:
              *Implementing disaster response plans
              *Conducting search and rescue missions
              *Taking actions to protect yourself, your family, your
              animals, and others
              *Addressing public perceptions about food safety

              Recovery
              During the recovery period, restoration efforts occur concurrently with regular operations and activities. The recovery period from a disaster can be prolonged. Examples of recovery activities include:
              *Preventing or reducing stress-related illnesses and
              excessive financial burdens
              *Rebuilding damaged structures based on advanced
              knowledge obtained from the preceding disaster
              *Reducing vulnerability to future disasters

              [text] Emergency Management in the United States- FEMA Training [link] hhttps://training.fema.gov/emiweb/downloads/is111_unit%204.pdf"
          />
          <QuestionItem
            question="What is the difference between a declared and undeclared disaster?"
            answer="A disaster always begins and ends at the local level. However, sometimes disasters big or small can overwhelm local jurisdictions' ability to respond and recover effectively; therefore leaving the jurisdictions looking to state and federal entities for additional assistance. It is important to remember that no matter how impactful a disaster may be to a community, not every disaster results in a Major Federal Disaster Declaration. 

              The long term recovery after a disaster oftentimes places the most financial strain on local and/or state governments. A major disaster declaration puts into motion long-term federal recovery programs that are designed to help disaster survivors, businesses, and public entities. 

              Disasters that are not federally declared
              Without a Major Federal Disaster Declaration, there is no outside assistance for individuals. The Office of Homeland Security and Emergency Management will work with local partners and non-profit organizations to address recovery needs for the community. 

              Disasters that are federally declared
              In instances where a Major Federal Disaster Declaration is issued, additional resources open up for disaster survivors, businesses, and public entities. Once a disaster has been declared, individuals are able to apply for FEMA assistance.  

              [text] Recover Montgomery County, TX [link] https://www.mctx.org/departments/departments_q_-_z/recover/declared_vs_non-_declared_disasters.php"
          />
          <QuestionItem
            question="What assistance is provided by FEMA?"
            answer="If a disaster is federally declared, financial assistance from FEMA may be available. If you are initailly denied, it is critical to continue the process and file a FEMA appeal. 

              What assistance is available?
              Disaster assistance may include grants to help pay for temporary housing, emergency home repairs for the primary residence, uninsured and underinsured personal property losses and medical, dental and funeral expenses caused by the disaster, along with other serious disaster-related expenses.

              The U.S. Small Business Administration (SBA) offers low-interest disaster loans to businesses of all sizes, homeowners and renters. These disaster loans cover uninsured and uncompensated losses and do not duplicate benefits of other agencies or organizations.

              Am I eligible for disaster assistance if I already have insurance?
              Insurance is your main source for money to put your life back in order after a disaster. It’s very important for you to contact your insurance agent and file your claim. If you have not, do so right away.

              Insurance does not cover many expenses so disaster programs may be able to help. So, you should apply for assistance even if you have insurance if you have unmet needs that FEMA may find to be eligible.

              Also, you should not wait for your insurance settlement to apply for a low-interest disaster loan from the SBA if you are referred to apply for one.

              I have applied. What happens next?
              Once registered, you can check on the status of your application, add new information to your application (such as change of email, home address, phone number, if you have received an insurance settlement and are reporting it or you’ve discover additional damages) or you can get answers to your questions by going online at https://www.disasterassistance.gov or by calling the FEMA Helpline at 800-621-3362, (TTY) 1-800-462-7585.

              Clicking on this link: Help After A Disaster, will take you to the “Applicant’s Guide to the Individuals and Households Program” on FEMA’s website which will answer many of your questions. The site explains how FEMA’s disaster assistance program works; describes additional kinds of help you may qualify for from other federal, state and voluntary agencies; and gives you many important tips on how best to make all these programs work for you.

              What kinds of low-interest disaster loans does SBA offer and for how much?
              Businesses may borrow up to $2 million for any combination of property damage or economic injury. SBA offers low-interest working capital loans (called Economic Injury Disaster Loans) to small businesses and most private, nonprofit organizations of all sizes having difficulty meeting obligations as a result of the disaster.

              If you’re a homeowner, you may borrow up to $200,000 from SBA to repair or replace your primary residence. Homeowners and renters may borrow up to $40,000 to replace personal property.

              FEMA’s temporary housing assistance and grants that help pay for medical, dental and funeral expenses do not require individuals to apply for an SBA loan. However, homeowners or renters must complete and submit an SBA loan application to be eligible for assistance that covers personal property, vehicle repair or replacement, and moving and storage expenses.

              Who Is Eligible to Receive Individual Assistance?
              To be eligible for FEMA Individual Assistance grants, you must be a U.S. citizen, Non-Citizen National or a Qualified Alien. The following general conditions must be met for an applicant to be eligible to receive IHP Assistance:

              The applicant must be a U.S. citizen, non-citizen national, or qualified alien
              FEMA must be able to verify the applicant's identity
              The applicant’s insurance, or other forms of disaster assistance received, cannot meet their disaster-caused needs
              The applicant’s necessary expenses and serious needs are directly caused by a declared disaster

              [text] Fact Sheet Frequently Asked Questions about FEMA Individual Assistance [link] https://www.fema.gov/press-release/20210318/fact-sheet-frequently-asked-questions-about-fema-individual-assistance"
          />
          <QuestionItem
            question="What assistance is provided by the American Red Cross?"
            answer="During the initial relief response
              The Red Cross works with partners to make sure people have a safe place to stay, food to eat, and relief supplies such as comfort kits containing items like toothbrushes and soap. Trained volunteers provide health, mental health and spiritual support, including help coping with new challenges, managing medical conditions, caring for wounds or injuries, and replacing prescription medications or other critical medical equipment like canes and wheelchairs. As soon as it’s safe, trained Red Crossers help assess the destruction left behind, including how many homes have been affected and the extent of that damage.

              Recovery begins 
              As folks begin to either return home or plan what their next steps will be, the Red Cross is there to help. For those able to go home, Red Cross emergency response vehicles are out in the communities, distributing meals and snacks as well as relief items such as tarps, rakes, gloves and more. For people who don’t have homes to return to, the Red Cross and other organizations help them make housing arrangements. Red Cross caseworkers are available at most shelters to meet one-on-one with people to create individual recovery plans, navigate paperwork and locate help from other organizations. These volunteers are also trying to connect with people via telephone. Those needing assistance should make sure their phones are set to accept unknown calls.

              Closing shelters
              The closing of emergency shelters is a sign that parts of the community are slowly beginning to recover. Emergency shelters aren’t typically intended to stay open for long periods of time and aren’t the ideal answer for people whose homes are destroyed. Residents plan their next steps by either returning home or moving into more comfortable and longer-term housing solutions.

              These housing arrangements will look different for each household. For example, some people may choose to live with friends or family while they rebuild. Others will move into new apartments. And some will be helped through transitional sheltering or other housing programs offered by government agencies and community organizations. Red Cross shelter resident transition teams are helping them work through their specific barriers to return to their pre-disaster lifestyle.

              The Red Cross has partnerships with local and national organizations that work alongside us to help communities prepare for, respond to and recover from disasters. This includes organizations and partners with county homeless coalitions that work with us to help people who did not have traditional housing prior to the disaster.

              Recovery
              As respources permit, after the emergency phase of a response has been completed, ARC turns to helping people recover and addressing lingering community needs. Working together with community leaders, government and relief agencies, they organize and execute recovery strategies that include:

              *Providing emergency financial assistance in the immediate aftermath of a disaster
              *Distributing financial assistance for households that need extra help in the long-term
              *Providing grants for community-based recovery services

              [text] What Services Does American Red Cross Provide After aDisaster [link] https://www.redcross.org/about-us/news-and-events/news/2022/what-services-does-the-red-cross-provide-during-a-disaster.html

              [text] Disaster Relief [link] https://www.redcross.org/about-us/our-work/disaster-relief.html#:~:text=Red%20Cross%20health%20volunteers%20travel,replace%20prescription%20medications%20or%20eyeglasses."
          />
          <QuestionItem
            question="What is Community Led Recovery?"
            answer="When a significant emergency occurs in an area, it is the people who make up that community who are the hardest hit. From the roof over their heads, to access to essential medical services, to the schools their children attend, to their jobs – the basic functions of everyday life can be severely impacted.

              It is important to support an approach to community recovery that gives communities greater opportunity to be involved in decisions and processes that contribute to reshaping and rebuilding their lives in ways that work for them. This approach also allows for greater social cohesion and the possibility that through rallying together in times of crises communities can emerge stronger, and more connected, community-minded and resilient. Community recovery is open-ended and not pre-determined, which means ideas are considered and agreed collectively by the community, and activities and priorities are adapted and refined as required.

              Community-Led Recovery
              *Participation from the community, ensuring those affected by a disaster make decisions about their own recovery.
              *Consider the values, culture and priorities of all affected communities.
              *Use and develop the community’s strengths, leadership and existing networks and structures.
              *Recognise communities might choose different paths to recovery and ensure policies, plans and services are flexible and adaptable to support this.
              *Build strong partnerships between communities and those involved in the recovery process.

              Principles
              *Understand the context - successful recovery is based on understanding the community context—the unique history, values and dynamics of a community.
              *Communicate effectively - successful recovery is built on effective communication between the affected community and other partners.
              *Strengthen communities - successful recovery should leave communities stronger by reducing vulnerabilities and building resilience.
              *Ensure an inclusive approach - successful recovery recognises that communities are made of many groups and ensures that actions, both intentional and unintentional, do not exclude groups of people.
              *Recognise complexity - successful recovery responds to the complex and dynamic nature of disasters and the community.
              *Recognise and build capacity - successful recovery recognises, supports and builds on individual, community and organisational capacity and resilience.
              *Use community-led approaches - successful recovery is community centred, responsive and flexible, and it relies on community engagement to support them to move forward.
              *Coordinate all activities - successful recovery requires a planned, coordinated and adaptative approach between community and partner agencies, based on continuing assessment of impacts and needs.

              [text] Community Recovery Toolkit [link] https://www.vic.gov.au/community-recovery-toolkit-0/what-ervs-role-community-recovery"
          />
          <QuestionItem
            question="What is a long term recovery group?"
            answer="Recovery begins and ends at the local level. Before FEMA leaves, localities are required to establish a Long-Term Recovery Group (LTRG) if one does not already exist. LTRG’s are independent of FEMA, American Red Cross or any other federal agency, and exist to help meet the unmet disaster recovery needs of the community and individuals. National VOAD defines a long term recovery group as 'A long term recovery group (LTRG) is a cooperative body that is made up of representatives from faith-based, non-profit, government, business and other organizations working within a community to assist individuals and families as they recover from disaster.'
                  
              LTRG’s are as varied in their structure as are the communities in which they work. The personality and operation of each group is unique and reflects local needs, available resources, cultural diversity, leadership style, and community support. No matter how a group is structured or what it calls itself, the goal is the same: to unite recovery resources with community needs in order to ensure that even the most vulnerable in the community recover from disaster."
          />
          <QuestionItem
            question="What are VOADs?"
            answer="Through their cooperation, communication, coordination, and collaboration, VOADs (voluntary organizations active in disaster) foster effective delivery of services to communities affected by disasters through coordination of local long term recovery groups. The state or local emergency management agency will request VOAD activation to address the scope of the emergency and the needs of the affected area. The state or local VOAD will initiate a conference call with National VOAD member and partner organizations to discuss needs and available resources and services. Local chapters of National VOAD members are engaged, as are local partners that are not National VOAD members and that have a narrower scope and more localized services.

              VOAD organizations provide many services during the response and recovery phases of a disaster. This can include help with debris removal, construction estimation and household repairs or rebuilding; family reunification and access to food and shelter; and pet care or shelter. VOAD members may also offer disaster casework and mental health and psychological first aid, affiliated volunteer management, and long-term recovery services. Members may also provide training as a preparedness or disaster-planning activity."
          />
          <QuestionItem
            question="What will my insurance cover?"
            answer="Home insurance policies provide an important layer of protection for your property, but the hazard insurance covering your property may not have the coverage you need if your community is hit by a natural disaster. For additional coverage, you may need to pay additional costs or take out a separate policy. It is important to review your policy carefully and make sure you understand exactly what is or is not covered. This will allow you to make a more informed decision about whether additional coverages are right for your situation. DO NOT ASSUME YOUR HOME AND CONTENTS ARE COVERED. Speak to your agent.

              If you are a renter, please speak with your insurance agent about renter's insurance."
          />
          <QuestionItem
            question="How long does recovery take?"
            answer="Damage and loss may be immediate, but the impact will last for a long time and usually requires years of recovery. The effect of the disaster can be immediate and localized but is often widespread and could last for a long period of time. Depending on the size of the disaster and amount of available resources, it can take individuals a few months to several years to recover. It can take communities a few years to a decade to recover. "
          />
        </section>
      </main>
    </div>
  );
};

export default QuickInfo;
