import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import PropTypes from "prop-types";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Toolbar,
  Filter,
  Edit,
  Page,
  Sort,
  Inject,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import {
  householdMembers,
  pets,
  USStates,
  surveyQuestions,
} from "../../pages/datasource";
import { Internationalization } from "@syncfusion/ej2-base";
import SurveyQuestion from "./SurveyQuestion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import citizenProfileService from "../../service/citizen/CitizenProfile";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../firebase";
import { doc, getDoc, setDoc, serverTimestamp, query, collection, getDocs, where } from "firebase/firestore";
import "../../pages/style.css";
import { Query } from "@syncfusion/ej2-data";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import DatePickerTemplate from "../../components/DatePickerTemplate";

const scrollToTop = (event) => {
  event.preventDefault();
  const header = document.getElementById("survey-questions-header");
  if (header) {
    header.scrollIntoView({ behavior: "smooth" });
  }
};
const scrollToBottom = (event) => {
  event.preventDefault();
  const footer = document.getElementById("survey-questions-footer");
  if (footer) {
    footer.scrollIntoView({ behavior: "smooth" });
  }
};

const chunkQuestions = (questions, size) => {
  const chunkedArr = [];
  for (let i = 0; i < questions.length; i += size) {
    chunkedArr.push(questions.slice(i, i + size));
  }
  return chunkedArr;
};

const questionGroups = chunkQuestions(surveyQuestions, 3);

const sortSettings = {
  allowSorting: true,
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const editing = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",

  dialog: { cssClass: "custom-dialog" },
};

const filterSettings = {
  type: "CheckBox",
};

const intl = new Internationalization();

const formatDate = (date) => {
  if (date) {
    return intl.formatDate(new Date(date), { format: "MM/dd/yyyy" });
  }
  return "";
};
const formattedData = householdMembers.map((item) => ({
  ...item,
  date_of_birth: formatDate(item.date_of_birth),
  household_member_id: item.household_member_id,
}));
const datepickerparams = {
  params: {
    format: "MM/dd/yyyy",
    value: new Date(),
    showClearButton: false,
    showTodayButton: false,
    start: "Decade",
    depth: "Day",
  },
};

const militaryvetTypes = [{ value: "No" }, { value: "Yes" }];

const militaryvetParams = {
  params: {
    allowFiltering: true,
    dataSource: militaryvetTypes,
    fields: { text: "value", value: "value" },
    query: new Query(),
  },
};

const ImmediateResponseSurvey = ({ selectedDisasters, activeDisasters, parentSetCaseNumber }) => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState(null);
  const [isPersonalInfoVisible, setIsPersonalInfoVisible] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDisplacedInfoVisible, setIsDisplacedInfoVisible] = useState(true);
  const [displacedEditMode, setDisplacedEditMode] = useState(false);
  const [tempFormData, setTempFormData] = useState({});
  const [displacedTempData, setDisplacedTempData] = useState({});
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [householdMembers, setHouseholdMembers] = useState();
  const [responses, setResponses] = useState({}); // State to hold responses
  const [dob, setDob] = useState(null);
  const errorTimeoutRef = useRef(null);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
        await fetchUserSurveyResponses(currentUser.uid); // Fetch survey responses
      } else {
        setData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);



  const fetchData = async () => {
    const citizenData = await citizenProfileService.getCitizenProfile(
      "users",
      userId
    );
    if (citizenData) {
      setData(citizenData);
      setTempFormData(citizenData);
      if (citizenData.updatedData_at)
        setLastEditedTime(citizenData.updated_at.toDate());

      const householdData = citizenData.household || {};
      setHouseholdFormData(householdData);
      setTempHouseholdFormData(householdData);
      setLastHouseholdEditedTime(
        householdData.updated_at ? householdData.updated_at.toDate() : null
      );

      // Fetch household members
      const householdMembersData =
        await citizenProfileService.getHouseholdMembers(userId);
      setHouseholdMembers(householdMembersData);
    }

    // Fetch pets
    const petsData = await citizenProfileService.getPets(userId);
    setPets(petsData);
  };

  const togglePersonalInfo = (event) => {
    event.preventDefault();
    setIsPersonalInfoVisible(!isPersonalInfoVisible);
  };

  const startEditing = (event) => {
    event.preventDefault();
    setTempFormData({ ...data });
    setIsEditMode(true);
  };

  const toggleDisplacedInfo = (event) => {
    event.preventDefault();
    setIsDisplacedInfoVisible((prev) => !prev);
  };

  const savePersonalInfo = async () => {
    const errors = validatePersonalInfo(tempFormData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);

      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }

      errorTimeoutRef.current = setTimeout(() => {
        setValidationErrors({});
      }, 5000);

      return;
    }

    const currentTime = new Date();
    const updatedData = { ...tempFormData, updated_at: currentTime };

    try {
      const requiredFields = [
        "first_name",
        "last_name",
        "mobile_number",
        "address1",
        "city",
        "state",
        "zip",
        "county",
        "alternate_contact_name",
        "alternate_email",
        "alternate_mobile_number",
        "date_of_birth",
        "gender",
      ];
      const isProfileComplete = requiredFields.every(
        (field) => updatedData[field]
      );
      if (isProfileComplete) {
        console.log("Profile is complete");
        await citizenProfileService.updateCitizenProfile("users", userId, {
          ...updatedData,
          profile_completed: true,
        });
      } else {
        console.log("Profile is not complete");
        await citizenProfileService.updateCitizenProfile("users", userId, {
          ...updatedData,
          profile_completed: true,
        });
      }
      setData(updatedData);
      setIsEditMode(false);
      setLastEditedTime(currentTime);
      setValidationErrors({});
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const onEditDisplacedInfo = (toggle) => (event) => {
    event.preventDefault();
    setDisplacedEditMode(() => toggle);
  };

  const onChangeDisplacedDetails = ({ target: { name, value } }) => {
    setDisplacedTempData((prev) => ({ ...prev, [name]: value }));
  };

  const onSaveDisplacedDetails = useCallback(async () => {
    await citizenProfileService.updateDisplacedDetails(
      userId,
      displacedTempData
    );
    const citizenData = await citizenProfileService.getCitizenProfile(
      "users",
      userId
    );
    setData(citizenData);
    onSetDefaultDisplacedDetails(citizenData);
  }, [displacedTempData, userId]);

  const onDisplaced = async ({ checked }) => {
    await citizenProfileService.toggleDisplaced(userId, checked);
    const citizenData = await citizenProfileService.getCitizenProfile(
      "users",
      userId
    );
    setData(citizenData);
    onSetDefaultDisplacedDetails(citizenData);
  };

  const onSetDefaultDisplacedDetails = (data) => {
    const { d_address_1, d_address_2, d_city, d_state, d_zip } = data || {};
    setDisplacedTempData((prev) => ({
      ...prev,
      d_address_1: d_address_1 || "",
      d_address_2: d_address_2 || "",
      d_city: d_city || "",
      d_state: d_state || "",
      d_zip: d_zip || "",
    }));
  };

  const saveChanges = async (event) => {
    event.preventDefault();
    const currentTime = new Date();
    const updatedData = { ...tempFormData, updated_at: currentTime };

    try {
      await citizenProfileService.updateCitizenProfile(
        "users",
        userId,
        updatedData
      );
      setData(updatedData);
      setIsEditMode(false);
      setLastEditedTime(currentTime);
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const cancelEditing = (event) => {
    event.preventDefault();
    setTempFormData({ ...data });
    setIsEditMode(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTempFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputBlur = async () => {
    const errors = validatePersonalInfo(tempFormData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);

      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }

      errorTimeoutRef.current = setTimeout(() => {
        setValidationErrors({});
      }, 5000);

      return;
    }

    const currentTime = new Date();
    const updatedData = { ...tempFormData, updated_at: currentTime };

    try {
      await citizenProfileService.updateCitizenProfile(
        "users",
        userId,
        updatedData
      );
      setData(updatedData);
      setLastEditedTime(currentTime);
      setValidationErrors({});
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const handleHouseholdInputBlur = async () => {
    const errors = validateHouseholdInfo(tempHouseholdFormData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);

      // Clear previous timeout if it exists
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }

      // Set a new timeout to clear errors after 5 seconds
      errorTimeoutRef.current = setTimeout(() => {
        setValidationErrors({});
      }, 5000);

      return;
    }

    const currentTime = new Date();
    const updatedHouseholdData = {
      ...tempHouseholdFormData,
      updated_at: currentTime,
    };

    try {
      // Fetch the current profile data
      const currentProfile = await citizenProfileService.getCitizenProfile(
        "users",
        userId
      );
      const updatedProfile = {
        ...currentProfile,
        household: updatedHouseholdData,
      };

      // Update the backend with the new household data
      await citizenProfileService.updateCitizenProfile(
        "users",
        userId,
        updatedProfile
      );

      // Update the frontend state
      setHouseholdFormData(updatedHouseholdData);
      setLastHouseholdEditedTime(currentTime);
      setValidationErrors({});
    } catch (error) {
      console.error("Error updating household profile: ", error);
    }
  };

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  const generateUniqueID = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  const renderField = (
    label,
    name,
    placeholder,
    isDropdown = false,
    className = ""
  ) => {
    const requiredFields = [
      "first_name",
      "last_name",
      "birth_date",
      "address1",
      "city",
      "state",
      "zip",
      "mobile_number",
      "email",
      "alternate_contact_name",
      "alternate_email",
      "alternate_mobile_number",
      "gender",
    ];
    const isRequired = requiredFields.includes(name);

    return (
      <div
        className={`flex flex-col items-start justify-start gap-1 ${className}`}
      >
        <div className="text-gray-600 relative flex items-center text-left text-xs leading-[20px]">
          {label}
          {isRequired && (
            <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
              *
            </p>
          )}
        </div>
        <div
          className={`self-stretch rounded-md ${
            isEditMode ? "bg-white" : "bg-gray-200"
          } box-border flex h-10 flex-row items-center justify-between border-[1px] border-solid ${
            validationErrors[name] ? "border-red-500" : "border-gray-300"
          } px-3 py-2`}
        >
          {isEditMode ? (
            name === "birth_date" ? (
              <DatePickerTemplate
                value={tempFormData[name || ""]}
                onChange={(val) =>
                  handleInputChange({ target: { name, value: val } })
                }
              />
            ) : isDropdown ? (
              <select
                className="w-full bg-white text-left text-xs text-black [border:none] [outline:none]"
                name={name}
                value={tempFormData[name] || ""}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              >
                <option value="" disabled>
                  {placeholder}
                </option>
                {name === "gender" ? (
                  <>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </>
                ) : USStates.map((state) => (
                  <option key={state.stateID} value={state.abbreviation}>
                    {state.abbreviation}
                  </option>
                ))}
              </select>
            ) : (
              <input
                className="w-full bg-white text-left text-xs text-black [border:none] [outline:none]"
                type="text"
                name={name}
                value={tempFormData[name] || ""}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                placeholder={placeholder}
              />
            )
          ) : (
            <div
              className={`flex-1 text-xs ${
                tempFormData[name]
                  ? "font-semibold text-gray-800"
                  : "text-gray-500"
              } text-left`}
            >
              {tempFormData[name] || placeholder}
            </div>
          )}
        </div>
        {isEditMode && validationErrors[name] && (
          <div className="mt-1 text-xs text-red-500">
            {validationErrors[name]}
          </div>
        )}
      </div>
    );
  };

  const renderDisplacedField = (
    label,
    name,
    placeholder,
    isDropdown = false,
    className = ""
  ) => {
    return (
      <div
        className={`flex flex-col items-start justify-start gap-1 ${className}`}
      >
        <div className="text-gray-600 relative text-left  text-xs leading-[20px]">
          {label}
        </div>
        <div
          className={`self-stretch rounded-md ${
            displacedEditMode ? "bg-white" : "bg-gray-200"
          } box-border flex h-10 flex-row items-center justify-between border-[1px] border-solid border-gray-300 px-3 py-2`}
        >
          {displacedEditMode ? (
            isDropdown ? (
              <select
                className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none]"
                name={name}
                value={displacedTempData[name] || ""}
                onChange={onChangeDisplacedDetails}
                onBlur={onSaveDisplacedDetails}
              >
                <option value="" disabled>
                  {placeholder}
                </option>
                {USStates.map((state) => (
                  <option key={state.stateID} value={state.abbreviation}>
                    {state.abbreviation}
                  </option>
                ))}
              </select>
            ) : (
              <input
                className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none]"
                placeholder={placeholder}
                type="text"
                name={name}
                value={displacedTempData[name] || ""}
                onChange={onChangeDisplacedDetails}
                onBlur={onSaveDisplacedDetails}
              />
            )
          ) : (
            <div
              className={`flex-1  text-xs ${
                displacedTempData[name]
                  ? "font-semibold text-gray-800"
                  : "text-gray-500"
              } text-left`}
            >
              {displacedTempData[name] || placeholder}
            </div>
          )}
        </div>
      </div>
    );
  };

  const validatePersonalInfo = (formData) => {
    const errors = {};
    const requiredFields = [
      "first_name",
      "last_name",
      "birth_date",
      "address1",
      "city",
      "state",
      "zip",
      "mobile_number",
      "email",
      "alternate_contact_name",
      "alternate_email",
      "alternate_mobile_number",
      "gender",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field] || formData[field].trim() === "") {
        errors[field] = "This field is required";
      }
    });

    return errors;
  };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const isNewlyRegistered = localStorage.getItem("newlyRegistered");
    if (isNewlyRegistered === "true") {
      setShowPopup(true);
      localStorage.removeItem("newlyRegistered"); // Remove the flag after showing the popup
    }
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const dialogContent = () => {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <h2
          style={{
            fontSize: "24px",
            fontWeight: "normal",
            color: "#1e1919",
            marginBottom: "10px",
          }}
        >
          Welcome to your Citizen Profile!
        </h2>
        <p style={{ fontSize: "16px", color: "#637381", marginBottom: "20px" }}>
          Please finish your profile to get the most out of our services.
        </p>
        <button
          onClick={handleClosePopup}
          style={{
            backgroundColor: "#0061fe",
            color: "white",
            border: "none",
            padding: "10px 20px",
            fontSize: "14px",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Got it!
        </button>
      </div>
    );
  };

  const validateHouseholdInfo = (formData) => {
    const errors = {};
    const requiredFields = [
      "disability",
      "military_vet",
      "owner_or_renter",
      "insurance",
      "household_size",
    ];

    requiredFields.forEach((field) => {
      if (
        formData[field] === undefined ||
        formData[field] === "" ||
        formData[field] === null
      ) {
        errors[field] = "This field is required";
      }
    });

    return errors;
  };

  const saveHouseholdChanges = async (event) => {
    event.preventDefault();
    const errors = validateHouseholdInfo(tempHouseholdFormData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);

      // Clear previous timeout if it exists
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }

      // Set a new timeout to clear errors after 5 seconds
      errorTimeoutRef.current = setTimeout(() => {
        setValidationErrors({});
      }, 5000);

      return;
    }
    const currentTime = new Date();
    const updatedHouseholdData = {
      ...tempHouseholdFormData,
      updated_at: currentTime,
    };

    try {
      // Fetch the current profile data
      const currentProfile = await citizenProfileService.getCitizenProfile(
        "users",
        userId
      );
      const updatedProfile = {
        ...currentProfile,
        household: updatedHouseholdData,
      };

      // Update the backend with the new household data
      await citizenProfileService.updateCitizenProfile(
        "users",
        userId,
        updatedProfile
      );

      // Update the frontend state
      setHouseholdFormData(updatedHouseholdData);
      setIsHouseholdEditMode(false);
      setLastHouseholdEditedTime(currentTime);
      setValidationErrors({});
    } catch (error) {
      console.error("Error updating household profile: ", error);
    }
  };

  {
    /* Household Information Fields*/
  }
  const [isHouseholdInfoVisible, setIsHouseholdInfoVisible] = useState(true);
  const [householdFormData, setHouseholdFormData] = useState({
    annual_income: "",
    ssn: "",
    owner_or_renter: "",
    insurance: false,
    household_size: "",
    disability: false,
    military_vet: false,
  });
  const [tempHouseholdFormData, setTempHouseholdFormData] = useState({
    ...householdFormData,
  });
  const [lastHouseholdEditedTime, setLastHouseholdEditedTime] = useState(null);

  const [isHouseholdEditMode, setIsHouseholdEditMode] = useState(false);

  const toggleHouseholdInfo = (event) => {
    event.preventDefault();
    setIsHouseholdInfoVisible(!isHouseholdInfoVisible);
  };

  const startHouseholdEditing = (event) => {
    event.preventDefault();
    setIsHouseholdEditMode(true);
  };

  const cancelHouseholdEditing = (event) => {
    event.preventDefault();
    setTempHouseholdFormData({ ...householdFormData });
    setIsHouseholdEditMode(false);
  };

  const handleHouseholdInputChange = (event) => {
    const { name, value } = event.target;
    setTempHouseholdFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "disability" || name === "military_vet" || name === "insurance"
          ? value === "Yes"
          : value,
    }));
  };

  const renderHouseholdField = (
    label,
    name,
    placeholder,
    isDropdown = false,
    className = "",
    isOptional = false
  ) => {
    const isRequired = !isOptional;

    const handleAnnualIncomeChange = (e) => {
      let inputValue = e.target.value;

      const numericValue = inputValue.replace(/[^0-9]/g, "");

      handleHouseholdInputChange({
        target: { name: name, value: numericValue ? `$${numericValue}` : "" },
      });
    };

    const validateAnnualIncome = () => {
      if (
        name === "annual_income" &&
        (!tempHouseholdFormData[name] || tempHouseholdFormData[name] === "$")
      ) {
        // Trigger validation error if the field is invalid
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Annual income is required",
        }));
      } else {
        // Remove the error if it's valid
        setValidationErrors((prevErrors) => {
          const { [name]: _, ...newErrors } = prevErrors;
          return newErrors;
        });
      }
    };

    return (
      <div
        className={`flex flex-col items-start justify-start gap-1 ${className}`}
      >
        <div className="text-gray-600 relative text-left  text-xs leading-[20px]">
          {label}
          {isRequired ? (
            <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
              *
            </p>
          ) : (
            <span className="ml-1 text-xs text-gray-400">(Optional)</span>
          )}
        </div>
        <div
          className={`self-stretch rounded-md ${
            isHouseholdEditMode ? "bg-white" : "bg-gray-200"
          } box-border flex h-10 flex-row items-center justify-between border-[1px] border-solid ${
            validationErrors[name] ? "border-red-500" : "border-gray-300"
          } px-3 py-2`}
        >
          {isHouseholdEditMode ? (
            isDropdown ? (
              <select
                className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none]"
                name={name}
                value={
                  name === "insurance"
                    ? tempHouseholdFormData[name]
                      ? "Yes"
                      : "No"
                    : tempHouseholdFormData[name] || ""
                }
                onChange={handleHouseholdInputChange}
                onBlur={handleHouseholdInputBlur}
              >
                <option value="" disabled>
                  {placeholder}
                </option>
                {name === "owner_or_renter" ? (
                  <>
                    <option value="Owner">Owner</option>
                    <option value="Renter">Renter</option>
                    <option value="Rent to Own">Rent to Own</option>
                  </>
                ) : name === "annual_income" ? (
                  <>
                    <option value="0-20000">0-$20,000</option>
                    <option value="20001-40000">$20,001-$40,000</option>
                    <option value="40001-60000">$40,001-$60,000</option>
                    <option value="60001-80000">$60,001-$80,000</option>
                    <option value="80001-100000">$80,001-$100,000</option>
                    <option value="100000+">Greater than $100,000</option>
                  </>
                ) : (
                  <>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </>
                )}
              </select>
            ) : (
              <input
                className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none]"
                placeholder={placeholder}
                name={name}
                value={tempHouseholdFormData[name] || ""}
                onChange={
                  name === "annual_income"
                    ? handleAnnualIncomeChange
                    : handleHouseholdInputChange
                }
                onBlur={
                  name === "annual_income"
                    ? validateAnnualIncome
                    : handleHouseholdInputBlur
                }
              />
            )
          ) : (
            <div
              className={`flex-1  text-xs ${
                householdFormData[name] !== undefined &&
                householdFormData[name] !== ""
                  ? "font-semibold text-gray-800"
                  : "text-gray-500"
              } text-left`}
            >
              {name === "insurance"
                ? householdFormData[name]
                  ? "Yes"
                  : "No"
                : householdFormData[name] || placeholder}
            </div>
          )}
        </div>
        {isHouseholdEditMode && validationErrors[name] && (
          <div className="mt-1 text-xs text-red-500">
            {validationErrors[name]}
          </div>
        )}
      </div>
    );
  };

  {
    /* Household Members */
  }
  const [isHouseholdMembersVisible, setIsHouseholdMembersVisible] =
    useState(true);
  const [lastHouseholdMembersEditedTime, setLastHouseholdMembersEditedTime] =
    useState(null);

  const toggleHouseholdMembers = (event) => {
    event.preventDefault();
    setIsHouseholdMembersVisible(!isHouseholdMembersVisible);
  };

  const gridRef = useRef(null);

  const handleAddClick = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const actionComplete = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Ensure household_member_id and status are set
        const newMemberData = {
          ...args.data,
          household_member_id:
            args.data.household_member_id || generateUniqueID(),
          is_deleted: false,
          date_of_birth: dob
            ? new Date(dob).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : null,
        };
        await citizenProfileService.addHouseholdMember(userId, newMemberData);
        setDob(() => null);
      } else if (args.action === "edit") {
        // Handle status changes during edit
        const updatedMemberData = {
          ...args.data,
          dateOfBirth: formatDate(args.data.dateOfBirth),
          is_deleted: false,
          date_of_birth: dob
            ? new Date(dob).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : null,
        };
        await citizenProfileService.updateHouseholdMember(
          userId,
          updatedMemberData.id,
          updatedMemberData
        );
        setDob(() => null);
      }
      setLastHouseholdMembersEditedTime(new Date());
      // Refresh the grid data
      const updatedMembers = await citizenProfileService.getHouseholdMembers(
        userId
      );
      setHouseholdMembers(
        updatedMembers.map((member) => ({
          ...member,
          date_of_birth: member.date_of_birth || "mm/dd/yyyy",
        }))
      );
    } else if (args.requestType === "delete") {
      // Set status to false instead of deleting
      const updatedMemberData = {
        ...args.data[0],
        is_deleted: true,
      };
      await citizenProfileService.updateHouseholdMember(
        userId,
        updatedMemberData.id,
        updatedMemberData
      );
      setLastHouseholdMembersEditedTime(new Date());
      // Refresh the grid data
      const updatedMembers = await citizenProfileService.getHouseholdMembers(
        userId
      );
      setHouseholdMembers(
        updatedMembers.map((member) => ({
          ...member,
          date_of_birth: member.date_of_birth || "mm/dd/yyyy",
        }))
      );
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit"
            ? "Edit Household Member"
            : "Add New Household Member";

        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#FFFFFF";
          }
          if (header) {
            header.style.color = "#FFFFFF";
          }

          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );

            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }

            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 0);
        }
      }
      if (args.requestType === "beginEdit") {
        const datepicker = args.form.elements["dateOfBirth"];
        if (datepicker) {
          datepicker.ej2_instances[0].value = new Date(
            args.rowData.dateOfBirth
          );
        }
      }
    }
  };

  // Filter household members to only include those with status true
  const filteredHouseholdMembers = Array.isArray(householdMembers)
    ? householdMembers
        .filter((member) => !member.is_deleted)
        .map((member) => ({
          ...member,
          date_of_birth: member.date_of_birth
            ? new Date(member.date_of_birth).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : "mm/dd/yyyy",
        }))
    : [];
  {
    /*Pets*/
  }
  const [pets, setPets] = useState([]);
  const [isPetsVisible, setIsPetsVisible] = useState(true);
  const [lastPetsEditedTime, setLastPetsEditedTime] = useState(null);
  const petsGridRef = useRef(null);

  const togglePets = () => {
    setIsPetsVisible(!isPetsVisible);
  };

  const handleAddPet = () => {
    if (petsGridRef.current) {
      petsGridRef.current.addRecord();
    }
  };

  const petsActionComplete = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Ensure pet_id and status are set
        const newPetData = {
          ...args.data,
          pet_id: generateUniqueID(),
          is_deleted: false,
        };
        await citizenProfileService.addPet(userId, newPetData);
      } else if (args.action === "edit") {
        // Handle status changes during edit
        const updatedPetData = {
          ...args.data,
          is_deleted: false,
        };
        // Use the id field for updating, as it corresponds to the Firestore document ID
        await citizenProfileService.updatePet(
          userId,
          updatedPetData.id,
          updatedPetData
        );
      }
      setLastPetsEditedTime(new Date());
      // Refresh the grid data
      const updatedPets = await citizenProfileService.getPets(userId);
      setPets(updatedPets);
    } else if (args.requestType === "delete") {
      // Set status to false instead of deleting
      const updatedPetData = {
        ...args.data[0],
        is_deleted: true,
      };
      await citizenProfileService.updatePet(
        userId,
        updatedPetData.id, // Use id instead of pet_id
        updatedPetData
      );
      setLastPetsEditedTime(new Date());
      // Refresh the grid data
      const updatedPets = await citizenProfileService.getPets(userId);
      setPets(updatedPets);
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit" ? "Edit Pet" : "Add New Pet";

        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#FFFFFF";
          }
          if (header) {
            header.style.color = "#FFFFFF";
          }

          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );

            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }

            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 0);
        }
      }
    }
  };

  // Filter pets to only include those with status true
  const filteredPets = Array.isArray(pets)
    ? pets.filter((pet) => !pet.is_deleted)
    : [];
  {
    /*Survey*/
  }
  const [checkedItems, setCheckedItems] = useState({});

  const handleResponseChange = (question, response) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [question]: response,
    }));
  };

  const generateCaseNumber = () => {
    return Math.floor(100000000 + Math.random() * 900000000).toString();
  };


  const [showCaseNumberPopup, setShowCaseNumberPopup] = useState(false);
  const [caseNumber, setCaseNumber] = useState("");
  const [disasters, setDisasters] = useState([]);

  // Fetch disasters
  useEffect(() => {
    const fetchDisasters = async () => {
      try {
        const disastersQuery = query(collection(db, "declared-disaster"));
        const querySnapshot = await getDocs(disastersQuery);
        const fetchedDisasters = querySnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().disaster_name,
          status: doc.data().disaster_status
        }));
        
        // Filter for active disasters
        const activeDisasters = fetchedDisasters.filter(disaster => 
          disaster.status === "active"
        );
        
        setDisasters(activeDisasters);
      } catch (error) {
        console.error("Error fetching disasters:", error);
      }
    };

    fetchDisasters();
  }, []);

  // Fetch case number for current disaster
  useEffect(() => {
    const fetchCaseNumber = async () => {
      if (userId && selectedDisasters.length > 0) {
        const currentDisasterId = selectedDisasters.find(id => id !== "All") || selectedDisasters[0];
        
        const surveyQuery = query(
          collection(db, "users/${userId}/survey_responses"),
          where("user_id", "==", userId),
          where("disaster_id", "==", currentDisasterId)
        );

        const querySnapshot = await getDocs(surveyQuery);
        querySnapshot.forEach(doc => {
          if (doc.data().case_number) {
            setCaseNumber(doc.data().case_number);
          }
        });
      }
    };

    fetchCaseNumber();
  }, [userId, selectedDisasters]);

  const [existingSurveys, setExistingSurveys] = useState({});

  const fetchUserSurveyResponses = async (userId) => {
    try {
      const surveysRef = collection(db, `users/${userId}/survey_responses`);
      const querySnapshot = await getDocs(surveysRef);
      
      const surveys = {};
      querySnapshot.forEach(doc => {
        const data = doc.data();
        // For each disaster in the survey response, store its data
        data.disasters.forEach(disaster => {
          surveys[disaster.disaster_id] = {
            responses: data.responses,
            case_number: data.case_number,
            submitted_at: data.submitted_at?.toDate() || new Date(),
            survey_id: doc.id // Store the document ID for potential updates
          };
        });
      });
      
      setExistingSurveys(surveys);
      return surveys;
    } catch (error) {
      console.error("Error fetching survey responses:", error);
      return {};
    }
  };

const fetchSurveyResponse = async (userId, disasterId) => {
  try {
    const surveysRef = collection(db, `users/${userId}/survey_responses`);
    const q = query(
      surveysRef,
      where("disasters", "array-contains", { 
        disaster_id: disasterId,
        disaster_name: disasters.find(d => d.id === disasterId)?.name || ''
      })
    );
    
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const surveyData = querySnapshot.docs[0].data();
      return {
        responses: surveyData.responses,
        case_number: surveyData.case_number,
        submitted_at: surveyData.submitted_at?.toDate() || new Date()
      };
    }
    return null;
  } catch (error) {
    console.error("Error fetching survey response:", error);
    return null;
  }
};

// Add effect to handle disaster selection changes
useEffect(() => {
  if (userId && selectedDisasters.length === 1) {
    const disasterId = selectedDisasters[0];
    const existingSurvey = existingSurveys[disasterId];
    
    if (existingSurvey) {
      // If a survey exists for this disaster, load its responses
      setResponses(existingSurvey.responses);
      setCaseNumber(existingSurvey.case_number);
    } else {
      // Clear form for new survey
      setResponses({});
      setCaseNumber("");
    }
  } else {
    // Clear form when multiple disasters are selected or none
    setResponses({});
    setCaseNumber("");
  }
}, [userId, selectedDisasters, existingSurveys]);

const handleSubmitSurvey = async (event) => {
  event.preventDefault();
  
  // Check if multiple disasters are selected
  const selectedDisasterIds = selectedDisasters.includes("All") 
    ? disasters.map(d => d.id)
    : selectedDisasters.filter(id => id !== "All");

  if (selectedDisasterIds.length > 1) {
    alert("Please select only one disaster per survey submission.");
    return;
  }

  if (selectedDisasterIds.length === 0) {
    alert("Please select a disaster before submitting the survey.");
    return;
  }

  // Validate questions
  const unansweredQuestions = surveyQuestions.filter(
    (question) => !responses[question.question]
  );
  if (unansweredQuestions.length > 0) {
    alert("Please answer all survey questions before submitting.");
    return;
  }

  try {
    const disasterId = selectedDisasterIds[0];
    
    // Check if survey already exists for this disaster
    if (existingSurveys[disasterId]) {
      const disaster = disasters.find(d => d.id === disasterId);
      alert(`A survey already exists for ${disaster?.name || 'this disaster'} (Case #: ${existingSurveys[disasterId].case_number})`);
      return;
    }

    // Continue with submission for new survey
    const newCaseNumber = generateCaseNumber();
    const selectedDisasterDetails = [{
      disaster_id: disasterId,
      disaster_name: disasters.find(d => d.id === disasterId)?.name || ''
    }];

    const surveyData = {
      user_id: userId,
      disasters: selectedDisasterDetails,
      survey_id: uuidv4(),
      case_number: newCaseNumber,
      submitted_at: serverTimestamp(),
      responses: responses,
    };

    const newDocRef = doc(db, `users/${userId}/survey_responses`, surveyData.survey_id);
    await setDoc(newDocRef, surveyData);

    // Update local state
    setExistingSurveys(prev => ({
      ...prev,
      [disasterId]: {
        responses: responses,
        case_number: newCaseNumber,
        submitted_at: new Date(),
        survey_id: surveyData.survey_id
      }
    }));

    // Set case number and show popup
    setCaseNumber(newCaseNumber);
    parentSetCaseNumber(newCaseNumber);
    setShowCaseNumberPopup(true);

  } catch (error) {
    console.error("Error submitting survey:", error);
    alert(`Error submitting survey: ${error.message}`);
  }
};

const renderSurveyStatus = () => {
  const selectedDisasterIds = selectedDisasters.filter(id => id !== "All");
  
  if (selectedDisasterIds.length > 1) {
    return (
      <div className="text-xs text-red-600 font-semibold">
        Please select only one disaster per survey
      </div>
    );
  }
  
  if (selectedDisasterIds.length === 1) {
    const disasterId = selectedDisasterIds[0];
    const existingSurvey = existingSurveys[disasterId];
    const disaster = disasters.find(d => d.id === disasterId);
    
    if (existingSurvey) {
      return (
        <div className="text-xs text-gray-600">
          <div className="font-semibold text-blue-600">
            Existing survey found for {disaster?.name}
          </div>
          Case #: {existingSurvey.case_number}
          <br />
          Submitted: {existingSurvey.submitted_at instanceof Date 
            ? existingSurvey.submitted_at.toLocaleDateString()
            : new Date(existingSurvey.submitted_at).toLocaleDateString()}
        </div>
      );
    } else {
      return (
        <div className="text-xs text-gray-600">
          No existing survey found for {disaster?.name}
        </div>
      );
    }
  }
  
  return (
    <div className="text-xs text-gray-600">
      Please select a disaster to submit a survey
    </div>
  );
};

  const closeCaseNumberPopup = () => {
    setShowCaseNumberPopup(false);
  };

  const CaseNumberModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-4 text-center">Survey Submitted Successfully</h2>
        <p className="mb-4 text-center">Thank you for your responses.</p>
        <p className="text-lg font-semibold mb-4 text-center">
          Your case number is:
        </p>
        <p className="text-2xl font-bold text-blue-600 mb-6 text-center">{caseNumber}</p>
        <div className="text-center">
          <button
            onClick={() => setShowCaseNumberPopup(false)}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <form className="relative mt-2 flex w-full flex-col items-start justify-center leading-[normal] tracking-[normal]">
      {showCaseNumberPopup && <CaseNumberModal />}
      <div className="relative mb-2 inline-block max-w-full font-poppins font-medium leading-[21px] text-darkslategray-200">
          <p>
            Below are the essential tools at your disposal to effectively track your disaster recovery journey.
            Utilize the Damage Assessment tool to record losses, document
            assistance received in the Assistance Log, and track your progress
            towards recovery by monitoring your remaining deficit in the Recovery
            Plan.
          </p>
      </div>

      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1  text-sm font-bold text-blue-500">
              IMPACTED ADDRESS
            </h2>
            <p className="font-poppins text-xs font-medium text-gray-500">
              Last Edited: {formatLastEditedTime(lastEditedTime)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            {isEditMode ? (
              <>
                <button
                  type="button"
                  className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                  onClick={saveChanges}
                >
                  <FaSave className="h-3 w-3" />
                  Save
                </button>
                <button
                  type="button"
                  className="flex cursor-pointer items-center gap-1 rounded bg-gray-400 px-3 py-1.5 text-xs font-semibold text-black"
                  onClick={cancelEditing}
                >
                  <FaTimes className="h-3 w-3" />
                  Cancel
                </button>
              </>
            ) : (
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={startEditing}
              >
                <FaEdit className="h-3 w-3" />
                Edit
              </button>
            )}
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200"
              onClick={togglePersonalInfo}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isPersonalInfoVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isPersonalInfoVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <div className="flex flex-row gap-4">
              {renderField("First Name", "first_name", "", false, "flex-1")}
              {renderField("Last Name", "last_name", "", false, "flex-1")}
              {renderField(
                "Date of Birth",
                "birth_date",
                "MM/DD/YYYY",
                false,
                "flex-1"
              )}
            </div>

            {renderField("Address 1", "address1", "")}
            {renderField("Address 2", "address2", "")}
            <div className="flex flex-row gap-4">
              {renderField("City", "city", "", false, "flex-1")}
              {renderField(
                "Select a state",
                "state",
                "Select a state",
                true,
                "flex-1"
              )}
              {renderField("Zip", "zip", "e.g., 62704", false, "flex-1")}
            </div>
            <div className="flex flex-row gap-4">
              {renderField("Email", "email", "", false, "flex-1")}
              {renderField(
                "Phone Number",
                "mobile_number",
                "",
                false,
                "flex-1"
              )}
              {renderField("Sex", "gender", "Sex", true, "flex-1")}
            </div>
            <header className="flex flex-row items-center justify-between self-stretch">
              <div>
                <h2 className="text-sm font-bold text-blue-500">
                  Alternate Contact
                </h2>
              </div>
            </header>
            <div className="flex flex-row gap-4">
              {renderField(
                "Contact Name",
                "alternate_contact_name",
                "",
                false,
                "flex-1"
              )}
              {renderField("Email", "alternate_email", "", false, "flex-1")}
              {renderField(
                "Phone Number",
                "alternate_mobile_number",
                "",
                false,
                "flex-1"
              )}
            </div>
            <div className="mb-2 flex flex-row gap-2">
              <div className="w-auto">
                <p className="text-gray-600 mb-1 pb-3 pt-1 text-xs">
                  Do you have a disability?
                  {isEditMode && (
                    <span
                      className="ml-1 text-red-500"
                      style={{ fontSize: "26px" }}
                    >
                      *
                    </span>
                  )}
                </p>
                <div className="flex gap-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="disability"
                      value="Yes"
                      checked={tempHouseholdFormData.disability === true}
                      onChange={(e) => {
                        handleHouseholdInputChange(e);
                        handleHouseholdInputBlur();
                      }}
                      disabled={!isEditMode}
                      className={`mr-2 ${!isEditMode ? "" : "cursor-pointer"}`}
                    />
                    <span className="text-xs">Yes</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="disability"
                      value="No"
                      checked={tempHouseholdFormData.disability === false}
                      onChange={(e) => {
                        handleHouseholdInputChange(e);
                        handleHouseholdInputBlur();
                      }}
                      disabled={!isEditMode}
                      className={`mr-2 ${!isEditMode ? "" : "cursor-pointer"}`}
                    />
                    <span className="text-xs">No</span>
                  </label>
                </div>
                {isEditMode && validationErrors.disability && (
                  <div className="mt-1 text-xs text-red-500">
                    {validationErrors.disability}
                  </div>
                )}
              </div>

              <div className="w-auto" style={{ marginLeft: "50px" }}>
                <p className="text-gray-600 mb-1 pb-3 pt-1 text-xs">
                  Are you Active Military / Vet?
                  {isEditMode && (
                    <span
                      className="ml-1 text-red-500"
                      style={{ fontSize: "26px" }}
                    >
                      *
                    </span>
                  )}
                </p>
                <div className="flex gap-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="military_vet"
                      value="Yes"
                      checked={tempHouseholdFormData.military_vet === true}
                      onChange={(e) => {
                        handleHouseholdInputChange(e);
                        handleHouseholdInputBlur();
                      }}
                      disabled={!isEditMode}
                      className={`mr-2 ${!isEditMode ? "" : "cursor-pointer"}`}
                    />
                    <span className="text-xs">Yes</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="military_vet"
                      value="No"
                      checked={tempHouseholdFormData.military_vet === false}
                      onChange={(e) => {
                        handleHouseholdInputChange(e);
                        handleHouseholdInputBlur();
                      }}
                      disabled={!isEditMode}
                      className={`mr-2 ${!isEditMode ? "" : "cursor-pointer"}`}
                    />
                    <span className="text-xs">No</span>
                  </label>
                </div>
                {isEditMode && validationErrors.military_vet && (
                  <div className="mt-1 text-xs text-red-500">
                    {validationErrors.military_vet}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </section>

      <div className="mt-3 flex w-full flex-col space-y-2">
        <div>
          <CheckBoxComponent
            checked={!!data?.is_displaced}
            label="Are you currently displaced?"
            change={onDisplaced}
          ></CheckBoxComponent>
        </div>
        {!!data?.is_displaced && (
          <div className="flex flex-col space-y-3">
            <div className="flex items-center justify-between border border-black">
              <div className="mt-3">
                <h2 className="mb-1  text-sm font-bold text-blue-500">
                  DISPLACED ADDRESS
                </h2>
                <p className="font-poppins text-xs font-medium text-gray-500">
                  Last Edited:{" "}
                  {formatLastEditedTime(data?.d_updated_at?.toDate() || null)}
                </p>
              </div>
              <div className="flex items-center gap-2">
                {displacedEditMode ? (
                  <>
                    <button
                      type="button"
                      className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                      onClick={onSaveDisplacedDetails}
                    >
                      <FaSave className="h-3 w-3" />
                      Save
                    </button>
                    <button
                      type="button"
                      className="flex cursor-pointer items-center gap-1 rounded bg-gray-400 px-3 py-1.5 text-xs font-semibold text-black"
                      onClick={onEditDisplacedInfo(false)}
                    >
                      <FaTimes className="h-3 w-3" />
                      Cancel
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                    onClick={onEditDisplacedInfo(true)}
                  >
                    <FaEdit className="h-3 w-3" />
                    Edit
                  </button>
                )}
                <button
                  type="button"
                  className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200"
                  onClick={toggleDisplacedInfo}
                >
                  <span
                    className={`transition-transform duration-300 ease-in-out ${
                      isDisplacedInfoVisible ? "rotate-0" : "rotate-180"
                    }`}
                  >
                    <FaChevronDown />
                  </span>
                </button>
              </div>
            </div>
            {isDisplacedInfoVisible && (
              <>
                <div className="flex space-x-3">
                  {renderDisplacedField(
                    "Address 1",
                    "d_address_1",
                    "",
                    false,
                    "flex-1"
                  )}
                </div>
                <div className="flex">
                  {renderDisplacedField(
                    "Address 2",
                    "d_address_2",
                    "",
                    false,
                    "flex-1"
                  )}
                </div>
                <div className="flex space-x-3">
                  {renderDisplacedField("City", "d_city", "", false, "flex-1")}
                  {renderDisplacedField("State", "d_state", "", true, "flex-1")}
                  {renderDisplacedField("Zip", "d_zip", "", false, "flex-1")}
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {/* Household Information */}
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pt-4">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1  text-sm font-bold text-blue-500">
              HOUSEHOLD INFORMATION
            </h2>
            <p className="font-poppins text-xs font-medium text-gray-500">
              Last Edited: {formatLastEditedTime(lastHouseholdEditedTime)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            {isHouseholdEditMode ? (
              <>
                <button
                  type="button"
                  className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                  onClick={saveHouseholdChanges}
                >
                  <FaSave className="h-3 w-3" />
                  Save
                </button>
                <button
                  type="button"
                  className="flex cursor-pointer items-center gap-1 rounded bg-gray-400 px-3 py-1.5 text-xs font-semibold text-black"
                  onClick={cancelHouseholdEditing}
                >
                  <FaTimes className="h-3 w-3" />
                  Cancel
                </button>
              </>
            ) : (
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={startHouseholdEditing}
              >
                <FaEdit className="h-3 w-3" />
                Edit
              </button>
            )}
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleHouseholdInfo}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isHouseholdInfoVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isHouseholdInfoVisible && (
          <div className="mt-2 flex flex-col gap-2 self-stretch">
            <div className="flex flex-row gap-4">
              {renderHouseholdField(
                "Annual Household Income",
                "annual_income",
                "Select Annual Income",
                true,
                "flex-1",
                "number",
                true // Mark as optional
              )}
              <div className="flex-1"></div>
              <div className="flex-1"></div>
            </div>
            <div className="flex flex-row gap-4">
              {renderHouseholdField(
                "Owner or Renter?",
                "owner_or_renter",
                "Select Owner or Renter",
                true,
                "flex-1"
              )}
              {renderHouseholdField(
                "Do you have insurance?",
                "insurance",
                "Select Yes or No",
                true,
                "flex-1"
              )}
              {renderHouseholdField(
                "Household size",
                "household_size",
                "",
                false,
                "flex-1"
              )}
            </div>
          </div>
        )}
      </section>

      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pt-4">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1  text-sm font-bold text-blue-500">
              ADDITIONAL HOUSEHOLD MEMBERS
            </h2>
            <p className="font-poppins text-xs font-medium text-gray-500">
              Last Edited:{" "}
              {formatLastEditedTime(lastHouseholdMembersEditedTime)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
              onClick={handleAddClick}
            >
              <FaPlus className="h-3 w-3" />
              Add
            </button>

            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleHouseholdMembers}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isHouseholdMembersVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isHouseholdMembersVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              ref={gridRef}
              dataSource={filteredHouseholdMembers}
              allowSorting={true}
              sortSettings={sortSettings}
              editSettings={editing}
              filterSettings={filterSettings}
              allowFiltering={true}
              actionComplete={actionComplete}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="household_member_id"
                  headerText="household_member_id"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="first_name"
                  headerText="First Name"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="last_name"
                  headerText="Last Name"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="date_of_birth"
                  headerText="Date of Birth"
                  width="100"
                  format="MM/dd/yyyy"
                  editTemplate={(props) => (
                    <DatePickerTemplate
                      label="Date of Birth *"
                      onChange={(val) => {
                        setDob(() => val);
                      }}
                      value={props.date_of_birth}
                    />
                  )}
                />
                <ColumnDirective
                  field="relationship"
                  headerText="Relationship"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="disability"
                  headerText="Disability"
                  width="100"
                  validationRules={{ required: true }}
                  editType="dropdownedit"
                  edit={militaryvetParams}
                />
                <ColumnDirective
                  field="military_vet"
                  headerText="Active Military/ Vet"
                  width="100"
                  editType="dropdownedit"
                  edit={militaryvetParams}
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  headerText="Actions"
                  width="120"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Edit, Sort, Filter, CommandColumn, ColumnMenu]}
              />
            </GridComponent>
          </div>
        )}
      </section>

      {/* Pets Section */}
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pb-4 pt-4">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1  text-sm font-bold text-blue-500">PETS</h2>
            <p className="font-poppins text-xs font-medium text-gray-500">
              Last Edited: {formatLastEditedTime(lastPetsEditedTime)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
              onClick={handleAddPet}
            >
              <FaPlus className="h-3 w-3" />
              Add
            </button>
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={togglePets}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isPetsVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isPetsVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              ref={petsGridRef}
              dataSource={filteredPets}
              keyField="pet_id"
              allowSorting={true}
              sortSettings={sortSettings}
              editSettings={editing}
              filterSettings={filterSettings}
              allowFiltering={true}
              actionComplete={petsActionComplete}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="pet_id"
                  headerText="Pet ID"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="pet_name"
                  headerText="Pet Name"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="pet_color"
                  headerText="Pet Color"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="pet_breed"
                  headerText="Pet Breed"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="pet_size"
                  headerText="Pet Size"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  headerText="Actions"
                  width="120"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Edit, Sort, Filter, CommandColumn, ColumnMenu]}
              />
            </GridComponent>
          </div>
        )}
      </section>
      {/* Survey Section */}
      <section
        className={`mt-4 box-border flex w-full max-w-[1628px] flex-col items-start justify-start gap-2.5 overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white px-[19px] py-[18px]`}
      >
        <header
          id="survey-questions-header"
          className="sticky top-0 flex w-full flex-row items-center justify-between bg-white"
        >
          <h2 className="mb-1  text-sm font-bold text-blue-500">
            SAMPLE SURVEY QUESTIONS
          </h2>
          {renderSurveyStatus()}
          <div className="flex items-center gap-2.5">
            <button
              className="flex cursor-pointer items-center gap-2 rounded-md border border-dodgerblue px-[19px] py-1 text-xs text-dodgerblue"
              onClick={scrollToBottom}
            >
              <FontAwesomeIcon icon={faArrowDown} className="h-4 w-4" />
              Jump to bottom
            </button>
            <button
              type="button"
              className="flex items-center gap-2 rounded-md bg-dodgerblue px-5 py-1.5 text-xs text-white"
              onClick={handleSubmitSurvey} // Call the submit function
            >
              <FontAwesomeIcon icon={faCheck} className="h-4 w-4" />
              Submit Survey
            </button>
          </div>
        </header>

        <main className="flex w-full flex-wrap gap-4  text-xs text-darkslategray-100">
          {surveyQuestions.map((question, index) => (
            <div
              key={index}
              className="flex w-[calc(33.333%-11px)] mq1125:w-[calc(50%-8px)] mq800:w-full"
            >
              <SurveyQuestion
                question={question.question}
                type={question.type}
                options={question.options || []}
                onResponseChange={handleResponseChange}
                userResponse={responses[question.question] || ""}
              />
            </div>
          ))}
        </main>
        <footer
          id="survey-questions-footer"
          className="sticky bottom-0 flex w-full flex-row items-center justify-end bg-white"
        >
          <div className="flex items-center gap-2.5">
            <button
              className="flex cursor-pointer items-center gap-2 rounded-md border border-dodgerblue px-[19px] py-1 text-xs text-dodgerblue"
              onClick={scrollToTop}
            >
              <FontAwesomeIcon icon={faArrowUp} className="h-4 w-4" />
              Jump to top
            </button>
            <button
              type="button"
              className="flex items-center gap-2 rounded-md bg-dodgerblue px-5 py-1.5 text-xs text-white"
              onClick={handleSubmitSurvey} // Call the submit function
            >
              <FontAwesomeIcon icon={faCheck} className="h-4 w-4" />
              Submit Survey
            </button>
          </div>
        </footer>
      </section>
    </form>
  );
};

ImmediateResponseSurvey.propTypes = {
  className: PropTypes.string,
};

export default ImmediateResponseSurvey;
