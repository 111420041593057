import React from "react";

const Privacy = () => {
  return (
    <div className="h-[100vh] overflow-auto">
      <div className="mx-auto flex w-[70%] flex-col py-10 text-base">
        <div className="mb-5">
          <h1 className="text-3xl my-10 flex justify-center font-bold">
            Blue Sky Privacy Policy
          </h1>

          <p>Blue Sky collects some Personal Data from its Users.</p>
        </div>

        <div className="flex flex-col border-b-2">
          <div className="mb-5">
            <p>
              This document contains a section dedicated to California consumers
              and their privacy rights.
            </p>

            <p>
              This document contains a section dedicated to Virginia consumers
              and their privacy rights.
            </p>

            <p>
              This document contains a section dedicated to Colorado consumers
              and their privacy rights.
            </p>

            <p>
              This document contains a section dedicated to Connecticut
              consumers and their privacy rights.
            </p>

            <p>
              This document contains a section dedicated to Utah consumers and
              their privacy rights.
            </p>

            <p>
              This document contains a section dedicated to Brazilian Users and
              their privacy rights.
            </p>

            <p>
              This document can be printed for reference by using the print
              command in the settings of any browser.
            </p>

            <p>
              Blue Sky follows the{" "}
              <a
                className="text-blue-600 underline"
                href="https://developers.google.com/terms/api-services-user-data-policy"
              >
                Google API Services User Data Policy
              </a>
              .
            </p>
          </div>
        </div>

        {/* <div className="flex flex-col border-b-2">
          <div className="mb-5">
            <p className="text-xl my-3 font-bold">Owner and Data Controller</p>

            <p>Blue Sky LLC</p>

            <p>15333 N PIMA RD STE 305</p>

            <p>Scottsdale, AZ</p>

            <p>85260</p>

            <p>Owner contact email: hello@clarity.video</p>
          </div>
        </div> */}

        <div className="flex flex-col border-b-2">
          <div className="mb-5">
            <p className="text-xl my-3 font-bold">Types of Data collected</p>

            <p>
              Among the types of Personal Data that Blue Sky collects, by itself
              or through third parties, there are: city; device information;
              session statistics; latitude (of city); longitude (of city);
              browser information; Compose; email address; Usage Data; Trackers;
              geographic position; various types of Data; phone number; first
              name; last name; Current City; company name; username; profile
              picture; Data communicated while using the
              service; invoicing information; password; country.
            </p>

            <p className="mb-5">
              Complete details on each type of Personal Data collected are
              provided in the dedicated sections of this privacy policy or by
              specific explanation texts displayed prior to the Data collection.
            </p>

            <p>
              Personal Data may be freely provided by the User, or, in case of
              Usage Data, collected automatically when using Blue Sky. Unless
              specified otherwise, all Data requested by Blue Sky is mandatory
              and failure to provide this Data may make it impossible for Blue
              Sky to provide its services. In cases where Blue Sky specifically
              states that some Data is not mandatory, Users are free not to
              communicate this Data without consequences to the availability or
              the functioning of the Service.
            </p>

            <p>
              Users who are uncertain about which Personal Data is mandatory are
              welcome to contact the Owner. Any use of Cookies – or of other
              tracking tools — by Blue Sky or by the owners of third-party
              services used by Blue Sky serves the purpose of providing the
              Service required by the User, in addition to any other purposes
              described in the present document and in the Cookie Policy.
            </p>

            <p>
              Users are responsible for any third-party Personal Data obtained,
              published or shared through Blue Sky.
            </p>
          </div>
        </div>

        <div className="flex flex-col border-b-2">
          <div className="mb-5">
            <p className="text-xl my-3 font-bold">Collected Data AI Policy</p>

            <p>
              Blue Sky LLC does not share any user data to any third-party AI
              company.
            </p>

            <p>
              Blue Sky AI functionality only requires users' first name and last
              name.
            </p>
          </div>
        </div>

        <div className="flex flex-col border-b-2">
          <div className="mb-5">
            <p className="text-xl my-3 font-bold">
              Mode and place of processing the Data
            </p>

            <p className="text-lg my-2 font-bold">Methods of processing</p>

            <p>
              The Owner takes appropriate security measures to prevent
              unauthorized access, disclosure, modification, or unauthorized
              destruction of the Data.
            </p>

            <p>
              The Data processing is carried out using computers and/or IT
              enabled tools, following organizational procedures and modes
              strictly related to the purposes indicated. In addition to the
              Owner, in some cases, the Data may be accessible to certain types
              of persons in charge, involved with the operation of Blue Sky
              (administration, sales, marketing, legal, system administration)
              or external parties (such as third-party technical service
              providers, mail carriers, hosting providers, IT companies,
              communications agencies) appointed, if necessary, as Data
              Processors by the Owner. The updated list of these parties may be
              requested from the Owner at any time.
            </p>

            <p className="text-lg my-2 font-bold">Place</p>

            <p>
              The Data is processed at the Owner's operating offices and in any
              other places where the parties involved in the processing are
              located.
            </p>

            <p>
              Depending on the User's location, data transfers may involve
              transferring the User's Data to a country other than their own. To
              find out more about the place of processing of such transferred
              Data, Users can check the section containing details about the
              processing of Personal Data.
            </p>

            <p className="text-lg my-2 font-bold">Retention time</p>

            <p>
              Unless specified otherwise in this document, Personal Data shall
              be processed and stored for as long as required by the purpose
              they have been collected for and may be retained for longer due to
              applicable legal obligation or based on the Users’ consent.
            </p>
          </div>
        </div>

        <div className="flex flex-col border-b-2">
          <div className="mb-5">
            <p className="text-xl my-3 font-bold">The purposes of processing</p>

            <p>
              The Data concerning the User is collected to allow the Owner to
              provide its Service, comply with its legal obligations, respond to
              enforcement requests, protect its rights and interests (or those
              of its Users or third parties), detect any malicious or fraudulent
              activity, as well as the following: Analytics, Registration and
              authentication, Managing contacts and sending messages,
              Infrastructure monitoring, Location-based interactions, Hosting
              and backend infrastructure, User database management, Collection
              of privacy-related preferences, Contacting the User, Handling
              activities related to productivity, Selling goods and services
              online and Registration and authentication provided directly by
              Blue Sky.
            </p>

            <p>
              For specific information about the Personal Data used for each
              purpose, the User may refer to the section “Detailed information
              on the processing of Personal Data”.
            </p>
          </div>
        </div>

        <div className="flex flex-col border-b-2">
          <div className="mb-5 flex flex-col space-y-5">
            <p className="text-xl mt-3 font-bold">
              Detailed information on the processing of Personal Data
            </p>

            <p>
              Personal Data is collected for the following purposes and using
              the following services:
            </p>

            <div className="flex w-full space-x-5">
              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">Analytics</p>

                <p>
                  The services contained in this section enable the Owner to
                  monitor and analyze web traffic and can be used to keep track
                  of User behavior.
                </p>

                <p>Google Analytics 4 (Google LLC)</p>

                <p>
                  Google Analytics 4 is a web analysis service provided by
                  Google LLC (“Google”). Google utilizes the Data collected to
                  track and examine the use of Blue Sky, to prepare reports on
                  its activities and share them with other Google services.
                </p>

                <p>
                  Google may use the Data collected to contextualize and
                  personalize the ads of its own advertising network. In Google
                  Analytics 4, IP addresses are used at collection time and then
                  discarded before Data is logged in any data center or server.
                  Users can learn more by consulting Google’s official
                  documentation.
                </p>

                <p>
                  Personal Data processed: browser information; city; device
                  information; latitude (of city); longitude (of city); session
                  statistics.
                </p>

                <p>
                  Place of processing: United States – Privacy Policy – Opt Out.
                </p>

                <p>
                  Category of personal information collected according to the
                  CCPA: identifiers; internet or other electronic network
                  activity information.
                </p>

                <p>This processing constitutes:</p>

                <li>
                  a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                </li>

                <li>
                  targeted advertising according to the CPA, CTDPA and UCPA
                </li>
              </div>

              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">
                  Collection of privacy-related preferences
                </p>

                <p>
                  This type of service allows Blue Sky to collect and store
                  Users’ preferences related to the collection, use, and
                  processing of their personal information, as requested by the
                  applicable privacy legislation.
                </p>

                <p>
                  iubenda Privacy Controls and Cookie Solution (iubenda srl)
                </p>

                <p>
                  The iubenda Privacy Controls and Cookie Solution allows the
                  Owner to collect and store Users’ preferences related to the
                  processing of personal information, and in particular to the
                  use of Cookies and other Trackers on Blue Sky.
                </p>

                <p>Personal Data processed: Trackers.</p>

                <p>Place of processing: Italy – Privacy Policy.</p>

                <p>
                  Category of personal information collected according to the
                  CCPA: internet or other electronic network activity
                  information.
                </p>

                <p>This processing constitutes:</p>

                <li>a sale according to the CPA, CTDPA and UCPA</li>

                <li>
                  targeted advertising according to the CPA, CTDPA and UCPA
                </li>
              </div>
            </div>

            <div className="flex w-full space-x-5">
              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">Contacting the User</p>

                <p>Contact form (Blue Sky)</p>

                <p>
                  By filling in the contact form with their Data, the User
                  authorizes Blue Sky to use these details to reply to requests
                  for information, quotes or any other kind of request as
                  indicated by the form’s header.
                </p>

                <p>
                  Personal Data processed: email address; first name; last name.
                </p>

                <p>
                  Category of personal information collected according to the
                  CCPA: identifiers.
                </p>

                <p>This processing constitutes:</p>

                <li>a sale according to the VCDPA</li>

                <p>Mailing list or newsletter (Blue Sky)</p>

                <p>
                  By registering on the mailing list or for the newsletter, the
                  User’s email address will be added to the contact list of
                  those who may receive email messages containing information of
                  commercial or promotional nature concerning Blue Sky. Your
                  email address might also be added to this list as a result of
                  signing up to Blue Sky.
                </p>

                <p>
                  Personal Data processed: email address; first name; last name.
                </p>

                <p>
                  Category of personal information collected according to the
                  CCPA: identifiers.
                </p>

                <p>This processing constitutes:</p>

                <li>a sale according to the VCDPA, CPA, CTDPA and UCPA</li>

                <p>Phone contact (Blue Sky)</p>

                <p>
                  Users that provided their phone number might be contacted for
                  commercial or promotional purposes related to Blue Sky, as
                  well as for fulfilling support requests.
                </p>

                <p>Personal Data processed: phone number.</p>

                <p>
                  Category of personal information collected according to the
                  CCPA: identifiers.
                </p>

                <p>This processing constitutes:</p>

                <li>
                  a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                </li>

                <li>
                  targeted advertising according to the CPA, CTDPA and UCPA
                </li>
              </div>

              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">
                  Handling activities related to productivity
                </p>

                <p>
                  This type of service helps the Owner to manage tasks,
                  collaboration and, in general, activities related to
                  productivity. In using this type of service, Data of Users
                  will be processed and may be retained, depending on the
                  purpose of the activity in question.
                </p>

                <p>
                  These services may be integrated with a wide range of
                  third-party services disclosed within this privacy policy to
                  enable the Owner to import or export Data needed for the
                  relative activity.
                </p>

                <p>Gmail (Google LLC)</p>

                <p>
                  Gmail is a service that manages email communication provided
                  by Google LLC. Such email communication is not scanned by
                  Google for advertising purposes. In addition, Google does not
                  collect or use data inside this service for advertising
                  purposes in any other way.
                </p>

                <p>
                  Personal Data processed: email address; first name; last name;
                  profile picture; username.
                </p>

                <p>Place of processing: United States – Privacy Policy.</p>

                <p>
                  Category of personal information collected according to the
                  CCPA: identifiers; audio, electronic, visual, thermal,
                  olfactory, or similar information.
                </p>
              </div>
            </div>

            <div className="flex w-full space-x-5">
              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">
                  Hosting and backend infrastructure
                </p>

                <p>
                  This type of service has the purpose of hosting Data and files
                  that enable Blue Sky to run and be distributed as well as to
                  provide a ready-made infrastructure to run specific features
                  or parts of Blue Sky.
                </p>

                <p>
                  Some services among those listed below, if any, may work
                  through geographically distributed servers, making it
                  difficult to determine the actual location where the Personal
                  Data are stored.
                </p>

                <p>Amazon Web Services (AWS) (Amazon Web Services, Inc.)</p>

                <p>
                  Amazon Web Services (AWS) is a hosting and backend service
                  provided by Amazon Web Services, Inc.
                </p>

                <p>
                  Personal Data processed: various types of Data as specified in
                  the privacy policy of the service.
                </p>

                <p>Place of processing: United States – Privacy Policy.</p>

                <p>
                  Category of personal information collected according to the
                  CCPA: internet or other electronic network activity
                  information.
                </p>

                <p>This processing constitutes:</p>

                <li>a sale according to the CPA, CTDPA and UCPA</li>

                <li>
                  targeted advertising according to the CPA, CTDPA and UCPA
                </li>

                <p>Cloudinary (Cloudinary Ltd.)</p>

                <p>
                  Cloudinary is a hosting service provided by Cloudinary Ltd.
                </p>

                <p>
                  Personal Data processed: various types of Data as specified in
                  the privacy policy of the service.
                </p>

                <p>Place of processing: Israel – Privacy Policy.</p>

                <p>
                  Category of personal information collected according to the
                  CCPA: internet or other electronic network activity
                  information.
                </p>

                <p>This processing constitutes:</p>

                <li>a sale according to the CPA, CTDPA and UCPA</li>

                <li>
                  targeted advertising according to the CPA, CTDPA and UCPA
                </li>
              </div>

              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">
                  Infrastructure monitoring
                </p>

                <p>
                  This type of service allows Blue Sky to monitor the use and
                  behavior of its components so its performance, operation,
                  maintenance and troubleshooting can be improved.
                </p>

                <p>
                  Which Personal Data are processed depends on the
                  characteristics and mode of implementation of these services,
                  whose function is to filter the activities of Blue Sky.
                </p>

                <p>Sentry (Functional Software, Inc. )</p>

                <p>
                  Sentry is a monitoring service provided by Functional
                  Software, Inc. .
                </p>

                <p>
                  Personal Data processed: various types of Data as specified in
                  the privacy policy of the service.
                </p>

                <p>Place of processing: United States – Privacy Policy.</p>

                <p>
                  Category of personal information collected according to the
                  CCPA: internet or other electronic network activity
                  information.
                </p>

                <p>This processing constitutes:</p>

                <li>a sale according to the CPA, CTDPA and UCPA</li>

                <li>
                  targeted advertising according to the CPA, CTDPA and UCPA
                </li>
              </div>
            </div>

            <div className="flex w-full space-x-5">
              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">
                  Location-based interactions
                </p>

                <p>Geolocation (Blue Sky)</p>

                <p>
                  Blue Sky may collect, use, and share User location Data in
                  order to provide location-based services. Most browsers and
                  devices provide tools to opt out from this feature by default.
                  If explicit authorization has been provided, the User’s
                  location data may be tracked by Blue Sky.
                </p>

                <p>Personal Data processed: geographic position.</p>

                <p>
                  Category of personal information collected according to the
                  CCPA: geolocation data.
                </p>

                <p>This processing constitutes:</p>

                <li>a sale according to the CPA, CTDPA and UCPA</li>

                <li>
                  targeted advertising according to the CPA, CTDPA and UCPA
                </li>
              </div>

              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">
                  Managing contacts and sending messages
                </p>

                <p>
                  This type of service makes it possible to manage a database of
                  email contacts, phone contacts or any other contact
                  information to communicate with the User.
                </p>

                <p>
                  These services may also collect data concerning the date and
                  time when the message was viewed by the User, as well as when
                  the User interacted with it, such as by clicking on links
                  included in the message.
                </p>

                <p>HubSpot Email (HubSpot, Inc.)</p>

                <p>
                  HubSpot Email is an email address management and message
                  sending service provided by HubSpot, Inc.
                </p>

                <p>
                  Personal Data processed: email address; Trackers; Usage Data.
                </p>

                <p>Place of processing: United States – Privacy Policy.</p>

                <p>
                  Category of personal information collected according to the
                  CCPA: identifiers; internet or other electronic network
                  activity information.
                </p>

                <p>This processing constitutes:</p>

                <li>
                  a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                </li>

                <li>a sharing according to the CCPA</li>

                <li>
                  targeted advertising according to the VCDPA, CPA, CTDPA and
                  UCPA
                </li>

                <p>Mailgun (Mailgun Technologies, Inc.)</p>

                <p>
                  Mailgun is an email address management and message sending
                  service provided by Mailgun Technologies, Inc.
                </p>

                <p>Personal Data processed: email address.</p>

                <p>Place of processing: United States – Privacy Policy.</p>

                <p>
                  Category of personal information collected according to the
                  CCPA: identifiers.
                </p>

                <p>This processing constitutes:</p>

                <li>
                  a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                </li>

                <li>a sharing according to the CCPA</li>

                <li>
                  targeted advertising according to the VCDPA, CPA, CTDPA and
                  UCPA
                </li>

                <p>Twilio (Twilio, Inc.)</p>

                <p>
                  Twilio is a phone numbers management and communication service
                  provided by Twilio, Inc.
                </p>

                <p>Personal Data processed: phone number.</p>

                <p>Place of processing: United States – Privacy Policy.</p>

                <p>
                  Category of personal information collected according to the
                  CCPA: identifiers.
                </p>

                <p>This processing constitutes:</p>

                <li>
                  a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                </li>

                <li>a sharing according to the CCPA</li>

                <li>
                  targeted advertising according to the VCDPA, CPA, CTDPA and
                  UCPA
                </li>

                <p>
                  Google Application Services (Sign In, Gmail Email Sending,
                  Google Contacts Importing)
                </p>

                <p>
                  Blue Sky's use and transfer to any other app of information
                  received from Google APIs will adhere to the Google API
                  Services User Data Policy, including the Limited Use
                  requirements.
                </p>
              </div>
            </div>

            <div className="flex w-full space-x-5">
              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">
                  Registration and authentication
                </p>

                <p>
                  By registering or authenticating, Users allow Blue Sky to
                  identify them and give them access to dedicated services.
                </p>

                <p>
                  Depending on what is described below, third parties may
                  provide registration and authentication services. In this
                  case, Blue Sky will be able to access some Data, stored by
                  these third-party services, for registration or identification
                  purposes.
                </p>

                <p>
                  Some of the services listed below may also collect Personal
                  Data for targeting and profiling purposes; to find out more,
                  please refer to the description of each service.
                </p>

                <p>
                  Gmail permissions to access User Data (OAuth addition) (Google
                  LLC)
                </p>

                <p>
                  To provide its service, Blue Sky uses restricted scopes for
                  Gmail which allow access to User Data related to this Google
                  service (as indicated below). This access is provided by
                  Google LLC under strict terms. Here's what this means for such
                  User Data:
                </p>

                <li>
                  The use of User Data is limited to providing or improving
                  User-facing features. User Data will not be used for serving
                  ads, including retargeting, personalized, or interest-based
                  advertising.
                </li>

                <li>
                  User Data will be transferred to third parties only if
                  necessary, to provide or improve User-facing features and as
                  necessary to comply with applicable law or as part of a
                  merger, acquisition, or sale of assets with notice to Users.
                </li>

                <li>Humans will not read the Data unless:</li>

                <li>
                  the Owner has obtained the User's affirmative agreement for
                  specific messages;
                </li>

                <li>
                  it is necessary for security purposes and/or to comply with
                  applicable law, or;
                </li>

                <li>
                  the use is limited to internal operations and the Data
                  (including derivations) have been aggregated and anonymized.
                </li>

                <p>Personal Data processed: Compose.</p>

                <p>Place of processing: United States – Privacy Policy.</p>

                <p>
                  Category of personal information collected according to the
                  CCPA: inferences drawn from other personal information.
                </p>

                <p>This processing constitutes:</p>

                <li>
                  a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                </li>

                <li>
                  targeted advertising according to the CPA, CTDPA and UCPA
                </li>

                <p>Google OAuth (Google LLC)</p>

                <p>
                  Google OAuth is a registration and authentication service
                  provided by Google LLC and is connected to the Google network.
                </p>

                <p>
                  Personal Data processed: various types of Data as specified in
                  the privacy policy of the service.
                </p>

                <p>Place of processing: United States – Privacy Policy.</p>

                <p>
                  Category of personal information collected according to the
                  CCPA: internet or other electronic network activity
                  information.
                </p>

                <p>This processing constitutes:</p>

                <li>
                  a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                </li>

                <p>Windows Live Connect (Microsoft Corporation)</p>

                <p>
                  Microsoft Live Connect is a registration and authentication
                  service provided by Microsoft Corporation and is connected to
                  the Microsoft network.
                </p>

                <p>
                  Personal Data processed: various types of Data as specified in
                  the privacy policy of the service.
                </p>

                <p>Place of processing: United States – Privacy Policy.</p>

                <p>
                  Category of personal information collected according to the
                  CCPA: internet or other electronic network activity
                  information.
                </p>

                <p>This processing constitutes:</p>

                <li>
                  a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                </li>

                <li>
                  targeted advertising according to the CPA, CTDPA and UCPA
                </li>

                <p>Dropbox Oauth (Dropbox, Inc.)</p>

                <p>
                  Dropbox Oauth is a registration and authentication service
                  provided by Dropbox, Inc. and is connected to the Dropbox
                  service.
                </p>

                <p>Personal Data processed: Trackers; various types of Data.</p>

                <p>Place of processing: United States – Privacy Policy.</p>

                <p>
                  Category of personal information collected according to the
                  CCPA: internet or other electronic network activity
                  information.
                </p>

                <p>This processing constitutes:</p>

                <li>
                  a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                </li>

                <li>
                  targeted advertising according to the CPA, CTDPA and UCPA
                </li>
              </div>

              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">
                  Registration and authentication provided directly by Blue Sky
                </p>

                <p>
                  By registering or authenticating, Users allow Blue Sky to
                  identify them and give them access to dedicated services. The
                  Personal Data is collected and stored for registration or
                  identification purposes only. The Data collected are only
                  those necessary for the provision of the service requested by
                  the Users.
                </p>

                <p>Direct registration (Blue Sky)</p>

                <p>
                  The User registers by filling out the registration form and
                  providing the Personal Data directly to Blue Sky.
                </p>

                <p>
                  Personal Data processed: company name; country; email address;
                  first name; last name; password; phone number; profile
                  picture; username.
                </p>

                <p>
                  Category of personal information collected according to the
                  CCPA: identifiers; commercial information; audio, electronic,
                  visual, thermal, olfactory, or similar information.
                </p>
              </div>
            </div>

            <div className="flex w-full space-x-5">
              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">
                  User database management
                </p>

                <p>
                  This type of service allows the Owner to build user profiles
                  by starting from an email address, a personal name, or other
                  information that the User provides to Blue Sky, as well as to
                  track User activities through analytics features. This
                  Personal Data may also be matched with publicly available
                  information about the User (such as social networks' profiles)
                  and used to build private profiles that the Owner can display
                  and use for improving Blue Sky.
                </p>

                <p>
                  Some of these services may also enable the sending of timed
                  messages to the User, such as emails based on specific actions
                  performed on Blue Sky.
                </p>

                <p>HubSpot CRM (HubSpot, Inc.)</p>

                <p>
                  HubSpot CRM is a User database management service provided by
                  HubSpot, Inc.
                </p>

                <p>
                  Personal Data processed: company name; Current City; email
                  address; first name; last name; phone number; username.
                </p>

                <p>Place of processing: United States – Privacy Policy.</p>

                <p>
                  Category of personal information collected according to the
                  CCPA: identifiers; commercial information.
                </p>

                <p>This processing constitutes:</p>

                <li>
                  a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                </li>

                <li>
                  targeted advertising according to the CPA, CTDPA and UCPA
                </li>
              </div>
            </div>

            <p className="text-lg font-bold">
              Information on opting out of interest-based advertising
            </p>

            <p>
              In addition to any opt-out feature provided by any of the services
              listed in this document, Users may learn more on how to generally
              opt out of interest-based advertising within the dedicated section
              of the Cookie Policy.
            </p>
          </div>
        </div>

        <div className="flex flex-col border-b-2">
          <div className="mb-5 flex flex-col space-y-5">
            <p className="text-xl mt-3 font-bold">
              Further information about the processing of Personal Data
            </p>

            <div className="flex w-full space-x-5">
              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">
                  Access the address book
                </p>

                <p>Blue Sky may request access to your address book.</p>
              </div>

              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">localStorage</p>

                <p>
                  localStorage allows Blue Sky to store and access data right in
                  the User's browser with no expiration date.
                </p>
              </div>
            </div>

            <div className="flex w-full space-x-5">
              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">Preference Cookies</p>

                <p>
                  Preference Cookies store the User preferences detected on Blue
                  Sky in the local domain such as, for example, their timezone
                  and region.
                </p>
              </div>

              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">Push notifications</p>

                <p>
                  Blue Sky may send push notifications to the User to achieve
                  the purposes outlined in this privacy policy.
                </p>

                <p>
                  Users may in most cases opt-out of receiving push
                  notifications by visiting their device settings, such as the
                  notification settings for mobile phones, and then change those
                  settings for Blue Sky, some or all of the apps on the
                  particular device.
                </p>

                <p>
                  Users must be aware that disabling push notifications may
                  negatively affect the utility of Blue Sky.
                </p>
              </div>
            </div>

            <div className="flex w-full space-x-5">
              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">
                  Push notifications based on the User's geographic location
                </p>

                <p>
                  Blue Sky may use the User's geographic location to send push
                  notifications for the purposes outlined in this privacy
                  policy.
                </p>

                <p>
                  Users may in most cases opt-out of receiving push
                  notifications by visiting their device settings, such as the
                  notification settings for mobile phones, and then changing
                  those settings for some or all of the apps on the particular
                  device.
                </p>

                <p>
                  Users must be aware that disabling push notifications may
                  negatively affect the utility of Blue Sky.
                </p>
              </div>

              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">sessionStorage</p>

                <p>
                  sessionStorage allows Blue Sky to store and access data right
                  in the User's browser. Data in sessionStorage is deleted
                  automatically when the session ends (in other words, when the
                  browser tab is closed).
                </p>
              </div>
            </div>

            <div className="flex w-full space-x-5">
              <div className="flex w-full flex-col border p-5">
                <p className="text-lg mb-2 font-bold">
                  The Service is not directed to children under the age of 13
                </p>

                <p>
                  Users declare themselves to be adult according to their
                  applicable legislation. Minors may use Blue Sky only with the
                  assistance of a parent or guardian. Under no circumstance
                  persons under the age of 13 may use Blue Sky.
                </p>
              </div>

              <div className="flex w-full flex-col border p-5"></div>
            </div>
          </div>
        </div>

        <div className="flex flex-col border-b-2">
          <div className="mb-5">
            <p className="text-xl my-3 font-bold">Cookie Policy</p>

            <p>
              Blue Sky uses Trackers. To learn more, Users may consult the
              Cookie Policy.
            </p>
          </div>
        </div>

        <div className="flex flex-col border-b-2">
          <div className="mb-5">
            <p className="text-xl my-3 font-bold">
              Further Information for Users
            </p>

            <p className="text-lg my-2 font-bold">Legal basis of processing</p>

            <p>
              The Owner may process Personal Data relating to Users if one of
              the following applies:
            </p>

            <li>
              Users have given their consent for one or more specific purposes.
            </li>

            <li>
              provision of Data is necessary for the performance of an agreement
              with the User and/or for any pre-contractual obligations thereof;
            </li>

            <li>
              processing is necessary for compliance with a legal obligation to
              which the Owner is subject;
            </li>

            <li>
              processing is related to a task that is carried out in the public
              interest or in the exercise of official authority vested in the
              Owner;
            </li>

            <li>
              processing is necessary for the purposes of the legitimate
              interests pursued by the Owner or by a third party.
            </li>

            <p>
              In any case, the Owner will gladly help to clarify the specific
              legal basis that applies to the processing, and in particular
              whether the provision of Personal Data is a statutory or
              contractual requirement, or a requirement necessary to enter into
              a contract.
            </p>

            <p className="text-lg my-2 font-bold">
              Further information about retention time
            </p>

            <p>
              Unless specified otherwise in this document, Personal Data shall
              be processed and stored for as long as required by the purpose
              they have been collected for and may be retained for longer due to
              applicable legal obligation or based on the Users’ consent.
            </p>

            <p>Therefore:</p>

            <li>
              Personal Data collected for purposes related to the performance of
              a contract between the Owner and the User shall be retained until
              such contract has been fully performed.
            </li>

            <li>
              Personal Data collected for the purposes of the Owner’s legitimate
              interests shall be retained as long as needed to fulfill such
              purposes. Users may find specific information regarding the
              legitimate interests pursued by the Owner within the relevant
              sections of this document or by contacting the Owner.
            </li>

            <p className="my-5">
              The Owner may be allowed to retain Personal Data for a longer
              period whenever the User has given consent to such processing, as
              long as such consent is not withdrawn. Furthermore, the Owner may
              be obliged to retain Personal Data for a longer period whenever
              required to fulfil a legal obligation or upon order of an
              authority.
            </p>

            <p>
              Once the retention period expires, Personal Data shall be deleted.
              Therefore, the right of access, the right to erasure, the right to
              rectification and the right to data portability cannot be enforced
              after expiration of the retention period.
            </p>

            <p className="text-lg my-2 font-bold">
              The rights of Users based on the General Data Protection
              Regulation (GDPR)
            </p>

            <p>
              Users may exercise certain rights regarding their Data processed
              by the Owner.
            </p>

            <p>
              In particular, Users have the right to do the following, to the
              extent permitted by law:
            </p>

            <li>
              Withdraw their consent at any time. Users have the right to
              withdraw consent where they have previously given their consent to
              the processing of their Personal Data.
            </li>

            <li>
              Object to processing of their Data. Users have the right to object
              to the processing of their Data if the processing is carried out
              on a legal basis other than consent.
            </li>

            <li>
              Access their Data. Users have the right to learn if Data is being
              processed by the Owner, obtain disclosure regarding certain
              aspects of the processing and obtain a copy of the Data undergoing
              processing.
            </li>

            <li>
              Verify and seek rectification. Users have the right to verify the
              accuracy of their Data and ask for it to be updated or corrected.
            </li>

            <li>
              Restrict the processing of their Data. Users have the right to
              restrict the processing of their Data. In this case, the Owner
              will not process their Data for any purpose other than storing it.
            </li>

            <li>
              Have their Personal Data deleted or otherwise removed. Users have
              the right to obtain the erasure of their Data from the Owner.
            </li>

            <li>
              Receive their Data and have it transferred to another controller.
              Users have the right to receive their Data in a structured,
              commonly used and machine readable format and, if technically
              feasible, to have it transmitted to another controller without any
              hindrance.
            </li>

            <li>
              Lodge a complaint. Users have the right to bring a claim before
              their competent data protection authority.
            </li>

            <p>
              Users are also entitled to learn about the legal basis for Data
              transfers abroad including to any international organization
              governed by public international law or set up by two or more
              countries, such as the UN, and about the security measures taken
              by the Owner to safeguard their Data.
            </p>

            <p className="mt-5">
              Details about the right to object to processing
            </p>

            <p>
              Where Personal Data is processed for a public interest, in the
              exercise of an official authority vested in the Owner or for the
              purposes of the legitimate interests pursued by the Owner, Users
              may object to such processing by providing a ground related to
              their particular situation to justify the objection.
            </p>

            <p>
              Users must know that, however, should their Personal Data be
              processed for direct marketing purposes, they can object to that
              processing at any time, free of charge and without providing any
              justification. Where the User objects to processing for direct
              marketing purposes, the Personal Data will no longer be processed
              for such purposes. To learn whether the Owner is processing
              Personal Data for direct marketing purposes, Users may refer to
              the relevant sections of this document.
            </p>

            <p className="mt-5">How to exercise these rights</p>

            <p>
              Any requests to exercise User rights can be directed to the Owner
              through the contact details provided in this document. Such
              requests are free of charge and will be answered by the Owner as
              early as possible and always within one month, providing Users
              with the information required by law. Any rectification or erasure
              of Personal Data or restriction of processing will be communicated
              by the Owner to each recipient, if any, to whom the Personal Data
              has been disclosed unless this proves impossible or involves
              disproportionate effort. At the Users’ request, the Owner will
              inform them about those recipients.
            </p>
          </div>
        </div>

        <div className="flex flex-col border-b-2">
          <div className="mb-5">
            <p className="text-xl my-3 font-bold">
              Further information for Users in Brazil
            </p>

            <p>
              This section of the document integrates with and supplements the
              information contained in the rest of the privacy policy and is
              provided by the entity running Blue Sky and, if the case may be,
              its parent, subsidiaries and affiliates (for the purposes of this
              section referred to collectively as “we”, “us”, “our”).
            </p>

            <p>
              This section applies to all Users in Brazil (Users are referred to
              below, simply as “you”, “your”, “yours”), according to the "Lei
              Geral de Proteção de Dados" (the "LGPD"), and for such Users, it
              supersedes any other possibly divergent or conflicting information
              contained in the privacy policy.
            </p>

            <p>
              This part of the document uses the term “personal information“ as
              it is defined in the LGPD.
            </p>

            <p className="text-lg my-2 font-bold">
              The grounds on which we process your personal information
            </p>

            <p>
              We can process your personal information solely if we have a legal
              basis for such processing. Legal bases are as follows:
            </p>

            <li>your consent to the relevant processing activities;</li>

            <li>
              compliance with a legal or regulatory obligation that lies with
              us;
            </li>

            <li>
              the carrying out of public policies provided in laws or
              regulations or based on contracts, agreements and similar legal
              instruments;
            </li>

            <li>
              studies conducted by research entities, preferably carried out on
              anonymized personal information;
            </li>

            <li>
              the carrying out of a contract and its preliminary procedures, in
              cases where you are a party to said contract;
            </li>

            <li>
              the exercising of our rights in judicial, administrative or
              arbitration procedures;
            </li>

            <li>protection or physical safety of yourself or a third party;</li>

            <li>
              the protection of health – in procedures carried out by health
              entities or professionals;
            </li>

            <li>
              our legitimate interests, provided that your fundamental rights
              and liberties do not prevail over such interests; and
            </li>

            <li>credit protection.</li>

            <p>
              To find out more about the legal bases, you can contact us at any
              time using the contact details provided in this document.
            </p>

            <p className="text-lg my-2 font-bold">
              Categories of personal information processed
            </p>

            <p>
              To find out what categories of your personal information are
              processed, you can read the section titled “Detailed information
              on the processing of Personal Data” within this document.
            </p>

            <p className="text-lg my-2 font-bold">
              Why we process your personal information
            </p>

            <p>
              To find out why we process your personal information, you can read
              the sections titled “Detailed information on the processing of
              Personal Data” and “The purposes of processing” within this
              document.
            </p>

            <p className="text-lg my-2 font-bold">
              Your Brazilian privacy rights, how to file a request and our
              response to your requests
            </p>

            <p>Your Brazilian privacy rights</p>

            <li>
              obtain confirmation of the existence of processing activities on
              your personal information;
            </li>

            <li>access to your personal information;</li>

            <li>
              have incomplete, inaccurate or outdated personal information
              rectified;
            </li>

            <li>
              obtain the anonymization, blocking or elimination of your
              unnecessary or excessive personal information, or of information
              that is not being processed in compliance with the LGPD;
            </li>

            <li>
              obtain information on the possibility to provide or deny your
              consent and the consequences thereof;
            </li>

            <li>
              obtain information about the third parties with whom we share your
              personal information;
            </li>

            <li>
              obtain, upon your express request, the portability of your
              personal information (except for anonymized information) to
              another service or product provider, provided that our commercial
              and industrial secrets are safeguarded;
            </li>

            <li>
              obtain the deletion of your personal information being processed
              if the processing was based upon your consent, unless one or more
              exceptions provided for in art. 16 of the LGPD apply;
            </li>

            <li>revoke your consent at any time;</li>

            <li>
              lodge a complaint related to your personal information with the
              ANPD (the National Data Protection Authority) or with consumer
              protection bodies;
            </li>

            <li>
              oppose a processing activity in cases where the processing is not
              carried out in compliance with the provisions of the law;
            </li>

            <li>
              request clear and adequate information regarding the criteria and
              procedures used for an automated decision; and
            </li>

            <li>
              request the review of decisions made solely on the basis of the
              automated processing of your personal information, which affect
              your interests. These include decisions to define your personal,
              professional, consumer and credit profile, or aspects of your
              personality.
            </li>

            <p>
              You will never be discriminated against, or otherwise suffer any
              sort of detriment, if you exercise your rights.
            </p>

            <p className="text-lg my-2 font-bold">How to file your request</p>

            <p>
              You can file your express request to exercise your rights free
              from any charge, at any time, by using the contact details
              provided in this document, or via your legal representative.
            </p>

            <p className="text-lg my-2 font-bold">
              How and when we will respond to your request
            </p>

            <p>We will strive to promptly respond to your requests.</p>

            <p>
              In any case, should it be impossible for us to do so, we’ll make
              sure to communicate to you the factual or legal reasons that
              prevent us from immediately, or otherwise ever, complying with
              your requests. In cases where we are not processing your personal
              information, we will indicate to you the physical or legal person
              to whom you should address your requests, if we are in the
              position to do so.
            </p>

            <p>
              In the event that you file an access or personal information
              processing confirmation request, please make sure that you specify
              whether you’d like your personal information to be delivered in
              electronic or printed form.
            </p>

            <p className="mt-5">
              You will also need to let us know whether you want us to answer
              your request immediately, in which case we will answer in a
              simplified fashion, or if you need a complete disclosure instead.
            </p>

            <p>
              In the latter case, we’ll respond within 15 days from the time of
              your request, providing you with all the information on the origin
              of your personal information, confirmation on whether or not
              records exist, any criteria used for the processing and the
              purposes of the processing, while safeguarding our commercial and
              industrial secrets.
            </p>

            <p>
              In the event that you file a rectification, deletion,
              anonymization or personal information blocking request, we will
              make sure to immediately communicate your request to other parties
              with whom we have shared your personal information in order to
              enable such third parties to also comply with your request —
              except in cases where such communication is proven impossible or
              involves disproportionate effort on our side.
            </p>

            <p className="text-lg my-2 font-bold">
              Transfer of personal information outside of Brazil permitted by
              the law
            </p>

            <p>
              We are allowed to transfer your personal information outside of
              the Brazilian territory in the following cases:
            </p>

            <li>
              when the transfer is necessary for international legal cooperation
              between public intelligence, investigation and prosecution bodies,
              according to the legal means provided by the international law;
            </li>

            <li>
              when the transfer is necessary to protect your life or physical
              security or those of a third party;
            </li>

            <li>when the transfer is authorized by the ANPD;</li>

            <li>
              when the transfer results from a commitment undertaken in an
              international cooperation agreement;
            </li>

            <li>
              when the transfer is necessary for the execution of a public
              policy or legal attribution of public service;
            </li>

            <li>
              when the transfer is necessary for compliance with a legal or
              regulatory obligation, the carrying out of a contract or
              preliminary procedures related to a contract, or the regular
              exercise of rights in judicial, administrative or arbitration
              procedures.
            </li>
          </div>
        </div>

        <div className="flex flex-col border-b-2">
          <div className="mb-5">
            <p className="text-xl my-3 font-bold">
              Futher information for California consumers
            </p>

            <p>
              This section of the document integrates with and supplements the
              information contained in the rest of the privacy policy and is
              provided by the business running Blue Sky and, if the case may be,
              its parent, subsidiaries and affiliates (for the purposes of this
              section referred to collectively as “we”, “us”, “our”).
            </p>

            <p>
              This section applies to all Users (Users are referred to below,
              simply as “you”, “your”, “yours”), who are consumers residing in
              the state of California, United States of America, according to
              the "California Consumer Privacy Act of 2018" (the "CCPA"), as
              updated by the "California Privacy Rights Act" (the "CPRA") and
              subsequent regulations. For such consumers, this section
              supersedes any other possibly divergent or conflicting information
              contained in the privacy policy.
            </p>

            <p>
              This part of the document uses the terms “personal information”
              (and “sensitive personal information”) as defined in the
              California Consumer Privacy Act (CCPA).
            </p>

            <p className="text-lg my-2 font-bold">Notice at collection</p>

            <p>
              Categories of personal information collected, used, sold, or
              shared
            </p>

            <p>
              In this section we summarize the categories of personal
              information that we've collected, used, sold, or shared and the
              purposes thereof. You can read about these activities in detail in
              the section titled “Detailed information on the processing of
              Personal Data” within this document.
            </p>

            <p className="mt-5">
              Information we collect: the categories of personal information we
              collect
            </p>

            <p>
              We have collected the following categories of personal information
              about you: identifiers, commercial information, internet or other
              electronic network activity information, geolocation data, audio,
              electronic, visual, thermal, olfactory, or similar information and
              inferences drawn from other personal information.
            </p>

            <p>
              We have collected the following categories of sensitive personal
              information: username, username, username and
              password
            </p>

            <p>
              We will not collect additional categories of personal information
              without notifying you.
            </p>

            <p className="text-lg my-2 font-bold">
              Your right to limit the use or disclosure of your sensitive
              personal information and how you can exercise it
            </p>

            <p>
              You have the right to request that we limit the use or disclosure
              of your sensitive personal information to only that which is
              necessary to perform the services or provide the goods, as is
              reasonably expected by an average consumer.
            </p>

            <p>
              We can also use your sensitive personal information to perform
              specific purposes set forth by the law (such as, including but not
              limited to, helping to ensure security and integrity; undertaking
              activities to verify or maintain the quality or safety of our
              service) and as authorized by the relevant regulations.
            </p>

            <p className="mt-5">
              Outside of the aforementioned specific purposes, you have the
              right to freely request, at any time, that we do not use or
              disclose your sensitive personal information. This means that
              whenever you ask us to stop using your sensitive personal
              information, we will abide by your request and we will instruct
              our service providers and contractors to do the same.
            </p>

            <p>
              To fully exercise your right to limit the use or disclosure of
              your sensitive personal information you can contact us at any
              time, using the contact details provided in this document.
            </p>

            <p>
              For a simplified method you can also use the privacy choices link
              provided on Blue Sky.
            </p>

            <p>
              We use any personal information collected from you in connection
              with the submission of your request solely for the purposes of
              complying with the request.
            </p>

            <p className="mt-5">
              Once you have exercised this right, we are required to wait at
              least 12 months before asking whether you have changed your mind.
            </p>

            <p>
              What are the purposes for which we use your personal information?
            </p>

            <p>
              We may use your personal information to allow the operational
              functioning of Blue Sky and features thereof (“business
              purposes”). In such cases, your personal information will be
              processed in a fashion necessary and proportionate to the business
              purpose for which it was collected, and strictly within the limits
              of compatible operational purposes.
            </p>

            <p>
              We may also use your personal information for other reasons such
              as for commercial purposes (as indicated within the section
              “Detailed information on the processing of Personal Data” within
              this document), as well as for complying with the law and
              defending our rights before the competent authorities where our
              rights and interests are threatened or we suffer an actual damage.
            </p>

            <p>
              We won’t process your information for unexpected purposes, or for
              purposes incompatible with the purposes originally disclosed,
              without your consent.
            </p>

            <p className="mt-5">
              How long do we keep your personal information?
            </p>

            <p>
              Unless stated otherwise inside the “Detailed information on the
              processing of Personal Data” section, we will not retain your
              personal information for longer than is reasonably necessary for
              the purpose(s) they have been collected for.
            </p>

            <p>
              How we collect information: what are the sources of the personal
              information we collect?
            </p>

            <p>
              We collect the above-mentioned categories of personal information,
              either directly or indirectly, from you when you use Blue Sky.
            </p>

            <p>
              For example, you directly provide your personal information when
              you submit requests via any forms on Blue Sky. You also provide
              personal information indirectly when you navigate Blue Sky,
              as personal information about you is automatically observed and
              collected.
            </p>

            <p>
              Finally, we may collect your personal information from third
              parties that work with us in connection with the Service or with
              the functioning of this Application and features thereof.
            </p>

            <p className="mt-5">
              How we use the information we collect: disclosing of your personal
              information with third parties for a business purpose
            </p>

            <p>
              For our purposes, the word “third party” means a person who is not
              any of the following: a service provider or a contractor, as
              defined by the CCPA.
            </p>

            <p>
              We disclose your personal information with the third parties
              listed in detail in the section titled “Detailed information on
              the processing of Personal Data” within this document. These third
              parties are grouped and categorized in accordance with the
              different purposes of processing.
            </p>

            <p className="mt-5">Sale or sharing of your personal information</p>

            <p>
              For our purposes, the word “sale” means any “selling, renting,
              releasing, disclosing, disseminating, making available,
              transferring or otherwise communicating orally, in writing, or by
              electronic means, a consumer's personal information by the
              business to a third party, for monetary or other valuable
              consideration”, as defined by the CCPA.
            </p>

            <p>
              This means that, for example, a sale can happen whenever an
              application runs ads, or makes statistical analyses on the traffic
              or views, or simply because it uses tools such as social network
              plugins and the like.
            </p>

            <p>
              For our purposes, the word “sharing” means any “sharing, renting,
              releasing, disclosing, disseminating, making available,
              transferring, or otherwise communicating orally, in writing, or by
              electronic or other means, a consumer's personal information by
              the business to a third party for cross-context behavioral
              advertising, whether or not for monetary or other valuable
              consideration, including transactions between a business and a
              third party for cross-context behavioral advertising for the
              benefit of a business in which no money is exchanged”, as defined
              by the CCPA.
            </p>

            <p className="mt-5">
              Please note that the exchange of personal information with a
              service provider pursuant to a written contract that meets the
              requirements set by the CCPA, does not constitute a sale or
              sharing of your personal information.
            </p>

            <p>
              Your right to opt out of the sale or sharing of your personal
              information and how you can exercise it
            </p>

            <p>
              We sell or share your personal information with the third parties
              listed in detail in the section titled “Detailed information on
              the processing of Personal Data” within this document. These third
              parties are grouped and categorized in accordance with the
              different purposes of processing.
            </p>

            <p>
              You have the right to opt out of the sale or sharing of your
              personal information. This means that whenever you request us to
              stop selling or sharing your personal information, we will abide
              by your request.
            </p>

            <p>
              Such requests can be made freely, at any time, without submitting
              any verifiable request.
            </p>

            <p className="mt-5">
              To fully exercise your right to opt out, you can contact us at any
              time using the contact details provided in this document.
            </p>

            <p>
              For a simplified opt-out method you can also use the privacy
              choices link provided on Blue Sky.
            </p>

            <p>
              If you want to submit requests to opt out of the sale or sharing
              of personal information via a user-enabled global privacy control,
              like the Global Privacy Control (“GPC”), you are free to do so and
              we will abide by such request in a frictionless manner (as defined
              in the CPRA regulations). The GPC consists of a setting or
              extension in the browser or mobile device and acts as a mechanism
              that websites can use to indicate they support the GPC signal. If
              you want to use GPC, you can download and enable it via a
              participating browser or browser extension. More information about
              downloading GPC is available here.
            </p>

            <p className="mt-5">
              We use any personal information collected from you in connection
              with the submission of your opt-out request solely for the
              purposes of complying with the opt-out request.
            </p>

            <p>
              Once you have opted out, we are required to wait at least 12
              months before asking whether you have changed your mind.
            </p>

            <p className="text-lg my-2 font-bold">
              Your privacy rights under the California Consumer Privacy Act and
              how to exercise them
            </p>

            <p>
              The right to access personal information: the right to know and to
              portability
            </p>

            <p>You have the right to request that we disclose to you:</p>

            <li>
              the categories of personal information that we collect about you;
            </li>

            <li>
              the sources from which the personal information is collected;
            </li>

            <li>the purposes for which we use your information;</li>

            <li>to whom we disclose such information;</li>

            <li>
              the specific pieces of personal information we have collected
              about you.
            </li>

            <p>
              You also have the right to know what personal information is sold
              or shared and to whom. In particular, you have the right to
              request two separate lists from us where we disclose:
            </p>

            <li>
              the categories of personal information that we sold or shared
              about you and the categories of third parties to whom the personal
              information was sold or shared;
            </li>

            <li>
              the categories of personal information that we disclosed about you
              for a business purpose and the categories of persons to whom it
              was disclosed for a business purpose.
            </li>

            <p className="mt-5">
              The disclosure described above will be limited to the personal
              information collected or used over the past 12 months.
            </p>

            <p>
              If we deliver our response electronically, the information
              enclosed will be "portable", i.e. delivered in an easily usable
              format to enable you to transmit the information to another entity
              without hindrance — provided that this is technically feasible.
            </p>

            <p>
              The right to request the deletion of your personal information
            </p>

            <p>
              You have the right to request that we delete any of your personal
              information, subject to exceptions set forth by the law (such as,
              including but not limited to, where the information is used to
              identify and repair errors on Blue Sky, to detect security
              incidents and protect against fraudulent or illegal activities, to
              exercise certain rights etc.).
            </p>

            <p>
              If no legal exception applies, as a result of exercising your
              right, we will delete your personal information and notify any of
              our service providers and all third parties to whom we have sold
              or shared the personal information to do so — provided that this
              is technically feasible and doesn’t involve disproportionate
              effort.
            </p>

            <p className="mt-5">
              The right to correct inaccurate personal information
            </p>

            <p>
              You have the right to request that we correct any inaccurate
              personal information we maintain about you, taking into account
              the nature of the personal information and the purposes of the
              processing of the personal information.
            </p>

            <p>
              The right of no retaliation following opt-out or exercise of other
              rights (the right to non-discrimination)
            </p>

            <p>
              We will not discriminate against you for exercising your rights
              under the CCPA. This means that we will not discriminate against
              you, including, but not limited to, by denying goods or services,
              charging you a different price, or providing a different level or
              quality of goods or services just because you exercised your
              consumer privacy rights.
            </p>

            <p className="mt-5">
              However, if you refuse to provide your personal information to us
              or ask us to delete or stop selling your personal information, and
              that personal information or sale is necessary for us to provide
              you with goods or services, we may not be able to complete that
              transaction.
            </p>

            <p>
              To the extent permitted by the law, we may offer you promotions,
              discounts, and other deals in exchange for collecting, keeping, or
              selling your personal information, provided that the financial
              incentive offered is reasonably related to the value of your
              personal information.
            </p>

            <p>How to exercise your rights</p>

            <p>
              To exercise the rights described above, you need to submit your
              verifiable request to us by contacting us via the details provided
              in this document.
            </p>

            <p className="mt-5">
              For us to respond to your request, it’s necessary that we know who
              you are. Therefore, you can only exercise the above rights by
              making a verifiable request which must:
            </p>

            <li>
              provide sufficient information that allows us to reasonably verify
              you are the person about whom we collected personal information or
              an authorized representative;
            </li>

            <li>
              describe your request with sufficient detail that allows us to
              properly understand, evaluate, and respond to it.
            </li>

            <p>
              We will not respond to any request if we are unable to verify your
              identity and therefore confirm the personal information in our
              possession actually relates to you.
            </p>

            <p className="mt-5">
              Making a verifiable consumer request does not require you to
              create an account with us. We will use any personal information
              collected from you in connection with the verification of your
              request solely for the purposes of verification and shall not
              further disclose the personal information, retain it longer than
              necessary for purposes of verification, or use it for unrelated
              purposes.
            </p>

            <p>
              If you cannot personally submit a verifiable request, you can
              authorize a person registered with the California Secretary of
              State to act on your behalf.
            </p>

            <p className="mt-5">
              You can submit a maximum number of 2 requests over a period of 12
              months.
            </p>

            <p>How and when we are expected to handle your request</p>

            <p>
              We will confirm receipt of your verifiable request within 10 days
              and provide information about how we will process your request.
            </p>

            <p>
              We will respond to your request within 45 days of its receipt.
              Should we need more time, we will explain to you the reasons why,
              and how much more time we need. In this regard, please note that
              we may take up to 90 days to fulfill your request.
            </p>

            <p>
              Our disclosure(s) will cover the preceding 12-month period. Only
              with regard to personal information collected on or after January
              1, 2022, you have the right to request that we disclose
              information beyond the 12-month period, and we will provide them
              to you unless doing so proves impossible or would involve a
              disproportionate effort.
            </p>

            <p className="mt-5">
              Should we deny your request, we will explain you the reasons
              behind our denial.
            </p>

            <p>
              We do not charge a fee to process or respond to your verifiable
              request unless such request is manifestly unfounded or excessive.
              In such cases, we may charge a reasonable fee, or refuse to act on
              the request. In either case, we will communicate our choices and
              explain the reasons behind it.
            </p>
          </div>
        </div>

        <div className="flex flex-col border-b-2">
          <div className="mb-5">
            <p className="text-xl my-3 font-bold">
              Further information for Virginia consumers
            </p>

            <p>
              This section of the document integrates with and supplements the
              information contained in the rest of the privacy policy and is
              provided by the controller running this Application and, if the
              case may be, its parent, subsidiaries and affiliates (for the
              purposes of this section referred to collectively as “we”, “us”,
              “our”).
            </p>

            <p>
              This section applies to all Users (Users are referred to below,
              simply as “you”, “your”, “yours”), who are consumers residing in
              the Commonwealth of Virginia, according to the “Virginia Consumer
              Data Protection Act" (the "VCDPA"), and, for such consumers, it
              supersedes any other possibly divergent or conflicting information
              contained in the privacy policy.
            </p>

            <p>
              This part of the document uses the term “personal data” as defined
              in the VCDPA.
            </p>

            <p className="text-lg my-2 font-bold">
              Categories of personal data processed
            </p>

            <p>
              In this section, we summarize the categories of personal data that
              we've processed and the purposes thereof. You can read about these
              activities in detail in the section titled “Detailed information
              on the processing of Persona Data” within this document.
            </p>

            <p>Categories of personal data we collect</p>

            <p>
              We have collected the following categories of personal data:
              identifiers, commercial information, internet information,
              geolocation data, sensorial information and inferred information
            </p>

            <p>We do not collect sensitive data.</p>

            <p>
              We will not collect additional categories of personal data without
              notifying you.
            </p>

            <p>Why we process your personal data</p>

            <p>
              To find out why we process your personal data, you can read the
              sections titled “Detailed information on the processing of
              Personal Data” and “The purposes of processing” within this
              document.
            </p>

            <p>
              We won’t process your information for unexpected purposes, or for
              purposes incompatible with the purposes originally disclosed,
              without your consent.
            </p>

            <p className="mt-5">
              You can freely give, deny, or withdraw such consent at any time
              using the contact details provided in this document.
            </p>

            <p>
              How we use the data we collect: sharing of your personal data with
              third parties
            </p>

            <p>
              We share your personal data with the third parties listed in
              detail in the section titled “Detailed information on the
              processing of Personal Data” within this document. These third
              parties are grouped and categorized in accordance with the
              different purposes of processing.
            </p>

            <p>
              For our purposes, the word "third party" means "a natural or legal
              person, public authority, agency, or body other than the consumer,
              controller, processor, or an affiliate of the processor or the
              controller" as defined by the VCDPA.
            </p>

            <p>Sale of your personal data</p>

            <p>
              For our purposes, the word “sale” means any “exchange of personal
              data for monetary consideration by us to a third party“ as defined
              by the VCDPA.
            </p>

            <p className="mt-5">
              Please note that according to the VCDPA, the disclosure of
              personal data to a processor that processes personal data on
              behalf of a controller does not constitute a sale. In addition,
              other specific exceptions set forth in the VCDPA may apply, such
              as, but not limited to, the disclosure of personal data to a third
              party for the provision of a product or service requested by you.
            </p>

            <p>
              As specified in the “Detailed information on the processing of
              Personal Data” section of this document, our use of your personal
              information may be considered a sale under VCDPA.
            </p>

            <p>
              Your right to opt out of the sale of your personal data and how
              you can exercise it
            </p>

            <p>
              You have the right to opt out of the sale of your personal data.
              This means that whenever you request us to stop selling your data,
              we will abide by your request. To fully exercise your right to opt
              out you can contact us at any time using the contact details
              provided in this document.
            </p>

            <p className="mt-5">
              We use any personal data collected from you in connection with the
              submission of your opt-out request solely for the purpose of
              complying with the request.
            </p>

            <p>Processing of your personal data for targeted advertising</p>

            <p>
              For our purposes, the word "targeted advertising" means
              "displaying advertisements to you where the advertisement is
              selected based on personal data obtained from your activities over
              time and across nonaffiliated websites or online applications to
              predict your preferences or interests" as defined by the VCDPA.
            </p>

            <p className="mt-5">
              Please note that according to the VCDPA, targeted advertising does
              not include: “advertisements based on activities within a
              controller's own websites or online applications; advertisements
              based on the context of a consumer's current search query, visit
              to a website or online application; advertisements directed to a
              consumer in response to the consumer's request for information or
              feedback; or processing personal data solely for measuring or
              reporting advertising performance, reach, or frequency”.
            </p>

            <p>
              To find out more details on the processing of your personal data
              for targeted advertising purposes, you can read the section titled
              “Detailed information on the processing of Personal Data” within
              this document.
            </p>

            <p className="mt-5">
              Your right to opt out of the processing of your personal data for
              targeted advertising and how you can exercise it
            </p>

            <p>
              You have the right to opt out of the processing of your personal
              data for targeted advertising. This means that whenever you ask us
              to stop processing your data for targeted advertising, we will
              abide by your request. To fully exercise your right to opt out you
              can contact us at any time, using the contact details provided in
              this document.
            </p>

            <p>
              We use any personal data collected from you in connection with the
              submission of your opt-out request solely for the purposes of
              complying with the opt-out request.
            </p>

            <p className="text-lg my-2 font-bold">
              Your privacy rights under the Virginia Consumer Data Protection
              Act and how to exercise them
            </p>

            <p>
              You may exercise certain rights regarding your data processed by
              us. In particular, you have the right to do the following:
            </p>

            <li>
              access personal data: the right to know. You have the right to
              request that we confirm whether or not we are processing your
              personal data. You also have the right to access such personal
              data.
            </li>

            <li>
              correct inaccurate personal data. You have the right to request
              that we correct any inaccurate personal data we maintain about
              you, taking into account the nature of the personal data and the
              purposes of the processing of the personal data.
            </li>

            <li>
              request the deletion of your personal data. You have the right to
              request that we delete any of your personal data.
            </li>

            <li>
              obtain a copy of your personal data. We will provide your personal
              data in a portable and usable format that allows you to transfer
              data easily to another entity — provided that this is technically
              feasible.
            </li>

            <li>
              opt out of the processing of your personal data for the purposes
              of targeted advertising, the sale of personal data, or profiling
              in furtherance of decisions that produce legal or similarly
              significant effects concerning you.
            </li>

            <li>
              non-discrimination. We will not discriminate against you for
              exercising your rights under the VCDPA. This means that we will
              not, among other things, deny goods or services, charge you a
              different price, or provide a different level or quality of goods
              or services just because you exercised your consumer privacy
              rights. However, if you refuse to provide your personal data to us
              or ask us to delete or stop selling your personal data, and that
              personal data or sale is necessary for us to provide you with
              goods or services, we may not be able to complete that
              transaction. To the extent permitted by the law, we may offer a
              different price, rate, level, quality, or selection of goods or
              services to you, including offering goods or services for no fee,
              if you have exercised your right to opt out, or our offer is
              related to your voluntary participation in a bona fide loyalty,
              rewards, premium features, discounts, or club card program.
            </li>

            <p>How to exercise your rights</p>

            <p className="mt-5">
              To exercise the rights described above, you need to submit your
              request to us by contacting us via the contact details provided in
              this document.
            </p>

            <p>
              For us to respond to your request, we need to know who you are.
            </p>

            <p>
              We will not respond to any request if we are unable to verify your
              identity using commercially reasonable efforts and therefore
              confirm that the personal data in our possession actually relate
              to you. In such cases, we may request that you provide additional
              information which is reasonably necessary to authenticate you and
              your request.
            </p>

            <p className="mt-5">
              Making a consumer request does not require you to create an
              account with us. However, we may require you to use your existing
              account. We will use any personal data collected from you in
              connection with your request solely for the purposes of
              authentication, without further disclosing the personal data,
              retaining it longer than necessary for purposes of authentication,
              or using it for unrelated purposes.
            </p>

            <p>
              If you are an adult, you can make a request on behalf of a child
              under your parental authority.
            </p>

            <p>How and when we are expected to handle your request</p>

            <p>
              We will respond to your request without undue delay, but in all
              cases and at the latest within 45 days of its receipt. Should we
              need more time, we will explain to you the reasons why, and how
              much more time we need. In this regard, please note that we may
              take up to 90 days to fulfill your request.
            </p>

            <p className="mt-5">
              Should we deny your request, we will explain to you the reasons
              behind our denial without undue delay, but in all cases and at the
              latest within 45 days of receipt of the request. It is your right
              to appeal such decision by submitting a request to us via the
              details provided in this document. Within 60 days of receipt of
              the appeal, we will inform you in writing of any action taken or
              not taken in response to the appeal, including a written
              explanation of the reasons for the decisions. If the appeal is
              denied you may contact the Attorney General to submit a complaint.
            </p>

            <p>
              We do not charge a fee to respond to your request, for up to two
              requests per year. If your request is manifestly unfounded,
              excessive or repetitive, we may charge a reasonable fee or refuse
              to act on the request. In either case, we will communicate our
              choices and explain the reasons behind them.
            </p>
          </div>
        </div>

        <div className="flex flex-col border-b-2">
          <div className="mb-5">
            <p className="text-xl my-3 font-bold">
              Further information for Colorado consumers
            </p>

            <p>
              This section of the document integrates with and supplements the
              information contained in the rest of the privacy policy and is
              provided by the controller running Blue Sky and, if the case may
              be, its parent, subsidiaries and affiliates (for the purposes of
              this section referred to collectively as “we”, “us”, “our”).
            </p>

            <p>
              This section applies to all Users (Users are referred to below,
              simply as “you”, “your”, “yours”), who are consumers residing in
              the State of Colorado, according to the “Colorado Privacy Act"
              (the "CPA"), and, for such consumers, it supersedes any other
              possibly divergent or conflicting information contained in the
              privacy policy.
            </p>

            <p>
              This part of the document uses the term “personal data” as defined
              in the CPA.
            </p>

            <p className="text-lg my-2 font-bold">
              Categories of personal data processed
            </p>

            <p>
              In this section, we summarize the categories of personal data that
              we've processed and the purposes thereof. You can read about these
              activities in detail in the section titled “Detailed information
              on the processing of Persona Data” within this document.
            </p>

            <p>Categories of personal data we collect</p>

            <p>
              We have collected the following categories of personal data:
              identifiers, commercial information, internet information,
              geolocation data, sensorial information and inferred information
            </p>

            <p>We do not collect sensitive data.</p>

            <p>
              We will not collect additional categories of personal data without
              notifying you.
            </p>

            <p>Why we process your personal data</p>

            <p className="mt-5">
              To find out why we process your personal data, you can read the
              sections titled “Detailed information on the processing of
              Personal Data” and “The purposes of processing” within this
              document.
            </p>

            <p>
              We won’t process your information for unexpected purposes, or for
              purposes incompatible with the purposes originally disclosed,
              without your consent.
            </p>

            <p className="mt-5">
              You can freely give, deny, or withdraw such consent at any time
              using the contact details provided in this document.
            </p>

            <p>
              How we use the data we collect: sharing of your personal data with
              third parties
            </p>

            <p>
              We share your personal data with the third parties listed in
              detail in the section titled “Detailed information on the
              processing of Personal Data” within this document. These third
              parties are grouped and categorized in accordance with the
              different purposes of processing.
            </p>

            <p>
              For our purposes, the word "third party" means "a natural or legal
              person, public authority, agency, or body other than the consumer,
              controller, processor, or an affiliate of the processor or the
              controller" as defined by the VCDPA.
            </p>

            <p>Sale of your personal data</p>

            <p className="mt-5">
              As specified in the “Detailed information on the processing of
              Personal Data” section of this document, our use of your personal
              data may be considered a sale under the CPA.
            </p>

            <p>
              For our purposes, the word "sale", "sell", or "sold" means "the
              exchange of personal data for monetary or other valuable
              consideration by a controller to a third party" as defined by the
              CPA.
            </p>

            <p>
              Please note that according to the CPA, the disclosure of personal
              data to a processor that processes personal data on behalf of a
              controller does not constitute a sale. In addition, other specific
              exceptions set forth in the CPA may apply, such as, but not
              limited to, the disclosure of personal data to a third party for
              the provision of a product or service requested by you.
            </p>

            <p>
              Your right to opt out of the sale of your personal data and how
              you can exercise it
            </p>

            <p>
              You have the right to opt out of the sale of your personal data.
              This means that whenever you request us to stop selling your data,
              we will abide by your request.
            </p>

            <p className="mt-5">
              To fully exercise your right to opt out you can contact us at any
              time, using the contact details provided in this document.
            </p>

            <p>
              For a simplified opt-out method you can also use the privacy
              choices link provided on Blue Sky.
            </p>

            <p>
              We use any personal data collected from you in connection with the
              submission of your opt-out request solely for the purpose of
              complying with the request.
            </p>

            <p>Processing of your personal data for targeted advertising</p>

            <p>
              As specified in the “Detailed information on the processing of
              Personal Data” section of this document, we may use your personal
              data for targeted advertising purposes.
            </p>

            <p>
              For our purposes, the word "targeted advertising" means
              "displaying to a consumer an advertisement that is selected based
              on personal data obtained or inferred over time from the
              consumer's activities across nonaffiliated websites, applications,
              or online services to predict consumer preferences or interests"
              as defined by CPA.
            </p>

            <p className="mt-5">
              Please note that according to the CPA, targeted advertising does
              not include: “advertisements directed to a consumer in response to
              the consumer's request for information or feedback; advertisements
              based on activities within a controller's own websites or online
              applications or any affiliated website or online application;
              advertisements based on the context of a consumer's current search
              query, visit to an internet web site or online application; or
              processing personal data solely to measure or report advertising
              frequency, performance or reach”.
            </p>

            <p>
              Your right to opt out of the processing of your personal data for
              targeted advertising and how you can exercise it
            </p>

            <p>
              You have the right to opt out of the processing of your personal
              data for targeted advertising. This means that whenever you ask us
              to stop processing your data for targeted advertising, we will
              abide by your request.
            </p>

            <p className="mt-5">
              To fully exercise your right to opt out you can contact us at any
              time, using the contact details provided in this document.
            </p>

            <p>
              For a simplified opt-out method you can also use the privacy
              choices link provided on Blue Sky.
            </p>

            <p>
              We use any personal data collected from you in connection with the
              submission of your opt-out request solely for the purposes of
              complying with the opt-out request.
            </p>

            <p>Universal opt-out mechanism: Global privacy control</p>

            <p className="mt-5">
              If you want to submit requests to opt-out of the sale of personal
              data or the targeted advertising via a user-enabled global privacy
              control, like the Global Privacy Control (“GPC”), you are free to
              do so and we will abide by such request. The GPC consists of a
              setting or extension in the browser or mobile device and acts as a
              mechanism that websites can use to indicate they support the GPC
              signal. If you want to use GPC, you can download and enable it via
              a participating browser or browser extension. More information
              about downloading GPC is available here.
            </p>

            <p className="text-lg my-2 font-bold">
              Your privacy rights under the Colorado Privacy Act and how to
              exercise them
            </p>

            <p>
              You may exercise certain rights regarding your data processed by
              us. In particular, you have the right to do the following:
            </p>

            <li>
              opt out of the processing of your personal data for the purposes
              of targeted advertising, the sale of personal data, or profiling
              in furtherance of decisions that produce legal or similarly
              significant effects concerning you.
            </li>

            <li>
              access personal data. You have the right to request that we
              confirm whether or not we are processing your personal data. You
              also have the right to access such personal data.
            </li>

            <li>
              correct inaccurate personal data. You have the right to request
              that we correct any inaccurate personal data we maintain about
              you, taking into account the nature of the personal data and the
              purposes of the processing of the personal data.
            </li>

            <li>
              request the deletion of your personal data. You have the right to
              request that we delete any of your personal data.
            </li>

            <li>
              obtain a copy of your personal data. We will provide your personal
              data in a portable and usable format that allows you to transfer
              data easily to another entity – provided that this is technically
              feasible.
            </li>

            <p className="mt-5">
              In any case, we will not increase the cost of, or decrease the
              availability of, a product or service, based solely on the
              exercise of any of your rights and unrelated to the feasibility or
              the value of a service. However, to the extent permitted by the
              law, we may offer a different price, rate, level, quality, or
              selection of goods or services to you, including offering goods or
              services for no fee, if our offer is related to your voluntary
              participation in a bona fide loyalty, rewards, premium features,
              discounts, or club card program.
            </p>

            <p>How to exercise your rights</p>

            <p>
              To exercise the rights described above, you need to submit your
              request to us by contacting us via the contact details provided in
              this document.
            </p>

            <p className="mt-5">
              For us to respond to your request, we need to know who you are and
              which right you wish to exercise.
            </p>

            <p>
              We will not respond to any request if we are unable to verify your
              identity using commercially reasonable efforts and therefore
              confirm that the personal data in our possession actually relate
              to you. In such cases, we may request that you provide additional
              information which is reasonably necessary to authenticate you and
              your request.
            </p>

            <p>
              Making a consumer request does not require you to create an
              account with us. However, we may require you to use your existing
              account. We will use any personal data collected from you in
              connection with your request solely for the purposes of
              authentication, without further disclosing the personal data,
              retaining it longer than necessary for purposes of authentication,
              or using it for unrelated purposes.
            </p>

            <p>
              If you are an adult, you can make a request on behalf of a child
              under your parental authority.
            </p>

            <p className="mt-5">
              How and when we are expected to handle your request
            </p>

            <p>
              We will respond to your request without undue delay, but in all
              cases and at the latest within 45 days of its receipt. Should we
              need more time, we will explain to you the reasons why, and how
              much more time we need. In this regard, please note that we may
              take up to 90 days to fulfill your request.
            </p>

            <p>
              Should we deny your request, we will explain to you the reasons
              behind our denial without undue delay, but in all cases and at the
              latest within 45 days of receipt of the request. It is your right
              to appeal such decision by submitting a request to us via the
              details provided in this document. Within 45 days of receipt of
              the appeal, we will inform you in writing of any action taken or
              not taken in response to the appeal, including a written
              explanation of the reasons for the decisions. If the appeal is
              denied you may contact the Attorney General to submit a complaint.
            </p>

            <p>
              We do not charge a fee to respond to your request, for up to two
              requests per year.
            </p>
          </div>
        </div>

        <div className="flex flex-col border-b-2">
          <div className="mb-5">
            <p className="text-xl my-3 font-bold">
              Further information for Connecticut consumers
            </p>

            <p>
              This section of the document integrates with and supplements the
              information contained in the rest of the privacy policy and is
              provided by the controller running Blue Sky and, if the case may
              be, its parent, subsidiaries and affiliates (for the purposes of
              this section referred to collectively as “we”, “us”, “our”).
            </p>

            <p>
              This section applies o all Users (Users are referred to below,
              simply as “you”, “your”, “yours”), who are consumers residing in
              the State of Connecticut, according to “An Act Concerning Personal
              Data Privacy and Online Monitoring " (also known as "The
              Connecticut Data Privacy Act" or the “CTDPA"), and, for such
              consumers, it supersedes any other possibly divergent or
              conflicting information contained in the privacy policy.
            </p>

            <p>
              This part of the document uses the term “personal data” as defined
              in the CTDPA.
            </p>

            <p className="text-lg my-2 font-bold">
              Categories of personal data processed
            </p>

            <p>
              In this section, we summarize the categories of personal data that
              we've processed and the purposes thereof. You can read about these
              activities in detail in the section titled “Detailed information
              on the processing of Persona Data” within this document.
            </p>

            <p>Categories of personal data we collect</p>

            <p>
              We have collected the following categories of personal data:
              identifiers, commercial information, internet information,
              geolocation data, sensorial information and inferred information
            </p>

            <p>We do not collect sensitive data.</p>

            <p>
              We will not collect additional categories of personal data without
              notifying you.
            </p>

            <p>Why we process your personal data</p>

            <p>
              To find out why we process your personal data, you can read the
              sections titled “Detailed information on the processing of
              Personal Data” and “The purposes of processing” within this
              document.
            </p>

            <p>
              We won’t process your information for unexpected purposes, or for
              purposes incompatible with the purposes originally disclosed,
              without your consent.
            </p>

            <p className="mt-5">
              You can freely give, deny, or withdraw such consent at any time
              using the contact details provided in this document.
            </p>

            <p>
              How we use the data we collect: sharing of your personal data with
              third parties
            </p>

            <p>
              We share your personal data with the third parties listed in
              detail in the section titled “Detailed information on the
              processing of Personal Data” within this document. These third
              parties are grouped and categorized in accordance with the
              different purposes of processing.
            </p>

            <p>
              For our purposes, the word "third party" means "a person, public
              authority, agency, or body other than a consumer, controller,
              processor, or affiliate of the processor or the controller." as
              defined by the CTDPA.
            </p>

            <p>Sale of your personal data</p>

            <p>
              As specified in the “Detailed information on the processing of
              Personal Data” section of this document, our use of your personal
              data may be considered a sale under the CTDPA.
            </p>

            <p>
              For our purposes, the word "sale", "sell", or "sold" means "the
              exchange of personal data for monetary or other valuable
              consideration by a controller to a third party" as defined by the
              CTDPA.
            </p>

            <p className="mt-5">
              Please note that according to the CTDPA, the disclosure of
              personal data to a processor that processes personal data on
              behalf of a controller does not constitute a sale. In addition,
              other specific exceptions set forth in the CTDPA may apply, such
              as, but not limited to, the disclosure of personal data to a third
              party for the provision of a product or service requested by you.
            </p>

            <p>
              Your right to opt out of the sale of your personal data and how
              you can exercise it
            </p>

            <p>
              You have the right to opt out of the sale of your personal data.
              This means that whenever you request us to stop selling your data,
              we will abide by your request.
            </p>

            <p>
              To fully exercise your right to opt out you can contact us at any
              time, using the contact details provided in this document.
            </p>

            <p>
              For a simplified opt-out method you can also use the privacy
              choices link provided on Blue Sky.
            </p>

            <p>
              We use any personal data collected from you in connection with the
              submission of your opt-out request solely for the purpose of
              complying with the request.
            </p>

            <p className="mt-5">
              Processing of your personal data for targeted advertising
            </p>

            <p>
              As specified in the “Detailed information on the processing of
              Personal Data” section of this document, we may use your personal
              data for targeted advertising purposes.
            </p>

            <p>
              For our purposes, the word "targeted advertising" means
              "displaying to a consumer an advertisement that is selected based
              on personal data obtained or inferred over time from the
              consumer's activities across non affiliated websites,
              applications, or online services to predict consumer preferences
              or interests" as defined by CTDPA.
            </p>

            <p>
              Please note that according to the CPA, targeted advertising does
              not include: “advertisements directed to a consumer in response to
              the consumer's request for information or feedback; advertisements
              based on activities within a controller's own websites or online
              applications or any affiliated website or online application;
              advertisements based on the context of a consumer's current search
              query, visit to an internet web site or online application; or
              processing personal data solely to measure or report advertising
              frequency, performance or reach”.
            </p>

            <p className="mt-5">
              Your right to opt out of the processing of your personal data for
              targeted advertising and how you can exercise it
            </p>

            <p>
              You have the right to opt out of the processing of your personal
              data for targeted advertising. This means that whenever you ask us
              to stop processing your data for targeted advertising, we will
              abide by your request.
            </p>

            <p>
              To fully exercise your right to opt out you can contact us at any
              time, using the contact details provided in this document.
            </p>

            <p>
              For a simplified opt-out method you can also use the privacy
              choices link provided on Blue Sky.
            </p>

            <p>
              We use any personal data collected from you in connection with the
              submission of your opt-out request solely for the purposes of
              complying with the opt-out request.
            </p>

            <p className="mt-5">
              Universal opt-out mechanism: Global privacy control
            </p>

            <p>
              If you want to submit requests to opt-out of the sale of personal
              data or the targeted advertising via a user-enabled global privacy
              control, like the Global Privacy Control (“GPC”), you are free to
              do so and we will abide by such request. The GPC consists of a
              setting or extension in the browser or mobile device and acts as a
              mechanism that websites can use to indicate they support the GPC
              signal. If you want to use GPC, you can download and enable it via
              a participating browser or browser extension. More information
              about downloading GPC is available here.
            </p>

            <p className="text-lg my-2 font-bold">
              Your privacy rights under the Colorado Privacy Act and how to
              exercise them
            </p>

            <p>
              You may exercise certain rights regarding your data processed by
              us. In particular, you have the right to do the following:
            </p>

            <li>
              access personal data. You have the right to request that we
              confirm whether or not we are processing your personal data. You
              also have the right to access such personal data.
            </li>

            <li>
              correct inaccurate personal data. You have the right to request
              that we correct any inaccurate personal data we maintain about
              you, taking into account the nature of the personal data and the
              purposes of the processing of the personal data.
            </li>

            <li>
              request the deletion of your personal data. You have the right to
              request that we delete any of your personal data.
            </li>

            <li>
              obtain a copy of your personal data. We will provide your personal
              data in a portable and usable format that allows you to transfer
              data easily to another entity – provided that this is technically
              feasible.
            </li>

            <li>
              opt out of the processing of your personal data for the purposes
              of targeted advertising, the sale of personal data, or profiling
              in furtherance of decisions that produce legal or similarly
              significant effects concerning you.
            </li>

            <p className="mt-5">
              In any case, we will not increase the cost of, or decrease the
              availability of, a product or service, based solely on the
              exercise of any of your rights and unrelated to the feasibility or
              the value of a service. However, to the extent permitted by the
              law, we may offer a different price, rate, level, quality, or
              selection of goods or services to you, including offering goods or
              services for no fee, if our offer is related to your voluntary
              participation in a bona fide loyalty, rewards, premium features,
              discounts, or club card program.
            </p>

            <p>How to exercise your rights</p>

            <p>
              To exercise the rights described above, you need to submit your
              request to us by contacting us via the contact details provided in
              this document.
            </p>

            <p className="mt-5">
              For us to respond to your request, we need to know who you are and
              which right you wish to exercise.
            </p>

            <p>
              We will not respond to any request if we are unable to verify your
              identity using commercially reasonable efforts and therefore
              confirm that the personal data in our possession actually relate
              to you. In such cases, we may request that you provide additional
              information which is reasonably necessary to authenticate you and
              your request.
            </p>

            <p>
              Making a consumer request does not require you to create an
              account with us. However, we may require you to use your existing
              account. We will use any personal data collected from you in
              connection with your request solely for the purposes of
              authentication, without further disclosing the personal data,
              retaining it longer than necessary for purposes of authentication,
              or using it for unrelated purposes.
            </p>

            <p>
              If you are an adult, you can make a request on behalf of a child
              under your parental authority.
            </p>

            <p>How and when we are expected to handle your request</p>

            <p className="mt-5">
              We will respond to your request without undue delay, but in all
              cases and at the latest within 45 days of its receipt. Should we
              need more time, we will explain to you the reasons why, and how
              much more time we need. In this regard, please note that we may
              take up to 90 days to fulfill your request.
            </p>

            <p>
              Should we deny your request, we will explain to you the reasons
              behind our denial without undue delay, but in all cases and at the
              latest within 45 days of receipt of the request. It is your right
              to appeal such decision by submitting a request to us via the
              details provided in this document. Within 45 days of receipt of
              the appeal, we will inform you in writing of any action taken or
              not taken in response to the appeal, including a written
              explanation of the reasons for the decisions. If the appeal is
              denied you may contact the Attorney General to submit a complaint.
            </p>

            <p>
              We do not charge a fee to respond to your request, for up to two
              requests per year.
            </p>
          </div>
        </div>

        <div className="flex flex-col border-b-2">
          <div className="mb-5">
            <p className="text-xl my-3 font-bold">
              Further information for Utah consumers
            </p>

            <p>
              This section of the document integrates with and supplements the
              information contained in the rest of the privacy policy and is
              provided by the controller running Blue Sky and, if the case may
              be, its parent, subsidiaries and affiliates (for the purposes of
              this section referred to collectively as “we”, “us”, “our”).
            </p>

            <p>
              This section applies to all Users (Users are referred to below,
              simply as “you”, “your”, “yours”), who are consumers residing in
              the State of Utah, according to the “Consumer Privacy Act" (the
              “UCPA"), and, for such consumers, it supersedes any other possibly
              divergent or conflicting information contained in the privacy
              policy.
            </p>

            <p>
              This part of the document uses the term “personal data” as defined
              in the UCPA.
            </p>

            <p className="text-lg my-2 font-bold">
              Categories of personal data processed
            </p>

            <p>
              In this section, we summarize the categories of personal data that
              we've processed and the purposes thereof. You can read about these
              activities in detail in the section titled “Detailed information
              on the processing of Persona Data” within this document.
            </p>

            <p>Categories of personal data we collect</p>

            <p>
              We have collected the following categories of personal data:
              identifiers, commercial information, internet information,
              geolocation data, sensorial information and inferred information
            </p>

            <p>We do not collect sensitive data.</p>

            <p>
              We will not collect additional categories of personal data without
              notifying you.
            </p>

            <p>Why we process your personal data</p>

            <p className="mt-5">
              To find out why we process your personal data, you can read the
              sections titled “Detailed information on the processing of
              Personal Data” and “The purposes of processing” within this
              document.
            </p>

            <p>
              How we use the data we collect: sharing of your personal data with
              third parties
            </p>

            <p>
              We share your personal data with the third parties listed in
              detail in the section titled “Detailed information on the
              processing of Personal Data” within this document. These third
              parties are grouped and categorized in accordance with the
              different purposes of processing.
            </p>

            <p>
              For our purposes, the word "third party" means "a person other
              than: the consumer, controller, or processor; or an affiliate or
              contractor of the controller or the processor" as defined by the
              UCPA.
            </p>

            <p>Sale of your personal data</p>

            <p className="mt-5">
              As specified in the “Detailed information on the processing of
              Personal Data” section of this document, our use of your personal
              data may be considered a sale under the UCPA.
            </p>

            <p>
              For our purposes, the word "sale", "sell", or "sold" means "the
              exchange of personal data for monetary or other valuable
              consideration by a controller to a third party" as defined by the
              UCPA.
            </p>

            <p>
              Please note that according to the UCPA, the disclosure of personal
              data to a processor that processes personal data on behalf of a
              controller does not constitute a sale. In addition, other specific
              exceptions set forth in the UCPA may apply, such as, but not
              limited to, the disclosure of personal data to a third party for
              the provision of a product or service requested by you.
            </p>

            <p>
              Your right to opt out of the sale of your personal data and how
              you can exercise it
            </p>

            <p>
              You have the right to opt out of the sale of your personal data.
              This means that whenever you request us to stop selling your data,
              we will abide by your request.
            </p>

            <p className="mt-5">
              To fully exercise your right to opt out you can contact us at any
              time, using the contact details provided in this document.
            </p>

            <p>
              For a simplified opt-out method you can also use the privacy
              choices link provided on Blue Sky.
            </p>

            <p>
              We use any personal data collected from you in connection with the
              submission of your opt-out request solely for the purpose of
              complying with the request.
            </p>

            <p>Processing of your personal data for targeted advertising</p>

            <p>
              As specified in the “Detailed information on the processing of
              Personal Data” section of this document, we may use your personal
              data for targeted advertising purposes.
            </p>

            <p>
              For our purposes, the word "targeted advertising" means
              "displaying to a consumer an advertisement that is selected based
              on personal data obtained or inferred over time from the
              consumer's activities across nonaffiliated websites, applications,
              or online services to predict consumer preferences or interests"
              as defined by UCPA.
            </p>

            <p className="mt-5">
              Please note that according to the UCPA, targeted advertising does
              not include: “advertisements based on activities within a
              controller's own websites or online applications or any affiliated
              website or online application; advertisements based on the context
              of a consumer's current search query, visit to an web site or
              online application; advertisements directed to a consumer in
              response to the consumer's request for information, product, a
              service or feedback; or processing personal data solely to measure
              or report advertising performance, reach or frequency.”
            </p>

            <p className="mt-5">
              Your right to opt out of the processing of your personal data for
              targeted advertising and how you can exercise it
            </p>

            <p>
              You have the right to opt out of the processing of your personal
              data for targeted advertising. This means that whenever you ask us
              to stop processing your data for targeted advertising, we will
              abide by your request.
            </p>

            <p>
              To fully exercise your right to opt out you can contact us at any
              time, using the contact details provided in this document.
            </p>

            <p>
              For a simplified opt-out method you can also use the privacy
              choices link provided on Blue Sky.
            </p>

            <p>
              We use any personal data collected from you in connection with the
              submission of your opt-out request solely for the purposes of
              complying with the opt-out request.
            </p>

            <p className="text-lg my-2 font-bold">
              Your privacy rights under the Colorado Privacy Act and how to
              exercise them
            </p>

            <p>
              You may exercise certain rights regarding your data processed by
              us. In particular, you have the right to do the following:
            </p>

            <li>
              access personal data. You have the right to request that we
              confirm whether or not we are processing your personal data. You
              also have the right to access such personal data.
            </li>

            <li>
              request the deletion of your personal data. You have the right to
              request that we delete any of your personal data.re of the
              personal data and the purposes of the processing of the personal
              data.
            </li>

            <li>
              obtain a copy of your personal data. We will provide your personal
              data in a portable and usable format that allows you to transfer
              data easily to another entity – provided that this is technically
              feasible.
            </li>

            <li>
              opt out of the processing of your personal data for the purposes
              of targeted advertising or the sale of personal data.
            </li>

            <p className="mt-5">
              In any case, we will not increase the cost of, or decrease the
              availability of, a product or service, based solely on the
              exercise of any of your rights and unrelated to the feasibility or
              the value of a service. However, to the extent permitted by the
              law, we may offer a different price, rate, level, quality, or
              selection of goods or services to you, including offering goods or
              services for no fee, if our offer is related to your voluntary
              participation in a bona fide loyalty, rewards, premium features,
              discounts, or club card program.
            </p>

            <p>How to exercise your rights</p>

            <p className="mt-5">
              To exercise the rights described above, you need to submit your
              request to us by contacting us via the contact details provided in
              this document.
            </p>

            <p>
              For us to respond to your request, we need to know who you are and
              which right you wish to exercise.
            </p>

            <p>
              We will not respond to any request if we are unable to verify your
              identity using commercially reasonable efforts and therefore
              confirm that the personal data in our possession actually relate
              to you. In such cases, we may request that you provide additional
              information which is reasonably necessary to authenticate you and
              your request.
            </p>

            <p>
              If you are an adult, you can make a request on behalf of a child
              under your parental authority.
            </p>

            <p className="mt-5">
              How and when we are expected to handle your request
            </p>

            <p>
              We will respond to your request without undue delay, but in all
              cases and at the latest within 45 days of its receipt. Should we
              need more time, we will explain to you the reasons why, and how
              much more time we need. In this regard, please note that we may
              take up to 90 days to fulfill your request.
            </p>

            <p>
              Should we deny your request, we will explain to you the reasons
              behind our denial without undue delay, but in all cases and at the
              latest within 45 days of receipt of the request.
            </p>

            <p>
              We do not charge a fee to respond to your request, for up to two
              requests per year.
            </p>
          </div>
        </div>

        <div className="flex flex-col border-b-2">
          <div className="mb-5">
            <p className="text-xl my-3 font-bold">
              Additional information about Data collection and processing
            </p>

            <p className="text-lg my-2 font-bold">Legal action</p>

            <p>
              The User's Personal Data may be used for legal purposes by the
              Owner in Court or in the stages leading to possible legal action
              arising from improper use of Blue Sky or the related Services.
            </p>

            <p>
              The User declares to be aware that the Owner may be required to
              reveal personal data upon request of public authorities.
            </p>

            <p className="text-lg my-2 font-bold">
              Additional information about User's Personal Data
            </p>

            <p>
              In addition to the information contained in this privacy policy,
              Blue Sky may provide the User with additional and contextual
              information concerning particular Services or the collection and
              processing of Personal Data upon request.
            </p>

            <p className="text-lg my-2 font-bold">
              System logs and maintenance
            </p>

            <p>
              For operation and maintenance purposes, Blue Sky and any
              third-party services may collect files that record interaction
              with Blue Sky (System logs) or use other Personal Data (such as
              the IP Address) for this purpose.
            </p>

            <p className="text-lg my-2 font-bold">
              Information not contained in this policy
            </p>

            <p>
              More details concerning the collection or processing of Personal
              Data may be requested from the Owner at any time. Please see the
              contact information at the beginning of this document.
            </p>

            <p className="text-lg my-2 font-bold">
              Changes to this privacy policy
            </p>

            <p>
              The Owner reserves the right to make changes to this privacy
              policy at any time by notifying its Users on this page and
              possibly within Blue Sky and/or - as far as technically and
              legally feasible - sending a notice to Users via any contact
              information available to the Owner. It is strongly recommended to
              check this page often, referring to the date of the last
              modification listed at the bottom.
            </p>

            <p>
              Should the changes affect processing activities performed on the
              basis of the User’s consent, the Owner shall collect new consent
              from the User, where required.
            </p>
          </div>
        </div>

        <div className="flex flex-col border-b-2">
          <div className="my-5">
            <div className="border p-5">
              <div className="border-b-2">
                <p className="text-xl my-3 font-bold">
                  Definitions and legal references
                </p>

                <p className="text-lg my-2 font-bold">
                  Personal Data (or Data)
                </p>

                <p>
                  Any information that directly, indirectly, or in connection
                  with other information — including a personal identification
                  number — allows for the identification or identifiability of a
                  natural person.
                </p>

                <p className="text-lg my-2 font-bold">Usage Data</p>

                <p>
                  Information collected automatically through Blue Sky (or
                  third-party services employed in Blue Sky), which can include:
                  the IP addresses or domain names of the computers utilized by
                  the Users who use Blue Sky, the URI addresses (Uniform
                  Resource Identifier), the time of the request, the method
                  utilized to submit the request to the server, the size of the
                  file received in response, the numerical code indicating the
                  status of the server's answer (successful outcome, error,
                  etc.), the country of origin, the features of the browser and
                  the operating system utilized by the User, the various time
                  details per visit (e.g., the time spent on each page within
                  the Application) and the details about the path followed
                  within the Application with special reference to the sequence
                  of pages visited, and other parameters about the device
                  operating system and/or the User's IT environment.
                </p>

                <p className="text-lg my-2 font-bold">User</p>

                <p>
                  The individual using Blue Sky who, unless otherwise specified,
                  coincides with the Data Subject.
                </p>

                <p className="text-lg my-2 font-bold">Data Subject</p>

                <p>The natural person to whom the Personal Data refers.</p>

                <p className="text-lg my-2 font-bold">
                  Data Processor (or Processor)
                </p>

                <p>
                  The natural or legal person, public authority, agency or other
                  body which processes Personal Data on behalf of the
                  Controller, as described in this privacy policy.
                </p>

                <p className="text-lg my-2 font-bold">
                  Data Controller (or Owner)
                </p>

                <p>
                  The natural or legal person, public authority, agency or other
                  body which, alone or jointly with others, determines the
                  purposes and means of the processing of Personal Data,
                  including the security measures concerning the operation and
                  use of Blue Sky. The Data Controller, unless otherwise
                  specified, is the Owner of Blue Sky.policy.
                </p>

                <p className="text-lg my-2 font-bold">
                  Blue Sky (or this Application)
                </p>

                <p>
                  The means by which the Personal Data of the User is collected
                  and processed.
                </p>

                <p className="text-lg my-2 font-bold">Service</p>

                <p>
                  The service provided by Blue Sky as described in the relative
                  terms (if available) and on this site/application.
                </p>

                <p className="text-lg my-2 font-bold">European Union (or EU)</p>

                <p>
                  Unless otherwise specified, all references made within this
                  document to the European Union include all current member
                  states to the European Union and the European Economic Area.
                </p>

                <p className="text-lg my-2 font-bold">Compose</p>

                <p>
                  Create, read, update, and delete drafts. Send messages and
                  drafts.
                </p>

                <p className="text-lg my-2 font-bold">Cookie</p>

                <p>
                  Cookies are Trackers consisting of small sets of data stored
                  in the User's browser.
                </p>

                <p className="text-lg my-2 font-bold">Tracker</p>

                <p>
                  Tracker indicates any technology - e.g Cookies, unique
                  identifiers, web beacons, embedded scripts, e-tags and
                  fingerprinting - that enables the tracking of Users, for
                  example by accessing or storing information on the User’s
                  device.
                </p>

                <p className="text-lg my-2 font-bold">Current City</p>

                <p className="mb-2">
                  Provides access to the User's current location.
                </p>
              </div>

              <p className="text-xl my-3 font-bold">Legal information</p>

              <p>
                This privacy statement has been prepared based on provisions of
                multiple legislations.
              </p>

              <p>
                This privacy policy relates solely to Blue Sky, if not stated
                otherwise within this document.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col border-b-2">
          <p className="my-3">
            Iubenda hosts this content and only collects the Personal Data
            strictly necessary for it to be provided.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
