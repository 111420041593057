import React, { useState } from "react";
import Phases from "../../../icons/CertificateVideo/Phases.png";
import Roles from "../../../icons/CertificateVideo/Roles.png";
import LedRecovery from "../../../icons/CertificateVideo/LedRecovery.png";
import CertifiedCitizen from "../../../icons/CertificateVideo/CertifiedCitizen.png";

const CertificationVideosPartners = ({
  openPhaseModal,
  openRolesModal,
  openCommunityRecoveryModal,
  openCertifiedCitizenModal,
  Modal,
  isPhaseModalOpen,
  closePhaseModal,
  isRolesModalOpen,
  closeRolesModal,
  isCommunityRecoveryModalOpen,
  closeCommunityRecoveryModal,
  isCertifiedCitizenModalOpen,
  closeCertifiedCitizenModal,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleDropdownPartners = () => {
    setIsOpen(!isOpen);
  };
  return (
    <section className="flex max-w-full flex-col items-start justify-start gap-[8px] self-stretch text-left text-sm text-darkslateblue">
      <div
        className="flex cursor-pointer items-center self-stretch"
        onClick={toggleDropdownPartners}
      >
        <svg
          className={`mr-2 h-6 w-6 transform transition-transform ${
            isOpen ? "rotate-90" : ""
          }`}
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M6 6L14 10L6 14V6Z" />
        </svg>
        <b className="relative flex-grow self-stretch pt-1 uppercase">
          DARCI Partner Course Videos
        </b>
      </div>
      {isOpen && (
        <div className="text-darkslategray box-border flex max-w-full flex-row items-start justify-start gap-[51px] self-stretch rounded-b-mini rounded-tl-none rounded-tr-mini bg-white px-[42px] pb-[27px] pt-3 mq1300:flex-wrap mq1125:box-border mq1125:pl-[21px] mq1125:pr-[21px] mq450:gap-[25px]">
          <div className="flex w-[563px] min-w-[563px] max-w-full flex-col items-start justify-start gap-[14px] mq1300:flex-1 mq800:min-w-full">
            <b className="relative self-stretch">Partner Introductory Videos</b>
            <div className="flex min-h-[451px] flex-row flex-wrap items-start justify-start gap-[17px_13px] self-stretch text-smi text-darkslateblue">
              <div className="flex h-[217px] w-[271px] flex-col items-start justify-start gap-[10px]">
                <div
                  className="relative h-full w-full cursor-pointer"
                  onClick={openPhaseModal}
                >
                  <img
                    className="relative max-h-full max-w-full flex-1 self-stretch overflow-hidden rounded-lg"
                    alt="Phases of Disaster thumbnail"
                    src={Phases}
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <svg
                      className="h-16 w-16 text-white opacity-80"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                    </svg>
                  </div>
                </div>
                <b className="relative self-stretch">Phases of Disaster</b>
              </div>

              <Modal
                isOpen={isPhaseModalOpen}
                onClose={closePhaseModal}
                videoSrc="https://www.youtube.com/embed/Sj4eXgQu3vo?autoplay=1"
              />

              <div className="flex w-[272px] flex-col items-start justify-start gap-[10px]">
                <div
                  className="relative h-48 cursor-pointer self-stretch"
                  onClick={openRolesModal}
                >
                  <img
                    className="absolute left-[0px] top-[0px] h-full w-full rounded-lg object-contain"
                    alt="National Roles and Responsibilities thumbnail"
                    src={Roles}
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <svg
                      className="h-16 w-16 text-white opacity-80"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                    </svg>
                  </div>
                </div>
                <b className="relative">
                  National Roles and Responsibility's in Re...
                </b>
              </div>

              <Modal
                isOpen={isRolesModalOpen}
                onClose={closeRolesModal}
                videoSrc="https://www.youtube.com/embed/zrbklf_1uOU?autoplay=1"
              />

              <div className="flex h-[217px] w-[273px] flex-col items-start justify-start gap-[10px]">
                <div
                  className="relative h-full w-full cursor-pointer"
                  onClick={openCommunityRecoveryModal}
                >
                  <img
                    className="relative max-h-full max-w-full flex-1 self-stretch overflow-hidden rounded-lg"
                    alt="Community Led Recovery thumbnail"
                    src={LedRecovery}
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <svg
                      className="h-16 w-16 text-white opacity-80"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                    </svg>
                  </div>
                </div>
                <b className="relative self-stretch">Community Led Recovery</b>
              </div>

              <Modal
                isOpen={isCommunityRecoveryModalOpen}
                onClose={closeCommunityRecoveryModal}
                videoSrc="https://www.youtube.com/embed/81t4quqwp5Q?autoplay=1"
              />

              <div className="flex h-[217px] w-[272px] flex-col items-start justify-start gap-[10px]">
                <div
                  className="relative h-full w-full cursor-pointer"
                  onClick={openCertifiedCitizenModal}
                >
                  <img
                    className="relative max-h-full max-w-full flex-1 self-stretch overflow-hidden rounded-lg"
                    alt="Certified Citizen thumbnail"
                    src={CertifiedCitizen}
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <svg
                      className="h-16 w-16 text-white opacity-80"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                    </svg>
                  </div>
                </div>
                <b className="relative self-stretch">Citizen</b>
              </div>

              <Modal
                isOpen={isCertifiedCitizenModalOpen}
                onClose={closeCertifiedCitizenModal}
                videoSrc="https://www.youtube.com/embed/t_Qlyfm6beY?autoplay=1"
              />
            </div>
          </div>
          <div className="flex min-w-[606px] max-w-full flex-1 flex-col items-start justify-start gap-[38px] mq800:min-w-full mq800:gap-[19px]">
            <b className="relative self-stretch">
              Supplemental Support Articles
            </b>
            <div className="flex max-w-full flex-col items-start justify-start gap-[23px] self-stretch">
              <div className="relative font-semibold">
                Support Article Nonprofit
              </div>
              <div className="box-border flex max-w-full flex-row items-start justify-start self-stretch py-0 pl-px pr-0 text-gray1-100">
                <div className="relative inline-block max-w-full flex-1">
                  <a className="text-[inherit]" href="" target="_blank">
                    Recovering from a disaster is a complex and lengthy process.
                    Government aid is distributed through multiple agencies,
                    each with its own processes and requirements{" "}
                  </a>
                </div>
              </div>
            </div>
            <div className="flex max-w-full flex-col items-start justify-start gap-[20px] self-stretch">
              <div className="relative font-semibold">Why DARCI PARTNER</div>
              <div className="box-border flex max-w-full flex-row items-start justify-start self-stretch py-0 pl-px pr-0 text-gray1-100">
                <div className="relative inline-block max-w-full flex-1">
                  Becoming a DARCI partner is instrumental in clarifying and
                  streamlining your role within the community following a
                  disaster, effectively minimizing confusion and chaos.
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default CertificationVideosPartners;
