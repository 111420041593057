import PropTypes from "prop-types";
import { ReactComponent as LibraryIcon } from "../icons/home/library.svg";
import { ReactComponent as MapsIcon } from "../icons/home/maps.svg";
import { ReactComponent as JobOpportunitiesIcon } from "../icons/home/job-opportunities.svg";
import { ReactComponent as VolunteerOpportunitiesIcon } from "../icons/home/volunteer-opportunities.svg";
import { registerLicense, Internationalization } from "@syncfusion/ej2-base";
import React, { useState, useEffect } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Toolbar,
  Filter,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { Inject, Page, Sort, Edit } from "@syncfusion/ej2-react-grids";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import { getAuth } from "firebase/auth"; // Import getAuth to access the current user
import { doc, getDoc, Query } from "firebase/firestore"; // Import getDoc and doc to fetch user data
import { useNavigate } from "react-router-dom";
import { ReactComponent as AnalyticsIcon } from "../icons/home/analytics.svg";
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  PieSeries,
  AccumulationLegend,
  AccumulationDataLabel,
  AccumulationTooltip,
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  ColumnSeries,
  Category,
  Legend,
  DataLabel,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import DisasterMgtService from "../service/citizen/DisasterMgtService";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const sortSettings = {
  allowSorting: true,
};
const filterSettings = {
  type: "CheckBox",
};

const Home = ({ className = "" }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [editing, setEditing] = useState({
    allowDeleting: true,
    allowAdding: true,
    allowEditing: true,
    mode: "Dialog",
    showDeleteConfirmDialog: true,
    dialog: { cssClass: "custom-dialog" },
  });
  const [toolbarOptions, setToolbarOptions] = useState([
    "Add",
    "Edit",
    "Delete",
  ]); // Initialize toolbar options
  const [userRole, setUserRole] = useState(""); // State to hold user role
  const [loading, setLoading] = useState(true);
  const [totalResponses, setTotalResponses] = useState(0);

  useEffect(() => {
    const fetchUserRole = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          const role = userDoc.data().user_role;
          setUserRole(role);
          if (role === "center") {
            setEditing({
              allowEditing: false,
              allowAdding: false,
              allowDeleting: false,
              mode: "Dialog",
              dialog: { cssClass: "custom-dialog" },
            });
            setToolbarOptions([]);
          }
        }
      }
    };

    const fetchData = async () => {
      try {
        // Fetch declared disaster data
        const querySnapshot = await getDocs(
          collection(db, "declared-disaster")
        );
        const fetchedData = querySnapshot.docs.map((doc) => {
          const activeData = doc.data();
          return {
            ...activeData,
            disaster_id: doc.id,
            disaster_date: activeData.disaster_date
              ? activeData.disaster_date.toDate().toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })
              : null,
          };
        });
        setActiveData(fetchedData);

        // Fetch survey responses data for charts
        const surveySnapshot = await getDocs(
          collection(db, "survey_responses")
        );
        setTotalResponses(surveySnapshot.size);
        // Initialize variables to store tally for charts
        let disasterImpactCount = { affected: 0, notAffected: 0 };
        let injuryCount = {
          noInjury: 0,
          minorInjury: 0,
          medicalTreatment: 0,
          visitedHospital: 0,
          admitted: 0,
        };
        let homeDamageCount = {
          noDamage: 0,
          slightDamage: 0,
          limitedDamage: 0,
          severeDamage: 0,
          totalDestruction: 0,
        };
        let homesDestroyedCount = { damaged: 0, destroyed: 0, undamaged: 0 };
        let typeOfHomeDamageCount = {
          "Check All": 0,
          Siding: 0,
          Flooring: 0,
          Cabinets: 0,
          "Shed/garage": 0,
          Roof: 0,
          Foundation: 0,
          "Floor Joists": 0,
          HVAC: 0,
          Driveway: 0,
          Gutters: 0,
          Ceiling: 0,
          Drywall: 0,
          Electrical: 0,
          "Yard/Uprooted trees": 0,
          Other: 0,
        };

        surveySnapshot.forEach((doc) => {
          const data = doc.data().responses;

          // Disaster Impact
          if (data["Was the impacted address your primary address?"] === "No") {
            disasterImpactCount.notAffected += 1;
          } else {
            disasterImpactCount.affected += 1;
          }

          // Injury Data Mapping
          if (data["Injury"] === "No Injury") {
            injuryCount.noInjury += 1;
          } else if (data["Injury"] === "Minor Injury") {
            injuryCount.minorInjury += 1;
          } else if (data["Injury"] === "Sought medical treatment") {
            injuryCount.medicalTreatment += 1;
          } else if (data["Injury"] === "Visited Hospital") {
            injuryCount.visitedHospital += 1;
          } else if (data["Injury"] === "Admitted") {
            injuryCount.admitted += 1;
          }

          // Home Damage
          switch (data["Home Damage"]) {
            case "No Damage":
              homeDamageCount.noDamage += 1;
              break;
            case "Slight Damage":
              homeDamageCount.slightDamage += 1;
              break;
            case "Limited Damage":
              homeDamageCount.limitedDamage += 1;
              break;
            case "Severe Damage":
              homeDamageCount.severeDamage += 1;
              break;
            case "Total Destruction":
              homeDamageCount.totalDestruction += 1;
              break;
            default:
              break;
          }

          // Homes Destroyed
          switch (data["The impacted home:"]) {
            case "Has minor damage":
              homesDestroyedCount.undamaged += 1;
              break;
            case "Has major damage":
              homesDestroyedCount.damaged += 1;
              break;
            case "Is destroyed":
              homesDestroyedCount.destroyed += 1;
              break;
            default:
              break;
          }

          // Type of Home Damage
          const homeDamageMap = data["There is visible damage to the home's:"]; // Assuming this is an object

          if (homeDamageMap) {
            // Iterate over the keys in typeOfHomeDamageCount and update the count if the value is true
            Object.keys(typeOfHomeDamageCount).forEach((type) => {
              if (homeDamageMap[type] === true) {
                typeOfHomeDamageCount[type] += 1;
              }
            });
          }
        });

        // Now update the state for each graph
        setDisasterImpactData(disasterImpactCount);
        setInjuryData(injuryCount);
        setHomeDamageData(homeDamageCount);
        setHomesDestroyedData(homesDestroyedCount);
        setTypeOfHomeDamageData(typeOfHomeDamageCount);

        setLoading(false); // Set loading to false after all data is fetched
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchUserRole();
    fetchData();
  }, []);

  // All possible userIds
  const userIds = [
    "2xRe086ekZW1DBLkPDBcXHIMEKK2",
    "E07ln2AjrDh4AqSIjk9Q3ZlXyGQ2",
    "HNAF2yP5C3ceETPpEweNlDGPPRO2",
    "KmDDF5dyVLZ1s73V7GyEtpMcpLJ3",
    "MkATiJIrRAdvdZ5tg7PZiSB7gqb2",
    "Swm4XhKi7IdJj2xHBh7ffs3squy1",
    "UluTI2TdmmfJ3Gw3Qwyd8hE4bI52",
    "mjJhuXLAeIVy3rgCzbjwNsTYCCz1",
    "pqU69Pl4CTcEzc6L3PUGuHlsX7f2",
    "sFXqoueVGmMmXVlpVwyxJMobTNv2",
    "vbDWCWdyAoYpK0qjxnuGk3GzvHi2",
  ];

  // All possible collection names
  const collectionNames = [
    "contents",
    "general",
    "home_repair",
    "landscaping",
    "vehicle",
  ];
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalValueSum, setTotalValue] = useState(0);
  const [immediateAssistance, setImmediateAssistance] = useState(0);
  const [recoveryAssistance, setRecoveryAssistance] = useState(0);

  const fetchDisasterData = async () => {
    let allRecords = [];
    let totalValueSum = 0;
    let immediateAssistanceSum = 0;
    let recoveryAssistanceSum = 0;

    try {
      for (const userId of userIds) {
        for (const collectionName of collectionNames) {
          const records = await DisasterMgtService.fetchRecords(
            userId,
            collectionName
          );
          console.log(
            `Fetched records for UserId: ${userId}, CollectionName: ${collectionName}`,
            records
          );
          allRecords.push({
            userId,
            collectionName,
            records,
          });

          for (const record of records) {
            if (record.value && !record.is_deleted) {
              const value = parseFloat(record.value);

              totalValueSum += value;

              if (collectionName === "general") {
                immediateAssistanceSum += value;
              } else {
                recoveryAssistanceSum += value;
              }
            }
          }
        }
      }

      const totalRecords = allRecords.reduce(
        (acc, item) => acc + item.records.length,
        0
      );

      setData(allRecords);
      setTotalRecords(totalRecords);
      setTotalValue(totalValueSum);
      setImmediateAssistance(immediateAssistanceSum);
      setRecoveryAssistance(recoveryAssistanceSum);
    } catch (error) {
      console.error("Error fetching disaster data:", error);
    }
  };

  useEffect(() => {
    fetchDisasterData();
  }, []);

  const [disasterImpactData, setDisasterImpactData] = useState({
    affected: 0,
    notAffected: 0,
  });
  const [injuryData, setInjuryData] = useState({
    noInjury: 0,
    minorInjury: 0,
    medicalTreatment: 0,
    visitedHospital: 0,
    admitted: 0,
  });
  const [homeDamageData, setHomeDamageData] = useState({
    noDamage: 0,
    slightDamage: 0,
    limitedDamage: 0,
    severeDamage: 0,
    totalDestruction: 0,
  });
  const [homesDestroyedData, setHomesDestroyedData] = useState({
    damaged: 0,
    destroyed: 0,
    undamaged: 0,
  });
  const [typeOfHomeDamageData, setTypeOfHomeDamageData] = useState({
    "Check All": 0,
    Siding: 0,
    Flooring: 0,
    Cabinets: 0,
    "Shed/garage": 0,
    Roof: 0,
    Foundation: 0,
    "Floor Joists": 0,
    HVAC: 0,
    Driveway: 0,
    Gutters: 0,
    Ceiling: 0,
    Drywall: 0,
    Electrical: 0,
    "Yard/Uprooted trees": 0,
    Other: 0,
  });

  const actionComplete = async (args) => {
    if (args.requestType === "delete") {
      const deletedItems = Array.isArray(args.data) ? args.data : [args.data];
      const deletedIds = deletedItems.map((item) => item.disaster_id);
      console.log("Deleting IDs:", deletedIds);
      const updatedData = data.filter(
        (item) => !deletedIds.includes(item.disaster_id)
      );
      setData(updatedData);
    } else if (args.requestType === "save") {
      console.log("Save action triggered", args.data);
      const updatedItem = args.data;
      const updatedData = data.map((item) =>
        item.disaster_id === updatedItem.disaster_id ? updatedItem : item
      );
      setData(updatedData);
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      if (dialog) {
        // Check if dialog is not null
        dialog.header =
          args.requestType === "beginEdit"
            ? "Edit Record of " + args.rowData["disasterID"]
            : "New Disaster";

        // Set the header styles
        const headerContent = dialog.element.querySelector(
          ".e-dlg-header-content"
        );
        const header = dialog.element.querySelector(".e-dlg-header");
        if (headerContent && header) {
          // Ensure elements exist before accessing
          headerContent.style.backgroundColor = "#348BFF";
          headerContent.style.color = "#FFFFFF";
          header.style.color = "#FFFFFF";
        }

        // Style the save and cancel buttons
        setTimeout(() => {
          const saveButton = dialog.element.querySelector(".e-primary");
          const cancelButton = dialog.element.querySelector(
            ".e-btn:not(.e-primary)"
          );

          if (saveButton) {
            saveButton.style.backgroundColor = "#FFFFFF";
            saveButton.style.color = "#348BFF";
            saveButton.style.border = "none";
          }

          if (cancelButton) {
            cancelButton.style.backgroundColor = "#FFFFFF";
            cancelButton.style.color = "#348BFF";
            cancelButton.style.border = "1px solid #348BFF";
          }
        }, 0);
      }
    } else if (args.requestType === "add") {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const newDisaster = {
          ...args.data,
          center_id: user.uid,
        };
        await addDoc(collection(db, "declared-disaster"), newDisaster);
        const querySnapshot = await getDocs(
          collection(db, "declared-disaster")
        );
        const fetchedData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          disaster_id: doc.id,
        }));
        setData(fetchedData);
      }
    }
  };

  const toolbarClick = (args) => {
    if (args.item.id === "Grid_add") {
    } else if (args.item.id === "Grid_edit") {
    } else if (args.item.id === "Grid_delete") {
    } else if (args.item.id === "Grid_update") {
    } else if (args.item.id === "Grid_cancel") {
    }
  };

  const queryCellInfo = (args) => {
    if (args.column.field === "disaster_name") {
      let bgColor, textColor;
      switch (args.data.disasterName) {
        case "Fire":
          bgColor = "#FFF6E6";
          textColor = "#FDA502";
          break;
        case "Typhoon":
          bgColor = "#DCF2FF";
          textColor = "#1565C0";
          break;
        case "Flood":
          bgColor = "#BED8FF";
          textColor = "#0E47A1";
          break;
        case "Earthquake":
          bgColor = "#F4EDE8";
          textColor = "#8B4514";
          break;
        default:
          bgColor = "";
          textColor = "";
      }

      if (bgColor) {
        args.cell.innerHTML = `<div style="
          background-color: ${bgColor};
          color: ${textColor};
          padding: 5px 10px;
          border-radius: 15px;
          display: inline-block;
          font-weight: bold;
          text-align: center;
        ">${args.data.disaster_name}</div>`;
      }
    }

    if (args.column.field === "disaster_status") {
      let bgColor, textColor;
      switch (args.data.disaster_status) {
        case "Active":
          bgColor = "#DEF7EC"; // Green
          textColor = "#299D91"; // White
          break;
        case "Inactive":
          bgColor = "#FDE8E8"; // Red
          textColor = "#E4626F"; // White
          break;
        default:
          bgColor = "";
          textColor = "";
      }

      if (bgColor) {
        args.cell.innerHTML = `<div style="
          background-color: ${bgColor};
          color: ${textColor};
          padding: 5px 10px;
          border-radius: 15px;
          display: inline-block;
          font-weight: bold;
          text-align: center;
        ">${args.data.disaster_status}</div>`;
      }
    }

    if (args.column.field === "alert_level") {
      let bgColor, textColor;
      switch (args.data.alert_level) {
        case "Level 1":
          bgColor = "#FFEDBC"; // Light Yellow
          textColor = "#F4B000"; // Dark Orange
          break;
        case "Level 2":
          bgColor = "#FAE2CC"; // Light Orange
          textColor = "#E46E00"; // Dark Orange
          break;
        case "Level 3":
          bgColor = "#F1D5D5"; // Light Red
          textColor = "#CD1A1A"; // Dark Red
          break;
        default:
          bgColor = "";
          textColor = "";
      }

      if (bgColor) {
        args.cell.innerHTML = `<div style="
          background-color: ${bgColor};
          color: ${textColor};
          padding: 5px 10px;
          border-radius: 15px;
          display: inline-block;
          font-weight: bold;
          text-align: center;
        ">${args.data.alert_level}</div>`;
      }
    }
  };

  const intl = new Internationalization();

  const formatDate = (date) => {
    if (date) {
      return intl.formatDate(new Date(date), { format: "MM/dd/yyyy" });
    }
    return "";
  };

  const formattedData = data.map((item) => ({
    ...item,
    disasterDate: formatDate(item.disasterDate),
  }));

  const getCurrentFormattedDate = () => {
    const currentDate = new Date();
    return currentDate.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  const datepickerparams = {
    params: {
      value: new Date(),
      format: "MM/dd/yyyy",
      showClearButton: false,
      showTodayButton: true,
    },
  };

  const toolbarStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };

  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };

  const ChartCard = ({ title, children }) => (
    <div className="min-w-[300px] flex-1 rounded-md border border-solid border-gainsboro-200 bg-white p-5 shadow-lg">
      <h2 className="mb-4 flex items-center justify-between text-sm font-bold text-blue-500">
        {title}
        <span className="text-gray-400">⋮</span>
      </h2>
      {children}
    </div>
  );

  const ChartCardLong = ({ title, children }) => (
    <div className="w-fit flex-1 rounded-md border border-solid border-gainsboro-200 bg-white p-5 shadow-lg">
      <h2 className="mb-4 flex items-center justify-between text-sm font-bold text-blue-500">
        {title}
        <span className="text-gray-400">⋮</span>
      </h2>
      {children}
    </div>
  );

  if (loading) {
    return <div className=""></div>;
  }

  return (
    <div
      className={`mq825:flex-wrap box-border flex w-[1640px] max-w-full flex-col items-start justify-start gap-[20px] px-2.5 py-[15px] text-left  text-xs leading-[normal] tracking-[normal] text-darkslateblue ${className}`}
    >
      <div
        className={`mq825:flex-wrap box-border flex w-[1640px] max-w-full flex-col items-start justify-start gap-[20px] px-2.5 py-[15px] text-left  text-xs leading-[normal] tracking-[normal] text-darkslateblue ${className}`}
      >
        <div className="flex flex-row items-center justify-start py-0 pl-0 pr-5">
          <div className="flex flex-row items-start justify-start">
            <a className="relative inline-block min-w-[35px] font-medium leading-[16px] text-[inherit] [text-decoration:none]">
              Dashboard
            </a>
          </div>
        </div>
        {/* Report Generator Start */}
        <div className="w-full flex-1 rounded-mini bg-white p-5">
          <section
            className={`text-13xl flex w-full max-w-full flex-col items-start justify-start gap-5 text-left  ${className}`}
          >
            <div className="flex w-full items-center justify-between gap-2.5">
              <select className="relative  text-[20px] font-extrabold leading-normal text-blue-500 [text-decoration:none]">
                <option value="arizona-fire">ARIZONA FIRE</option>
                <option value="flood">Flood</option>
                <option value="earthquake">Earthquake</option>
                <option value="hurricane">Hurricane</option>
              </select>
              <select className="relative  text-[20px] font-extrabold leading-normal text-blue-500 [text-decoration:none]">
                <option value="yearly">Yearly</option>
                <option value="monthly">Monthly</option>
                <option value="daily">Daily</option>
              </select>
            </div>

            <div className="flex w-full flex-row items-start justify-end self-stretch text-sm text-blue-500">
              <div className="flex w-full max-w-full flex-col items-start justify-start gap-5">
                <div className="mq1100:pl-[33px] mq1100:pr-[33px] mq1100:box-border box-border flex w-full max-w-full flex-row items-start justify-start py-0">
                  <div className="flex w-full flex-1 flex-row flex-wrap content-start items-start justify-start gap-5">
                    <div className="z-[1] box-border flex flex-1 flex-row items-start justify-start gap-[13px] rounded-md border-[1px] border-solid border-gainsboro-200 bg-white pb-6 pl-[22px] pr-3 pt-3 shadow-lg">
                      <div className="relative box-border hidden h-[158px] w-[282px] rounded-b-mini rounded-tl-none rounded-tr-mini border-[1px] border-solid border-gainsboro-200 bg-white" />
                      <div className="flex flex-1 flex-col items-start justify-start px-0 pb-0 pt-3">
                        <div className="z-[2] flex flex-col items-start justify-start gap-[9px] self-stretch py-0 pl-0 pr-[37px]">
                          <h2 className="relative m-0 inline-block min-w-[108px] font-[inherit] text-inherit font-bold text-blue-800">
                            People Affected
                          </h2>
                          <div className="text-29xl flex flex-row items-start justify-start gap-2.5 self-stretch text-darkslategray-300">
                            <div className="relative inline-block text-[40px] font-semibold text-darkslategray-300">
                              {totalRecords}
                            </div>
                            <div className="flex flex-col items-start justify-start px-0 pb-0 pt-[18.5px] text-base">
                              <h1 className="relative m-0 inline-block min-w-[54px] font-[inherit] text-inherit font-semibold">
                                People
                              </h1>
                            </div>
                          </div>
                          <div className="relative pb-2 font-semibold text-gray1-100">
                            As of {getCurrentFormattedDate()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box-border flex flex-1 flex-row items-start justify-start gap-[5.5px] rounded-md border-[1px] border-solid border-gainsboro-200 bg-white pb-[33px] pl-[23px] pr-[9px] pt-3 shadow-lg">
                      <div className="relative box-border hidden h-[158px] w-[273px] rounded-b-mini rounded-tl-none rounded-tr-mini border-[1px] border-solid border-gainsboro-200 bg-white" />
                      <div className="flex flex-1 flex-col items-start justify-start px-0 pb-0 pt-3">
                        <div className="z-[1] flex flex-col items-start justify-start gap-[9px] self-stretch">
                          <h2 className="relative m-0 font-[inherit] text-inherit font-bold">
                            Immediate Response Assistance Received
                          </h2>
                          <div className="relative inline-block text-[40px] font-semibold text-darkslategray-300">
                            ${immediateAssistance}
                          </div>
                          <div className="relative font-semibold text-gray1-100">
                            As of {getCurrentFormattedDate()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box-border flex flex-1 flex-row items-start justify-start gap-[5.5px] rounded-md border-[1px] border-solid border-gainsboro-200 bg-white pb-[33px] pl-[23px] pr-[9px] pt-3 shadow-lg">
                      <div className="relative box-border hidden h-[158px] w-[273px] rounded-b-mini rounded-tl-none rounded-tr-mini border-[1px] border-solid border-gainsboro-200 bg-white" />
                      <div className="flex flex-1 flex-col items-start justify-start px-0 pb-0 pt-3">
                        <div className="z-[1] flex flex-col items-start justify-start gap-[9px] self-stretch">
                          <h2 className="relative m-0 font-[inherit] text-inherit font-bold">
                            Recovery Assistance Received
                          </h2>
                          <div className="relative inline-block text-[40px] font-semibold text-darkslategray-300">
                            ${recoveryAssistance}
                          </div>
                          <div className="relative font-semibold text-gray1-100">
                            As of {getCurrentFormattedDate()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mx-auto mt-4 w-full">
            <div className="flex flex-wrap justify-between gap-5">
              <ChartCard title="Disaster Impact">
                <AccumulationChartComponent
                  id="disaster-impact"
                  tooltip={{ enable: true }}
                  height="300px"
                  legendSettings={{ visible: true, position: "Bottom" }}
                >
                  <Inject
                    services={[
                      PieSeries,
                      AccumulationLegend,
                      AccumulationDataLabel,
                      AccumulationTooltip,
                    ]}
                  />
                  <AccumulationSeriesCollectionDirective>
                    <AccumulationSeriesDirective
                      dataSource={[
                        { x: "Affected", y: disasterImpactData.affected },
                        {
                          x: "Not Affected",
                          y: disasterImpactData.notAffected,
                        },
                      ]}
                      xName="x"
                      yName="y"
                      innerRadius="40%"
                      dataLabel={{
                        visible: true,
                        position: "Outside",
                        name: "x",
                      }}
                    />
                  </AccumulationSeriesCollectionDirective>
                </AccumulationChartComponent>
              </ChartCard>
              <ChartCard title="Injury">
                <ChartComponent
                  id="injury"
                  primaryXAxis={{ valueType: "Category" }}
                  primaryYAxis={{ minimum: 0, maximum: 100, interval: 10 }}
                  height="300px"
                >
                  <Inject
                    services={[
                      ColumnSeries,
                      Category,
                      Legend,
                      DataLabel,
                      Tooltip,
                    ]}
                  />
                  <SeriesCollectionDirective>
                    <SeriesDirective
                      dataSource={[
                        { x: "No Injury", y: injuryData.noInjury },
                        { x: "Minor Injury", y: injuryData.minorInjury },
                        {
                          x: "Medical Treatment",
                          y: injuryData.medicalTreatment,
                        },
                        {
                          x: "Visited Hospital",
                          y: injuryData.visitedHospital,
                        },
                        { x: "Admitted", y: injuryData.admitted },
                      ]}
                      xName="x"
                      yName="y"
                      type="Column"
                    />
                  </SeriesCollectionDirective>
                </ChartComponent>
              </ChartCard>

              <ChartCard title="Home Damage">
                <ChartComponent
                  id="home-damage"
                  primaryXAxis={{ valueType: "Category" }}
                  primaryYAxis={{ minimum: 0, maximum: 70, interval: 10 }}
                  height="300px"
                >
                  <Inject
                    services={[
                      ColumnSeries,
                      Category,
                      Legend,
                      DataLabel,
                      Tooltip,
                    ]}
                  />
                  <SeriesCollectionDirective>
                    <SeriesDirective
                      dataSource={[
                        { x: "No Damage", y: homeDamageData.noDamage },
                        { x: "Slight Damage", y: homeDamageData.slightDamage },
                        {
                          x: "Limited Damage",
                          y: homeDamageData.limitedDamage,
                        },
                        { x: "Severe Damage", y: homeDamageData.severeDamage },
                        {
                          x: "Total Destruction",
                          y: homeDamageData.totalDestruction,
                        },
                      ]}
                      xName="x"
                      yName="y"
                      type="Column"
                    />
                  </SeriesCollectionDirective>
                </ChartComponent>
              </ChartCard>
              <ChartCard title="Homes Destroyed">
                <AccumulationChartComponent
                  id="homes-destroyed"
                  tooltip={{ enable: true }}
                  height="300px"
                  legendSettings={{ visible: true, position: "Bottom" }}
                >
                  <Inject
                    services={[
                      PieSeries,
                      AccumulationLegend,
                      AccumulationDataLabel,
                      AccumulationTooltip,
                    ]}
                  />
                  <AccumulationSeriesCollectionDirective>
                    <AccumulationSeriesDirective
                      dataSource={[
                        { x: "Damaged", y: homesDestroyedData.damaged },
                        { x: "Destroyed", y: homesDestroyedData.destroyed },
                        { x: "Undamaged", y: homesDestroyedData.undamaged },
                      ]}
                      xName="x"
                      yName="y"
                      innerRadius="40%"
                      dataLabel={{
                        visible: true,
                        position: "Outside",
                        name: "x",
                        format: "{point.x}: {point.y}%",
                      }}
                    />
                  </AccumulationSeriesCollectionDirective>
                </AccumulationChartComponent>
              </ChartCard>
            </div>
          </section>

          <section className="mt-4 w-full">
            <div className="flex justify-between gap-5">
              <ChartCardLong title="Type of Home Damage">
                <ChartComponent
                  id="home-damage-type"
                  primaryXAxis={{
                    valueType: "Category",
                    labelStyle: { size: "10px" },
                    enableTextWrapping: true,
                    maximumLabelWidth: 60,
                    labelIntersectAction: "Wrap",
                    majorGridLines: { width: 0 },
                    minorGridLines: { width: 0 },
                    majorTickLines: { width: 0 },
                    minorTickLines: { width: 0 },
                  }}
                  primaryYAxis={{
                    minimum: 0,
                    maximum: 70,
                    interval: 10,
                    labelFormat: "{value}%", // Keep the y-axis label format as a percentage
                  }}
                  height="350px"
                  chartArea={{ border: { width: 0 } }}
                  palettes={["#1e90ff"]}
                  tooltip={{ enable: true }} // Enable tooltip
                >
                  <Inject
                    services={[ColumnSeries, Category, DataLabel, Tooltip]} // Inject Tooltip service here
                  />
                  <SeriesCollectionDirective>
                    <SeriesDirective
                      dataSource={[
                        {
                          x: "Check All",
                          y: typeOfHomeDamageData["Check All"],
                        },
                        { x: "Siding", y: typeOfHomeDamageData.Siding },
                        { x: "Flooring", y: typeOfHomeDamageData.Flooring },
                        { x: "Cabinets", y: typeOfHomeDamageData.Cabinets },
                        {
                          x: "Shed/garage",
                          y: typeOfHomeDamageData["Shed/garage"],
                        },
                        { x: "Roof", y: typeOfHomeDamageData.Roof },
                        { x: "Foundation", y: typeOfHomeDamageData.Foundation },
                        {
                          x: "Floor Joists",
                          y: typeOfHomeDamageData["Floor Joists"],
                        },
                        { x: "HVAC", y: typeOfHomeDamageData.HVAC },
                        { x: "Driveway", y: typeOfHomeDamageData.Driveway },
                        { x: "Gutters", y: typeOfHomeDamageData.Gutters },
                        { x: "Ceiling", y: typeOfHomeDamageData.Ceiling },
                        { x: "Drywall", y: typeOfHomeDamageData.Drywall },
                        { x: "Electrical", y: typeOfHomeDamageData.Electrical },
                        {
                          x: "Yard/Uprooted trees",
                          y: typeOfHomeDamageData["Yard/Uprooted trees"],
                        },
                        { x: "Other", y: typeOfHomeDamageData.Other },
                      ]}
                      xName="x"
                      yName="y"
                      type="Column"
                      dataLabel={{
                        visible: true,
                        position: "Top",
                        format: "{value}%",
                        font: { size: "10px" },
                      }}
                    />
                  </SeriesCollectionDirective>
                </ChartComponent>
              </ChartCardLong>
            </div>
          </section>
        </div>
        {/* Report Generator End */}

        {/* Active Disaster Grid Start */}
        <section className="border-soft-gray box-border w-full rounded-3xs border-[1px] border-solid bg-white px-[19px] pb-5 pt-[18px] text-left  text-xs text-darkslateblue">
          <div>
            <h1 className="title-text mb-3 text-left text-[#193861]">
              ACTIVE DISASTERS
            </h1>

            <GridComponent
              dataSource={activeData}
              allowSorting={true}
              sortSettings={sortSettings}
              // editSettings={editing} // Use the editing state
              actionComplete={actionComplete}
              // toolbar={
              //   editing.allowEditing &&
              //   editing.allowAdding &&
              //   editing.allowDeleting
              //     ? toolbarOptions
              //     : []
              // } // Remove toolbar for citizen and partner
              toolbarClick={toolbarClick}
              toolbarStyle={toolbarStyle}
              filterSettings={filterSettings}
              allowFiltering={true}
              queryCellInfo={queryCellInfo}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="disaster_id"
                  headerText="Disaster ID"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="disaster_name"
                  headerText="Disaster"
                  width="100"
                  textAlign="Left"
                  editType="stringedit" // Ensure editType is set
                />
                <ColumnDirective
                  field="disaster_status"
                  headerText="Status"
                  width="100"
                  textAlign="Left"
                  editType="dropdownedit"
                  edit={{
                    params: {
                      dataSource: [
                        { text: "Active", value: "Active" },
                        { text: "Inactive", value: "Inactive" },
                      ],
                      fields: { text: "text", value: "value" },
                      query: new Query(),
                    },
                  }}
                />
                <ColumnDirective
                  field="disaster_date"
                  headerText="Date"
                  width="100"
                  format="MM/dd/yyyy"
                  editType="datepickeredit"
                  edit={datepickerparams}
                />

                <ColumnDirective
                  field="location"
                  headerText="Location"
                  width="100"
                  editType="stringedit" // Ensure editType is set
                />
                {/* {userRole === "center" && ( // Conditionally render the Actions column
                  <ColumnDirective
                    headerText="Actions"
                    width="120"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                      {
                        type: "Delete",
                        buttonOption: {
                          content: '<i class="fas fa-trash-alt"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                )} */}
              </ColumnsDirective>
              <Inject
                services={[
                  Page,
                  Sort,
                  Edit,
                  CommandColumn,
                  Toolbar,
                  Filter,
                  ColumnMenu,
                ]}
              />
            </GridComponent>
          </div>
        </section>
        {/* Active Disaster Grid End */}
      </div>
    </div>
  );
};

export default Home;
