import { db } from "../../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";

const generateUniqueID = () => {
  return "_" + Math.random().toString(36).substr(2, 9);
};

const citizenProfileService = {
  getCitizenProfile: async (collectionName, docId) => {
    try {
      const docRef = doc(db, collectionName, docId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        console.error("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error reading document: ", error);
      throw error;
    }
  },

  updateCitizenProfile: async (collectionName, docId, data) => {
    try {
      const docRef = doc(db, collectionName, docId);
      const updatedData = { ...data, updated_at: new Date() };
      await updateDoc(docRef, updatedData);
      console.log("Document successfully updated!");
      return { id: docId, ...updatedData };
    } catch (error) {
      console.error("Error updating document: ", error);
      throw error;
    }
  },

  // Get all household members for a citizen
  getHouseholdMembers: async (citizenId) => {
    try {
      const householdMembersRef = collection(
        db,
        "users",
        citizenId,
        "household_members"
      );
      const snapshot = await getDocs(householdMembersRef);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Error getting household members: ", error);
      throw error;
    }
  },

  // Add a new household member
  addHouseholdMember: async (userId, memberData) => {
    try {
      console.log("Adding household member:", memberData); // Log the data being passed
      const docRef = await addDoc(
        collection(db, "users", userId, "household_members"),
        memberData
      );
      console.log("Document written with ID: ", docRef.id);
      return { ...memberData, id: docRef.id };
    } catch (error) {
      console.error("Error adding household member: ", error);
      throw error;
    }
  },

  // Update a household member
  updateHouseholdMember: async (citizenId, householdMemberID, data) => {
    try {
      const memberRef = doc(
        db,
        "users",
        citizenId,
        "household_members",
        householdMemberID
      );
      await setDoc(memberRef, data, { merge: true });
      console.log("Household member updated successfully");
      return { id: householdMemberID, ...data };
    } catch (error) {
      console.error("Error updating household member: ", error);
      throw error;
    }
  },

  // Get all pets for a citizen
  getPets: async (citizenId) => {
    try {
      const petsRef = collection(db, "users", citizenId, "pets");
      const snapshot = await getDocs(petsRef);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Error getting pets: ", error);
      throw error;
    }
  },

  // Add a new pet
  addPet: async (citizenId, petData) => {
    try {
      const petID = generateUniqueID();
      const docRef = await addDoc(collection(db, "users", citizenId, "pets"), {
        ...petData,
        created_at: new Date(),
        petId: petID, // Generate a unique ID for the new pet
      });
      console.log("Document written with ID: ", docRef.id);
      return { ...petData, id: docRef.id, petId: docRef.id };
    } catch (error) {
      console.error("Error adding pet: ", error);
      throw error;
    }
  },

  // Update a pet
  updatePet: async (citizenId, petId, petData) => {
    try {
      const petRef = doc(db, "users", citizenId, "pets", petId);
      await setDoc(
        petRef,
        { ...petData, updated_at: new Date() },
        { merge: true }
      );
      console.log("Pet updated successfully");
      return { id: petId, ...petData };
    } catch (error) {
      console.error("Error updating pet: ", error);
      throw error;
    }
  },

  // Delete a pet
  deletePet: async (citizenId, petId) => {
    try {
      console.log("Deleting pet:", citizenId, petId);
      const petRef = doc(db, "users", citizenId, "pets", petId);
      await deleteDoc(petRef);
      console.log("Pet deleted successfully");
    } catch (error) {
      console.error("Error deleting pet: ", error);
      throw error;
    }
  },

  toggleDisplaced: async (citizenId, toggle) => {
    const userRef = doc(db, "users", citizenId);
    try {
      console.log("Toggling displaced checkbox", citizenId, toggle);
      await updateDoc(userRef, {
        is_displaced: toggle,
      });
      console.log("Displaced checkbox toggled!");
    } catch (error) {
      console.log("Error toggling displaced checkbox: ", error);
      throw error;
    }
  },

  updateDisplacedDetails: async (citizenId, data) => {
    const userRef = doc(db, "users", citizenId);
    try {
      console.log("Updating displaced details...", citizenId, data);
      await updateDoc(userRef, {
        ...data,
        d_updated_at: new Date(),
      });
      console.log("Displaced details updated!");
    } catch (error) {
      console.log("Error updating displaced details: ", error);
      throw error;
    }
  },
};
export default citizenProfileService;
