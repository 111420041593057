import React, { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Edit,
  Toolbar,
  Filter,
  Search,
  Sort,
  Page,
  CommandColumn,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { USStates } from "../datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from "../../firebase"; // Import Firebase config
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { createUserWithEmailAndPassword } from "firebase/auth";
import "../style.css";
import dataService from "../../service/data/DataService";
import {
  CheckBoxSelection,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const sortSettings = {
  allowSorting: true,
};
const filterSettings = {
  type: "CheckBox",
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const validationRules = { required: true };

const preprocessData = (data) => {
  return data.map((item) => ({
    ...item,
    Address: `${item.address1} ${item.address2} ${item.city} ${item.state} ${item.zip}`,
  }));
};

const formatLastEditedTime = (time) => {
  if (!time) return "Never";

  const now = new Date();
  const diffInSeconds = Math.floor((now - time) / 1000);

  if (diffInSeconds < 60) return "Just now";
  if (diffInSeconds < 3600)
    return `${Math.floor(diffInSeconds / 60)} minutes ago`;
  if (diffInSeconds < 86400)
    return `${Math.floor(diffInSeconds / 3600)} hours ago`;
  if (diffInSeconds < 604800)
    return `${Math.floor(diffInSeconds / 86400)} days ago`;

  return time.toLocaleDateString();
};

const generateRandomPassword = (length = 8) => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
  let password = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
};

const servicesProvided = [
  { value: "Funding", text: "Funding" },
  { value: "Debris Removal", text: "Debris Removal" },
  { value: "Case Management", text: "Case Management" },
  { value: "House Repair", text: "House Repair" },
  { value: "House Rebuilds", text: "House Rebuilds" },
  { value: "Volunteer Labor", text: "Volunteer Labor" },
  { value: "Spriritual/Mental Care", text: "Spriritual/Mental Care" },
  { value: "Donation of Goods", text: "Donation of Goods" },
];

const fields = { value: "value", text: "text" };

const Services = (props) => {
  const currentServices = props.servicesProvided || [];

  return (
    <div>
      <label
        htmlFor="servicesProvided"
        className="mb-2 block text-xs text-gray-500"
      >
        Primary Service Provided
      </label>
      <MultiSelectComponent
        id="servicesProvided"
        dataSource={servicesProvided}
        fields={fields}
        value={currentServices}
        mode="CheckBox"
        selectAllText="Select All"
        unSelectAllText="Unselect All"
        showSelectAll={true}
        change={(e) => {
          if (props.onChange) {
            props.onChange({ value: e.value });
          }
        }}
      >
        <Inject services={[CheckBoxSelection]} />
      </MultiSelectComponent>
    </div>
  );
};

const disaster = [
  { value: "Fire", text: "1" },
  { value: "Flood", text: "2" },
  { value: "Earthquake", text: "3" },
];

const fieldsDisaster = { value: "value", text: "value" };

const DisasterTemplate = (props) => {
  const currentAccess = (props && props.disaster) || [];

  return (
    <div>
      <label htmlFor="disaster" className="mb-2 block text-xs text-gray-500">
        Disaster
      </label>
      <MultiSelectComponent
        id="disaster"
        dataSource={disaster}
        fields={fieldsDisaster}
        value={currentAccess}
        mode="CheckBox"
        selectAllText="Select All"
        unSelectAllText="Unselect All"
        showSelectAll={true}
      >
        <Inject services={[CheckBoxSelection]} />
      </MultiSelectComponent>
    </div>
  );
};

const DisasterRecoverySearch = ({ className = "" }) => {
  const [centerDisasterRecovery, setCenterDisasterRecovery] = useState([]);
  const gridRef = useRef(null);
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [userId, setUserId] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid);
        console.log("User ID:", user.uid); // Check if user ID is retrieved

        const userData = await dataService.getUserProfile("users", user.uid);
        if (userData) {
          setCurrentUserData(userData);
          console.log("Data: ", userData);
          try {
            const userQuerySnapshot = await getDocs(
              query(
                collection(db, "users"),
                where("center_id", "array-contains", userData.center_id),
                where("user_role", "==", "partner")
              )
            );
            const drpPartnerData = userQuerySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            console.log("DRP Partner Data:", drpPartnerData);

            const partnerIds = drpPartnerData.map(
              (partner) => partner.partner_id
            );
            const partnerData = await Promise.all(
              partnerIds.map(async (partnerId) => {
                const partnerDocRef = doc(db, "partners", partnerId);
                const partnerDocSnap = await getDoc(partnerDocRef);
                if (partnerDocSnap.exists()) {
                  const partnerData = partnerDocSnap.data();
                  if (partnerData.type === "disaster-recovery") {
                    return { id: partnerDocSnap.id, ...partnerData };
                  } else {
                    console.log(
                      `Partner with id: ${partnerId} is not a disaster-recovery partner.`
                    );
                    return null;
                  }
                } else {
                  console.error(
                    `No partner document found with id: ${partnerId}`
                  );
                  return null;
                }
              })
            );

            console.log("Fetched partner data:", partnerData.filter(Boolean));
            setCenterDisasterRecovery(
              preprocessData(partnerData.filter(Boolean))
            );
            return preprocessData(partnerData.filter(Boolean));
          } catch (error) {
            console.error("Error fetching contacts:", error);
          }
        }
      } else {
        setUserId(null);
        setCenterDisasterRecovery([]); // Clear contacts if no user is logged in
      }
    });

    return () => unsubscribe(); // Clean up subscription
  }, [userId]); // Add userId as a dependency

  return (
    <>
      <div className={`flex max-w-full flex-col gap-5 p-4 ${className}`}>
        <div className="flex items-center gap-2 pt-2 text-xs text-darkslateblue">
          <a className="font-medium">Database</a>
          <span className="text-base font-semibold text-gray-400">/</span>
          <span className="font-medium">Disaster Recovery Partners Search</span>
        </div>
        <div className="relative mb-2 inline-block max-w-full font-poppins font-medium leading-[21px] text-darkslategray-200">
          <p>
            Disaster Partners are organizations and agencies from various
            regions that provide specialized support during disaster response
            and recovery. While not active year-round, they are critical
            resources during emergencies, offering expertise, personnel, and aid
            when your community needs it most.
          </p>
        </div>
      </div>
      <header className=" flex w-full flex-row items-center  justify-between self-stretch rounded-3xs pl-5 pr-5 pt-[8px] text-left text-xs text-darkslateblue">
        <div>
          <h2 className="mb-1 text-sm font-bold uppercase text-blue-500">
            Disaster Recovery Partners
          </h2>
        </div>
      </header>
      <div className="mt-3 w-full rounded-3xs pl-5 pr-5  text-left text-xs text-darkslateblue">
        <GridComponent
          dataSource={centerDisasterRecovery.filter(
            (contact) => contact.is_deleted !== true
          )}
          filterSettings={filterSettings}
          allowFiltering={true}
          allowSorting={true}
          ref={gridRef}
          allowPaging={true}
          pageSettings={pageSettings}
          toolbar={["Search"]}
          sortSettings={sortSettings}
          showColumnMenu={true}
          columnMenuItems={columnMenuItems}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="county"
              headerText="Primary County"
              visible={true}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="name_of_org"
              headerText="Organization"
              visible={true}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="servicesProvided"
              headerText="Primary Service Provided"
              editTemplate={Services}
              validationRules={validationRules}
            />
            <ColumnDirective field="Address" headerText="Address" />
            <ColumnDirective
              field="address1"
              headerText="Address 1"
              visible={false}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="address2"
              headerText="Address 2"
              visible={false}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="city"
              headerText="City"
              visible={false}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="state"
              headerText="State"
              visible={false}
              editType="dropdownedit"
              edit={{
                params: {
                  dataSource: USStates,
                  fields: { text: "abbreviation", value: "abbreviation" },
                  query: new Query(),
                },
              }}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="zip"
              headerText="Zip"
              visible={false}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="mobile_number"
              headerText="Mobile Number"
              validationRules={validationRules}
            />
            <ColumnDirective
              field="email"
              headerText="Email"
              validationRules={validationRules}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Edit,
              Filter,
              Sort,
              Page,
              CommandColumn,
              Search,
              ColumnMenu,
            ]}
          />
        </GridComponent>
      </div>
    </>
  );
};

export default DisasterRecoverySearch;
