import React, { useState, useEffect, useRef } from "react";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Edit,
  Toolbar,
  Filter,
  Sort,
  Page,
  CommandColumn,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { USStates } from "../datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from "../../firebase"; // Import Firebase config
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import "../style.css";
import { config } from "../../utils/config";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const editOptions = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",
  dialog: { cssClass: "custom-dialog" },
};
const filterSettings = {
  type: "CheckBox",
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const preprocessData = (data) => {
  return data.map((item) => ({
    ...item,
    Name: `${item.first_name} ${item.last_name}`,
    Address: `${item.address1} ${item.address2} ${item.city} ${item.state} ${item.zip}`,
  }));
};

const Contacts = ({ className = "" }) => {
  const [commContacts, setCommContacts] = useState([]);
  const gridRef = useRef(null);
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        console.log("User ID:", user.uid);
        fetchContacts(); // Use the new fetchContacts function
      } else {
        setUserId(null);
        setCommContacts([]);
      }
    });

    return () => unsubscribe();
  }, [userId]);

  const fetchContacts = async () => {
    if (!userId) {
      console.log("No user ID available, skipping fetch.");
      return;
    }

    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, "community-contacts"),
          where("user_id", "==", userId)
        )
      );
      const contactsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("Fetched contacts:", contactsData);
      setCommContacts(preprocessData(contactsData));
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const handleAdd = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  const actionBegin = async (args) => {
    if (gridRef.current) {
      try {
        if (args.requestType === "beginEdit" || args.requestType === "add") {
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "first_name",
                "last_name",
                "address1",
                "address2",
                "city",
                "state",
                "zip",
              ].includes(col.field)
            ) {
              col.visible = true;
            } else if (["Name", "Address"].includes(col.field)) {
              col.visible = false;
            }
          }
        }

        if (args.requestType === "save") {
          setLastEditedTime(new Date());

          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "first_name",
                "last_name",
                "address1",
                "address2",
                "city",
                "state",
                "zip",
              ].includes(col.field)
            ) {
              col.visible = false;
            } else if (["Name", "Address"].includes(col.field)) {
              col.visible = true;
            }
          }

          // Update Name and Address fields
          const data = args.data;
          data.Name = `${data.first_name} ${data.last_name}`;
          data.Address = `${data.address1 || ""} ${data.address2 || ""} ${
            data.city || ""
          } ${data.state || ""} ${data.zip || ""}`;

          // Fetch Longitude and Latitude from the Google Maps API based on the address
          const address = `${data.address1}, ${data.city}, ${data.state}, ${data.zip}`;
          let lat, lng;
          try {
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                address
              )}&key=${config.google_maps}`
            );
            const geoData = await response.json();
            if (geoData.results.length > 0) {
              lat = geoData.results[0].geometry.location.lat;
              lng = geoData.results[0].geometry.location.lng;
            } else {
              throw new Error("No results found");
            }
          } catch (err) {
            console.error("Error fetching geolocation:", err);
          }

          if (lat && lng) {
            data.latitude = lat;
            data.longitude = lng;
          }

          // Remove undefined fields
          Object.keys(data).forEach((key) => {
            if (data[key] === undefined) {
              delete data[key];
            }
          });

          if (args.action === "add") {
            await addDoc(collection(db, "community-contacts"), {
              ...data,
              contactID: userId, // Use logged in user UID as contactID
              last_updated: serverTimestamp(),
              inserted_at: serverTimestamp(),
              user_id: userId,
              is_deleted: false,
              latitude: lat,
              longitude: lng,
            });
            await fetchContacts();
          } else if (args.action === "edit") {
            if (data.id) {
              const contactDoc = doc(db, "community-contacts", data.id);
              await updateDoc(contactDoc, {
                ...data,
                contactID: userId, // Use logged in user UID as contactID
                last_updated: serverTimestamp(),
                is_deleted: false,
                latitude: lat,
                longitude: lng,
              });
            } else {
              console.error("Document ID is missing for update.");
            }
            await fetchContacts();
          }
        }

        if (args.requestType === "delete") {
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "first_name",
                "last_name",
                "address1",
                "address2",
                "city",
                "state",
                "zip",
              ].includes(col.field)
            ) {
              col.visible = false;
            } else if (["Name", "Address"].includes(col.field)) {
              col.visible = true;
            }
          }
          const data = args.data[0];
          if (data.id) {
            const contactDoc = doc(db, "community-contacts", data.id);
            await updateDoc(contactDoc, {
              is_deleted: true,
              last_updated: serverTimestamp(),
            });
          } else {
            console.error("Document ID is missing for delete.");
          }
          await fetchContacts();
        }
      } catch (error) {
        console.error("Error during actionBegin:", error);
      }
    }
  };

  return (
    <>
      <div className={`flex max-w-full flex-col gap-5 p-4 ${className}`}>
        <div className="flex items-center gap-2 pt-2 text-xs text-darkslateblue">
          <a className="ml-1 font-medium">Recovery Plan</a>
          <span className="text-base font-semibold text-gray-400">/</span>
          <span className="font-medium">Community Contacts</span>
        </div>
        <div className="relative mb-2 inline-block max-w-full font-poppins font-medium leading-[21px] text-darkslategray-200">
          <p>
            The Community Contacts section is designed for you to store and
            share the contact information of key community members essential to
            disaster response and recovery. Having quick access to these
            contacts helps ensure effective communication and coordination
            during emergencies. Please provide accurate and up-to-date
            information to strengthen your community's readiness.
          </p>
        </div>
      </div>
      <header className=" flex w-full flex-row items-center  justify-between self-stretch rounded-3xs pl-5 pr-5 pt-[8px] text-left text-xs text-darkslateblue">
        <div>
          <h2 className="mb-1 text-sm font-bold text-blue-500">
            COMMUNITY CONTACTS
          </h2>
          <p className="font-poppins text-xs font-medium text-gray-500">
            Last Edited: {formatLastEditedTime(lastEditedTime)}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <button
            type="button"
            className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
            onClick={handleAdd}
          >
            <FaPlus className="h-3 w-3" />
            Add
          </button>
        </div>
      </header>
      <section className="mt-3 w-full rounded-3xs pl-5 pr-5  text-left text-xs text-darkslateblue">
        <div>
          <GridComponent
            dataSource={commContacts.filter(
              (contact) => contact.is_deleted !== true
            )}
            actionBegin={actionBegin}
            editSettings={editOptions}
            filterSettings={filterSettings}
            allowFiltering={true}
            allowSorting={true}
            ref={gridRef}
            allowPaging={true}
            pageSettings={pageSettings}
            showColumnMenu={true}
            columnMenuItems={columnMenuItems}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="contactID"
                headerText="Contact ID"
                isPrimaryKey={true}
                visible={false}
              />
              <ColumnDirective
                field="first_name"
                headerText="First Name"
                visible={false}
              />
              <ColumnDirective
                field="last_name"
                headerText="Last Name"
                visible={false}
              />
              <ColumnDirective field="Name" headerText="Name" width="150" />
              <ColumnDirective
                field="occupation"
                headerText="Occupation"
                width="150"
              />
              <ColumnDirective
                field="mobileNumber"
                headerText="Mobile Number"
                width="100"
              />
              <ColumnDirective
                field="email"
                headerText="Email Address"
                width="150"
              />
              <ColumnDirective
                field="Address"
                headerText="Address"
                width="250"
              />
              <ColumnDirective
                field="address1"
                headerText="Address 1"
                visible={false}
              />
              <ColumnDirective
                field="address2"
                headerText="Address 2"
                visible={false}
              />
              <ColumnDirective field="city" headerText="City" visible={false} />
              <ColumnDirective
                field="state"
                headerText="State"
                visible={false}
                editType="dropdownedit"
                edit={{
                  params: {
                    dataSource: USStates,
                    fields: { text: "abbreviation", value: "abbreviation" },
                    query: new Query(),
                  },
                }}
              />
              <ColumnDirective field="zip" headerText="Zip" visible={false} />
              <ColumnDirective
                headerText="Commands"
                width="120"
                textAlign="Center"
                commands={[
                  {
                    type: "Edit",
                    buttonOption: {
                      content: '<i class="fas fa-edit"></i>',
                      cssClass: "e-outline custom-button",
                    },
                  },
                  {
                    type: "Delete",
                    buttonOption: {
                      content: '<i class="fas fa-trash-alt"></i>',
                      cssClass: "e-outline custom-button",
                    },
                  },
                ]}
              />
            </ColumnsDirective>
            <Inject
              services={[
                Edit,
                Filter,
                Sort,
                Page,
                CommandColumn,
                ColumnMenu,
                ColumnMenu,
              ]}
            />
          </GridComponent>
        </div>
      </section>
    </>
  );
};

export default Contacts;
