import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import weatherRadioIcon from "../icons/Preparedness/weatherRadio.png";
import weatherRadioIcon2 from "../icons/Preparedness/weatherRadio2.png";
import IntroPic from "../icons/CertificateVideo/Intro.png";
import ready from "../icons/Preparedness/ready.png";
import Phases from "../icons/CertificateVideo/Phases.png";
import Roles from "../icons/CertificateVideo/Roles.png";
import LedRecovery from "../icons/CertificateVideo/LedRecovery.png";
import CertifiedCitizen from "../icons/CertificateVideo/CertifiedCitizen.png";
import QuickInfo from "./Library/QuickInfo";
import Recovery from "./Library/Recovery";
import Response from "./Library/Response";
import Preparedness from "./Library/Preparedness";
import { auth, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import CertificationVideosCenter from "./Library/CertificationVideos/CertificationVideosCenter";
import CertificationVideosPartners from "./Library/CertificationVideos/CertificationVideosPartners";

const CertificationVideos = (className = "") => {
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserRole(userDoc.data().user_role);
        }
      }
    };

    fetchUserRole();
  }, []);

  const [activeTab, setActiveTab] = useState(
    "DARCI Preparedness Program - PIC"
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [isPhaseModalOpen, setIsPhaseModalOpen] = useState(false);

  const openPhaseModal = () => setIsPhaseModalOpen(true);
  const closePhaseModal = () => setIsPhaseModalOpen(false);

  const [isRolesModalOpen, setIsRolesModalOpen] = useState(false);

  const openRolesModal = () => setIsRolesModalOpen(true);
  const closeRolesModal = () => setIsRolesModalOpen(false);

  const [isCommunityRecoveryModalOpen, setIsCommunityRecoveryModalOpen] =
    useState(false);

  const openCommunityRecoveryModal = () =>
    setIsCommunityRecoveryModalOpen(true);
  const closeCommunityRecoveryModal = () =>
    setIsCommunityRecoveryModalOpen(false);

  const [isCertifiedCitizenModalOpen, setIsCertifiedCitizenModalOpen] =
    useState(false);

  const openCertifiedCitizenModal = () => setIsCertifiedCitizenModalOpen(true);
  const closeCertifiedCitizenModal = () =>
    setIsCertifiedCitizenModalOpen(false);

  // Citizen modals
  const [isCitizenPhaseModalOpen, setIsCitizenPhaseModalOpen] = useState(false);
  const [isCitizenRolesModalOpen, setIsCitizenRolesModalOpen] = useState(false);
  const [
    isCitizenCommunityRecoveryModalOpen,
    setIsCitizenCommunityRecoveryModalOpen,
  ] = useState(false);
  const [
    isCitizenCertifiedCitizenModalOpen,
    setIsCitizenCertifiedCitizenModalOpen,
  ] = useState(false);

  // Center modals
  const [isCenterPhaseModalOpen, setIsCenterPhaseModalOpen] = useState(false);
  const [isCenterRolesModalOpen, setIsCenterRolesModalOpen] = useState(false);
  const [
    isCenterCommunityRecoveryModalOpen,
    setIsCenterCommunityRecoveryModalOpen,
  ] = useState(false);
  const [
    isCenterCertifiedCitizenModalOpen,
    setIsCenterCertifiedCitizenModalOpen,
  ] = useState(false);

  // Partners modals
  const [isPartnerPhaseModalOpen, setIsPartnerPhaseModalOpen] = useState(false);
  const [isPartnerRolesModalOpen, setIsPartnerRolesModalOpen] = useState(false);
  const [
    isPartnerCommunityRecoveryModalOpen,
    setIsPartnerCommunityRecoveryModalOpen,
  ] = useState(false);
  const [
    isPartnerCertifiedCitizenModalOpen,
    setIsPartnerCertifiedCitizenModalOpen,
  ] = useState(false);

  const Modal = ({ isOpen, onClose, videoSrc }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 !font-[Inter]">
        <div
          className="overflow-hidden rounded-lg bg-black shadow-lg"
          style={{ width: "560px" }}
        >
          <div className="flex items-center justify-between bg-gray-800 p-2 text-white">
            <span className="font-bold">Video</span>
            <button
              className="flex h-8 w-8 items-center justify-center rounded-full border border-gray-300 bg-black text-gray-300 transition-colors duration-200 ease-in-out hover:border-white hover:text-white focus:outline-none"
              onClick={onClose}
            >
              <span className="text-3xl leading-none">X</span>
            </button>
          </div>
          <div className="p-4">
            <iframe
              width="100%"
              height="315"
              src={videoSrc}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const tabs = [
    "DARCI Preparedness Program - PIC",
    "Quick Info",
    "Preparedness",
    "Response",
    "Recovery",
  ];

  const [isOpen, setIsOpen] = useState(true);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "DARCI Preparedness Program - PIC":
        return (
          <div
            className={` flex max-w-full flex-col gap-5 !font-[Inter] ${className}`}
          >
            {/* Breadcrumb and Navigation */}

            {/* Main Content */}
            <div className="flex flex-col">
              <div className="relative mb-2 inline-block max-w-full font-[Inter] font-medium leading-[21px] text-darkslategray-200">
                <p>
                  The DARCI/Blue Sky library is constantly expanding, gathering
                  new content and resources to better equip communities for
                  disaster preparedness and recovery. By continuously adding
                  valuable insights and tools, we ensure that local leaders,
                  nonprofits, and citizens have access to the most up-to-date
                  information and strategies for effective disaster response.
                </p>
              </div>
              <section className="flex max-w-full flex-col items-start justify-start gap-[8px] self-stretch text-left text-sm text-darkslateblue">
                <h2 className="mb-1 text-sm font-bold text-blue-500 mt-4">
                  INTRODUCTION TO DARCI AND COMMUNITY PREPAREDNESS
                </h2>
                <div className="text-darkslategray box-border flex max-w-full flex-row items-start justify-start gap-[51px] self-stretch rounded-b-mini rounded-tl-none rounded-tr-mini bg-white px-[42px] py-3 mq1300:flex-wrap mq1125:box-border mq1125:pl-[21px] mq1125:pr-[21px] mq450:gap-[25px]">
                  <div className="flex w-[563px] min-w-[563px] max-w-full flex-col items-start justify-start gap-[14px] mq1300:flex-1 mq800:min-w-full">
                    <b className="relative self-stretch">
                      Community Introductory Videos
                    </b>
                    <div className="flex h-[217px] flex-row flex-wrap items-start justify-start self-stretch text-smi text-darkslateblue">
                      <div className="flex w-[271px] flex-col items-start justify-start gap-[10px] self-stretch">
                        <div
                          className="relative aspect-video w-full cursor-pointer"
                          onClick={openModal}
                        >
                          <img
                            className="h-full w-full rounded-lg object-cover"
                            alt="Welcome to DARCI video thumbnail"
                            src={IntroPic} // Replace with your actual thumbnail image
                          />
                          <div className="absolute inset-0 flex items-center justify-center">
                            <svg
                              className="h-16 w-16 text-white opacity-80"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                            </svg>
                          </div>
                        </div>
                        <b className="relative self-stretch">
                          Welcome to DARCI
                        </b>
                      </div>
                    </div>
                    <Modal
                      isOpen={isModalOpen}
                      onClose={closeModal}
                      videoSrc="https://www.youtube.com/embed/SrU0Bv1gi3E?autoplay=1"
                    />
                  </div>
                  <div className="flex min-w-[606px] max-w-full flex-1 flex-col items-start justify-start gap-[38px] mq800:min-w-full mq800:gap-[19px]">
                    <b className="relative self-stretch">
                      Supplemental Support Articles
                    </b>
                    <div className="flex max-w-full flex-col items-start justify-start gap-[23px]">
                      <div className="relative font-semibold">
                        Support Article Community
                      </div>
                      <div className="flex flex-row items-start justify-start py-0 pl-px pr-0 text-gray1-100">
                        <div className="relative">
                          The increasing frequency and severity of natural
                          disasters, including drought, is placing enormous...
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/*Citizen Content*/}
              {(userRole === "citizen" ||
                userRole === "center" ||
                userRole === "admin") && (
                <section className="flex max-w-full flex-col items-start justify-start gap-[8px] self-stretch text-left  text-sm text-darkslateblue">
                  <div
                    className="flex cursor-pointer items-center self-stretch"
                    onClick={toggleDropdown}
                  >
                    <svg
                      className={`mr-2 h-6 w-6 transform transition-transform ${
                        isOpen ? "rotate-90" : ""
                      }`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M6 6L14 10L6 14V6Z" />
                    </svg>
                    <h2 className="mb-1 text-sm font-bold text-blue-500">
                      DARCI Citizen Course Videos
                    </h2>
                  </div>
                  {isOpen && (
                    <div className="text-darkslategray box-border flex max-w-full flex-row items-start justify-start gap-[51px] self-stretch rounded-b-mini rounded-tl-none rounded-tr-mini bg-white px-[42px] pb-[27px] pt-3 mq1300:flex-wrap mq1125:box-border mq1125:pl-[21px] mq1125:pr-[21px] mq450:gap-[25px]">
                      <div className="flex w-[563px] min-w-[563px] max-w-full flex-col items-start justify-start gap-[14px] mq1300:flex-1 mq800:min-w-full">
                        <b className="relative self-stretch">
                          Citizen Introductory Videos
                        </b>
                        <div className="flex min-h-[451px] flex-row flex-wrap items-start justify-start gap-[17px_13px] self-stretch text-smi text-darkslateblue">
                          <div className="flex h-[217px] w-[271px] flex-col items-start justify-start gap-[10px]">
                            <div
                              className="relative h-full w-full cursor-pointer"
                              onClick={() => setIsCitizenPhaseModalOpen(true)}
                            >
                              <img
                                className="relative max-h-full max-w-full flex-1 self-stretch overflow-hidden rounded-lg"
                                alt="Phases of Disaster thumbnail"
                                src={Phases}
                              />
                              <div className="absolute inset-0 flex items-center justify-center">
                                <svg
                                  className="h-16 w-16 text-white opacity-80"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                                </svg>
                              </div>
                            </div>
                            <b className="relative self-stretch">
                              Phases of Disaster
                            </b>
                          </div>

                          <Modal
                            isOpen={isCitizenPhaseModalOpen}
                            onClose={() => setIsCitizenPhaseModalOpen(false)}
                            videoSrc="https://www.youtube.com/embed/Sj4eXgQu3vo?autoplay=1"
                          />

                          <div className="flex w-[272px] flex-col items-start justify-start gap-[10px]">
                            <div
                              className="relative h-48 cursor-pointer self-stretch"
                              onClick={openRolesModal}
                            >
                              <img
                                className="absolute left-[0px] top-[0px] h-full w-full rounded-lg object-contain"
                                alt="National Roles and Responsibilities thumbnail"
                                src={Roles}
                              />
                              <div className="absolute inset-0 flex items-center justify-center">
                                <svg
                                  className="h-16 w-16 text-white opacity-80"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                                </svg>
                              </div>
                            </div>
                            <b className="relative">
                              National Roles and Responsibility's in Re...
                            </b>
                          </div>

                          <Modal
                            isOpen={isRolesModalOpen}
                            onClose={closeRolesModal}
                            videoSrc="https://www.youtube.com/embed/zrbklf_1uOU?autoplay=1"
                          />

                          <div className="flex h-[217px] w-[273px] flex-col items-start justify-start gap-[10px]">
                            <div
                              className="relative h-full w-full cursor-pointer"
                              onClick={openCommunityRecoveryModal}
                            >
                              <img
                                className="relative max-h-full max-w-full flex-1 self-stretch overflow-hidden rounded-lg"
                                alt="Community Led Recovery thumbnail"
                                src={LedRecovery}
                              />
                              <div className="absolute inset-0 flex items-center justify-center">
                                <svg
                                  className="h-16 w-16 text-white opacity-80"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                                </svg>
                              </div>
                            </div>
                            <b className="relative self-stretch">
                              Community Led Recovery
                            </b>
                          </div>

                          <Modal
                            isOpen={isCommunityRecoveryModalOpen}
                            onClose={closeCommunityRecoveryModal}
                            videoSrc="https://www.youtube.com/embed/81t4quqwp5Q?autoplay=1"
                          />

                          <div className="flex h-[217px] w-[272px] flex-col items-start justify-start gap-[10px]">
                            <div
                              className="relative h-full w-full cursor-pointer"
                              onClick={openCertifiedCitizenModal}
                            >
                              <img
                                className="relative max-h-full max-w-full flex-1 self-stretch overflow-hidden rounded-lg"
                                alt="Certified Citizen thumbnail"
                                src={CertifiedCitizen}
                              />
                              <div className="absolute inset-0 flex items-center justify-center">
                                <svg
                                  className="h-16 w-16 text-white opacity-80"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                                </svg>
                              </div>
                            </div>
                            <b className="relative self-stretch">Citizen</b>
                          </div>

                          <Modal
                            isOpen={isCertifiedCitizenModalOpen}
                            onClose={closeCertifiedCitizenModal}
                            videoSrc="https://www.youtube.com/embed/t_Qlyfm6beY?autoplay=1"
                          />
                        </div>
                      </div>
                      <div className="flex min-w-[606px] max-w-full flex-1 flex-col items-start justify-start gap-[38px] mq800:min-w-full mq800:gap-[19px]">
                        <b className="relative self-stretch">
                          Supplemental Support Articles
                        </b>
                        <div className="flex max-w-full flex-col items-start justify-start gap-[23px] self-stretch">
                          <div className="relative font-semibold">
                            Support Article Citizen
                          </div>
                          <div className="box-border flex max-w-full flex-row items-start justify-start self-stretch py-0 pl-px pr-0 text-gray1-100">
                            <div className="relative inline-block max-w-full flex-1">
                              The process of recovering from a disaster can be
                              complex for victims. Government aid is often
                              distributed through multiple agencies, each with
                              their own
                            </div>
                          </div>
                        </div>
                        <div className="flex max-w-full flex-col items-start justify-start gap-[20px] self-stretch">
                          <div className="relative font-semibold">
                            Why DARCI CITIZEN
                          </div>
                          <div className="box-border flex max-w-full flex-row items-start justify-start self-stretch py-0 pl-px pr-0 text-gray1-100">
                            <div className="relative inline-block max-w-full flex-1">
                              Enrolling as a DARCI Citizen presents an optimal
                              opportunity to fortify yourself and your family
                              against potential disasters. Key advantages entail
                              com...
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </section>
              )}
              {/*Center Content*/}
              <div className="flex flex-col gap-8">
                {(userRole === "center" ||
                  userRole === "partner" ||
                  userRole === "admin") && (
                  <CertificationVideosCenter
                    openPhaseModal={() => setIsCenterPhaseModalOpen(true)}
                    openRolesModal={() => setIsCenterRolesModalOpen(true)}
                    openCommunityRecoveryModal={() =>
                      setIsCenterCommunityRecoveryModalOpen(true)
                    }
                    openCertifiedCitizenModal={() =>
                      setIsCenterCertifiedCitizenModalOpen(true)
                    }
                    Modal={Modal}
                    isPhaseModalOpen={isCenterPhaseModalOpen}
                    closePhaseModal={() => setIsCenterPhaseModalOpen(false)}
                    isRolesModalOpen={isCenterRolesModalOpen}
                    closeRolesModal={() => setIsCenterRolesModalOpen(false)}
                    isCommunityRecoveryModalOpen={
                      isCenterCommunityRecoveryModalOpen
                    }
                    closeCommunityRecoveryModal={() =>
                      setIsCenterCommunityRecoveryModalOpen(false)
                    }
                    isCertifiedCitizenModalOpen={
                      isCenterCertifiedCitizenModalOpen
                    }
                    closeCertifiedCitizenModal={() =>
                      setIsCenterCertifiedCitizenModalOpen(false)
                    }
                  />
                )}
              </div>

              {/*Partners Content*/}
              <div className="flex flex-col gap-8">
                {(userRole === "center" ||
                  userRole === "partner" ||
                  userRole === "admin") && (
                  <CertificationVideosPartners
                    openPhaseModal={() => setIsPartnerPhaseModalOpen(true)}
                    openRolesModal={() => setIsPartnerRolesModalOpen(true)}
                    openCommunityRecoveryModal={() =>
                      setIsPartnerCommunityRecoveryModalOpen(true)
                    }
                    openCertifiedCitizenModal={() =>
                      setIsPartnerCertifiedCitizenModalOpen(true)
                    }
                    Modal={Modal}
                    isPhaseModalOpen={isPartnerPhaseModalOpen}
                    closePhaseModal={() => setIsPartnerPhaseModalOpen(false)}
                    isRolesModalOpen={isPartnerRolesModalOpen}
                    closeRolesModal={() => setIsPartnerRolesModalOpen(false)}
                    isCommunityRecoveryModalOpen={
                      isPartnerCommunityRecoveryModalOpen
                    }
                    closeCommunityRecoveryModal={() =>
                      setIsPartnerCommunityRecoveryModalOpen(false)
                    }
                    isCertifiedCitizenModalOpen={
                      isPartnerCertifiedCitizenModalOpen
                    }
                    closeCertifiedCitizenModal={() =>
                      setIsPartnerCertifiedCitizenModalOpen(false)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        );
      case "Quick Info":
        return;
      case "Preparedness":
        return;
      case "Response":
        return;
      case "Recovery":
        return;
      default:
        return null;
    }
  };

  return (
    <div
      className={`box-border flex w-full max-w-[1640px] flex-col items-start justify-start px-2.5 py-[15px] ${className}`}
    >
      {/* Combined Breadcrumb Navigation and Tabs */}
      <div className="mb-4 flex w-full flex-row items-center justify-between">
        {/* Breadcrumb Navigation on the left */}
        <div className="flex flex-row items-start justify-start gap-1.5  text-xs text-darkslateblue">
          <a className="relative inline-block min-w-[40px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Library
          </a>
          <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <a className="relative inline-block min-w-[77px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            {activeTab}
          </a>
        </div>

        {/* Tabs on the right side of the screen */}
        <div className="ml-auto flex flex-wrap justify-end gap-2 text-xs">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`rounded-full px-4 py-2 ${
                activeTab === tab
                  ? "bg-dodgerblue text-white"
                  : "border border-dodgerblue text-dodgerblue hover:bg-blue-100"
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      {/* Main Content */}
      {activeTab === "Blue Sky Certification Videos" && ""}
      {activeTab === "Quick Info" && <QuickInfo />}
      {activeTab === "Preparedness" && <Preparedness />}
      {activeTab === "Response" && <Response />}
      {activeTab === "Recovery" && <Recovery />}

      {/* Rendered Content */}
      {renderContent()}
    </div>
  );
};

CertificationVideos.propTypes = {
  className: PropTypes.string,
};

export default CertificationVideos;
