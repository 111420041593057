import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { memo, useEffect, useState } from "react";
import { Months } from "../utils/constants";

const DatePickerTemplate = ({ label, onChange, value }) => {
  const [date, setDate] = useState({
    month: value ? parseInt(value.split("/")[0]) : null,
    day: value ? parseInt(value.split("/")[1]) : null,
    year: value ? parseInt(value.split("/")[2]) : null,
  });

  const dayDataSource = Array.from({ length: 31 }, (_, index) => ({
    label: `${index + 1}`,
    value: index + 1,
  }));

  const startYear = 1899;
  const endYear = 2024;

  const yearDataSource = Array.from(
    { length: endYear - startYear },
    (_, index) => ({
      label: `${endYear - index}`,
      value: endYear - index,
    })
  );

  const field = { text: "label", value: "value" };

  const onDropDownChange =
    (key) =>
    ({ value }) => {
      setDate((prev) => ({ ...prev, [key]: value }));
    };

  useEffect(() => {
    if (Object.values(date).some((d) => !d)) return;
    onChange(`${date.month}/${date.day}/${date.year}`);
  }, [date]);

  return (
    <div className="my-2">
      {!!label && <p className="text-darkgray">{label}</p>}
      <div className="flex space-x-3">
        <DropDownListComponent
          dataSource={Months}
          fields={field}
          placeholder="Month"
          floatLabelType="Auto"
          width={80}
          {...(date.month && { value: date.month })}
          onChange={onDropDownChange("month")}
        />
        <DropDownListComponent
          dataSource={dayDataSource}
          fields={field}
          placeholder="Day"
          floatLabelType="Auto"
          width={70}
          {...(date.day && { value: date.day })}
          onChange={onDropDownChange("day")}
        />
        <DropDownListComponent
          dataSource={yearDataSource}
          fields={field}
          placeholder="Year"
          floatLabelType="Auto"
          width={80}
          {...(date.year && { value: date.year })}
          onChange={onDropDownChange("year")}
        />
      </div>
    </div>
  );
};

export default memo(DatePickerTemplate);
