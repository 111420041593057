import React from "react";

const GeneralInfoArticle = [
  {
    title: "Finding Post-Disaster Resources for Seniors",
    content:
      "Natural disasters can strike unexpectedly, leaving people looking for ways to rebuild their lives.",
    link: "https://www.ncoa.org/article/post-disaster-resources-seniors",
  },
  {
    title: "Disaster Distress Helpline",
    content:
      "The Disaster Distress Helpline (DDH) is the first national hotline dedicated to providing year-round disaster crisis counseling.",
    link: "https://www.samhsa.gov/find-help/disaster-distress-helpline",
  },
];

const TornadoArticle = [
  {
    title: "How Can You Stay Safe After a Tornado?",
    content:
      "A tornado is a tube of spinning air that forms from a thunderstorm and touches the ground.",
    link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/tornado.html",
  },
  {
    title: "Stay Safe After a Tornado",
    content:
      "After a tornado, there are many hazards you could face, including injuries from walking among debris or entering damaged buildings.",
    link: "https://www.cdc.gov/disasters/tornadoes/after.html",
  },
  {
    title: "What to do After a Tornado",
    content:
      "Stay Informed: Continue to listen to local news or a NOAA Weather Radio to stay updated about tornado watches and warnings.",
    link: "https://www.weather.gov/safety/tornado-after",
  },
  {
    title: "Tornado | Disaster Clean-up and Debris Removal",
    content:
      "There is evidence of significant injury and even death resulting from participation in cleanup activities.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Tornado-Disaster-Clean-up-and-Debris-Removal",
  },
  {
    title:
      "Tornado | Awareness of Related Subsequent Hazards (Carbon Monoxide)",
    content:
      "Use carbon monoxide sensors with a battery backup on every floor.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Tornado-Awareness-of-Related-Subsequent-Hazards-Carbon-Monoxide",
  },
  {
    title: "Tornado | If Your Home is Damaged",
    content: "Learn about what to do if your home is damaged after a tornado.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Tornado-If-Your-Home-is-Damaged",
  },
  {
    title: "Response - Mental Health",
    content:
      "The helpline is open to anyone experiencing emotional distress related to disasters.",
    link: "https://www.samhsa.gov/find-help/disaster-distress-helpline/disaster-types/tornadoes",
  },
];

const FloodArticle = [
  {
    title: "Reentering Your Flooded Home",
    content:
      "When returning home after a flood, know the steps you can take to prevent electric shock, carbon monoxide poisoning, and mold growth.",
    link: "https://www.cdc.gov/disasters/floods/after.html",
  },
  {
    title: "After a Flood",
    content:
      "When flood waters recede, the damage left behind can be devastating and present many dangers.",
    link: "https://www.weather.gov/safety/flood-after",
  },
  {
    title: "Floodwater After a Disaster or Emergency",
    content:
      "Floodwater and standing water can be dangerous and can make you vulnerable to infectious diseases, chemical hazards, and injuries.",
    link: "https://www.cdc.gov/disasters/floods/floodsafety.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Fdisasters%2Ffloods%2Fcleanupwater.html",
  },
  {
    title: "Staying Safe After a Flood",
    content:
      "Flooding is a temporary overflow of water onto land that is normally dry.",
    link: "https://www.ready.gov/floods#after",
  },
  {
    title: "Flood | Systems Inspections (Electrical Equipment)",
    content:
      "Waterproof your home and basement by installing a back valve, maintaining a working sump pump, and installing a battery-operated sump pump in case of power failure.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Flood-Systems-Inspections-Electrical-Equipment",
  },
  {
    title: "Flood | Damaged Buildings (Insurance)",
    content:
      "Be prepared to handle damaged buildings and insurance claims after a disaster.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Flood-Damaged-Buildings-Insurance",
  },
  {
    title:
      "Flood | Awareness of Related Subsequent Hazards (Avoid Unstable Trees and Poles and Downed Power Lines)",
    content: "Avoid unstable trees and poles and downed power lines.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Flood-Awareness-of-Related-Subsequent-Hazards-Avoid-Unstable-Trees-and-Poles-and-Downed-Power-Lines",
  },
  {
    title: "Flood | Debris Removal",
    content:
      "Clear debris from gutters and drains regularly to keep water from accumulating in them.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Flood-Debris-Removal",
  },
  {
    title:
      "Flood | Awareness of Related Subsequent Hazards (Monitor Communications for Information Regarding Water Safety)",
    content: "Monitor communications for information regarding water safety.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Flood-Awareness-of-Related-Subsequent-Hazards-Monitor-Communications-for-Information-Regarding-Water-Safety",
  },
  {
    title: "Response - Mental Health",
    content:
      "The helpline is open to anyone experiencing emotional distress related to disasters.",
    link: "https://www.samhsa.gov/find-help/disaster-distress-helpline/disaster-types/floods",
  },
];

const FireArticle = [
  {
    title: "After a Fire",
    content:
      "Contact your local disaster relief service, such as The Red Cross, if you need temporary housing, food and medicines.",
    link: "https://www.ready.gov/home-fires#after",
  },
  {
    title: "Stay Safe After a Wildfire",
    content:
      "If your home was affected by a wildfire, do not return home until authorities say it is safe.",
    link: "https://www.cdc.gov/disasters/wildfires/afterfire.html",
  },
  {
    title: "How Can You Stay Safe After a Wildfire?",
    content:
      "A wildfire is an unplanned fire that burns in a natural area such as a forest, grassland, or prairie.",
    link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/wildfire.html",
  },
  {
    title: "What To Do After A Home Fire",
    content:
      "If you need help after a home fire, please contact your local Red Cross.",
    link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/fire/home-fire-recovery.html",
  },
  {
    title: "Picking Up the Pieces After a Fire",
    content:
      "This booklet provides information on recovering from a fire in your home.",
    link: "https://www.redcross.org/content/dam/redcross/atg/PDF_s/Preparedness___Disaster_Recovery/General_Preparedness___Recovery/Home/picking-up-the-pieces-after-a-fire.pdf",
  },
  {
    title: "Checking Your Home After a Fire",
    content:
      "Check for smoke and embers throughout the home, including the attic.",
    link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/fire/checking-home-after-fire.html",
  },
];

const HurricaneArticle = [
  {
    title: "After a Hurricane",
    content:
      "Continue listening to a NOAA Weather Radio or the local news for the latest updates.",
    link: "https://www.weather.gov/safety/hurricane-after",
  },
  {
    title: "Returning Home After a Hurricane",
    content:
      "Learn how to prepare for a hurricane, stay safe during a hurricane, and what to do when returning home from a hurricane.",
    link: "https://www.ready.gov/hurricanes#after",
  },
  {
    title: "Response - Mental Health",
    content:
      "The helpline is open to anyone experiencing emotional distress related to disasters.",
    link: "https://www.samhsa.gov/find-help/disaster-distress-helpline/disaster-types/hurricanes-tropical-storms",
  },
];

const EarthquakeArticle = [
  {
    title: "Stay Safe After an Earthquake",
    content:
      "There can be serious hazards after an earthquake, such as damage to the building, leaking gas and water lines, or downed power lines.",
    link: "https://www.ready.gov/earthquakes#after",
  },
  {
    title: "What Should You Do Before an Earthquake?",
    content:
      "Learn what to do during an earthquake, and how to strengthen your home to prepare for an earthquake.",
    link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/earthquake.html",
  },
  {
    title: "Stay Safe After an Earthquake",
    content:
      "Protect yourself and your loved ones by learning what to do to stay safe after an earthquake.",
    link: "https://www.cdc.gov/disasters/earthquakes/after.html",
  },
  {
    title: "Earthquake | Awareness of Related Subsequent Hazards: Aftershock",
    content:
      "Earthquakes are the sudden, rapid shaking of the earth, caused by the breaking and shifting of underground rock.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Earthquake-Awareness-of-Related-Subsequent-Hazards-Aftershock",
  },
  {
    title: "Earthquake | Disaster Clean-up and Debris Removal",
    content:
      "Learn about disaster clean-up and debris removal after an earthquake.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Earthquake-Disaster-Clean-up-and-Debris-Removal",
  },
  {
    title: "Response - Mental Health",
    content:
      "The helpline is open to anyone experiencing emotional distress related to disasters.",
    link: "https://www.samhsa.gov/find-help/disaster-distress-helpline/disaster-types/earthquakes",
  },
];

const Response = () => {
  return (
    <div className="relative flex w-full flex-col items-start justify-start gap-2.5 text-left text-sm leading-[normal] tracking-[normal] text-dodgerblue">
      {/* General Info ARTICLE */}
      <section className="flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch text-left text-sm text-dodgerblue">
        <b className="relative self-stretch uppercase">General Info</b>
        <b
          className="relative inline-block min-w-[52px]"
          style={{ color: "#333333" }}
        >
          Articles
        </b>
        <div className="sm:gap-x-6 lg:pr-[93px] lg:box-border mq750:pr-[46px] mq750:box-border box-border flex  max-w-full flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[19px] self-stretch rounded-xl pb-10 pl-[30px] pr-[187px] pt-5 mq450:box-border mq450:pr-5">
          {GeneralInfoArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.content}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* General Info ARTICLE */}

      {/* TORNADO ARTICLE */}
      <section className="flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch text-left text-sm text-dodgerblue">
        <b className="relative self-stretch uppercase">TORNADO</b>
        <b
          className="relative inline-block min-w-[52px]"
          style={{ color: "#333333" }}
        >
          Articles
        </b>
        <div className="sm:gap-x-6 lg:pr-[93px] lg:box-border mq750:pr-[46px] mq750:box-border box-border flex  max-w-full flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[19px] self-stretch rounded-xl pb-10 pl-[30px] pr-[187px] pt-5 mq450:box-border mq450:pr-5">
          {TornadoArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.content}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* TORNADO ARTICLE */}

      {/* FLOOD ARTICLE */}
      <section className="flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch text-left text-sm text-dodgerblue">
        <b className="relative self-stretch uppercase">Flood</b>
        <b
          className="relative inline-block min-w-[52px]"
          style={{ color: "#333333" }}
        >
          Articles
        </b>
        <div className="sm:gap-x-6 lg:pr-[93px] lg:box-border mq750:pr-[46px] mq750:box-border box-border flex  max-w-full flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[19px] self-stretch rounded-xl pb-10 pl-[30px] pr-[187px] pt-5 mq450:box-border mq450:pr-5">
          {FloodArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.content}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* FLOOD ARTICLE */}

      {/* FIRE ARTICLE */}
      <section className="flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch text-left text-sm text-dodgerblue">
        <b className="relative self-stretch uppercase">Fire</b>
        <b
          className="relative inline-block min-w-[52px]"
          style={{ color: "#333333" }}
        >
          Articles
        </b>
        <div className="sm:gap-x-6 lg:pr-[93px] lg:box-border mq750:pr-[46px] mq750:box-border box-border flex  max-w-full flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[19px] self-stretch rounded-xl pb-10 pl-[30px] pr-[187px] pt-5 mq450:box-border mq450:pr-5">
          {FireArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.content}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* FIRE ARTICLE */}

      {/* HURRICANE ARTICLE */}
      <section className="flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch text-left text-sm text-dodgerblue">
        <b className="relative self-stretch uppercase">Hurricane</b>
        <b
          className="relative inline-block min-w-[52px]"
          style={{ color: "#333333" }}
        >
          Articles
        </b>
        <div className="sm:gap-x-6 lg:pr-[93px] lg:box-border mq750:pr-[46px] mq750:box-border box-border flex  max-w-full flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[19px] self-stretch rounded-xl pb-10 pl-[30px] pr-[187px] pt-5 mq450:box-border mq450:pr-5">
          {HurricaneArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.content}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* HURRICANE ARTICLE */}

      {/* EARTHQUAKE ARTICLE */}
      <section className="flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch text-left text-sm text-dodgerblue">
        <b className="relative self-stretch uppercase">Earthquake</b>
        <b
          className="relative inline-block min-w-[52px]"
          style={{ color: "#333333" }}
        >
          Articles
        </b>
        <div className="sm:gap-x-6 lg:pr-[93px] lg:box-border mq750:pr-[46px] mq750:box-border box-border flex  max-w-full flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[19px] self-stretch rounded-xl pb-10 pl-[30px] pr-[187px] pt-5 mq450:box-border mq450:pr-5">
          {EarthquakeArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.content}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* EARTHQUAKE ARTICLE */}
    </div>
  );
};

export default Response;
