import React from "react";

const GeneralInfoArticle = [
  {
    title: "What to Wear when Cleaning up Debris",
    content: "Natural Disasters and Severe Weather",
    link: "https://www.cdc.gov/disasters/cleanup/cleanup-debris-after-disaster.html",
  },
];

const TornadoArticle = [
  {
    title: "Tornado | Response Planning (Shelter)",
    content:
      "Build a safe room or storm shelter. A structure designed and built to the FEMA P-361 safe room criteria or the ICC 500 storm shelter...",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Tornado-Response-Planning-Shelter",
  },
  {
    title: "Tornado | Alerts and Warnings",
    content:
      "Tornado Watch – The NWS issues a Tornado Watch when weather conditions in an area indicate an increased risk for severe weather that may be capable of producing a tornado. ",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Tornado-Alerts-and-Warnings",
  },
  {
    title: "Tornado | Communication Plan",
    content:
      "Develop a communications plan. Your family may not be together when a fire occurs, so it is important to know how you will contact one another and how you will get back together in case of an emergency.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Tornado-Communication-Plan",
  },
  {
    title: "Tornado | Learn and Practice Skills",
    content:
      "Practice moving quickly to the protective location in the places where you spend a lot of time. Practice how you will communicate with family members.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Tornado-Learn-and-Practice-Skills",
  },
  {
    title: "Tornado | Vehicle (Do Not Use Overpass/Underpass as Shelter)",
    content:
      "Overpasses are very poor sheltering areas. By climbing up underneath the overpass, people are moving into a place where the wind speeds typically will be higher.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Tornado-Vehicle-Do-Not-Use-Overpass-Underpass-as-Shelter",
  },
  {
    title: "Tornado | Outdoors (Vehicles vs. Ditch)",
    content:
      "When a Warning is issued, and it is not possible to get to a tornado safe room or storm shelter, to an identified Best Available Refuge Area...",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Tornado-Outdoors-Vehicles-vs-Ditch",
  },
  {
    title: "Tornado | Vehicle (Do Not Try to Outrun a Tornado in a Vehicle)",
    content:
      "isk of death, severe injury, or minor injury was not greater among persons in motor vehicles than among those in houses. ",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Tornado-Vehicle-Do-Not-Try-to-Outrun-a-Tornado-in-a-Vehicle",
  },
];

const FloodArticle = [
  {
    title: "Flood | Evacuation (Do Not Take Shortcuts)",
    content: "Do not take shortcuts when evacuating during a flood.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Flood-Evacuation-Do-Not-Take-Shortcuts",
  },
  {
    title: "Flood | Evacuation (Move to Higher Ground; Avoid Low Areas)",
    content:
      "Move to higher ground and avoid low areas during a flood evacuation.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Flood-Evacuation-Move-to-Higher-Ground-Avoid-Low-Areas",
  },
  {
    title:
      "Flood | Vehicle (Never Try to Outrun a Flood in Vehicle; Seek Higher Ground)",
    content: "Never try to outrun a flood in a vehicle; seek higher ground.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Flood-Vehicle-Never-Try-to-Outrun-a-Flood-in-Vehicle-Seek-Higher-Ground",
  },
  {
    title:
      "Flood | Trapped (Move to Higher Floors in Buildings; Call 911 if Trapped)",
    content:
      "Move to higher floors in buildings and call 911 if trapped during a flood.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Flood-Trapped-Move-to-Higher-Floors-in-Buildings-Call-911-if-Trapped",
  },
  {
    title: "Flood | Evacuation",
    content: "Evacuate immediately if you are told to do so.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Flood-Evacuation",
  },
];

const FireArticle = [
  {
    title: "",
    content: "",
    link: "",
  },
];

const HurricaneArticle = [
  {
    title: "Hurricane | Shelter (Building)",
    content:
      "Flying debris from high winds is dangerous and can be deadly. If you are outside, safely move inside as quickly as possible. ",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Hurricane-Shelter-Building",
  },
  {
    title: "Hurricane | Evacuation",
    content:
      "Evacuate promptly when ordered to do so, before high winds and flooding conditions make it too dangerous to leave.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Hurricane-Evacuation",
  },
  {
    title: "Hurricane | Exiting Shelter",
    content:
      "Be careful when going outside after a hurricane. Many objects blown around by strong winds may be hidden or blocking pathways and can cause serious injuries.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Hurricane-Exiting-Shelter",
  },
  {
    title: "Hurricane | Outdoors",
    content:
      "Stay away from floodwater — it may be contaminated or contain hidden hazards and moving floodwater can sweep people away.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Hurricane-Outdoors",
  },
  {
    title: "Hurricane | Avoid Impacted Areas",
    content:
      "Stay away from floodwater — it may be contaminated or contain hidden hazards and moving floodwater can sweep people away.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Hurricane-Avoid-Impacted-Areas",
  },
  {
    title: "Stay Safe During a Hurricane",
    content:
      "Hurricanes are dangerous and can cause major damage from storm surge, wind damage, rip currents and flooding. ",
    link: "https://www.ready.gov/hurricanes#during",
  },
];

const EarthquakeArticle = [
  {
    title: "Earthquake | Outdoors: Stay Outdoors",
    content: "If you are outdoors when an earthquake hits, stay outdoors.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Earthquake-Outdoors-Stay-Outdoors",
  },
  {
    title: "Earthquake | Vehicle: Stay in Vehicle",
    content:
      "If you are in a vehicle when the earthquake hits, stay inside the vehicle.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Earthquake-Vehicle-Stay-in-Vehicle",
  },
  {
    title: "Earthquake | Vehicle: Pull Over",
    content:
      "If you are in a moving vehicle during an earthquake, stop safely and pull over; move to the shoulder or curb.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Earthquake-Vehicle-Pull-Over",
  },
  {
    title:
      "Earthquake | Personal Cover/Barriers: Inside Building: Personal Cover",
    content: "Cover your head and neck with your arms",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Earthquake-Personal-Cover-Barriers-Inside-Building-Personal-Cover",
  },
  {
    title:
      "Earthquake | Personal Cover/Barriers: Inside Building: Cover in Corner/Near Interior Wall",
    content:
      "If there is not a table or desk near you when an earthquake hits, cover your face and head with your arms and crouch in an inside corner or interior wall of the building.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Earthquake-Personal-Cover-Barriers-Inside-Building-Cover-in-Corner-Near-Interior-Wall",
  },
  {
    title: "Earthquake | Personal Cover/Barriers: Inside Building: Hold On",
    content:
      "HOLD ON to your cover and be prepared to move with it. If you do not have furniture cover, hold your position until the ground stops shaking and it is safe to move.",
    link: "https://community.fema.gov/ProtectiveActions/s/article/Earthquake-Personal-Cover-Barriers-Inside-Building-Hold-On",
  },
];

const Response = () => {
  return (
    <div className="relative flex w-full flex-col items-start justify-start gap-2.5 text-left text-sm leading-[normal] tracking-[normal] text-dodgerblue">
      {/* General Info ARTICLE */}
      <section className="flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch text-left text-sm text-dodgerblue">
        <b className="relative self-stretch uppercase">General Info</b>
        <b
          className="relative inline-block min-w-[52px]"
          style={{ color: "#333333" }}
        >
          Articles
        </b>
        <div className="sm:gap-x-6 lg:pr-[93px] lg:box-border mq750:pr-[46px] mq750:box-border box-border flex  max-w-full flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[19px] self-stretch rounded-xl pb-10 pl-[30px] pr-[187px] pt-5 mq450:box-border mq450:pr-5">
          {GeneralInfoArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.content}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* General Info ARTICLE */}

      {/* TORNADO ARTICLE */}
      <section className="flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch text-left text-sm text-dodgerblue">
        <b className="relative self-stretch uppercase">TORNADO</b>
        <b
          className="relative inline-block min-w-[52px]"
          style={{ color: "#333333" }}
        >
          Articles
        </b>
        <div className="sm:gap-x-6 lg:pr-[93px] lg:box-border mq750:pr-[46px] mq750:box-border box-border flex  max-w-full flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[19px] self-stretch rounded-xl pb-10 pl-[30px] pr-[187px] pt-5 mq450:box-border mq450:pr-5">
          {TornadoArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.content}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* TORNADO ARTICLE */}

      {/* FLOOD ARTICLE */}
      <section className="flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch text-left text-sm text-dodgerblue">
        <b className="relative self-stretch uppercase">Flood</b>
        <b
          className="relative inline-block min-w-[52px]"
          style={{ color: "#333333" }}
        >
          Articles
        </b>
        <div className="sm:gap-x-6 lg:pr-[93px] lg:box-border mq750:pr-[46px] mq750:box-border box-border flex  max-w-full flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[19px] self-stretch rounded-xl pb-10 pl-[30px] pr-[187px] pt-5 mq450:box-border mq450:pr-5">
          {FloodArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.content}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* FLOOD ARTICLE */}

      {/* FIRE ARTICLE */}
      {/* <section className="flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch text-left text-sm text-dodgerblue">
        <b className="relative self-stretch uppercase">Fire</b>
        <b
          className="relative inline-block min-w-[52px]"
          style={{ color: "#333333" }}
        >
          Articles
        </b>
        <div className="sm:gap-x-6 lg:pr-[93px] lg:box-border mq750:pr-[46px] mq750:box-border box-border flex  max-w-full flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[19px] self-stretch rounded-xl pb-10 pl-[30px] pr-[187px] pt-5 mq450:box-border mq450:pr-5">
          {FireArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.content}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section> */}
      {/* FIRE ARTICLE */}

      {/* HURRICANE ARTICLE */}
      <section className="flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch text-left text-sm text-dodgerblue">
        <b className="relative self-stretch uppercase">Hurricane</b>
        <b
          className="relative inline-block min-w-[52px]"
          style={{ color: "#333333" }}
        >
          Articles
        </b>
        <div className="sm:gap-x-6 lg:pr-[93px] lg:box-border mq750:pr-[46px] mq750:box-border box-border flex  max-w-full flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[19px] self-stretch rounded-xl pb-10 pl-[30px] pr-[187px] pt-5 mq450:box-border mq450:pr-5">
          {HurricaneArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.content}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* HURRICANE ARTICLE */}

      {/* EARTHQUAKE ARTICLE */}
      <section className="flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch text-left text-sm text-dodgerblue">
        <b className="relative self-stretch uppercase">Earthquake</b>
        <b
          className="relative inline-block min-w-[52px]"
          style={{ color: "#333333" }}
        >
          Articles
        </b>
        <div className="sm:gap-x-6 lg:pr-[93px] lg:box-border mq750:pr-[46px] mq750:box-border box-border flex  max-w-full flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[19px] self-stretch rounded-xl pb-10 pl-[30px] pr-[187px] pt-5 mq450:box-border mq450:pr-5">
          {EarthquakeArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.content}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* EARTHQUAKE ARTICLE */}
    </div>
  );
};

export default Response;
