import axios from 'axios';
import environment from './environment';
import getToken from './token';

const mailer = { 
  sendOtpEmail: async (toEmail, otp, firstName) => {
    try {
      const token = await getToken();
      const fname = firstName ? firstName : "";
      const data = JSON.stringify({
        to_email: toEmail,
        otp: otp,
        first_name: fname
      });
      
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${environment.apiUrl}/mailer/send_otp`,
        headers: {
          'X-API-Key': environment.apiKey,
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data : data
      };
      
      const response = await axios.request(config)
      
      if (response.status === 200) {
        console.log('OTP sent successfully');
        return response.data;
      } else {
        console.error('Failed to send OTP:', response.status);
        return null;
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      return null;
    }
  },

  sendAccessEmail: async (toEmail, firstName, masterOtp, accessOtp, orgName, employeeName, employeeId, employeePhone, reason) => {
    try {
      const token = await getToken();
      const fname = firstName ? firstName : "";
      const data = JSON.stringify({
        to_email: toEmail,
        first_name: fname,
        master_otp: masterOtp,
        access_otp: accessOtp,
        organization_name: orgName,
        employee_name: employeeName,
        employee_id: employeeId,
        employee_phone: employeePhone,
        reason_for_access: reason
      });
      
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${environment.apiUrl}/mailer/send_access_otp`,
        headers: {
          'X-API-Key': environment.apiKey,
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data : data
      };
      
      const response = await axios.request(config)
      
      if (response.status === 200) {
        console.log('OTP sent successfully');
        return response.data;
      } else {
        console.error('Failed to send OTP:', response.status);
        return null;
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      return null;
    }
  },
}

export default mailer;
