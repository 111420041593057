import React, { useEffect, useState, useRef, useCallback } from "react"; // Ensure useEffect is imported
import PropTypes from "prop-types";
import { FaEdit, FaSave, FaTimes } from "react-icons/fa";
import communityProfileService from "../../service/center/communityProfileService"; // Adjusted path
import { auth } from "../../firebase"; // Corrected path
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  CommandColumn,
  Inject,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { USStates } from "./../datasource";

const CommunityProfile = ({ className = "" }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [tempFormData, setTempFormData] = useState({});
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [data, setData] = useState(null);
  const [userId, setUserId] = useState(null); // State to hold user ID
  const gridStaff = useRef(null);
  const [staff, setStaff] = useState([]);
  const govConactsRef = useRef(null);
  const [govContacts, setGovContacts] = useState([]);
  const [a501c3, seta501c3] = useState(null);

  useEffect(() => {
    const fetchUserId = () => {
      const user = auth.currentUser; // Get the current user
      if (user) {
        setUserId(user.uid); // Set the user ID
      }
    };

    fetchUserId();
  }, []);

  const fetchData = useCallback(async () => {
    if (userId) {
      const userData = await communityProfileService.getCommunityProfileByRole(
        "users",
        userId
      );

      const profileData =
        await communityProfileService.getCommunityProfileByRole(
          "centers",
          userData.center_id
        );

      console.log("Profile Data:", profileData);
      setData(profileData);
      setTempFormData(profileData);
      console.log(profileData);
      if (profileData.a501c3) seta501c3(profileData.a501c3);
      if (profileData.first_name) {
        setTempFormData({
          ...profileData,
          contact_person: `${profileData.first_name} ${profileData.last_name}`,
        });
      }

      const staffData = await communityProfileService.getStaff(userId);
      setStaff(staffData.filter((s) => s.is_deleted !== true));

      const govData = await communityProfileService.getGov(userId);
      setGovContacts(govData.filter((g) => g.is_deleted !== true));
    }
  }, [userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const startEditing = (event) => {
    event.preventDefault();
    setTempFormData({ ...data });
    setIsEditMode(true);
  };

  const saveChanges = async (event) => {
    event.preventDefault();
    const currentTime = new Date();
    const updatedData = {
      ...tempFormData,
      updated_at: currentTime,
    };
    console.log("Saving changes with data: ", updatedData);

    try {
      await communityProfileService.updateCommunityProfile("users", userId);
      if (data.id) {
        await communityProfileService.updateCommunityProfile(
          "centers",
          data.id,
          updatedData
        );
      }
      if (data.id && updatedData.organization) {
        await communityProfileService.updateCommunityProfile2(
          "users",
          userId,
          "organizations",
          data.id,
          updatedData
        );
      }

      // Check if all required fields are filled before marking profile as complete
      const requiredFields = [
        "name_of_hub",
        "contact_person",
        "mobile_number",
        "address1",
        "city",
        "state",
        "zip",
        "email",
        "website",
        "soc_med",
        "other_info",
        "a501c3",
      ];
      const isProfileComplete = requiredFields.every(
        (field) => updatedData[field]
      );
      if (isProfileComplete) {
        console.log("Profile is complete");
        await communityProfileService.updateCommunityProfile("users", userId, {
          profile_completed: true,
        });
      } else {
        console.log("Profile is not complete");
        await communityProfileService.updateCommunityProfile("users", userId, {
          profile_completed: false,
        });
      }

      setData(updatedData);
      setIsEditMode(false);
      setLastEditedTime(currentTime);
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const handleInputBlur = async (event) => {
    const currentTime = new Date();
    const updatedData = {
      ...tempFormData,
      updated_at: currentTime,
    };

    try {
      await communityProfileService.updateCommunityProfile(
        "centers",
        data.id,
        updatedData
      );
      setData(updatedData);
      setLastEditedTime(currentTime);
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const cancelEditing = (event) => {
    event.preventDefault();
    setTempFormData({ ...data });
    setIsEditMode(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTempFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "a501c3") {
      seta501c3(value);
    }
  };

  const handleAddStaff = () => {
    if (gridStaff.current) {
      gridStaff.current.addRecord();
    }
  };

  const handleAddGov = () => {
    if (govConactsRef.current) {
      govConactsRef.current.addRecord();
    }
  };

  const generateUniqueID = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  const sortSettings = {
    allowSorting: true,
  };
  const filterSettings = {
    type: "CheckBox",
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };

  const yesNoOptions = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
  ];

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const isNewlyRegistered = localStorage.getItem("newlyRegistered");
    if (isNewlyRegistered === "true") {
      setShowPopup(true);
      localStorage.removeItem("newlyRegistered"); // Remove the flag after showing the popup
    }
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const dialogContent = () => {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <h2
          style={{
            fontSize: "24px",
            fontWeight: "normal",
            color: "#1e1919",
            marginBottom: "10px",
          }}
        >
          Welcome to your Center Profile!
        </h2>
        <p style={{ fontSize: "16px", color: "#637381", marginBottom: "20px" }}>
          Please finish your profile to get the most out of our services.
        </p>
        <button
          onClick={handleClosePopup}
          style={{
            backgroundColor: "#0061fe",
            color: "white",
            border: "none",
            padding: "10px 20px",
            fontSize: "14px",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Got it!
        </button>
      </div>
    );
  };

  const actionBeginStaff = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Ensure staffId is set and status is true
        const newStaffData = {
          ...args.data,
          staffId: args.data.staffId || generateUniqueID(),
          is_deleted: false, // Set status to true when adding
        };
        console.log("Adding new staff:", newStaffData);
        await communityProfileService.addStaff(userId, newStaffData);
      } else if (args.action === "edit") {
        await communityProfileService.updateStaff(
          userId,
          args.data.id,
          args.data
        );
      }
      fetchData(); // Refresh the data after adding or editing
    } else if (args.requestType === "delete") {
      // Set status to false when deleting
      const updatedData = {
        ...args.data[0],
        is_deleted: true,
      };
      await communityProfileService.updateStaff(
        userId,
        updatedData.id,
        updatedData
      );
      fetchData();
      //setDisastersLastEditedTime(new Date());
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit"
            ? "Edit Staff Info"
            : "Add Staff Info";

        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#FFFFFF";
          }
          if (header) {
            header.style.color = "#FFFFFF";
          }

          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );

            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }

            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 0);
        }
      }
    }
  };

  const actionBeginGov = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Ensure govId is set and status is true
        const newGovData = {
          ...args.data,
          govId: args.data.govId || generateUniqueID(),
          is_deleted: false, // Set status to true when adding
        };
        console.log("Adding new gov:", newGovData);
        await communityProfileService.addGov(userId, newGovData);
      } else if (args.action === "edit") {
        await communityProfileService.updateGov(
          userId,
          args.data.id,
          args.data
        );
      }
      fetchData(); // Refresh the data after adding or editing
    } else if (args.requestType === "delete") {
      // Set status to false when deleting
      const updatedData = {
        ...args.data[0],
        is_deleted: true,
      };
      await communityProfileService.updateGov(
        userId,
        updatedData.id,
        updatedData
      );
      fetchData();
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit"
            ? "Edit Government Contact Info"
            : "Add Government Contact Info";

        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#FFFFFF";
          }
          if (header) {
            header.style.color = "#FFFFFF";
          }

          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );

            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }

            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 0);
        }
      }
    }
  };

  const renderField = (label, name, required, type) => {
    return (
      <div className="flex w-full flex-col items-start justify-start gap-1">
        <div className="text-gray-600 relative text-left text-xs leading-[20px]">
          {label}
          {required && (
            <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
              *
            </p>
          )}
        </div>
        <div
          className={`box-border flex h-10 flex-row items-center justify-between self-stretch `}
        >
          {name === "state" ? (
            <DropDownListComponent
              readonly={!isEditMode}
              dataSource={USStates}
              fields={{ text: "name", value: "abbreviation" }}
              style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
              floatLabelType="Auto"
              cssClass="e-outline"
              change={(e) => {
                setTempFormData({ ...tempFormData, state: e.value });
                handleInputBlur();
              }}
              blur={handleInputBlur}
              value={tempFormData.state || ""}
            />
          ) : name === "a501c3" ? (
            <DropDownListComponent
              readonly={!isEditMode}
              dataSource={yesNoOptions}
              fields={{ text: "text", value: "value" }}
              style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
              floatLabelType="Auto"
              cssClass="e-outline"
              change={(e) => {
                setTempFormData({ ...tempFormData, a501c3: e.value });
                handleInputBlur();
              }}
              blur={handleInputBlur}
              value={tempFormData.a501c3 || ""}
            />
          ) : (
            <TextBoxComponent
              floatLabelType="Auto"
              id={name}
              type={type}
              name={name}
              cssClass="e-outline"
              value={tempFormData[name] || ""}
              onChange={handleInputChange}
              readOnly={!isEditMode}
              blur={handleInputBlur}
              style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <form
      className={`m-0 box-border flex w-full max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1 mt-3 text-sm font-bold text-blue-500">
              Blue Sky CENTER INFORMATION
            </h2>
            <p className="font-poppins text-xs font-medium text-gray-500">
              Last Edited:{" "}
              {lastEditedTime ? lastEditedTime.toLocaleString() : "Never"}
            </p>
          </div>
          <div className="mr-3 flex items-center gap-2">
            {isEditMode ? (
              <>
                <button
                  type="button"
                  className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                  onClick={saveChanges}
                >
                  <FaSave className="h-3 w-3" /> Save
                </button>
                <button
                  type="button"
                  className="flex cursor-pointer items-center gap-1 rounded bg-gray-400 px-3 py-1.5 text-xs font-semibold text-black"
                  onClick={cancelEditing}
                >
                  <FaTimes className="h-3 w-3" /> Cancel
                </button>
              </>
            ) : (
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={startEditing}
              >
                <FaEdit className="h-3 w-3" />
                Edit
              </button>
            )}
          </div>
        </header>
        <div className="mr-3 flex flex-col gap-4 self-stretch">
          {data && renderField("Name of Hub", "name_of_hub", true)}
          {data && renderField("Contact Person", "contact_person", true)}
          {data && renderField("Contact Number", "mobile_number", true)}
          {data &&
            renderField(
              "Address 1",
              "address1",
              true,
              "e.g., 123 Main St, Springfield, IL 62704"
            )}
          {data &&
            renderField(
              "Address 2",
              "address2",
              false,
              "e.g., 123 Main St, Springfield, IL 62704"
            )}
          <div className="flex gap-2">
            {data && renderField("City", "city", true)}
            {data && renderField("State", "state", true)}
            {data && renderField("Zip", "zip", true)}
          </div>

          <div className="flex flex-row gap-4 self-stretch">
            <div className="flex-1">
              {data &&
                renderField(
                  "Email",
                  "email",
                  true,
                  "e.g., janedoe@example.com"
                )}
            </div>
            <div className="flex-1">
              {data && renderField("Website", "website", true)}
            </div>
          </div>
          <div className="flex flex-row gap-4 self-stretch">
            <div className="flex-1">
              {data && renderField("Social Media", "soc_med", true)}
            </div>
            <div className="flex-1">
              {data &&
                renderField("Other Information Provided", "other_info", true)}
            </div>
          </div>
          <div className="flex flex-row gap-4 self-stretch">
            <div className="flex-1">
              {data && renderField("Are you a 501C3", "a501c3", true)}
            </div>
            <div className="flex-1">
              {data &&
                a501c3 === "Yes" &&
                renderField("EIN", "ein", true, "number")}
            </div>
          </div>
        </div>
      </section>

      <div className="box-border flex max-w-full flex-col items-start justify-start gap-[10px] self-stretch rounded-3xs border-[1px] border-solid border-gray-200 bg-white px-[19px] pb-5 pt-[18px] text-left text-xs text-darkslateblue">
        <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
          <div
            className={`mq280:flex-wrap mb-2 flex w-[1588px] max-w-full flex-row items-start justify-between gap-5 text-left text-xs leading-[normal] tracking-[normal] text-dodgerblue ${className}`}
          >
            <div
              className={`mq280:flex-wrap flex w-[1588px] max-w-full flex-row items-start justify-between gap-5 text-left text-xs leading-[normal] tracking-[normal] text-dodgerblue ${className}`}
            >
              <div className="flex w-full flex-col items-start justify-start">
                <a className="relative inline-block w-full font-bold leading-[21px] text-[inherit] [text-decoration:none]">
                  Official Staff Contacts (At least 2)
                </a>
                <div className="relative self-stretch font-medium leading-[21px] text-silver-200">
                  {/* {data.lastUpdatedDisasters &&
                    `Last Updated: ${tempDisastersFormData.lastUpdatedDisasters}`}
                  {console.log("ahah ", data.lastUpdatedDisasters)} */}
                </div>
              </div>
              <div className="flex flex-col items-start justify-start px-0 pb-0 pt-[7px]">
                <div className="flex flex-row items-start justify-start gap-2.5">
                  <button
                    type="button"
                    className="flex cursor-pointer flex-row items-start justify-start gap-2 rounded-md bg-dodgerblue px-[9px] py-[5px] [border:none]"
                    onClick={handleAddStaff}
                  >
                    <div className="flex flex-col items-start justify-start px-0 pb-0 pt-px">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.4"
                          d="M14.0672 6.39191H13.2651V5.60774C13.2651 5.27296 12.9966 5 12.6658 5C12.3357 5 12.0665 5.27296 12.0665 5.60774V6.39191H11.2658C10.935 6.39191 10.6665 6.66487 10.6665 6.99965C10.6665 7.33443 10.935 7.6074 11.2658 7.6074H12.0665V8.39226C12.0665 8.72704 12.3357 9 12.6658 9C12.9966 9 13.2651 8.72704 13.2651 8.39226V7.6074H14.0672C14.3973 7.6074 14.6665 7.33443 14.6665 6.99965C14.6665 6.66487 14.3973 6.39191 14.0672 6.39191Z"
                          fill="white"
                        />
                        <path
                          d="M6.33325 10.0107C3.63607 10.0107 1.33325 10.442 1.33325 12.1648C1.33325 13.887 3.62205 14.3337 6.33325 14.3337C9.02977 14.3337 11.3333 13.9025 11.3333 12.1797C11.3333 10.4568 9.04445 10.0107 6.33325 10.0107Z"
                          fill="white"
                        />
                        <path
                          opacity="0.4"
                          d="M6.33332 8.3698C8.1697 8.3698 9.64174 6.87879 9.64174 5.01873C9.64174 3.15868 8.1697 1.66699 6.33332 1.66699C4.49694 1.66699 3.0249 3.15868 3.0249 5.01873C3.0249 6.87879 4.49694 8.3698 6.33332 8.3698Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <a className="relative inline-block text-left text-xs font-semibold text-white [text-decoration:none]">
                      Add
                    </a>
                  </button>
                  <div className="flex flex-col items-start justify-start px-0 pb-0 pt-0.5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 15.5L12 8.5L19 15.5"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
            <GridComponent
              dataSource={staff.filter((s) => s.is_deleted !== true)}
              editSettings={{
                allowEditing: true,
                allowAdding: true,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: "Dialog",
              }}
              ref={gridStaff}
              actionBegin={actionBeginStaff}
              allowFiltering={true}
              filterSettings={filterSettings}
              allowSorting={true}
              sortSettings={sortSettings}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="staffId"
                  isPrimaryKey={true}
                  isIdentity={true}
                  visible={false}
                />
                <ColumnDirective
                  field="firstName"
                  headerText="First Name"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="lastName"
                  headerText="Last Name"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="email"
                  headerText="Email"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="phoneNumber"
                  headerText="Phone Number"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  headerText="Actions"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Sort, Filter, Edit, CommandColumn, ColumnMenu]}
              />
            </GridComponent>
          </div>
        </div>
      </div>

      <div className="box-border flex max-w-full flex-col items-start justify-start gap-[10px] self-stretch rounded-3xs border-[1px] border-solid border-gray-200 bg-white px-[19px] pb-5 pt-[18px] text-left text-xs text-darkslateblue">
        <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
          <div
            className={`mq280:flex-wrap mb-2 flex w-[1588px] max-w-full flex-row items-start justify-between gap-5 text-left text-xs leading-[normal] tracking-[normal] text-dodgerblue ${className}`}
          >
            <div
              className={`mq280:flex-wrap flex w-[1588px] max-w-full flex-row items-start justify-between gap-5 text-left text-xs leading-[normal] tracking-[normal] text-dodgerblue ${className}`}
            >
              <div className="flex w-full flex-col items-start justify-start">
                <a className="relative inline-block w-full font-bold leading-[21px] text-[inherit] [text-decoration:none]">
                  Local Government Contacts
                </a>
                <div className="relative self-stretch font-medium leading-[21px] text-silver-200">
                  {/* {data.lastUpdatedDisasters &&
                    `Last Updated: ${tempDisastersFormData.lastUpdatedDisasters}`}
                  {console.log("ahah ", data.lastUpdatedDisasters)} */}
                </div>
              </div>
              <div className="flex flex-col items-start justify-start px-0 pb-0 pt-[7px]">
                <div className="flex flex-row items-start justify-start gap-2.5">
                  <button
                    type="button"
                    className="flex cursor-pointer flex-row items-start justify-start gap-2 rounded-md bg-dodgerblue px-[9px] py-[5px] [border:none]"
                    onClick={handleAddGov}
                  >
                    <div className="flex flex-col items-start justify-start px-0 pb-0 pt-px">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.4"
                          d="M14.0672 6.39191H13.2651V5.60774C13.2651 5.27296 12.9966 5 12.6658 5C12.3357 5 12.0665 5.27296 12.0665 5.60774V6.39191H11.2658C10.935 6.39191 10.6665 6.66487 10.6665 6.99965C10.6665 7.33443 10.935 7.6074 11.2658 7.6074H12.0665V8.39226C12.0665 8.72704 12.3357 9 12.6658 9C12.9966 9 13.2651 8.72704 13.2651 8.39226V7.6074H14.0672C14.3973 7.6074 14.6665 7.33443 14.6665 6.99965C14.6665 6.66487 14.3973 6.39191 14.0672 6.39191Z"
                          fill="white"
                        />
                        <path
                          d="M6.33325 10.0107C3.63607 10.0107 1.33325 10.442 1.33325 12.1648C1.33325 13.887 3.62205 14.3337 6.33325 14.3337C9.02977 14.3337 11.3333 13.9025 11.3333 12.1797C11.3333 10.4568 9.04445 10.0107 6.33325 10.0107Z"
                          fill="white"
                        />
                        <path
                          opacity="0.4"
                          d="M6.33332 8.3698C8.1697 8.3698 9.64174 6.87879 9.64174 5.01873C9.64174 3.15868 8.1697 1.66699 6.33332 1.66699C4.49694 1.66699 3.0249 3.15868 3.0249 5.01873C3.0249 6.87879 4.49694 8.3698 6.33332 8.3698Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <a className="relative my-auto inline-block text-left text-xs font-semibold text-white [text-decoration:none]">
                      Add
                    </a>
                  </button>
                  <div className="flex flex-col items-start justify-start px-0 pb-0 pt-0.5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 15.5L12 8.5L19 15.5"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
            <GridComponent
              dataSource={govContacts.filter((g) => g.is_deleted !== true)}
              editSettings={{
                allowEditing: true,
                allowAdding: true,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: "Dialog",
              }}
              ref={govConactsRef}
              actionBegin={actionBeginGov}
              allowFiltering={true}
              filterSettings={filterSettings}
              allowSorting={true}
              sortSettings={sortSettings}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="govId"
                  isPrimaryKey={true}
                  isIdentity={true}
                  visible={false}
                />
                <ColumnDirective
                  field="firstName"
                  headerText="First Name"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="lastName"
                  headerText="Last Name"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="email"
                  headerText="Email"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="phoneNumber"
                  headerText="Phone Number"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  headerText="Actions"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Sort, Filter, Edit, CommandColumn, ColumnMenu]}
              />
            </GridComponent>
          </div>
        </div>
      </div>
      <DialogComponent
        width="400px"
        isModal={true}
        visible={showPopup}
        close={handleClosePopup}
        header={null}
        content={dialogContent}
        showCloseIcon={true}
        closeOnEscape={true}
        target={document.body}
        cssClass="welcome-dialog"
      />
    </form>
  );
};

CommunityProfile.propTypes = {
  className: PropTypes.string,
};

export default CommunityProfile;
