import React, { useEffect, useRef, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  CommandColumn,
  Inject,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import partnerProfileService from "../service/partner/PartnerProfileService";
import userService from "../service/UserService";
import { Query } from "@syncfusion/ej2-data";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  CheckBoxSelection,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import CenterService from "../service/center/CenterService";
import { useAuth } from "../components/AuthContext";

const Employees = (props) => {
  const gridRef = useRef(null);
  const [orgId, setOrgId] = useState(null);
  const [orgType, setOrgType] = useState(null);
  const [organization, setOrganization] = useState({});
  const [employeesData, setEmployeesData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const { role, setRoleBasedRoute } = useAuth();
  const sortSettings = {
    allowSorting: true,
  };
  const filterSettings = {
    type: "CheckBox",
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];
  const pageSettings = { pageSize: 10 };

  useEffect(() => {
    if (props.data && props.data.orgId && props.data.orgType) {
      setOrgId(props.data.orgId);
      setOrgType(props.data.orgType);
      setOrganization(props.data.org);
      if (props.data.orgType === "center") {
        console.log("center");
        console.log("role", role);
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (orgId && orgType) {
      switch (orgType) {
        case "partner":
          getPartnerEmployees();
          break;
        case "center":
          getCenterEmployees();
          break;
        default:
          setEmployeesData([]);
      }
    }
  }, [orgId, orgType]);

  const getPartnerEmployees = async () => {
    try {
      let emps = await partnerProfileService.getEmployees(orgId);
      emps = emps.filter((emp) => !emp.is_deleted);
      setEmployeesData(emps);
    } catch (error) {
      console.error("Error fetching employees", error);
    }
  };

  const getCenterEmployees = async () => {
    try {
      let emps = await CenterService.getEmployees(orgId);
      emps = emps.filter((emp) => !emp.is_deleted);
      setEmployeesData(emps);
    } catch (error) {
      console.error("Error fetching employees", error);
    }
  };

  const emailExistsAction = async (employeeData) => {
    switch (orgType) {
      case "partner":
        // get user, add organization subcollection to user, add to partner employees
        const user = await userService.getUserByEmail(employeeData.email);
        console.log("employeeData", user);
        console.log("orgId", orgId);
        const userOrg = await userService.getOrganizationById(
          employeeData.user_id,
          orgId
        );
        const newOrg = {
          ...organization,
          org_type: "partner",
          org_id: orgId,
          role: "partner",
          is_representative: employeeData.is_representative,
          is_admin: employeeData.is_admin,
        };
        if (userOrg) {
          let mergedOrg = { ...userOrg, ...newOrg };
          mergedOrg.is_deleted = false;
          await userService.updateOrganization(
            employeeData.id,
            orgId,
            mergedOrg
          );
        } else {
          await userService.createOrganizationWithOrgId(user.id, orgId, newOrg);
        }
        let emp = await partnerProfileService.getEmployeeByEmail(
          orgId,
          employeeData.email
        );
        if (emp) {
          let tmp = Object.assign(employeeData);
          delete tmp["id"];
          let newEmp = { ...emp, ...tmp };
          newEmp.is_deleted = false;
          await partnerProfileService.updateEmployee(emp.id, orgId, newEmp);
        } else {
          const newEmp = {
            ...employeeData,
            status: "active",
            user_id: user.id,
          };
          delete newEmp.id; // Delete the "id" field from newEmp
          console.log("newEmp", newEmp);
          await partnerProfileService.createEmployeeWithUserId(
            user.id,
            orgId,
            newEmp
          );
          // TODO send email
        }
        break;
      case "center":
        // TODO: create a link account if user registers.
        break;
      default:
      // do nothing
    }
  };

  const emailNotExistingAction = async (employeeData) => {
    switch (orgType) {
      case "partner":
        // Changed to save to employees subcollection for now then TODO: create a link account if user registers.
        let emp = await partnerProfileService.getEmployeeByEmail(
          orgId,
          employeeData.email
        );
        if (emp) {
          let tmp = Object.assign(employeeData);
          delete tmp["id"];
          let newEmp = { ...emp, ...tmp };
          newEmp.is_deleted = false;
          await partnerProfileService.updateEmployee(emp.id, orgId, newEmp);
        } else {
          const newEmp = {
            ...employeeData,
            status: "invited",
            id: Math.random().toString(36).substr(2, 9),
          };
          console.log("newEmp", newEmp);
          console.log("orgId", orgId);
          await partnerProfileService.createEmployee(orgId, newEmp);
          // TODO send email
        }
        break;
      case "center":
        break;
      default:
      // do nothing
    }
  };

  const editAction = async (employeeData) => {
    switch (orgType) {
      case "partner":
        // edit employee
        console.log("employeeData", employeeData);
        const user = await userService.getUserByEmail(employeeData.email);
        console.log("employeeData", user);
        console.log("orgId", orgId);
        const userOrg = await userService.getOrganizationById(user.id, orgId);
        const newOrg = {
          ...organization,
          org_type: "partner",
          org_id: orgId,
          role: "partner",
          is_representative: employeeData.is_representative,
          is_admin: employeeData.is_admin,
          default_role: employeeData.default_role,
          old_employee: true,
        };
        if (userOrg) {
          let mergedOrg = { ...userOrg, ...newOrg };
          mergedOrg.is_deleted = false;
          await userService.updateOrganization(user.id, orgId, mergedOrg);
        } else {
          await userService.createOrganizationWithOrgId(user.id, orgId, newOrg);
        }
        let emp = await partnerProfileService.getEmployeeByEmail(
          orgId,
          employeeData.email
        );
        if (emp) {
          let updatedEmp = {
            ...emp,
            default_role: employeeData.default_role,
            email: employeeData.email,
            first_name: employeeData.first_name,
            is_admin: employeeData.is_admin,
            is_representative: employeeData.is_representative,
            last_name: employeeData.last_name,
            roles: employeeData.roles, // Updated to "roles"
            user_id: user.id,
            is_deleted: false,
            old_employee: true,
          };
          await partnerProfileService.updateEmployee(emp.id, orgId, updatedEmp);
        } else {
          const newEmp = {
            default_role: employeeData.default_role,
            email: employeeData.email,
            first_name: employeeData.first_name,
            is_admin: employeeData.is_admin,
            is_representative: employeeData.is_representative,
            last_name: employeeData.last_name,
            roles: employeeData.roles, // Updated to "roles"
            user_id: user.id,
            is_deleted: false,
            old_employee: true,
          };
          console.log("newEmp", newEmp);
          await partnerProfileService.createEmployeeWithUserId(
            user.id,
            orgId,
            newEmp
          );
          // TODO send email
        }
        break;
      case "center":
        console.log("employeeData", employeeData);
        const user2 = await userService.getUserByEmail(employeeData.email);
        console.log("employeeData", 2);
        console.log("orgId", orgId);
        const userOrg2 = await userService.getOrganizationById(user2.id, orgId);
        const newOrg2 = {
          ...organization,
          org_type: "center",
          org_id: orgId,
          role: "center",
          is_representative: employeeData.is_representative,
          is_admin: employeeData.is_admin,
          default_role: employeeData.default_role,
          old_employee: true,
        };
        if (userOrg2) {
          let mergedOrg = { ...userOrg2, ...newOrg2 };
          mergedOrg.is_deleted = false;
          await userService.updateOrganization(user2.id, orgId, mergedOrg);
        } else {
          await userService.createOrganizationWithOrgId(
            user2.id,
            orgId,
            newOrg2
          );
        }
        let emp2 = await partnerProfileService.getEmployeeByEmail(
          orgId,
          employeeData.email
        );
        if (emp2) {
          let updatedEmp2 = {
            ...emp2,
            can_approve_disaster: employeeData.can_approve_disaster,
            can_declare_disaster: employeeData.can_declare_disaster,
            default_role: employeeData.default_role,
            email: employeeData.email,
            first_name: employeeData.first_name,
            is_admin: employeeData.is_admin,
            is_representative: employeeData.is_representative,
            last_name: employeeData.last_name,
            roles: employeeData.roles, // Updated to "roles"
            user_id: user2.id,
            is_deleted: false,
            old_employee: true,
          };
          await partnerProfileService.updateEmployeeCenter(
            emp2.id,
            orgId,
            updatedEmp2
          );
        } else {
          const newEmp2 = {
            can_approve_disaster: employeeData.can_approve_disaster,
            can_declare_disaster: employeeData.can_declare_disaster,
            default_role: employeeData.default_role,
            email: employeeData.email,
            first_name: employeeData.first_name,
            is_admin: employeeData.is_admin,
            is_representative: employeeData.is_representative,
            last_name: employeeData.last_name,
            roles: employeeData.roles, // Updated to "roles"
            user_id: user2.id,
            is_deleted: false,
            old_employee: true,
          };
          console.log("newEmp2", newEmp2);
          await partnerProfileService.createEmployeeWithUserIdCenter(
            user2.id,
            orgId,
            newEmp2
          );
          // TODO send email
        }
        break;
      default:
      // do nothing
    }
  };

  const deleteAction = async (employeeData) => {
    switch (orgType) {
      case "partner":
        // delete employee
        await partnerProfileService.updateEmployee(employeeData.id, orgId, {
          is_deleted: true,
        });
        const userOrg = await userService.getOrganizationById(
          employeeData.id,
          orgId
        );
        if (userOrg) {
          await userService.updateOrganization(employeeData.id, orgId, {
            is_deleted: true,
          });
        }
        break;
      case "center":
        await CenterService.updateEmployee(employeeData.id, orgId, {
          is_deleted: true,
        });
        const userOrg2 = await userService.getOrganizationById(
          employeeData.id,
          orgId
        );
        if (userOrg2) {
          await userService.updateOrganization(employeeData.id, orgId, {
            is_deleted: true,
          });
        }
        break;
      default:
      // do nothing
    }
  };

  const actionBegin = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        const exists = await userService.checkIfEmailExists(args.data.email);
        if (exists) {
          await emailExistsAction(args.data);
        } else {
          await emailNotExistingAction(args.data);
        }
        await getPartnerEmployees();
      } else if (args.action === "edit") {
        // Ensure roles are being saved correctly
        const selectedRoles = args.data.roles || []; // Get the selected roles from args.data
        const roleNames = selectedRoles
          .map((roleId) => {
            const role = roleDatasource.find((r) => r.id === roleId);
            return role ? role.idName : null; // Get the role name or null if not found
          })
          .filter(Boolean); // Filter out any null values

        // Update the employee data with role names
        args.data.roles = roleNames; // Update the roles in args.data
        console.log("Selected Roles:", args.data); // Log selected roles before saving

        await editAction(args.data); // Call the edit action with updated data
      }
    } else if (args.requestType === "delete") {
      await deleteAction(args.data[0]);
    }
    if (args.requestType === "beginEdit") {
      setTimeout(() => {
        if (args.dialog) {
          if (args.rowData.old_employee) {
            args.dialog.header = "Edit Employee"; // Set the desired header
          } else {
            args.dialog.header = "Add Employee"; // Set the desired header
          }
        }
      }, 0); // Delay execution to allow dialog to initialize
      setIsEditMode(true); // Set edit mode to true
      // Make columns visible again
      if (role === "center") {
        gridRef.current.columns.forEach((column) => {
          if (
            column.field === "can_declare_disaster" ||
            column.field === "can_approve_disaster" ||
            column.field === "is_representative" ||
            column.field === "is_admin"
          ) {
            column.visible = true;
          }
        });
      }
    } else if (args.requestType === "save" || args.requestType === "cancel") {
      setIsEditMode(false); // Reset edit mode on save or cancel
    }
  };

  const handleAddEmployee = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const roleDatasource = [
    { idName: "Regular Employee", id: "Regular Employee" },
    { idName: "Navigator", id: "Navigator" },
    { idName: "Navigator Supervisor", id: "Navigator Supervisor" },
  ];

  const typeParams = {
    params: {
      allowFiltering: true,
      dataSource: roleDatasource,
      fields: { text: "idName", value: "idName" },
      query: new Query(),
    },
  };

  const handleSearch = async () => {
    const firstName = document.getElementById("firstName").value.trim();
    const lastName = document.getElementById("lastName").value.trim();
    const email = document.getElementById("email").value.trim();

    // Check if all inputs are empty
    if (!firstName && !lastName && !email) {
      await getPartnerEmployees();
      if (gridRef.current) {
        gridRef.current.refresh();
      }
      return;
    }

    const whereClauses = [];
    if (firstName) {
      whereClauses.push({
        field: "first_name",
        operator: "==",
        value: firstName.charAt(0).toUpperCase() + firstName.slice(1),
      });
    }
    if (lastName) {
      whereClauses.push({
        field: "last_name",
        operator: "==",
        value: lastName.charAt(0).toUpperCase() + lastName.slice(1),
      });
    }
    if (email) {
      whereClauses.push({ field: "email", operator: "==", value: email });
    }

    // Include data where "user_role" is "citizen"
    whereClauses.push({ field: "user_role", operator: "==", value: "citizen" });

    const db = getFirestore();
    const queryRef = query(
      collection(db, "users"),
      ...whereClauses.map((whereClause) =>
        where(whereClause.field, whereClause.operator, whereClause.value)
      )
    );

    const querySnapshot = await getDocs(queryRef);
    console.log(
      "querySnapshot",
      querySnapshot.docs.map((doc) => doc.data())
    );
    const users = []; // Initialize users array

    for (const doc of querySnapshot.docs) {
      const userData = doc.data();
      const userId = doc.id; // Get the user ID from the document
      console.log("userId", userId);

      if (orgType === "partner") {
        const partnerRef = collection(db, "partners", orgId, "employees");
        const partnerQuerySnapshot = await getDocs(partnerRef);
        const partnerUsers = partnerQuerySnapshot.docs.map((doc) => doc.id);

        // Log the IDs for debugging
        console.log("Current partner users:", partnerUsers);

        // Check if the citizen's ID already exists in the employees collection
        if (partnerUsers.includes(userId)) {
          console.log("User already exists in partner employees:", userData);
          const employeeRef = collection(db, "partners", orgId, "employees");
          const q = query(employeeRef, where("user_id", "==", userId));
          const querySnapshot = await getDocs(q);
          if (querySnapshot.docs.length > 0) {
            const employeeDoc = querySnapshot.docs[0];
            const employeeData = employeeDoc.data();
            console.log("Employee data:", employeeData);
            users.push({ ...employeeData, old_employee: true });
          } else {
            console.log("No such document!");
          }
        } else {
          users.push({ ...userData, old_employee: false });
          console.log("User does not exist in partner employees:", userId);
        }
      } else {
        const centerRef = collection(db, "centers", orgId, "employees");
        const centerQuerySnapshot = await getDocs(centerRef);
        const centerUsers = centerQuerySnapshot.docs.map((doc) => doc.id);
        if (centerUsers.includes(userId)) {
          console.log("User already exists in center employees:", userData);
          const employeeRef = collection(db, "centers", orgId, "employees");
          const q = query(employeeRef, where("user_id", "==", userId));
          const querySnapshot = await getDocs(q);
          if (querySnapshot.docs.length > 0) {
            const employeeDoc = querySnapshot.docs[0];
            const employeeData = employeeDoc.data();
            users.push({ ...employeeData, old_employee: true });
          }
        } else {
          users.push({ ...userData, old_employee: false });
        }
      }
    }

    // If the search returns 0 items, make the grid empty
    if (users.length === 0) {
      setEmployeesData([]);
      if (gridRef.current) {
        setTimeout(() => {
          gridRef.current.refresh();
        }, 100); // Delay of 100ms before refresh
      }
    } else {
      setEmployeesData(users);
      if (gridRef.current) {
        setTimeout(() => {
          gridRef.current.refresh();
        }, 100); // Delay of 100ms before refresh
      }
    }
  };

  const roleEditTemplate = (props) => {
    // Ensure that props.roles is an array of role IDs
    const currentRole = Array.isArray(props.roles) ? props.roles : []; // Ensure it's an array

    return (
      <div>
        <label htmlFor="roles" className="mb-2 block text-xs text-gray-500">
          Roles
        </label>
        <MultiSelectComponent
          id="roles"
          dataSource={roleDatasource}
          fields={{ text: "idName", value: "id" }} // Ensure value is set to "id"
          value={currentRole} // Set the value to the current roles
          mode="CheckBox"
          selectAllText="Select All"
          unSelectAllText="Unselect All"
          showSelectAll={true}
        >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
      </div>
    );
  };

  return (
    <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white px-2.5 py-[15px]">
      <div className="mq280:flex-wrap mb-2 flex w-full max-w-full flex-row items-start justify-between gap-5 text-left text-xs text-dodgerblue">
        <div className=" flex w-full max-w-full flex-row items-start justify-between gap-5 text-left text-xs">
          <div className="my-auto flex flex-col items-start justify-start">
            <a className="p-0 py-2 font-medium text-gray-800">
              {orgType === "partner" ? "Partner" : "Center"}{" "}
              <span className="text-gray-400"> / </span> Employees
            </a>
          </div>
        </div>
      </div>
      <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white p-4">
        <div className="mb-2 flex w-[60%] gap-2">
          <TextBoxComponent
            id="firstName"
            placeholder="First Name"
            cssClass="e-outline"
            floatLabelType="Auto"
            className="mr-2 rounded border px-2 py-1"
            onChange={handleSearch}
          />
          <TextBoxComponent
            id="lastName"
            placeholder="Last Name"
            cssClass="e-outline"
            floatLabelType="Auto"
            className="mr-2 rounded border px-2 py-1"
            onChange={handleSearch}
          />
          <TextBoxComponent
            id="email"
            placeholder="Email"
            cssClass="e-outline"
            floatLabelType="Auto"
            className="mr-2 rounded border px-2 py-1"
            onChange={handleSearch}
          />
          <button
            type="button"
            className="rounded-full bg-dodgerblue px-4 text-white hover:cursor-pointer"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
        <GridComponent
          dataSource={employeesData}
          editSettings={{
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            showDeleteConfirmDialog: true,
            mode: "Dialog",
          }}
          ref={gridRef}
          actionBegin={actionBegin}
          allowFiltering={true}
          filterSettings={filterSettings}
          allowSorting={true}
          sortSettings={sortSettings}
          allowPaging={true}
          pageSettings={pageSettings}
          showColumnMenu={true}
          columnMenuItems={columnMenuItems}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="id"
              headerText="ID"
              width={0}
              visible={false}
            />
            <ColumnDirective
              field="email"
              headerText="Email"
              width={250}
              validationRules={{ required: true }}
              onValueChange={async (args) => {
                // Ensure args.value is being logged
                console.log("Email value on change:", args.value); // Added logging
                // const exists = await userService.checkIfEmailExists(args.value);
                // if (exists) {
                //   console.log("exists", exists);
                //   const user = await userService.getUserByEmail(args.value);
                //   args.data.first_name = user.first_name;
                //   args.data.last_name = user.last_name;
                // }
              }}
            />
            <ColumnDirective
              field="first_name"
              headerText="First Name"
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="last_name"
              headerText="Last Name"
              validationRules={{ required: true }}
            />

            <ColumnDirective
              field="roles"
              headerText="Roles"
              editTemplate={roleEditTemplate}
              template={(props) => {
                // Check if props.role is an array before calling join
                return Array.isArray(props.roles) && props.roles.length > 0
                  ? props.roles.join(", ")
                  : "None";
              }}
            />
            <ColumnDirective
              field="default_role"
              headerText="Default Role"
              editType="dropdownedit"
              edit={typeParams}
            />

            <ColumnDirective
              field="can_declare_disaster"
              headerText="Can Declare Disaster"
              template={(props) => (props.can_declare_disaster ? "Yes" : "No")}
              editType="booleanedit"
              visible={role === "center"}
            />
            <ColumnDirective
              field="can_approve_disaster"
              headerText="Can Approve Disaster"
              template={(props) => (props.can_approve_disaster ? "Yes" : "No")}
              editType="booleanedit"
              visible={role === "center"}
            />

            <ColumnDirective
              field="is_representative"
              headerText="Representative"
              template={(props) => (props.is_representative ? "Yes" : "No")}
              editType="booleanedit"
            />
            <ColumnDirective
              field="is_admin"
              headerText="Admin"
              template={(props) => (props.is_admin ? "Yes" : "No")}
              editType="booleanedit"
            />

            <ColumnDirective
              headerText="Actions"
              commands={[
                {
                  type: "Edit",
                  buttonOption: {
                    content: `<i class="fas fa-edit"></i>`,
                    cssClass: "e-outline custom-button",
                  },
                },
                {
                  type: "Delete",
                  buttonOption: {
                    content: '<i class="fas fa-trash-alt"></i>',
                    cssClass: "e-outline custom-button",
                  },
                },
              ]}
            />
          </ColumnsDirective>
          <Inject
            services={[Page, Sort, Filter, Edit, CommandColumn, ColumnMenu]}
          />
        </GridComponent>
      </div>
    </div>
  );
};

export default Employees;
