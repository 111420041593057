import React from "react";

const Terms = () => {
  return (
    <div className="h-[100vh] overflow-auto">
      <div className="mx-auto flex w-[70%] py-10 text-base overflow-auto">
        <div className="global-container">
          <div className="global-content">
            <div className="container">
              <div className="breadcrumb-container multi-crumbs"></div>

              <h1 className="text-3xl my-10 flex justify-center font-bold">
                Blue Sky Terms & Conditions
              </h1>

              <div className="pane-wrapper">
                <div className="left-pane">
                  <section
                    dir="LTR"
                    className="kb-article kb-article-variant gradient"
                    data-countries=",BD,TV,NP,TW,HK,FJ,NR,PW,FM,NU,BN,SB,WF,BT,SG,ID,MH,WS,KH,KI,MM,MN,MO,MP,MV,MY,GU,AS,TH,CX,LA,NC,PF,TK,PG,TL,NF,PH,TO,PK,PN,VU,LK,"
                  >
                    <div className="page-block">
                      <div className="c-wrapper">
                        <div>
                          <p>
                            Blue Sky provides a personalized service
                            that allows our members to access sercices
                            content (“Blue Sky content”) over the Internet on
                            certain Internet-connected TVs, computers and other
                            devices ("Blue Sky ready devices").
                          </p>
                          <p>
                            These Terms of Use govern your use of our service.
                            As used in these Terms of Use, "Blue Sky service",
                            "our service" or "the service" means the
                            personalized service provided by Blue Sky for
                            discovering and accessing Blue Sky content, including
                            all features and functionalities, recommendations
                            and reviews, our websites, and user interfaces, as
                            well as all content and software associated with our
                            service. References to ‘you’ in these Terms of Use
                            indicate the member who created the Blue Sky account.
                          </p>
                          <ol>
                            <li>
                              <p className="mt-5">
                                <strong className="text-lg">
                                  Blue Sky Service
                                </strong>
                                <br />
                                <br />
                                4.1. You must be at least 18 years of age, or
                                the age of majority in your province, territory
                                or country, to become a member of the Blue Sky
                                service. Minors may only use the service under
                                the supervision of an adult.
                                <br />
                                <br />
                                4.2. The Blue Sky service and any content
                                accessed through the service are for your
                                personal and non-commercial use only and may not
                                be shared with individuals beyond your household
                                unless. During your Blue Sky service we grant
                                you a limited, non-exclusive, non-transferable
                                right to access the Blue Sky service and Blue Sky
                                content. Except for the foregoing, no right,
                                title or interest shall be transferred to you.
                                You agree not to use the service for public
                                performances.
                                <br />
                                <br />
                                4.3. You may access the Blue Sky content
                                primarily within the country in which you have
                                established your account and only in geographic
                                locations where we offer our service and have
                                licensed such content. The content that may be
                                available will vary by geographic location and
                                will change from time to time. The number of
                                devices on which you may simultaneously watch
                                depends on your chosen user type and is
                                specified on the "Account" page.
                                <br />
                                <br />
                                4.4. The Blue Sky service, including the content
                                library, is regularly updated and we may give
                                you the opportunity to watch live or special
                                events (including replays of such content) or to
                                enjoy new additional features. Such content and
                                new features may contain commercial breaks and
                                other types of commercial messages. Blue Sky does
                                not endorse or sponsor any advertised products
                                or services and any interactions with
                                advertisers, including through engagement with
                                interactive advertisements, are at your own
                                option and risk. In addition, we continually
                                test various aspects of our service, including
                                our websites, user interfaces and promotional
                                features. You can turn off test participation at
                                any time by visiting the "Account" page and
                                changing the "Test participation" settings.
                                <br />
                                <br />
                                4.5. Some Blue Sky content is available for
                                temporary download and offline viewing on
                                certain supported devices (“Offline Titles”).
                                Limitations apply, including restrictions on the
                                number of Offline Titles per account, the
                                maximum number of devices that can contain
                                Offline Titles, the time period within which you
                                will need to begin viewing Offline Titles and
                                how long the Offline Titles will remain
                                accessible. Some Offline Titles may not be
                                playable in certain countries and if you go
                                online in a country where you would not be able
                                to stream that Offline Title, the Offline Title
                                will not be playable while you are in that
                                country.
                                <br />
                                <br />
                                4.6. You agree to use the Blue Sky service,
                                including all features and functionalities
                                associated therewith, in accordance with all
                                applicable laws, rules and regulations, or other
                                restrictions on use of the service or content
                                therein. Except as explicitly authorized by us,
                                you agree not to:
                              </p>
                              <p>
                                (i) archive, reproduce, distribute, modify,
                                display, perform, publish, license, create
                                derivative works from, offer for sale, or use
                                content and information contained on or obtained
                                from or through the Blue Sky service;&nbsp;
                              </p>
                              <p>
                                (ii) circumvent, remove, alter, deactivate,
                                degrade, block, obscure or thwart any of the
                                content protections or other elements of the
                                Blue Sky service, including the graphical user
                                interface, copyright notices, and
                                trademarks;&nbsp;
                              </p>
                              <p>
                                (iii) use any robot, spider, scraper or other
                                automated means to access the Blue Sky service;
                                decompile, reverse engineer or disassemble any
                                software or other products or processes
                                accessible through the Blue Sky service;&nbsp;
                              </p>
                              <p>
                                (iv) insert any code or product or manipulate
                                the content of the Blue Sky service in any way;
                              </p>
                              <p>
                                (v) use any data mining, data gathering or
                                extraction method;
                              </p>
                              <p>
                                (vi) upload, post, e-mail or otherwise send or
                                transmit any material designed to interrupt,
                                destroy or limit the functionality of any
                                computer software or hardware or
                                telecommunications equipment associated with the
                                Blue Sky service, including any software viruses
                                or any other computer code, files or
                                programs.&nbsp;
                              </p>
                              <p>
                                We may terminate or restrict your use of our
                                service if you violate these Terms of Use or are
                                engaged in illegal or fraudulent use of the
                                service.
                              </p>
                              <p>
                                <br />
                                4.7. The quality of the display of the Blue Sky
                                content may vary from device to device, and may
                                be affected by a variety of factors, such as
                                your location, the bandwidth available through
                                and/or speed of your Internet connection. HD,
                                Ultra HD and HDR availability is subject to your
                                Internet service and device capabilities. Not
                                all content is available in all formats. 
                                You are responsible for all Internet
                                access charges. Please check with your Internet
                                provider for information on possible Internet
                                data usage charges. The time it takes to begin
                                watching Blue Sky content will vary based on a
                                number of factors, including your location,
                                available bandwidth at the time, the content you
                                have selected and the configuration of your
                                Blue Sky ready device.
                                <br />
                                <br />
                                4.8. Blue Sky software is developed by, or for,
                                Blue Sky and may solely be used for authorized
                                streaming and to access Blue Sky content through
                                Blue Sky ready devices. This software may vary by
                                device and medium, and functionalities and
                                features may also differ between devices. You
                                acknowledge that the use of the service may
                                require third party software that is subject to
                                third party licenses. You agree that you may
                                automatically receive updated versions of the
                                Blue Sky software and related third-party
                                software. <br />
                              </p>
                              <h2></h2>
                            </li>
                            <li>
                              <p className="mt-5">
                                <strong className="text-lg">
                                  Passwords and Account Access.
                                </strong>
                                You are responsible for any activity that occurs
                                through the Blue Sky account. By allowing others
                                to access the account (which includes access to
                                information on viewing activity for the
                                account), you agree that such individuals are
                                acting on your behalf and that you are bound by
                                any changes that they may make to the account,
                                including but not limited to changes. To help maintain control over
                                the account and to prevent any unauthorized
                                users from accessing the account, you should
                                maintain control over the devices that are used
                                to access the service and not reveal the
                                password or details of the Payment Method
                                associated with the account to anyone. You agree
                                to provide and maintain accurate information
                                relating to your account, including a valid
                                email address so we can send you account related
                                notices. We can terminate your account or place
                                your account on hold in order to protect you,
                                Blue Sky or our partners from identity theft or
                                other fraudulent activity.
                              </p>
                              <h2></h2>
                            </li>
                            <li>
                              <p className="mt-5">
                                <strong className="text-lg">
                                  Warranties and Limitations on Liability.
                                </strong>
                                The Blue Sky service is provided "as is" and
                                without warranty or condition. In particular,
                                our service may not be uninterrupted or
                                error-free. You waive all special, indirect and
                                consequential damages against us. These terms
                                will not limit any non-waivable warranties or
                                consumer protection rights that you may be
                                entitled to under the mandatory laws of your
                                country of residence.
                                <br />
                              </p>
                              <h2></h2>
                            </li>
                            <li>
                              <p className="mt-5">
                                <strong className="text-lg">
                                  Class Action Waiver.
                                </strong>
                                WHERE PERMITTED UNDER THE APPLICABLE LAW, YOU
                                AND BLUE SKY AGREE THAT EACH MAY BRING CLAIMS
                                AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
                                CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER
                                IN ANY PURPORTED CLASS OR REPRESENTATIVE
                                PROCEEDING. Further, where permitted under the
                                applicable law, unless both you and Blue Sky
                                agree otherwise, the court may not consolidate
                                more than one person's claims with your claims,
                                and may not otherwise preside over any form of a
                                representative or className proceeding.
                                <br />
                              </p>
                              <h2></h2>
                            </li>
                            <li>
                              <p className="mt-5">
                                <strong className="text-lg">
                                  Miscellaneous
                                </strong>
                                <br />
                                <br />
                                8.1.{" "}
                                <span className="underline">
                                  Governing Law.
                                </span>
                                These Terms of Use shall be governed by and
                                construed in accordance with the laws of the
                                Republic of Singapore. These terms will not
                                limit any consumer protection rights that you
                                may be entitled to under the mandatory laws of
                                your country of residence.
                                <br />
                                <br />
                                8.2.{" "}
                                <span className="underline">
                                  Unsolicited Materials.
                                </span>
                                Blue Sky does not accept unsolicited materials or
                                ideas for Blue Sky content and is not responsible
                                for the similarity of any of its content or
                                programming in any media to materials or ideas
                                transmitted to Blue Sky. <br />
                                <br />
                                8.3.
                                <span className="underline">
                                  Customer Support.
                                </span>
                                To find more information about our service and
                                its features or if you need assistance with your
                                account, please visit the Blue Sky Help Center,
                                which is accessible through the Blue Sky.com
                                website. In certain instances, Customer Service
                                may best be able to assist you by using a remote
                                access support tool through which we have full
                                access to your computer. If you do not want us
                                to have this access, you should not consent to
                                support through the remote access tool, and we
                                will assist you through other means. In the
                                event of any conflict between these Terms of Use
                                and information provided by Customer Support or
                                other portions of our websites, these Terms of
                                Use will control.
                                <br />
                                <br />
                                8.4.{" "}
                                <span className="underline">Survival.</span>
                                If any provision or provisions of these Terms of
                                Use shall be held to be invalid, illegal, or
                                unenforceable, the validity, legality and
                                enforceability of the remaining provisions shall
                                remain in full force and effect. <br />
                                <br />
                                8.5.
                                <span className="underline">
                                  Changes to Terms of Use and Assignment.
                                </span>
                                Blue Sky may, from time to time, change these
                                Terms of Use. In case of material changes we
                                will notify you at least one month before such
                                changes apply to you. If you do not wish to
                                accept the changes, you can cancel your
                                account before they take effect. We may
                                assign or transfer our agreement with you
                                including our associated rights and obligations
                                at any time and you agree to cooperate with us
                                in connection with such an assignment or
                                transfer.
                                <br />
                                <br />
                                8.6.
                                <span className="underline">
                                  Electronic Communications.
                                </span>
                                We will send you information relating to your
                                account (e.g. authorizations, invoices,
                                changes in password or Payment Method,
                                confirmation messages, notices) in electronic
                                form only, for example via emails to your email
                                address provided during registration.
                              </p>
                            </li>
                          </ol>
                          <p></p>
                          <p></p>
                        </div>
                      </div>
                    </div>

                    {/* <p className="mt-5">
                      <strong>Last Updated:</strong> February 9, 2024
                    </p> */}
                  </section>
                </div>

                <div className="right-pane">
                  <div className="wrapper"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-push"></div>
        </div>

        <div className="global-page-footer"></div>
      </div>
    </div>
  );
};

export default Terms;
