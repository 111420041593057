import React, { useState, useRef, useEffect } from "react";

import PropTypes from "prop-types";
import { useDisasterContext } from "../../../pages/Disasters/DisasterContext";
import CenterImmediateResponseSurvey from "./CenterImmediateResponseSurvey";
import CenterDamageAssessment from "./CenterDamageAssessment";
import CenterCaseAssistanceLog from "./CenterCaseAssistanceLog";
import CenterRecoveryPlanDisasters from "./CenterRecoveryPlan";
import CenterContactsDisaster from "./CenterContacts";
import CenterJournals from "./CenterJournals";
import { Link } from "react-router-dom";

import { db } from "../../../firebase";
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { getAuth } from 'firebase/auth';

const CenterDisasters = ({ className = "" }) => {
  const [activeTab, setActiveTab] = useState("Immediate Response Survey");
  const { activeDisasterTab, setActiveDisasterTab } = useDisasterContext();
  const [selectedDisaster, setSelectedDisaster] = useState("All");

  const [selectedDisasters, setSelectedDisasters] = useState(["All"]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [disasterOptions, setDisasterOptions] = useState([{ id: "All", name: "All Disasters" }]);
  const [activeDisasters, setActiveDisasters] = useState([]);
  const dropdownRef = useRef(null);

  const [showBlur, setShowBlur] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

 // Add these states
const [accessedCaseNumber, setAccessedCaseNumber] = useState(null);
const [accessedDisasterId, setAccessedDisasterId] = useState(null);

// Modify the fetchDisasters useEffect
useEffect(() => {
  const fetchDisasters = async () => {
    try {
      setIsLoading(true);
      
      // First get the accessed case number and disaster ID
      const auth = getAuth();
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      const userData = userDoc.data();
      const accessedUserId = userData.accessed_userId;
      const caseNum = userData.accessed_case_number;
      
      setAccessedCaseNumber(caseNum);

      // Get the survey response for this case number
      const surveyQuery = query(
        collection(db, `users/${accessedUserId}/survey_responses`),
        where("case_number", "==", caseNum)
      );
      
      const surveySnapshot = await getDocs(surveyQuery);
      let associatedDisasters = [];
      
      if (!surveySnapshot.empty) {
        const surveyData = surveySnapshot.docs[0].data();
        if (surveyData.disasters) {
          associatedDisasters = surveyData.disasters.map(d => ({
            id: d.disaster_id,
            name: d.disaster_name,
            status: 'active'
          }));
        }
      }

      // Set the disasters options to only show associated disasters
      setDisasterOptions([...associatedDisasters]);
      setActiveDisasters(associatedDisasters.map(d => d.id));
      setSelectedDisasters(associatedDisasters.map(d => d.id));
      
      setShowBlur(associatedDisasters.length === 0);
      setIsLoading(false);

    } catch (error) {
      console.error("Error fetching disasters:", error);
      setShowBlur(true);
      setIsLoading(false);
    }
  };

  fetchDisasters();
}, []);

// Remove or modify the All option from disaster selection
const handleDisasterChange = (disaster) => {
  setSelectedDisasters((prev) => {
    const newSelection = prev.includes(disaster)
      ? prev.filter((d) => d !== disaster)
      : [...prev, disaster];
    return newSelection;
  });
};

  const handleGoBack = () => {
    window.history.back();
  };



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const auth = getAuth();
  const [caseNumber, setCaseNumber] = useState("");

  useEffect(() => {
    const fetchCaseNumber = async () => {
      try {
        const auth = getAuth();
        if (!auth.currentUser?.uid) return;
  
        // Get the accessed user ID and case number from current user's document
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        const userData = userDoc.data();
        const accessedUserId = userData.accessed_userId;
        const accessedCaseNum = userData.accessed_case_number;
  
        if (!accessedUserId || !accessedCaseNum) {
          console.log("No accessed user or case number found");
          return;
        }
  
        // Get survey responses for the accessed user
        const surveyQuery = query(
          collection(db, `users/${accessedUserId}/survey_responses`),
          where("case_number", "==", accessedCaseNum)
        );
  
        const querySnapshot = await getDocs(surveyQuery);
        
        if (!querySnapshot.empty) {
          const surveyData = querySnapshot.docs[0].data();
          
          // Set the case number
          setCaseNumber(accessedCaseNum);
  
          // If there are disasters in the survey, update the selected disasters
          if (surveyData.disasters) {
            const surveyDisasterIds = surveyData.disasters.map(d => d.disaster_id);
            setSelectedDisasters(surveyDisasterIds);
            
            // Also update disaster options if needed
            const disasterDetails = surveyData.disasters.map(d => ({
              id: d.disaster_id,
              name: d.disaster_name,
              status: 'active'
            }));
            setDisasterOptions(disasterDetails);
          }
  
          console.log("Found case number:", accessedCaseNum);
          console.log("Associated disasters:", surveyData.disasters);
        }
  
      } catch (error) {
        console.error("Error fetching case number:", error);
        setCaseNumber("");
      }
    };
  
    fetchCaseNumber();
  }, []);  // Remove dependencies since this should only run once on mount

  const tabs = [
    "Immediate Response Survey",
    "Damage Assessment",
    "Assistance Log",
    "Recovery Plan",
    "Contacts",
    "Journals",
  ];
  const handleTabClick = (tab) => {
    setActiveDisasterTab(tab);
  };

  if (isLoading) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="text-2xl font-bold text-blue-500">Loading...</div>
      </div>
    );
  }

  if (showBlur) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="flex flex-col items-center justify-center rounded-lg p-8 ">
          <div className="flex w-full flex-col items-center justify-center gap-2 space-x-4">
            <p className="text-[40px] font-bold text-[#348BFF]">
              This section will be activated when a disaster is declared
            </p>
            <button
              onClick={handleGoBack}
              className="mx-auto flex w-24 cursor-pointer items-center justify-center rounded-lg border border-blue-500 bg-white px-4 py-2 text-center text-blue-500"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
    className={`box-border flex w-full max-w-[1640px] flex-col items-start justify-start px-2.5 py-[15px] ${className}`}
  >
    <div className="mb-2 flex flex-row items-center justify-between w-full">
      <div className="flex flex-row items-start justify-start gap-1.5 text-xs text-darkslateblue">
        <a className="relative font-medium leading-[16px] text-[inherit] [text-decoration:none]">
          Recovery Plan
        </a>
        <div className="relative font-mulish text-base font-semibold leading-[16px] text-gray-400">
          /
        </div>
        <a className="relative font-medium leading-[16px] text-[inherit] [text-decoration:none]">
          Disasters
        </a>
        <div className="relative font-mulish text-base font-semibold leading-[16px] text-gray-400">
          /
        </div>
        <span className="relative font-medium leading-[16px]">
          {activeDisasterTab}
        </span>
      </div>
      
      {/* Legend */}
      <div className="flex items-center space-x-4 text-xs">
        <div className="flex items-center">
          <span className="w-6 h-6 rounded-full bg-green-500 mr-1 flex items-center justify-center text-white font-bold">C</span>
          <span>Center</span>
        </div>
        <div className="flex items-center">
          <span className="w-6 h-6 rounded-full bg-yellow-500 mr-1 flex items-center justify-center text-white font-bold">P</span>
          <span>Partner</span>
        </div>
        <div className="flex items-center">
          <span className="w-6 h-6 rounded-full bg-blue-500 mr-1 flex items-center justify-center text-white font-bold">S</span>
          <span>Survivor</span>
        </div>
      </div>
    </div>

    <div className="flex flex-row items-start justify-start gap-1.5 text-xs pb-4 text-darkslateblue">
          <Link
                to="/casemanagement"
                className="text-[#348BFF] hover:cursor-pointer flex items-center text-sm font-500"
              >
                <i className="fas fa-arrow-left mr-2"></i>
                Back to Case Management
              </Link>
        </div>
      {/* Disaster selector and tabs */}
      <div className="flex max-w-full flex-row items-center justify-between gap-5 self-stretch mq1600:flex-wrap">
        {/* Disaster selector */}
        
        <div className="relative flex items-center gap-2" ref={dropdownRef}>
          <span className="text-sm font-bold text-red-500">DISASTERS</span>
          <div className="relative">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="flex items-center justify-between rounded border border-dodgerblue px-4 py-3 text-sm w-[450px] relative"
            >
              <span className="absolute inset-0 flex items-center justify-center">
                <span className="truncate px-6">
                  {selectedDisasters.includes("All") 
                    ? "All Disasters" 
                    : selectedDisasters.length > 0
                      ? disasterOptions
                          .filter(disaster => selectedDisasters.includes(disaster.id))
                          .map(disaster => disaster.name)
                          .join(", ")
                      : "Select Disasters"}
                </span>
              </span>
            </button>
          </div>
        </div>

        {/* Tabs */}
        <div className="flex flex-wrap gap-2 text-xs">
          {tabs.map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={`rounded-full px-4 py-1 ${
                activeDisasterTab === tab
                  ? "bg-dodgerblue text-white"
                  : "border border-dodgerblue text-dodgerblue hover:bg-blue-100"
              }`}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      <div className="relative mt-4 inline-block max-w-full font-poppins font-medium leading-[21px] text-darkslategray-200">
        {/* <p className="m-0 text-sm font-medium">
          Below are the essential tools at your disposal to effectively track
          your disaster recovery journey.
        </p>
        <p className="m-0 text-sm font-medium">
          Utilize the Damage Assessment tool to record losses, document
          assistance received in the Assistance Log, and track your progress
          towards recovery by monitoring your remaining deficit in the Recovery
          Plan.
        </p> */}
      </div>
      {caseNumber && (
      <div className="w-full bg-blue-100 p-4 rounded-md">
        <p className="text-lg font-semibold text-blue-800">
          Your case number for {selectedDisasters.length > 1 ? 'these disasters' : 'this disaster'} is: 
          <span className="text-xl font-bold"> {caseNumber}</span>
        </p>
        <p className="text-sm text-blue-600 mt-1">
          {selectedDisasters.length > 1 
            ? 'This case number covers all selected disasters' 
            : ''}
        </p>
      </div>
    )}
      {activeDisasterTab === "Immediate Response Survey" && (
        <CenterImmediateResponseSurvey 
        selectedDisasters={selectedDisasters} 
        activeDisasters={activeDisasters}/>
      )}
      {activeDisasterTab === "Damage Assessment" && (
        <CenterDamageAssessment 
          selectedDisasters={selectedDisasters} 
          activeDisasters={activeDisasters}
        />
      )}
      {activeDisasterTab === "Assistance Log" && (
        <CenterCaseAssistanceLog selectedDisasters={selectedDisasters} 
        activeDisasters={activeDisasters} />
      )}
      {activeDisasterTab === "Recovery Plan" && (
        <CenterRecoveryPlanDisasters 
          selectedDisasters={selectedDisasters} 
          activeDisasters={activeDisasters}
        />
      )}
      {activeDisasterTab === "Contacts" && <CenterContactsDisaster />}
      {activeDisasterTab === "Journals" && <CenterJournals />}

      
    </div>
  );
};

CenterImmediateResponseSurvey.propTypes = {
  className: PropTypes.string,
};

export default CenterDisasters;
