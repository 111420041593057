import { db } from "../../firebase";
import { getDoc, doc } from "firebase/firestore";

const DrcCenterService = {
  // Get citizen profile by UID
  getCitizenProfileByCenterId: async (centerId) => {
    try {
      const docRef = doc(db, "centers", centerId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return {
          id: docSnap.id,
          ...docSnap.data(),
        }; // Return the document data
      } else {
        console.error("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error getting citizen profile: ", error);
      throw error;
    }
  },
};

export default DrcCenterService;
