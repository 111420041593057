import { React, useState, useEffect } from "react";
import PropTypes from "prop-types";
import placeholderImage from "../icons/Opportunities/opportunities.png";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { collection, query, getDocs } from "firebase/firestore";

const VolunteerCard = ({ title, organization, location, date, time }) => (
  <div className="box-border flex w-[330px] flex-col items-end justify-start gap-[18.5px] rounded-mini border-[1px] border-solid border-gainsboro-200 bg-white px-0 pb-[26px] pt-0">
    <div className="relative h-[190px] self-stretch">
      <img
        className="absolute left-0 top-0 z-[1] h-full w-full rounded-b-none rounded-t-mini object-cover"
        alt=""
        src={placeholderImage}
      />
    </div>
    <div className="flex flex-row items-start justify-end self-stretch py-0 pl-[26px] pr-[22px]">
      <div className="flex flex-1 flex-row items-start justify-between gap-5">
        <div className="flex flex-col items-start justify-start gap-2">
          <b className="relative z-[1] inline-block min-w-[58px]">{title}</b>
          <div className="relative z-[1] inline-block min-w-[57px] font-medium">
            {organization}
          </div>
          <div className="relative z-[1] inline-block min-w-[57px] font-medium">
            {location}
          </div>
        </div>
        <div className="flex flex-col items-start justify-start px-0 pb-0 pt-px text-right text-xs">
          <div className="flex flex-col items-end justify-start gap-2">
            <div className="relative z-[1] inline-block min-w-[71px] font-medium">
              {date}
            </div>
            <div className="relative z-[1] inline-block min-w-[107px] whitespace-nowrap font-medium">
              {time}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-row items-start justify-end self-stretch py-0 pl-[26px] pr-[21px] text-center text-white">
      <div className="z-[1] flex flex-1 flex-row items-start justify-start rounded-8xs border-[1px] border-solid border-dodgerblue bg-dodgerblue">
        <b className="relative z-[1] flex h-10 flex-1 items-center justify-center">
          Apply
        </b>
      </div>
    </div>
  </div>
);

VolunteerCard.propTypes = {
  title: PropTypes.string.isRequired,
  organization: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

const VolunteerOpportunities = ({ className = "" }) => {
  const [showBlur, setShowBlur] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const opportunities = [
    {
      jobTitle: "Job Title",
      location: "Location",
      date: "Oct. 16, 2023",
      time: "8:00 am - 12:00 pm",
    },
    {
      jobTitle: "Job Title",
      location: "Location",
      date: "Oct. 16, 2023",
      time: "8:00 am - 12:00 pm",
    },
    {
      jobTitle: "Job Title",
      location: "Location",
      date: "Oct. 16, 2023",
      time: "8:00 am - 12:00 pm",
    },
    {
      jobTitle: "Job Title",
      location: "Location",
      date: "Oct. 16, 2023",
      time: "8:00 am - 12:00 pm",
    },
  ];

  useEffect(() => {
    const fetchOpportunities = async () => {
      try {
        setIsLoading(true);
        const opportunitiesQuery = query(collection(db, "declared-disaster"));
        const querySnapshot = await getDocs(opportunitiesQuery);
        const fetchedOpportunities = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().disaster_name,
          status: doc.data().disaster_status,
        }));
        console.log("fetchedOpportunities", fetchedOpportunities);

        // Add a delay before updating showBlur
        setTimeout(() => {
          setShowBlur(fetchedOpportunities.length === 0);
          setIsLoading(false);
        }, 1000); // 1 second delay
      } catch (error) {
        console.error("Error fetching opportunities:", error);
        setTimeout(() => {
          setShowBlur(true);
          setIsLoading(false);
        }, 1000); // 1 second delay
      }
    };

    fetchOpportunities();
  }, []);

  const handleGoBack = () => {
    window.history.back();
    window.history.back();
  };

  if (isLoading) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="text-2xl font-bold text-blue-500">Loading...</div>
      </div>
    );
  }

  if (showBlur) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="flex flex-col items-center justify-center rounded-lg p-8 ">
          <div className="flex w-full flex-col items-center justify-center gap-2 space-x-4">
            <p className="text-[40px] font-bold text-[#348BFF]">
              Opportunities will be available after a disaster
            </p>
            <button
              onClick={handleGoBack}
              className="mx-auto flex w-24 cursor-pointer items-center justify-center rounded-lg border border-blue-500 bg-white px-4 py-2 text-center text-blue-500"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div id="dialog-container"></div>
        <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
            <a className="relative inline-block min-w-[80px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
              Opportunities
            </a>
            <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
              /
            </div>
            <a className="relative inline-block min-w-[77px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
              Volunteer Opportunities
            </a>
        </div>

      <div className="flex w-full max-w-[1623px] flex-wrap gap-6 text-sm text-darkslategray-200 mt-4">
        {opportunities.map((opportunity, index) => (
          <VolunteerCard key={index} {...opportunity} />
        ))}
      </div>
    </div>
  );
};

VolunteerOpportunities.propTypes = {
  className: PropTypes.string,
};

export default VolunteerOpportunities;
