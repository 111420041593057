import { db } from "../../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  setDoc,
  serverTimestamp,
  query,
  where,
} from "firebase/firestore";

const generateUniqueID = () => {
  return "_" + Math.random().toString(36).substr(2, 9);
};

const partnerProfileService = {
  updateProfileComplete: async (collectionname, docid) => {
    console.log("Completing profile...");
    try {
      const docRef = doc(db, collectionname, docid);
      await updateDoc(docRef, { profile_completed: true });
      console.log("Profile completed!");
    } catch (error) {
      throw error;
    }
  },
  getPartnerProfile: async (
    collectionName,
    docId,
    subcollection,
    subcollectionid
  ) => {
    try {
      const docRef = doc(
        db,
        collectionName,
        docId,
        subcollection,
        subcollectionid
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        console.error("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error reading document: ", error);
      throw error;
    }
  },

  updatePartnerProfile: async (
    collectionName,
    docId,
    subcollection,
    subcollectionid,
    data
  ) => {
    try {
      const docRef = doc(
        db,
        collectionName,
        docId,
        subcollection,
        subcollectionid
      );
      const updatedData = { ...data, updated_at: new Date() };
      await updateDoc(docRef, updatedData);
      return { id: docId, ...updatedData };
    } catch (error) {
      console.error("Error updating document: ", error);
      throw error;
    }
  },

  // Get all  members for a citizen
  getMembers: async (partnerId) => {
    try {
      const membersRef = collection(db, "partners", partnerId, "members");
      const snapshot = await getDocs(membersRef);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Error getting members: ", error);
      throw error;
    }
  },

  // Add a new member
  addMember: async (userId, memberData) => {
    try {
      const docRef = await addDoc(
        collection(db, "partners", userId, "members"),
        memberData
      );
      return { ...memberData, id: docRef.id };
    } catch (error) {
      console.error("Error adding member: ", error);
      throw error;
    }
  },

  // Update a member
  updateMember: async (partnerId, memberId, data) => {
    if (!partnerId || !memberId || !data) {
      throw new Error("Invalid arguments passed to updateMember");
    }

    try {
      const memberRef = doc(db, "partners", partnerId, "members", memberId);
      await setDoc(memberRef, data, { merge: true });
      return { id: memberId, ...data };
    } catch (error) {
      console.error("Error updating member: ", error);
      throw error;
    }
  },

  // Delete a member
  deleteMember: async (userId, memberId) => {
    try {
      console.log("Deleting member:", userId, memberId);
      const memberRef = doc(db, "partners", userId, "members", memberId);
      console.log("Document reference:", memberRef.path);
      await deleteDoc(memberRef);
    } catch (error) {
      console.error("Error deleting member:", error);
      throw error;
    }
  },

  // Get all  disasters for a citizen
  getDisasters: async (partnerId) => {
    try {
      const disastersRef = collection(db, "partners", partnerId, "disasters");
      const snapshot = await getDocs(disastersRef);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Error getting members: ", error);
      throw error;
    }
  },

  // Add a new member
  addDisasters: async (userId, disasterData) => {
    try {
      const docRef = await addDoc(
        collection(db, "partners", userId, "disasters"),
        disasterData
      );
      return { ...disasterData, id: docRef.id };
    } catch (error) {
      console.error("Error adding disaster: ", error);
      throw error;
    }
  },

  // Update a disaster
  updateDisasters: async (partnerId, disasterId, data) => {
    try {
      // Reference to the specific disaster document
      const disasterRef = doc(
        db,
        "partners",
        partnerId,
        "disasters",
        disasterId
      );

      // Reference to the partner document where the lastUpdated timestamp will be set
      const partnerRef = doc(db, "partners", partnerId);

      // Update the disaster document with the provided data
      await setDoc(disasterRef, data, { merge: true });

      // Update the partner document with the lastUpdated timestamp
      await setDoc(
        partnerRef,
        { lastUpdatedDisasters: serverTimestamp() },
        { merge: true }
      );

      return { id: disasterId, ...data };
    } catch (error) {
      console.error("Error updating disaster: ", error);
      throw error;
    }
  },

  deleteDisaster: async (userId, disasterId) => {
    try {
      console.log("Deleting member:", userId, disasterId);
      const disasterRef = doc(db, "partners", userId, "disasters", disasterId);
      console.log("Document reference:", disasterRef.path);
      await deleteDoc(disasterRef);
    } catch (error) {
      console.error("Error deleting member:", error);
      throw error;
    }
  },

  // get data from partner table
  getPartnerById: async (id) => {
    try {
      const partnerDocRef = doc(db, "partners", id);
      const partnerDoc = await getDoc(partnerDocRef);
      if (partnerDoc.exists()) {
        return { id: partnerDoc.id, ...partnerDoc.data() };
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error getting user:", error);
    }
  },

  getPartnerByUserId: async (userId) => {
    try {
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);
      const user = { ...userSnap.data() };
      const docRef = doc(db, "partners", user.partner_id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        console.error("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error reading document: ", error);
      throw error;
    }
  },

  getEmployees: async (partnerId) => {
    try {
      const employeesRef = collection(db, `partners/${partnerId}/employees`);
      const querySnapshot = await getDocs(employeesRef);
      const employees = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return employees;
    } catch (error) {
      return [];
    }
  },

  getEmployeeByEmail: async (partnerId, email) => {
    try {
      const employeesRef = collection(db, `partners/${partnerId}/employees`);
      const q = query(employeesRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const employeeDoc = querySnapshot.docs[0];
        return { id: employeeDoc.id, ...employeeDoc.data() };
      } else {
        // No employee found with this email
        return null;
      }
    } catch (error) {
      console.error("Error getting employee by email:", error);
      return null;
    }
  },

  getEmployeeByEmailCenter: async (centerId, email) => {
    try {
      const employeesRef = collection(db, `centers/${centerId}/employees`);
      const q = query(employeesRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const employeeDoc = querySnapshot.docs[0];
        return { id: employeeDoc.id, ...employeeDoc.data() };
      } else {
        // No employee found with this email
        return null;
      }
    } catch (error) {
      console.error("Error getting employee by email:", error);
      return null;
    }
  },

  createEmployee: async (partnerId, employeeData) => {
    try {
      const employeeRef = collection(db, `partners/${partnerId}/employees`);
      const employeeDocRef = await addDoc(employeeRef, employeeData);
      await updateDoc(employeeDocRef, { id: employeeDocRef.id });
      return employeeDocRef.id;
    } catch (error) {
      console.error("Error creating employee:", error);
    }
  },

  createEmployeeWithUserId: async (userId, partnerId, employeeData) => {
    try {
      const employeeRef = doc(db, `partners/${partnerId}/employees`, userId);
      await setDoc(employeeRef, employeeData);
      return userId;
    } catch (error) {
      console.error("Error creating employee:", error);
    }
  },

  createEmployeeWithUserIdCenter: async (userId, centerId, employeeData) => {
    try {
      const employeeRef = doc(db, `centers/${centerId}/employees`, userId);
      await setDoc(employeeRef, employeeData);
      return userId;
    } catch (error) {
      console.error("Error creating employee:", error);
    }
  },

  updateEmployee: async (userId, partnerId, updatedData) => {
    try {
      const employeeRef = doc(db, `partners/${partnerId}/employees`, userId);
      await updateDoc(employeeRef, updatedData);
    } catch (error) {
      console.error("Error updating employee:", error);
    }
  },

  updateEmployeeCenter: async (userId, centerId, updatedData) => {
    try {
      const employeeRef = doc(db, `centers/${centerId}/employees`, userId);
      await updateDoc(employeeRef, updatedData);
    } catch (error) {
      console.error("Error updating employee:", error);
    }
  },

  deleteEmployee: async (userId, partnerId) => {
    try {
      const employeeRef = doc(db, `partners/${partnerId}/employees`, userId);
      await deleteDoc(employeeRef);
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
  },
};

export default partnerProfileService;
