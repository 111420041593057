import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { auth, db } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  where,
  addDoc,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../components/AuthContext";
import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
import note from "../../src/assets/note.png";
import check from "../../src/assets/check.png";
import umbrella from "../../src/assets/umbrella.png";
import lightning from "../../src/assets/lightning.png";
import fire from "../../src/assets/fire.png";
import bxcheck from "../../src/assets/bx-check.svg";
import bxclose from "../../src/assets/bx-close.svg";
import google from "../icons/login/google-icon.svg";
import microsoft from "../icons/login/microsoft-icon.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { USStates } from "./datasource";
import countiesData from "../counties_list.json";
import mailer from "../service/api/mailer";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { countries } from "./datasource";

const CenterRegister = ({ className = "" }) => {
  const [selectedValueNational, setSelectedValueNational] = useState(null);
  const [a501c3, seta501c3] = useState(null);
  const yesNoOptions = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
  ];

  const handleYesNoChange = (e) => {
    setSelectedValueNational(e.itemData.value);
  };

  const handleYesNoChange01c3 = (e) => {
    seta501c3(e.itemData.value);
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    name_of_hub: "",
    email: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
    county: "",
    mobile_number: "",
    password: "",
  });
  const [confirmPW, setConfirmPW] = useState("");
  const [agree, setAgree] = useState(false);
  const { login } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [counties, setCounties] = useState([]);
  const [missingFields, setMissingFields] = useState([]);

  const handleStateChange = (e) => {
    const stateAbbreviation = e.target.value;
    const stateObject = USStates.find(
      (state) => state.abbreviation === stateAbbreviation
    ); // Find the state object
    const stateName = stateObject ? stateObject.name : "";

    setSelectedState(stateAbbreviation);
    setFormData({ ...formData, state: stateAbbreviation }); // Store full state name

    // Filter counties based on selected state
    const filteredCounties = countiesData.filter(
      (county) => county.State === stateName
    );
    setCounties(filteredCounties);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();

  const generateOtp = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const fieldLabels = {
    name_of_hub: "Name of Organization",
    address1: "Address 1",
    address2: "Address 2",
    country: "Country",
    state: "State",
    city: "City",
    county: "County",
    zip: "ZIP code",
    email: "Email",
    first_name: "First name",
    last_name: "Last name",
    mobile_number: "Contact number",
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    const requiredFields = [
      "name_of_hub",
      "address1",
      "country",
      "city",
      "state",
      "zip",
      "county",
      "email",
      "first_name",
      "last_name",
      "mobile_number",
    ];

    const missing = requiredFields.filter((field) => !formData[field]);
    setMissingFields(missing);

    if (missing.length > 0) {
      setTimeout(() => {
        setMissingFields([]);
      }, 3000); // Clear missing fields after 5 seconds
      return;
    }

    try {
      const emailExists = await checkIfEmailExists(formData.email);

      if (emailExists) {
        alert("Email already exists. Please use a different email or log in.");
        return;
      }

      const { email, password, ...otherData } = formData;
      const status = "submitted";
      const user_role = "center";

      await addDoc(collection(db, "center-for-approval"), {
        email,
        status,
        user_role,
        ...otherData,
      });
      alert("Account details submitted. Please wait for approval.");
      window.location.reload();
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        alert("This email is already registered.");
      } else if (err.code === "auth/invalid-email") {
        alert("Invalid email format.");
      } else if (err.code === "auth/weak-password") {
        alert("Password is too weak.");
      } else {
        alert(err.message);
      }
    }
  };

  const handleGoogleSignup = async (e) => {
    e.preventDefault();
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    try {
      const result = await signInWithPopup(auth, provider);

      const emailExists = await checkIfEmailExists(result.user.email);
      if (!emailExists) {
        const userData = {
          first_name: result.user.displayName.split(" ")[0],
          last_name: result.user.displayName.split(" ").slice(-1).join(" "),
          email: result.user.email,
          mobile_number: result.user.phoneNumber || "",
          status: "submitted",
          role: "center",
        };

        await setDoc(doc(db, "users", result.user.uid), userData);

        alert("User registered successfully!");
        login(result.user.accessToken);
        setTimeout(() => {
          navigate("/home");
        }, 1000);
      } else {
        alert("Email already exists. Logging you in...");
        login(result.user.accessToken);
        setTimeout(() => {
          navigate("/home");
        }, 1000);
      }
    } catch (err) {
      alert(err.message);
      console.error("Error during Google Sign-Up:", err);
    }
  };

  const checkIfEmailExists = async (email) => {
    // Query the "users" collection
    const usersQuery = query(
      collection(db, "users"),
      where("email", "==", email)
    );
    const usersSnapshot = await getDocs(usersQuery);

    const centerQuery = query(
      collection(db, "center-for-approval"),
      where("email", "==", email)
    );
    const centerSnapshot = await getDocs(centerQuery);

    return !usersSnapshot.empty || !centerSnapshot.empty;
  };

  const handleCountryChange = (e) => {
    setFormData({
      ...formData,
      country: e.target.value, // Update country in formData
    });
  };

  const handleCountyChange = (e) => {
    setFormData({
      ...formData,
      county: e.target.value, // Update county in formData
    });
  };

  // New function to get center_id by zip code
  const getCenterIdByZip = async (zipCode) => {
    const usersRef = collection(db, "users");
    const q = query(
      usersRef,
      where("zip", "==", zipCode),
      where("user_role", "==", "center")
    );
    const snapshot = await getDocs(q);

    if (!snapshot.empty) {
      return snapshot.docs[0].id;
    }

    return "";
  };

  return (
    <div className="flex h-screen w-full justify-center overflow-y-auto bg-white px-6 py-2">
      <div className="my-auto flex h-auto min-h-[90vh] w-full max-w-[850px] overflow-hidden rounded-[10px] bg-white shadow-lg">
        <div
          className="flex-grow flex-col items-center justify-center p-[20px] text-center md:p-[45px]"
          style={{
            border: "1px solid var(--Text-Locked, #D5D7DB)",
            background: "var(--Pure-White, #FFF)",
            boxShadow: "0px 4px 9.2px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <img
            src={blueSkyLogo}
            className="mx-auto mt-14 h-[100.942px] w-[110.703px]"
          ></img>
          <p className="mt-[5px] text-[20px] font-bold text-[#348BFF]">
            DARCI, powered by Blue Sky
          </p>
          <p className="my-[5px] text-[20px] font-bold text-[#3D435A]">
            Create a Center Account
          </p>
          {/* <p className="pb-[10px] text-xs text-[#3D435A]">Step 1 of 2</p> */}
          <div className="flex w-full flex-col gap-[15px] pt-3">
            <div>
              <TextBoxComponent
                placeholder="Name of Organization"
                floatLabelType="Auto"
                cssClass="e-outline"
                name="name_of_hub"
                value={formData.name_of_hub}
                change={(e) =>
                  handleChange({
                    target: { name: "name_of_hub", value: e.value },
                  })
                }
              />
              {missingFields.includes("name_of_hub") && (
                <span className="text-xs text-red-500">Required</span>
              )}
            </div>
            <div>
              <DropDownListComponent
                placeholder="Select a country"
                floatLabelType="Auto"
                dataSource={countries}
                fields={{ text: "name", value: "abbreviation" }}
                value={formData.country}
                name="country"
                change={(e) =>
                  handleChange({
                    target: { name: "country", value: e.value },
                  })
                }
                cssClass="e-outline"
              />
              {missingFields.includes("country") && (
                <span className="text-xs text-red-500">Required</span>
              )}
            </div>
            <div className="w-full">
              <TextBoxComponent
                placeholder="Address 1"
                floatLabelType="Auto"
                cssClass="e-outline"
                name="address1"
                value={formData.address1}
                change={(e) =>
                  handleChange({ target: { name: "address1", value: e.value } })
                }
              />
              {missingFields.includes("address1") && (
                <span className="text-xs text-red-500">Required</span>
              )}
            </div>
            <div className="w-full">
              <TextBoxComponent
                placeholder="Address 2"
                floatLabelType="Auto"
                cssClass="e-outline"
                name="address2"
                value={formData.address2}
                change={(e) =>
                  handleChange({ target: { name: "address2", value: e.value } })
                }
              />
            </div>
            <div className="flex gap-2">
              <div className="w-[25%]">
                <TextBoxComponent
                  placeholder="City"
                  floatLabelType="Auto"
                  name="city"
                  cssClass="e-outline"
                  value={formData.city}
                  change={(e) =>
                    handleChange({ target: { name: "city", value: e.value } })
                  }
                />
                {missingFields.includes("city") && (
                  <span className="text-xs text-red-500">Required</span>
                )}
              </div>
              <div className="flex-1">
                <DropDownListComponent
                  dataSource={USStates}
                  fields={{ text: "abbreviation", value: "abbreviation" }}
                  value={selectedState}
                  change={(e) =>
                    handleStateChange({ target: { value: e.value } })
                  }
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  placeholder="State"
                />
                {missingFields.includes("state") && (
                  <span className="text-xs text-red-500">Required</span>
                )}
              </div>
              <div className="w-[21%]">
                <TextBoxComponent
                  placeholder="Zip"
                  floatLabelType="Auto"
                  name="zip"
                  cssClass="e-outline"
                  value={formData.zip}
                  change={(e) =>
                    handleChange({ target: { name: "zip", value: e.value } })
                  }
                />
                {missingFields.includes("zip") && (
                  <span className="text-xs text-red-500">Required</span>
                )}
              </div>
              <div className="flex-1">
                <DropDownListComponent
                  dataSource={counties}
                  fields={{ text: "County", value: "County" }}
                  value={formData.county}
                  change={(e) =>
                    handleCountyChange({ target: { value: e.value } })
                  }
                  enabled={!!selectedState}
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  placeholder="County"
                />
                {missingFields.includes("county") && (
                  <span className="text-xs text-red-500">Required</span>
                )}
              </div>
            </div>

            <div className="flex gap-2">
              <div className="w-full">
                <TextBoxComponent
                  placeholder="First Name"
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  name="first_name"
                  value={formData.first_name}
                  change={(e) =>
                    handleChange({
                      target: { name: "first_name", value: e.value },
                    })
                  }
                />
                {missingFields.includes("first_name") && (
                  <span className="text-xs text-red-500">Required</span>
                )}
              </div>
              <div className="w-full">
                <TextBoxComponent
                  placeholder="Last Name"
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  name="last_name"
                  value={formData.last_name}
                  change={(e) =>
                    handleChange({
                      target: { name: "last_name", value: e.value },
                    })
                  }
                />
                {missingFields.includes("last_name") && (
                  <span className="text-xs text-red-500">Required</span>
                )}
              </div>
            </div>
            <div>
              <TextBoxComponent
                placeholder="Email Address"
                floatLabelType="Auto"
                cssClass="e-outline"
                name="email"
                value={formData.email}
                change={(e) =>
                  handleChange({ target: { name: "email", value: e.value } })
                }
              />
              {missingFields.includes("email") && (
                <span className="text-xs text-red-500">Required</span>
              )}
            </div>

            <div>
              <TextBoxComponent
                placeholder="Contact Number"
                floatLabelType="Auto"
                name="mobile_number"
                cssClass="e-outline"
                value={formData.mobile_number}
                change={(e) =>
                  handleChange({
                    target: { name: "mobile_number", value: e.value },
                  })
                }
              />
              {missingFields.includes("mobile_number") && (
                <span className="text-xs text-red-500">Required</span>
              )}
            </div>

            {/* <div className="mt-[6px] flex items-center gap-[10px]">
              <CheckBoxComponent
                checked={agree}
                change={(e) => setAgree(e.checked)}
              />
              <label className="text-sm">
                I agree with the{" "}
                <a
                  href="/privacy-policy"
                  className="text-[#348BFF] hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a
                  href="/terms-and-conditions"
                  className="text-[#348BFF] hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </a>
              </label>
            </div> */}
            <div className="mt-[10px] flex flex-col ">
              <button
                className="text-medium h-10 w-full rounded-[4px] bg-[#348BFF] p-[10px] text-base font-bold text-white hover:cursor-pointer"
                onClick={handleSignup}
              >
                Submit
              </button>
              <p className="mt-2 text-sm">
                Already have an account?
                <Link
                  to="/login"
                  className="text-[#348BFF] hover:cursor-pointer"
                >
                  {" "}
                  Login
                </Link>
              </p>
            </div>
            {/* <div className="flex justify-center">
              <button
                className="mr-2 h-10 rounded-[4px] border border-[#D5D7DB] bg-[#FFFFFF] p-[10px] text-base font-bold text-white hover:cursor-pointer"
                onClick={handleGoogleSignup}
              >
                <img alt="" src={google} />
              </button>
              <button className="h-10 rounded-[4px] border border-[#D5D7DDB] bg-[#FFFFFF] p-[10px] text-base font-bold text-white hover:cursor-pointer">
                <img alt="" src={microsoft} />
              </button>
            </div> */}
          </div>
        </div>
        <div
          className="relative w-[288px] rounded-r-[10px] bg-[#348BFF]"
          style={{
            border: "1px solid var(--Text-Locked, #D5D7DB)",
            boxShadow: "0px 4px 9.2px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <img src={note} className="absolute right-0 top-[80px]" />
          <img src={check} className="absolute left-[-10px] top-[230px]" />
          <img src={umbrella} className="absolute right-0 top-[380px]" />
          <img src={lightning} className="absolute left-[-5px] top-[470px]" />
          <img src={fire} className="absolute right-0 top-[600px]" />
        </div>
      </div>
    </div>
  );
};

CenterRegister.propTypes = {
  className: PropTypes.string,
};

export default CenterRegister;
