import React, { useState, useRef, useEffect } from "react";

import PropTypes from "prop-types";
import { useDisasterContext } from "../pages/Disasters/DisasterContext";
import DisasterManagement from "./Disasters/DisasterManagement";
import AssistanceLog from "./Disasters/AssistanceLog";
import RecoveryPlanDisasters from "./Disasters/RecoveryPlan";
import Journals from "./Disasters/Journals";
import ContactsDisaster from "./Disasters/ContactsDisaster";
import ImmediateResponseSurvey from "./Disasters/ImmediateResponseSurvey";
import { db } from "../firebase";
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { getAuth } from 'firebase/auth';

const Disasters = ({ className = "" }) => {
  const [activeTab, setActiveTab] = useState("Immediate Response Survey");
  const { activeDisasterTab, setActiveDisasterTab } = useDisasterContext();
  const [selectedDisaster, setSelectedDisaster] = useState("All");

  const [selectedDisasters, setSelectedDisasters] = useState(["All"]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [disasterOptions, setDisasterOptions] = useState([{ id: "All", name: "All Disasters" }]);
  const [activeDisasters, setActiveDisasters] = useState([]);
  const dropdownRef = useRef(null);

  const [showBlur, setShowBlur] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDisasters = async () => {
      try {
        setIsLoading(true);
        const disastersQuery = query(collection(db, "declared-disaster"));
        const querySnapshot = await getDocs(disastersQuery);
        const fetchedDisasters = querySnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().disaster_name,
          status: doc.data().disaster_status
        }));
        
        const activeDisasters = fetchedDisasters.filter(disaster => disaster.status === "active");
        
        // Add a delay before updating showBlur
        setTimeout(() => {
          setShowBlur(activeDisasters.length === 0);
          setIsLoading(false);
        }, 1000); // 1 second delay

        // Update other state variables as needed
        setActiveDisasters(activeDisasters.map(disaster => disaster.id));
        setDisasterOptions([
          { id: "All", name: "All Disasters" },
          ...activeDisasters
        ]);
      } catch (error) {
        console.error("Error fetching disasters:", error);
        // If there's an error, we'll show the blur as a precaution
        setTimeout(() => {
          setShowBlur(true);
          setIsLoading(false);
        }, 1000); // 1 second delay
      }
    };

    fetchDisasters();
  }, []);

  const handleGoBack = () => {
    window.history.back();
  };

  const handleDisasterChange = (disaster) => {
    setSelectedDisasters((prev) => {
      if (disaster === "All") {
        return ["All"];
      }
      const newSelection = prev.includes(disaster)
        ? prev.filter((d) => d !== disaster && d !== "All")
        : [...prev.filter((d) => d !== "All"), disaster];
      return newSelection.length === 0 ? ["All"] : newSelection;
    });
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const auth = getAuth();
  const [caseNumber, setCaseNumber] = useState("");

  useEffect(() => {
    const fetchCaseNumber = async () => {
      if (auth.currentUser?.uid && selectedDisasters.length > 0) {
        // Clear case number if "All" is selected
        if (selectedDisasters.includes("All")) {
          setCaseNumber("");
          return;
        }
  
        try {
          // Get all survey responses for the user
          const surveyQuery = query(
            collection(db, `users/${auth.currentUser.uid}/survey_responses`)
          );
  
          const querySnapshot = await getDocs(surveyQuery);
          let foundCaseNumber = "";
  
          // Look for a survey that contains any of the selected disasters
          querySnapshot.forEach(doc => {
            const data = doc.data();
            if (data.disasters) {
              const surveyDisasterIds = data.disasters.map(d => d.disaster_id);
              
              // Check if any of the selected disasters are in this survey
              const selectedDisastersInSurvey = selectedDisasters.filter(id => 
                surveyDisasterIds.includes(id)
              );
  
              // If all selected disasters are in this survey response
              if (selectedDisastersInSurvey.length === selectedDisasters.length) {
                foundCaseNumber = data.case_number;
                console.log("Found case number that covers all selected disasters:", foundCaseNumber);
              }
            }
          });
  
          setCaseNumber(foundCaseNumber);
        } catch (error) {
          console.error("Error fetching case number:", error);
          setCaseNumber("");
        }
      } else {
        setCaseNumber("");
      }
    };
  
    fetchCaseNumber();
  }, [auth.currentUser?.uid, selectedDisasters]);

  const tabs = [
    "Immediate Response Survey",
    "Damage Assessment",
    "Assistance Log",
    "Recovery Plan",
    "Contacts",
    "Journals",
  ];
  const handleTabClick = (tab) => {
    setActiveDisasterTab(tab);
  };

  if (isLoading) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="text-2xl font-bold text-blue-500">Loading...</div>
      </div>
    );
  }

  if (showBlur) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="flex flex-col items-center justify-center rounded-lg p-8 ">
          <div className="flex w-full flex-col items-center justify-center gap-2 space-x-4">
            <p className="text-[40px] font-bold text-[#348BFF]">
              This section will be activated when a disaster is declared
            </p>
            <button
              onClick={handleGoBack}
              className="mx-auto flex w-24 cursor-pointer items-center justify-center rounded-lg border border-blue-500 bg-white px-4 py-2 text-center text-blue-500"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
  <div
    className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
  >
    <div className="mb-4 flex flex-row items-center justify-between w-full">
      <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5 mt-3">
          <a className="relative inline-block min-w-[80px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Recovery Plan
          </a>
          <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <a className="relative inline-block min-w-[55px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Disasters
          </a>
          <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <a className="relative inline-block min-w-[120px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
              {activeDisasterTab}
          </a>
      </div>
      
      {/* Legend */}
      <div className="flex items-center space-x-4 text-xs">
        <div className="flex items-center">
          <span className="w-6 h-6 rounded-full bg-green-500 mr-1 flex items-center justify-center text-white font-bold">C</span>
          <span>Center</span>
        </div>
        <div className="flex items-center">
          <span className="w-6 h-6 rounded-full bg-yellow-500 mr-1 flex items-center justify-center text-white font-bold">P</span>
          <span>Partner</span>
        </div>
        <div className="flex items-center">
          <span className="w-6 h-6 rounded-full bg-blue-500 mr-1 flex items-center justify-center text-white font-bold">S</span>
          <span>Survivor</span>
        </div>
      </div>
    </div>
  
      {/* Disaster selector and tabs */}
      <div className="flex max-w-full flex-row items-center justify-between gap-5 self-stretch mq1600:flex-wrap">
        {/* Disaster selector */}
        {/* <div className="relative flex items-center gap-2" ref={dropdownRef}>
          <span className="text-sm font-bold text-red-500">DISASTERS</span>
          <div className="relative">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="flex items-center justify-between rounded border border-dodgerblue px-4 py-1 text-sm w-[450px] relative"
            >
              <span className="absolute inset-0 flex items-center justify-center">
                <span className="truncate px-6">
                  {selectedDisasters.includes("All") 
                    ? "All Disasters" 
                    : selectedDisasters.length > 0
                      ? disasterOptions
                          .filter(disaster => selectedDisasters.includes(disaster.id))
                          .map(disaster => disaster.name)
                          .join(", ")
                      : "Select Disasters"}
                </span>
              </span>
              <span className="ml-auto">▼</span>
            </button>
            {isDropdownOpen && (
              <div className="absolute left-0 right-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-10">
                {disasterOptions.map((disaster) => (
                  <label key={disaster.id} className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={selectedDisasters.includes(disaster.id)}
                      onChange={() => handleDisasterChange(disaster.id)}
                      className="mr-2"
                    />
                    {disaster.name}
                  </label>
                ))}
              </div>
            )}
          </div>
        </div> */}
<div className="relative flex items-center gap-2" ref={dropdownRef}>
  <span className="text-sm font-bold text-red-500">DISASTERS</span>
  <div className="relative">
    <button
      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      className="flex items-center justify-between rounded border border-dodgerblue px-4 py-1 text-sm w-[450px] relative"
    >
      <span className="absolute inset-0 flex items-center justify-center">
        <span className="truncate px-6">
          {selectedDisasters.length > 0
            ? disasterOptions
                .find(disaster => disaster.id === selectedDisasters[0])?.name
            : "Select a Disaster"}
        </span>
      </span>
      <span className="ml-auto">▼</span>
    </button>
    {isDropdownOpen && (
      <div className="absolute left-0 right-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-10">
        {disasterOptions
          .filter(disaster => disaster.id !== "All")
          .map((disaster) => (
            <div
              key={disaster.id}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-center"
              onClick={() => {
                setSelectedDisasters([disaster.id]);
                setIsDropdownOpen(false);
              }}
            >
              {disaster.name}
            </div>
          ))}
      </div>
    )}
  </div>
</div>
        {/* Tabs */}
        <div className="flex flex-wrap gap-2 text-xs">
          {tabs.map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={`rounded-full px-4 py-1 ${
                activeDisasterTab === tab
                  ? "bg-dodgerblue text-white"
                  : "border border-dodgerblue text-dodgerblue hover:bg-blue-100"
              }`}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      <div className="relative mt-4 inline-block max-w-full font-poppins font-medium leading-[21px] text-darkslategray-200">
        {/* <p className="m-0 text-sm font-medium">
          Below are the essential tools at your disposal to effectively track
          your disaster recovery journey.
        </p>
        <p className="m-0 text-sm font-medium">
          Utilize the Damage Assessment tool to record losses, document
          assistance received in the Assistance Log, and track your progress
          towards recovery by monitoring your remaining deficit in the Recovery
          Plan.
        </p> */}
      </div>
      {caseNumber && (
      <div className="w-full bg-blue-100 p-4 rounded-md">
        <p className="text-lg font-semibold text-blue-800">
          Your case number for {selectedDisasters.length > 1 ? 'these disasters' : 'this disaster'} is: 
          <span className="text-xl font-bold"> {caseNumber}</span>
        </p>
        <p className="text-sm text-blue-600 mt-1">
          {selectedDisasters.length > 1 
            ? 'This case number covers all selected disasters' 
            : ''}
        </p>
      </div>
    )}
      {activeDisasterTab === "Immediate Response Survey" && (
        <ImmediateResponseSurvey 
        selectedDisasters={selectedDisasters} 
        activeDisasters={activeDisasters}
        parentSetCaseNumber={setCaseNumber} />
      )}
      {activeDisasterTab === "Damage Assessment" && (
        <DisasterManagement 
          selectedDisasters={selectedDisasters} 
          activeDisasters={activeDisasters}
          disasterOptions={disasterOptions.filter(d => d.id !== "All")}
        />
      )}
      {activeDisasterTab === "Assistance Log" && (
        <AssistanceLog selectedDisasters={selectedDisasters} 
        activeDisasters={activeDisasters} />
      )}
      {activeDisasterTab === "Recovery Plan" && (
        <RecoveryPlanDisasters 
          selectedDisasters={selectedDisasters} 
          activeDisasters={activeDisasters}
        />
      )}
      {activeDisasterTab === "Contacts" && <ContactsDisaster />}
      {activeDisasterTab === "Journals" && <Journals />}

      
    </div>
  );
};

ImmediateResponseSurvey.propTypes = {
  className: PropTypes.string,
};

export default Disasters;
