const loadEnv = (key) => {
  const value = process.env[`REACT_APP_${key}`];
  if (!value) {
    console.error(`Environment variable REACT_APP_${key} is not set.`);
  }
  return value;
};
export const config = {
  google_maps: loadEnv("GOOGLE_MAPS_API_KEY"),
};
