import { db, auth } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  setDoc,
  query,
  where,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";

const userService = {
  createAuthAndMoveUserDocument: async (tempUserId, email, password) => {
    try {
      // Create Firebase Authentication user
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const uid = userCredential.user.uid;

      // Get the temporary user document
      const tempUserDocRef = doc(db, "users", tempUserId);
      const tempUserDoc = await getDoc(tempUserDocRef);

      if (!tempUserDoc.exists()) {
        throw new Error("Temporary user document does not exist.");
      }

      // Copy the data to a new document with the UID from Firebase Authentication
      const newUserDocRef = doc(db, "users", uid);
      await setDoc(newUserDocRef, { ...tempUserDoc.data(), uid });

      console.log("User document copied to new ID:", uid);

      // Step 3: Delete the temporary user document
      await deleteDoc(tempUserDocRef);
      console.log("Temporary user document deleted.");

      return uid;
    } catch (error) {
      console.error("Error during authentication and Firestore update:", error);
    }
  },

  checkIfEmailExists: async (email) => {
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty; // Returns true if email exists
  },

  getUserByEmail: async (email) => {
    try {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        return { id: userDoc.id, ...userDoc.data() };
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error getting user by email:", error);
      return null;
    }
  },

  getUserById: async (userId) => {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        return { id: userDoc.id, ...userDoc.data() };
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error getting user:", error);
    }
  },

  createUser: async (userData) => {
    try {
      const usersRef = collection(db, "users");
      const userDocRef = await addDoc(usersRef, userData);
      return userDocRef.id;
    } catch (error) {
      console.error("Error creating user:", error);
    }
  },

  createUserWithUID: async (uid, userData) => {
    try {
      const userDocRef = doc(db, "users", uid);
      await setDoc(userDocRef, userData);
      return uid;
    } catch (error) {
      console.error("Error creating user:", error);
    }
  },

  updateUser: async (userId, updatedData) => {
    try {
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, updatedData);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  },

  deleteUser: async (userId) => {
    try {
      const userDocRef = doc(db, "users", userId);
      await deleteDoc(userDocRef);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  },

  // Organizations subcollection under users table

  getOrganizationById: async (userId, orgId) => {
    try {
      const orgDocRef = doc(db, `users/${userId}/organizations`, orgId);
      const orgDoc = await getDoc(orgDocRef);
      if (orgDoc.exists()) {
        return { id: orgDoc.id, ...orgDoc.data() };
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error getting organization:", error);
    }
  },

  createOrganization: async (userId, organizationData) => {
    try {
      const orgsRef = collection(db, `users/${userId}/organizations`);
      const orgDocRef = await addDoc(orgsRef, organizationData);
      return orgDocRef.id;
    } catch (error) {
      console.error("Error creating organization:", error);
    }
  },

  createOrganizationWithOrgId: async (userId, orgId, organizationData) => {
    try {
      const orgDocRef = doc(db, `users/${userId}/organizations`, orgId);
      await setDoc(orgDocRef, organizationData);
      return orgId;
    } catch (error) {
      console.error("Error creating organization:", error);
    }
  },

  updateOrganization: async (userId, orgId, updatedData) => {
    try {
      const orgDocRef = doc(db, `users/${userId}/organizations`, orgId);
      await updateDoc(orgDocRef, updatedData);
    } catch (error) {
      console.error("Error updating organization:", error);
    }
  },

  deleteOrganization: async (userId, orgId) => {
    try {
      const orgDocRef = doc(db, `users/${userId}/organizations`, orgId);
      await deleteDoc(orgDocRef);
    } catch (error) {
      console.error("Error deleting organization:", error);
    }
  },
};

export default userService;
