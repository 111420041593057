import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const SurveyQuestion = ({ question, type, options, onResponseChange, userResponse }) => {
  const [checkedItems, setCheckedItems] = useState({});
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    // Initialize state based on userResponse
    if (type === 'checkbox') {
      setCheckedItems(userResponse || {});
    } else if (type === 'text') {
      setInputValue(userResponse || "");
    }
  }, [userResponse, type]);

  const handleCheckboxChange = (option) => {
    const newCheckedItems = {
      ...checkedItems,
      [option]: !checkedItems[option]
    };
    setCheckedItems(newCheckedItems);
    onResponseChange(question, newCheckedItems);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    onResponseChange(question, value);
  };

  const handleRadioChange = (option) => {
    onResponseChange(question, option);
  };

  const renderCheckboxes = () => {
    // Check for specific question to apply different styling
    if (question === "Do you have insurance on your home or contents?") {
      return (
        <div className="flex flex-col gap-2">
          {options.map((option, index) => (
            <label key={index} className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="form-checkbox h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                checked={checkedItems[option] || false}
                onChange={() => handleCheckboxChange(option)}
              />
              <span className="ml-2 text-sm text-gray-700">{option}</span>
            </label>
          ))}
        </div>
      );
    } else if (question === "There is visible damage to the home's:") {
      // Render grid layout for the specific checkbox question
      return (
        <div className="grid grid-cols-3 gap-4">
          {options.map((option, index) => (
            <label key={index} className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="form-checkbox h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                checked={checkedItems[option] || false}
                onChange={() => handleCheckboxChange(option)}
              />
              <span className="ml-2 text-sm text-gray-700">{option}</span>
            </label>
          ))}
        </div>
      );
    }
    // Default case for other checkbox questions
    return (
      <div className="flex flex-col gap-2">
        {options.map((option, index) => (
          <label key={index} className="flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="form-checkbox h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
              checked={checkedItems[option] || false}
              onChange={() => handleCheckboxChange(option)}
            />
            <span className="ml-2 text-sm text-gray-700">{option}</span>
          </label>
        ))}
      </div>
    );
  };

  const renderRadioButtons = () => {
    return options.map((option, index) => (
      <label key={index} className="flex items-start w-full cursor-pointer mb-2">
        <input
          className="form-radio h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500 appearance-none"
          type="radio"
          name={question.replace(/\s+/g, '-').toLowerCase()}
          value={option}
          checked={userResponse === option}
          onChange={() => handleRadioChange(option)}
        />
        <span className="ml-2 text-sm text-gray-800 break-words">{option}</span>
      </label>
    ));
  };

  return (
    <div className="w-full flex flex-col shadow-[0px_4px_4px_-1px_rgba(12,_12,_13,_0.1),_0px_4px_4px_-1px_rgba(12,_12,_13,_0.05)] rounded-mini bg-ghostwhite-100 border-gainsboro-300 border-[0.5px] border-solid box-border overflow-hidden">
      <div className="flex-shrink-0 self-stretch relative font-semibold text-sm text-darkslategray-100 break-words pl-6 pr-6 pt-6 pb-3 bg-gray-100">
        {question}
      </div>
      <div className="flex-grow self-stretch pl-6 pr-6 pb-6">
        {type === 'text' ? (
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder={options[0] || ''}
            className="w-full p-2 border border-gray-300 rounded"
          />
        ) : type === 'checkbox' ? (
          renderCheckboxes()
        ) : (
          renderRadioButtons()
        )}
      </div>
    </div>
  );
};

SurveyQuestion.propTypes = {
  question: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['radio', 'checkbox', 'text']).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onResponseChange: PropTypes.func.isRequired,
  userResponse: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Accept string for text and object for checkboxes
};

export default SurveyQuestion;