import React, { useState, useEffect, useRef } from "react";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Filter,
  Sort,
  Page,
  CommandColumn,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { USStates } from "./datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from "../firebase"; // Import Firebase config
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import "./style.css";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const editOptions = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",
  dialog: { cssClass: "custom-dialog" },
};

const sortSettings = {
  allowSorting: true,
};
const filterSettings = {
  type: "CheckBox",
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const preprocessData = (data) => {
  return data.map((item) => ({
    ...item,
    Name: `${item.first_name} ${item.last_name}`,
    Address: `${item.address1} ${item.address2} ${item.city} ${item.state} ${item.zip}`,
  }));
};

const CommunityContacts = ({ className = "" }) => {
  const [commContacts, setCommContacts] = useState([]);
  const [loading, setLoading] = useState(true); // New state for loading
  const gridRef = useRef(null);
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [userId, setUserId] = useState(null);
  const [centerId, setCenterId] = useState(null); // New state for center_id

  useEffect(() => {
    const fetchContacts = async () => {
      setLoading(true); // Set loading to true before fetching
      if (centerId) {
        // Only fetch contacts if centerId is available
        const querySnapshot = await getDocs(
          collection(db, "community-contacts")
        );
        const contactsData = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((contact) => contact.contactID === centerId); // Filter by centerId
        setCommContacts(preprocessData(contactsData));
      }
      setLoading(false); // Set loading to false after fetching
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid);

        // Fetch user data to get center_id
        const userDoc = await getDoc(doc(db, "centers", user.uid));
        if (userDoc.exists()) {
          setCenterId(userDoc.data().center_id); // Store center_id
        }

        fetchContacts(); // Fetch contacts after setting centerId
      } else {
        setUserId(null);
        setCommContacts([]);
        setCenterId(null); // Reset centerId on logout
      }
    });

    return () => unsubscribe();
  }, [centerId]); // Dependency on centerId

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  const actionBegin = async (args) => {
    if (gridRef.current) {
      try {
        if (args.requestType === "beginEdit" || args.requestType === "add") {
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "first_name",
                "last_name",
                "address1",
                "address2",
                "city",
                "state",
                "zip",
              ].includes(col.field)
            ) {
              col.visible = true;
            } else if (["Name", "Address"].includes(col.field)) {
              col.visible = false;
            }
          }
        }
        if (args.requestType === "save") {
          setLastEditedTime(new Date());
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "first_name",
                "last_name",
                "address1",
                "address2",
                "city",
                "state",
                "zip",
              ].includes(col.field)
            ) {
              col.visible = false;
            } else if (["Name", "Address"].includes(col.field)) {
              col.visible = true;
            }
          }

          // Update Name and Address fields
          const data = args.data;
          data.Name = `${data.first_name} ${data.last_name}`;
          data.Address = `${data.address1 || ""} ${data.address2 || ""} ${
            data.city || ""
          } ${data.state || ""} ${data.zip || ""}`;

          // Remove undefined fields
          Object.keys(data).forEach((key) => {
            if (data[key] === undefined) {
              delete data[key];
            }
          });

          if (args.action === "add") {
            // Adding new document
            await addDoc(collection(db, "community-contacts"), {
              ...data,
              contactID: data.contactID || generateContactID(), // Ensure contactID is included
              last_updated: serverTimestamp(),
              inserted_at: serverTimestamp(),
              user_id: userId,
            });
          } else if (args.action === "edit") {
            // Updating existing document
            if (data.id) {
              const contactDoc = doc(db, "community-contacts", data.id);
              await updateDoc(contactDoc, {
                ...data,
                contactID: data.contactID, // Ensure contactID is included
                last_updated: serverTimestamp(),
              });
            } else {
              console.error("Document ID is missing for update.");
            }
          }
        }
        if (args.requestType === "delete") {
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "first_name",
                "last_name",
                "address1",
                "address2",
                "city",
                "state",
                "zip",
              ].includes(col.field)
            ) {
              col.visible = false;
            } else if (["Name", "Address"].includes(col.field)) {
              col.visible = true;
            }
          }
          const data = args.data[0];
          if (data.id) {
            const contactDoc = doc(db, "community-contacts", data.id);
            await deleteDoc(contactDoc);
          } else {
            console.error("Document ID is missing for delete.");
          }
        }
      } catch (error) {
        console.error("Error during actionBegin:", error);
      }
    }
  };

  // Helper function to generate a unique contactID if not provided
  const generateContactID = () => {
    return `CID-${Math.random().toString(36).substr(2, 9)}`;
  };

  return (
    <>
      <div
        className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
      >
        <div id="dialog-container"></div>
        <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
          <a className="relative inline-block min-w-[100px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Local Community
          </a>
          <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <a className="relative inline-block min-w-[77px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Community Contacts
          </a>
        </div>
      </div>
      <div className="ml-3 flex flex-col items-start justify-start">
        <h2 className="mb-1 text-sm font-bold text-blue-500">
          COMMUNITY CONTACTS
        </h2>
        <div className="relative text-xs leading-[21px] text-darkgray">
          Last Edited: {formatLastEditedTime(lastEditedTime)}
        </div>
        <section className="w-full rounded-3xs pb-5 pr-7 pt-[14px] text-left text-xs text-darkslateblue">
          <div>
            {loading ? ( // Conditional rendering for loading state
              <div className="text-center">Loading...</div> // Loading message or spinner
            ) : (
              <GridComponent
                dataSource={commContacts}
                filterSettings={filterSettings}
                allowFiltering={true}
                allowSorting={true}
                ref={gridRef}
                sortSettings={sortSettings}
                allowPaging={true}
                pageSettings={pageSettings}
                showColumnMenu={true}
                columnMenuItems={columnMenuItems}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="contactID"
                    headerText="Contact ID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="first_name"
                    headerText="First Name"
                    visible={false}
                  />
                  <ColumnDirective
                    field="last_name"
                    headerText="Last Name"
                    visible={false}
                  />
                  <ColumnDirective field="Name" headerText="Name" width="150" />
                  <ColumnDirective
                    field="occupation"
                    headerText="Occupation"
                    width="100"
                  />
                  <ColumnDirective
                    field="mobileNumber"
                    headerText="Mobile Number"
                    width="100"
                  />
                  <ColumnDirective
                    field="email"
                    headerText="Email Address"
                    width="150"
                  />
                  <ColumnDirective
                    field="Address"
                    headerText="Address"
                    width="250"
                  />
                  <ColumnDirective
                    field="address1"
                    headerText="Address 1"
                    visible={false}
                  />
                  <ColumnDirective
                    field="address2"
                    headerText="Address 2"
                    visible={false}
                  />
                  <ColumnDirective
                    field="city"
                    headerText="City"
                    visible={false}
                  />
                  <ColumnDirective
                    field="state"
                    headerText="State"
                    visible={false}
                    editType="dropdownedit"
                    edit={{
                      params: {
                        dataSource: USStates,
                        fields: { text: "abbreviation", value: "abbreviation" },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="zip"
                    headerText="Zip"
                    visible={false}
                  />
                </ColumnsDirective>
                <Inject
                  services={[Filter, Sort, Page, CommandColumn, ColumnMenu]}
                />
              </GridComponent>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default CommunityContacts;
